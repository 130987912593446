import styled from '@emotion/styled';
import isEqual from 'lodash.isequal';
import React from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

export class TreeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = (nextProps) => {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  };

  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps.data, this.state.data);
  };

  onChange = ({ selectedNodes, onChange }) => {
    let products;
    products = selectedNodes.map((prod) => {
      if (Array.isArray(prod.Product))
        return prod.Product.map((d) => d.idProduct);
      return prod.idProduct;
    });
    products = products.flat(1);
    onChange(null, products);
  };

  render() {
    const { onChange, disabled, ...rest } = this.props;
    return (
      <Container>
        <DropdownTreeSelect
          disabled={disabled}
          data={this.state.data}
          onChange={(_, selectedNodes) => {
            this.onChange({ selectedNodes, onChange });
          }}
          {...rest}
          texts={{
            placeholder: this.props.placeholder,
          }}
          className="mdl-demo"
        />
      </Container>
    );
  }
}

const Container = styled.div`
  .tag-item .search {
    border-bottom: 0;
  }

  .react-dropdown-tree-select .dropdown {
    width: 100%;
  }

  .mdl-demo ul,
  .mdl-demo .dropdown-trigger,
  .tag-item,
  .tag-item input {
    width: 100%;
    display: flex;
  }

  .mdl-demo .arrow {
    transform: rotate(0) !important;
    &:after {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .mdl-demo .node-label {
    font-size: 1.4rem !important;
    margin: 0 8px;
  }

  .mdl-demo .dropdown-content {
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
  }

  .mdl-demo {
    direction: ${({ isRTL }) => (isRTL ? 'rtl' : 'ltr')} !important;
    direction: ltr !important;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    font-size: 12px !important;
  }
`;
