import styled from '@emotion/styled';
import { mq } from '../../../utils';

export const Layout = styled.div<{ iconBColor: string; isInfo: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p,
  h1,
  h2,
  h3 {
    white-space: nowrap;
  }

  p {
    color: #8181a5;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 0.4em;
  }

  h3 {
    font-weight: 600;
  }

  .icon-left {
    margin-right: 1em;
  }

  .icon-right {
    margin-left: 1em;
  }

  .box,
  .left-box {
    display: flex;
  }
  .box {
    width: 100%;
    justify-content: space-between;
  }

  li {
    padding: 0 !important;
  }

  ${mq('smalllabtop')} {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;

    > * {
      margin-bottom: 0.8em;
    }

    .icon-right {
      margin-left: 0;
    }
  }
`;
