import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { mainColor } from '../../../utils';

interface Props {
  padding?: string;
  isFilled?: boolean;
  isCenter: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid #eee',
      },
      '& .MuiInput-underline:hover:before': {
        borderBottom: `1px solid ${mainColor} !important`,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${mainColor} !important`,
      },
    },
    select: {
      backgroundColor: ({ isFilled }: Props) =>
        isFilled ? '#F5F5FA !important' : '',
      fontSize: 15,
      padding: (props: Props) => (props.padding ? props.padding : '5px 0'),
      fontWeight: 400,

      '& .MuiSelect-select.MuiSelect-select': {
        textAlign: (props) =>
          props.isCenter
            ? 'center'
            : theme.direction === 'ltr'
            ? 'left'
            : 'right',
        padding: '10px',
      },
    },

    label: {
      color: '#292222 !important',
      fontWeight: 400,
      whiteSpace: 'nowrap',

      [theme.breakpoints.down('md')]: {
        whiteSpace: 'break-spaces;',
      },
    },
    paper: {
      fontSize: 15,
    },

    async: {
      '& .MuiAutocomplete-input': {
        textAlign: 'center',
        fontSize: '15px',
        fontWeight: 400,
      },
      '.MuiInput-underline::after': {
        borderBottom: `1px solid ${mainColor} !important`,
      },
      '& .MuiAutocomplete-input:first-child': {
        padding: (props: { padding?: string }) =>
          props.padding ? props.padding : '8px',
      },
      '& label': {
        transform: 'scale(1) !important',
      },
    },
  })
);
