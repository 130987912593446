import styled from '@emotion/styled';
import React from 'react';
import useSWR from 'swr';
import { uuid } from 'uuidv4';

import {
  InfoBox,
  MemberBox,
  StatisticsLoader,
} from '../../../components/Common';

export const Statistics = () => {
  const { data: { data: merchatData } = { data: [[], []] }, error }: any =
    useSWR('/merchant');

  const {
    data: { data: incomeData } = { data: [[], []] },
    icomeDataError,
  }: any = useSWR('/merchant/income-stats');

  if (error)
    return (
      <h3 className="title">
        Opps!!!!, {JSON.stringify(error)} please try again later
      </h3>
    );

  return (
    <Layout>
      {Array.isArray(merchatData) ? (
        <>
          <InfoContainer numberOfBox={3} mrBottom minWidth={140}>
            {merchatData[0].map((item: any) => (
              <InfoBox key={uuid()} {...item} />
            ))}
          </InfoContainer>

          <InfoContainer numberOfBox={2} minWidth={285}>
            {merchatData[1].map((item: any) => (
              <MemberBox key={uuid()} {...item} />
            ))}
            {Array.isArray(incomeData) && (
              <MemberBox key={uuid()} {...incomeData[0]} withAllPeriod />
            )}
          </InfoContainer>
        </>
      ) : (
        <StatisticsLoader />
      )}
    </Layout>
  );
};

const Layout = styled.div`
  max-width: 100vw;
`;

const InfoContainer = styled.div<{
  numberOfBox: number;
  mrBottom?: boolean;
  minWidth?: number;
}>`
  display: grid;
  grid-template-columns: ${({ minWidth = 280 }) =>
    `repeat(auto-fit, minmax(${minWidth}px, 1fr))`};
  grid-gap: 1em;

  margin-bottom: ${({ mrBottom }) => mrBottom && '1em'};
`;
