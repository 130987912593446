import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { RouteComponentProps, useNavigate } from '@reach/router';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { Pagination } from '../../components/Common/PaginationV2';
import styles from './index.module.css';

import { useGlobalState } from '../../components/GlobalState';

interface Props extends RouteComponentProps {}

export const List: FC<Props> = () => {
  const { language } = useGlobalState();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { data } = useSWR(`/campaign/list/${page}`);
  const navigate = useNavigate();

  return (
    <div>
      <Paper
        elevation={0}
        style={{
          padding: 23,
          height: 70,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: 18,
            fontWeight: 400,
            color: '#292222',
          }}
        >
          {t('campaign')}
        </p>
      </Paper>

      <div className={styles.campaignHeader}>
        <p
          style={{
            fontSize: 17,
            fontWeight: 400,
            color: '#292222',
          }}
        >
          {t('campaign:created-campaigns')}
        </p>
        <Button
          style={{ fontSize: 14, fontWeight: 400, padding: '5px 18px' }}
          size="small"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate('/campaign/new')}
        >
          {t('campaign:create')}
        </Button>
      </div>

      <Table
        style={{
          borderCollapse: 'separate',
          borderSpacing: '0px 5px',
        }}
        size="small"
        aria-label="simple table"
      >
        <TableHead style={{ marginBottom: 10, borderRadius: 5 }}>
          <TableRow
            className="campaign-list"
            style={{
              backgroundColor: '#8181A5',
            }}
          >
            <TableCell
              style={{
                color: 'white',
                fontSize: 11,
                borderBottom: 'none',
                maxHeight: 50,
              }}
              align="justify"
            >
              {t('campaignName')}
            </TableCell>
            <TableCell
              style={{
                color: 'white',
                fontSize: 11,
                borderBottom: 'none',
                maxHeight: 50,
              }}
              align="center"
            >
              {t('created_at')}
            </TableCell>
            <TableCell
              style={{
                color: 'white',
                fontSize: 11,
                borderBottom: 'none',
                maxHeight: 50,
              }}
              align="center"
            >
              {t('num_of_customers')}
            </TableCell>
            <TableCell
              style={{
                color: 'white',
                fontSize: 11,
                borderBottom: 'none',
                maxHeight: 50,
              }}
              align="center"
            >
              {t(`${language === 'ar' ? 'type_ar' : 'type_en'}`)}
            </TableCell>
            <TableCell
              style={{
                color: 'white',
                fontSize: 11,
                borderBottom: 'none',
                maxHeight: 50,
              }}
              align="center"
            >
              {t('usedCoupons')}
            </TableCell>
            <TableCell
              style={{
                color: 'white',
                fontSize: 11,
                borderBottom: 'none',
                maxHeight: 50,
              }}
              align="center"
            >
              {t('generatedIncome')}
            </TableCell>
            <TableCell
              style={{
                color: 'white',
                fontSize: 11,
                borderBottom: 'none',
                maxHeight: 50,
              }}
              align="center"
            >
              {t('status')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.data?.map((campaign: any) => (
            <TableRow
              className="campaign-list"
              style={{
                backgroundColor: 'white',
              }}
              key={campaign.idOnlineMerchantCampaign}
            >
              <TableCell
                style={{
                  borderBottom: 'none',
                  fontSize: 15,
                  color: '#1A1818',
                  padding: 16,
                }}
                component="th"
                scope="row"
                align="justify"
              >
                {campaign.campaignName}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: 'none',
                  fontSize: 15,
                  color: '#1A1818',
                  padding: 16,
                }}
              >
                {new Date(campaign.created_at).toLocaleDateString(language)}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: 'none',
                  fontSize: 15,
                  color: '#1A1818',
                  padding: 16,
                }}
              >
                {campaign.num_of_customers}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: 'none',
                  fontSize: 15,
                  color: '#1A1818',
                  padding: 16,
                }}
              >
                {language === 'ar' ? campaign.type_ar : campaign.type_en}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: 'none',
                  fontSize: 15,
                  color: '#1A1818',
                  padding: 16,
                }}
              >
                {campaign.usedCoupons}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: 'none',
                  fontSize: 15,
                  color: '#1A1818',
                  padding: 16,
                }}
              >
                {campaign.generatedIncome}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderBottom: 'none',
                  fontSize: 15,
                  color: '#1A1818',
                  padding: 16,
                }}
              >
                <div
                  style={{
                    color: campaign.status === 'sent' ? '#84C731' : '#E57E09',
                    borderRadius: '4px',
                    fontSize: '15px',

                    padding: '8px 13px',
                    backgroundColor:
                      campaign.status !== 'sent'
                        ? 'rgba(229,126,9,0.19)'
                        : 'rgba(109,210,48,0.19)',
                  }}
                >
                  {t(campaign.status)}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Paper
        elevation={0}
        style={{
          padding: 23,
          height: 70,
          display: 'flex',
          alignItems: 'center',
          marginTop: 40,
          justifyContent: 'center',
        }}
      >
        <Pagination
          count={data?.totalPages ?? 3}
          page={page}
          onChange={(_: React.ChangeEvent<unknown>, page: number) =>
            setPage(page)
          }
        />
      </Paper>
    </div>
  );
};
