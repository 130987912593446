import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AvgValueOrder } from '../../../../assets/svg/avg_value_order.svg';
import { ReactComponent as NumberOfOrdersICon } from '../../../../assets/svg/number_of_orders.svg';
import { ReactComponent as PointValid } from '../../../../assets/svg/point_valid.svg';
import { ReactComponent as RewardRedm } from '../../../../assets/svg/reward_redm.svg';
import { ReactComponent as TotalIcon } from '../../../../assets/svg/total_spent.svg';
import { Accordion } from '../../../Common';
import { useProfileStyles } from '../index';

export const CustomerAccordian = ({
  noOfOrders,
  noPointsValid,
  ordersAvg,
  rewardsRedeemed,
  totalSpent,
}) => {
  const { t } = useTranslation('segmentation');
  return (
    <Box px={4} style={{ position: 'relative' }}>
      <Accordion
        DisplayedChildren={
          <Grid container spacing={2}>
            <StatsBox
              title={t('noOrders')}
              value={Number(noOfOrders).toLocaleString('en-US')}
              icon={NumberOfOrdersICon}
            />
            <StatsBox
              title={t('totalSpent')}
              value={Number(totalSpent).toLocaleString('en-US')}
              icon={TotalIcon}
            />
            <StatsBox
              title={t('averageOrderValue')}
              value={Number(ordersAvg).toLocaleString('en-US')}
              icon={AvgValueOrder}
            />
            <StatsBox
              title={t('poinsNo_Valid')}
              value={noPointsValid
                .split(' / ')
                .map((number) => Number(number).toLocaleString('en-US'))
                .join(' / ')}
              icon={PointValid}
            />
            <StatsBox
              title={t('rewardsRedemption')}
              value={Number(rewardsRedeemed).toLocaleString('en-US')}
              icon={RewardRedm}
            />
          </Grid>
        }
        HiddenChildren={<Grid container spacing={3}></Grid>}
        expanded={true}
      />
    </Box>
  );
};

export const StatsBox = ({ title, value = 1000, icon: Icon }) => {
  const classes = useProfileStyles();
  return (
    <Grid item xs={6} md={6} lg className={classes.statsBox}>
      <Grid container spacing={2} component={Grid} textAlign="center">
        <Grid item xs={12}>
          <Icon />
        </Grid>
        <Grid item xs={12}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography variant="h4">{value}</Typography>
            <p style={{ fontSize: 12, minHeight: 15 }}>{title}</p>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
