import React, { createContext, FC, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { signoutAPI } from '../api';
import { User } from '../reducers';
import {
  getDiffDays,
  getLocal,
  NUMBER_OF_DAYS_TO_SUBSCRIPTION_END_NOTIFICATION,
  setLocal,
} from '../utils';

function canShowNotification(
  subscription: { end_date: string; idsubscription_status: number },
  numberOfRemainDaysForSubscription: number
) {
  const aboutToExpire =
    numberOfRemainDaysForSubscription <=
      NUMBER_OF_DAYS_TO_SUBSCRIPTION_END_NOTIFICATION &&
    subscription.idsubscription_status !== 4;

  const canShow =
    aboutToExpire ||
    subscription.idsubscription_status === 3 ||
    subscription.idsubscription_status === 4;

  return canShow;
}

const ALERT_TIME = 5000;

type AlertType = 'success' | 'info' | 'warning' | 'error' | null;

export type Language = 'ar' | 'en';

type Subscription = {
  end_date: string;
  idsubscription_status: number;
};

interface IGlobalState {
  user: User | null;
  language: Language;
  subscription: Subscription;
  isEnglish: boolean;
  isRtl: boolean;
  showNotification: boolean;
  alertText: string;
  alertType: AlertType;
}

enum GlobalActionType {
  SET_USER = 'SET_USER',
  RESET_USER = 'RESET_USER',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_SUBSCRIPTION = 'SET_SUBSCRIPTION',
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  SET_ALERT = 'SET_ALERT',
}

interface GlobalAction {
  type: GlobalActionType;
  payload?: any;
}

const intialState = {
  user: getLocal({ key: 'ebonat_user' }) as User | null,
  language: (localStorage.getItem('ebonat_lang') as Language | null) ?? 'ar',
  isRtl: localStorage.getItem('ebonat_lang')
    ? localStorage.getItem('ebonat_lang') === 'ar'
    : true,
  isEnglish: localStorage.getItem('ebonat_lang') === 'en',
  subscription: {
    end_date:
      (getLocal({ key: 'ebonat_user' }) as User | null)?.end_date ??
      new Date().toISOString(),
    idsubscription_status: 1,
  },
  showNotification: false,
  alertText: '',
  alertType: null as AlertType,
};

function globalStateReducer(state: IGlobalState, action: GlobalAction) {
  const { type, payload } = action;
  switch (type) {
    case GlobalActionType.SET_USER: {
      setLocal({ key: 'ebonat_user', value: JSON.stringify(payload, null, 2) });

      return {
        ...state,
        user: payload as User | null,
        ...((payload as User | null)?.end_date && {
          subscription: {
            end_date: payload.end_date,
            idsubscription_status: payload.idsubscription_status,
          },
        }),
      };
    }
    case GlobalActionType.RESET_USER: {
      signoutAPI();
      setLocal({ key: 'ebonat_user' });
      localStorage.setItem('isNavigationExpand', JSON.stringify(true));

      return {
        ...state,
        user: null,
      };
    }
    case GlobalActionType.SET_LANGUAGE: {
      localStorage.setItem('ebonat_lang', payload);

      return {
        ...state,
        language: payload as Language,
        isRtl: payload === 'ar',
        isEnglish: payload === 'en',
      };
    }
    case GlobalActionType.SET_SUBSCRIPTION: {
      return {
        ...state,
        subscription: payload as Subscription,
      };
    }
    case GlobalActionType.SET_NOTIFICATION: {
      return {
        ...state,
        showNotification: payload as boolean,
      };
    }
    case GlobalActionType.SET_ALERT: {
      return {
        ...state,
        alertText: payload.alertText as string,
        alertType: payload.alertType as AlertType,
      };
    }
    default:
      return state;
  }
}

export const GlobalState = createContext({
  ...intialState,
  setUser: (user: User) => {},
  resetUser: () => {},
  setLanguage: (language: Language) => {},
  setSubscription: (subscription: Subscription) => {},
  setAlert: (alertText: string, type: AlertType) => {},
  updateUser: (cb: (user: User | null) => User) => {},
});

export const GlobalStateProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, intialState);
  const { i18n } = useTranslation();

  const setUser = (user: User) =>
    dispatch({ type: GlobalActionType.SET_USER, payload: user });

  const updateUser = (cb: (user: User | null) => User) => {
    setUser(cb(state.user));
  };

  const setNotification = (show: boolean) =>
    dispatch({ type: GlobalActionType.SET_NOTIFICATION, payload: show });

  const resetUser = () => dispatch({ type: GlobalActionType.RESET_USER });

  const setLanguage = (language: Language) =>
    dispatch({ type: GlobalActionType.SET_LANGUAGE, payload: language });

  const setSubscription = (subscription: Subscription) =>
    dispatch({
      type: GlobalActionType.SET_SUBSCRIPTION,
      payload: subscription,
    });

  const setAlert = (alertText: string, alertType: AlertType) => {
    dispatch({
      type: GlobalActionType.SET_ALERT,
      payload: { alertText, alertType },
    });

    setTimeout(() => {
      dispatch({
        type: GlobalActionType.SET_ALERT,
        payload: { alertText: '', alertType: null },
      });
    }, ALERT_TIME);
  };

  useEffect(() => {
    if (i18n.language !== state.language) {
      i18n.changeLanguage(state.language);
    }
  }, [i18n, state.language]);

  useEffect(() => {
    const today = new Date();

    const numberOfRemainDaysForSubscription: any = getDiffDays({
      start: state.subscription.end_date
        ? new Date(state.subscription.end_date)
        : today,
      end: today,
    });

    setNotification(
      canShowNotification(state.subscription, numberOfRemainDaysForSubscription)
    );
  }, [state.subscription]);

  return (
    <GlobalState.Provider
      value={{
        ...state,
        setUser,
        resetUser,
        setLanguage,
        setSubscription,
        setAlert,
        updateUser,
      }}
    >
      {children}
    </GlobalState.Provider>
  );
};

export const useGlobalState = () => React.useContext(GlobalState);
