import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { RouteComponentProps, useNavigate } from '@reach/router';
import arLocale from 'date-fns/locale/ar';
import enLocale from 'date-fns/locale/en-US';
import { Formik } from 'formik';
import React, { FC, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EmailEditor from 'react-email-editor';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'react-select';
import useSWR from 'swr';
import * as yup from 'yup';
import { postNewCampaignAPI } from '../../api/campaign';
import { Button, TextInput } from '../../components/Common';
import { IOSSwitch } from '../../components/Common/Toggle/styled';
import { useGlobalState } from '../../components/GlobalState';
import { usePost } from '../../utils';
import { Steps } from './steps';
import template from './template.json';

registerLocale('en', enLocale);
registerLocale('ar', arLocale);

interface Props extends RouteComponentProps {}

export const Create: FC<Props> = () => {
  const [view, setView] = useState(0);
  const { data } = useSWR('/segment/list');
  const { user, isEnglish } = useGlobalState();
  const emailEditorArabicRef = useRef<any>(null);
  const emailEditorEnglishRef = useRef<any>(null);
  const navigate = useNavigate();

  const exportArabicHtml = () =>
    new Promise((resolve) => {
      emailEditorArabicRef.current.editor.exportHtml((data: any) => {
        resolve(data);
      });
    });

  const accessDenied = !user?.permissions?.['CampaignModule']?.['Edit'];

  const exportEnglishHtml = () =>
    new Promise((resolve) => {
      emailEditorEnglishRef.current.editor.exportHtml((data: any) => {
        resolve(data);
      });
    });

  const { t } = useTranslation();

  const [{ loading }, submitForm] = usePost({
    apiFunction: postNewCampaignAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const rewardTypes = [
    {
      arName: 'خصم مبلغ ثابت',
      enName: 'Fixed Amount Discount',
      value: 1,
    },
    { arName: 'خصم نسبة مئوية', enName: 'Percentage Discount', value: 2 },
    { arName: 'شحن مجاني', enName: 'Free Shipping', value: 3 },
  ];

  const validationSchema = yup.object({
    campaignName: yup.string().required(t('requiredField')),
    targetCustomers: yup
      .array()
      .of(yup.string())
      .min(1, t('minNumberValidation') + '1')
      .required(t('requiredField')),
    couponType: yup.number().required(t('requiredField')),
    couponValue: yup
      .number()
      .nullable()
      .notRequired()
      .when('couponType', {
        is: (couponType) => couponType == '1' || couponType == '2',
        then: yup
          .number()
          .min(1, t('minNumberValidation') + '1')
          .required(t('requiredField')),
      })
      .when('couponType', {
        is: (couponType) => couponType == '2',
        then: yup
          .number()
          .max(100, t('maxNumberValidation') + '100')
          .min(1, t('minNumberValidation') + '1')
          .required(t('requiredField')),
      }),
    isExcludeDiscountedProduct: yup.bool().required(t('requiredField')),
    sendNow: yup.bool().required(t('requiredField')),
    sendTime: yup.date().when('sendNow', {
      is: false,
      then: yup
        .date()
        .min(dateValidation(), t('date-past-error'))
        .required(t('requiredField')),
    }),
    subjectLine: yup.string().required(t('requiredField')),
    senderName: yup.string().required(t('requiredField')),
    replyTo: yup.string().email(t('invalidEmail')).required(t('requiredField')),
  });

  const onLoadArabic = () => {
    emailEditorArabicRef?.current?.editor?.loadDesign?.(template);
  };

  const onLoadEnglish = () => {
    emailEditorEnglishRef?.current?.editor?.loadDesign?.(template);
  };

  const targetedCustomerOptions = [
    ...(data?.data?.[0]?.segments?.map((segment: any) => ({
      label: `${t(segment.segmentType)} (${segment.allCustomers})`,
      value: segment.segmentType,
    })) ?? []),
    ...(data?.data?.[0]?.customSegments?.map((segment: any) => ({
      label: `${t(segment.segmentType)} (${
        segment.segmentType === 'birthdayCustomers'
          ? segment.thisMonth
          : segment.allCustomers
      })`,
      value: segment.segmentType,
    })) ?? []),
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
      <Paper
        elevation={0}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '1rem',
          height: '70px',
          paddingInlineStart: 20,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <span
            onClick={() => navigate('/campaign')}
            style={{ color: '#8181A5', fontSize: 18, cursor: 'pointer' }}
          >
            {t('campaign')}
          </span>
          <ChevronRightIcon htmlColor="#111827" />
          <span style={{ color: '#292222', fontSize: 18 }}>
            {t('campaign:create-campaign')}
          </span>
        </div>

        <Steps view={view} setView={setView} />
      </Paper>
      <Formik
        initialValues={{
          campaignName: '',
          targetCustomers: [],
          couponType: 2,
          couponValue: '',
          isExcludeDiscountedProduct: false,
          sendNow: true,
          sendTime: new Date(),
          subjectLine: '',
          senderName: '',
          replyTo: '',
        }}
        onSubmit={async (body: any) => {
          const email: any = isEnglish
            ? await exportEnglishHtml()
            : await exportArabicHtml();

          await submitForm({
            body: {
              ...body,
              merchantId: user?.idMerchant,
              emailContent: email.html,
              sendTime: body?.sendTime?.toISOString(),
            },
          });

          navigate('/campaign');
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          setFieldValue,
          submitForm,
          validateField,
          errors,
          setFieldTouched,
          touched,
        }) => (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                ...(view === 1 && { display: 'none' }),
              }}
            >
              <Paper
                elevation={0}
                style={{
                  padding: '30px',
                  border: '1px solid #F0F0F3',
                }}
              >
                <p
                  style={{
                    color: '#13131E',
                    fontSize: 18,
                    marginBottom: '8px',
                  }}
                >
                  {t('campaign:campaign-name')}
                </p>
                <p style={{ fontSize: 14, color: '#606084', marginBottom: 20 }}>
                  {t('campaign:campaign-name-label')}
                </p>
                <TextInput
                  name="campaignName"
                  placeholder={t('campaign:campaign-name')}
                  labelColor="#8181A5"
                  variant="filled"
                  disableUnderline
                />
              </Paper>
              <Paper
                elevation={0}
                style={{
                  padding: '30px',
                  border: '1px solid #F0F0F3',
                }}
              >
                <p
                  style={{
                    color: '#13131E',
                    fontSize: 18,
                    marginBottom: '8px',
                  }}
                >
                  {t('campaign:targeted-customer')}
                </p>
                <p style={{ fontSize: 14, color: '#606084', marginBottom: 20 }}>
                  {t('campaign:targeted-customer-label')}
                </p>
                <div style={{ width: 'calc(50% - 20px)' }}>
                  <MultiSelect
                    isMulti
                    name="targetCustomers"
                    menuPlacement="bottom"
                    styles={{
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: 'all .2s ease',
                        transform: state.selectProps.menuIsOpen
                          ? 'rotate(180deg)'
                          : '',
                      }),
                      placeholder: (base, state) => ({
                        ...base,
                        fontSize: 14,
                        color: '#8181a5',
                      }),
                      multiValueLabel: (base, state) => ({
                        ...base,
                        fontSize: 14,
                        color: '#8181a5',
                      }),
                      menuList: (base, state) => ({
                        ...base,
                        fontSize: 14,
                        color: '#1C1D21',
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#FF6645',
                        primary75: '#FF856A',
                        primary50: '#FFA490',
                        primary25: '#FFC3B5',
                      },
                    })}
                    onBlur={() =>
                      setFieldTouched('targetCustomers', true, true)
                    }
                    closeMenuOnSelect={false}
                    onChange={(targetCustomers) =>
                      setFieldValue(
                        'targetCustomers',
                        targetCustomers.map(
                          (targetCustomer) => targetCustomer.value
                        )
                      )
                    }
                    options={targetedCustomerOptions}
                    placeholder={t('campaign:targeted-customer-placeholder')}
                    noOptionsMessage={() => t('reward:no-options')}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {errors['targetCustomers'] && touched['targetCustomers'] && (
                    <FormHelperText
                      style={{
                        color: '#f44336',
                        fontSize: '14px',
                        margin: '3px 14px 0',
                      }}
                    >
                      {errors['targetCustomers']}
                    </FormHelperText>
                  )}
                </div>
              </Paper>

              <Paper
                elevation={0}
                style={{
                  padding: '30px',
                  border: '1px solid #F0F0F3',
                }}
              >
                <p
                  style={{
                    color: '#13131E',
                    fontSize: 18,
                    marginBottom: '8px',
                  }}
                >
                  {t('campaign:gift-detail')}
                </p>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    gap: '40px',
                    flexWrap: 'wrap',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        fontSize: 14,
                        color: '#606084',
                        marginBottom: 20,
                      }}
                    >
                      {t('campaign:select-coupon-type')}
                    </p>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        disableUnderline
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.couponType}
                        className=""
                        variant="filled"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        style={{
                          backgroundColor: 'rgb(246, 247, 248)',
                          fontSize: 16,
                          height: 49,
                        }}
                        onChange={(event) => {
                          setFieldValue('couponType', event.target.value);
                          if (event.target.value == 3 && !values.couponValue) {
                            setFieldValue('couponValue', '10');
                          }
                          if (event.target.value !== 3 && values.couponValue) {
                            setFieldValue('couponValue', '');
                          }
                        }}
                      >
                        {rewardTypes.map((type) => (
                          <MenuItem value={type.value}>
                            {isEnglish ? type.enName : type.arName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 14,
                        color: '#606084',
                        marginBottom: 20,
                        ...(values.couponType === 3 && { display: 'none' }),
                      }}
                    >
                      {t('campaign:enter-coupon-value')}
                    </p>
                    <TextInput
                      style={{
                        ...(values.couponType === 3 && { display: 'none' }),
                      }}
                      name={'couponValue'}
                      placeholder="10"
                      labelColor="#8181A5"
                      withAdornment={
                        values.couponType === 2
                          ? '%'
                          : user?.currency
                          ? user?.currency
                          : t('sar')
                      }
                      adornmentPosition="end"
                      alignCenter={true}
                      variant="filled"
                      disableUnderline
                      type="number"
                      isCurrency
                    />
                  </div>
                </div>
                <ExcludeDiscountedProducts
                  active={values.isExcludeDiscountedProduct}
                  setFieldValue={(_, value) =>
                    setFieldValue('isExcludeDiscountedProduct', value)
                  }
                  loading={loading}
                />
              </Paper>
              <Paper
                elevation={0}
                style={{
                  padding: '30px',
                  border: '1px solid #F0F0F3',
                }}
              >
                <p
                  style={{
                    color: '#13131E',
                    fontSize: 18,
                    marginBottom: '8px',
                  }}
                >
                  {t('campaign:schedule-campaign')}
                </p>
                <p style={{ fontSize: 14, color: '#606084', marginBottom: 15 }}>
                  {t('campaign:schedule-campaign-label')}
                </p>
                <div
                  style={{
                    display: 'flex',
                    gap: '40px',
                    alignItems: 'flex-end',
                  }}
                >
                  <FormControl
                    style={{ flexBasis: 'calc(50% - 20px)' }}
                    component="fieldset"
                  >
                    <RadioGroup
                      value={values.sendNow ? '1' : '2'}
                      onChange={(_, value) =>
                        setFieldValue('sendNow', value === '1' ? true : false)
                      }
                    >
                      <FormControlLabel
                        value={'1'}
                        control={<Radio size="small" color="primary" />}
                        label={
                          <p style={{ fontSize: 16, color: '#13131E' }}>
                            {t('campaign:send-now')}
                          </p>
                        }
                      />
                      <FormControlLabel
                        value={'2'}
                        control={<Radio size="small" color="primary" />}
                        label={
                          <p style={{ fontSize: 16, color: '#13131E' }}>
                            {t('campaign:send-later')}
                          </p>
                        }
                      />
                    </RadioGroup>
                  </FormControl>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexBasis: 'calc(50% - 20px)',
                    }}
                  >
                    <DatePicker
                      className="datetimepicker"
                      selected={values.sendTime}
                      locale={isEnglish ? 'en' : 'ar'}
                      onChange={(date) =>
                        setFieldValue('sendTime', date === null ? '' : date)
                      }
                      onBlur={() => setFieldTouched('sendTime', true, true)}
                      showTimeSelect
                      disabled={values.sendNow}
                      minDate={new Date()}
                      dateFormat="Pp"
                    />

                    {errors['sendTime'] && touched['sendTime'] && (
                      <FormHelperText
                        style={{
                          color: '#f44336',
                          fontSize: '14px',
                          margin: '3px 14px 0',
                        }}
                      >
                        {(errors['sendTime'] as string).includes('Invalid')
                          ? t('invalidDate')
                          : errors['sendTime']}
                      </FormHelperText>
                    )}
                  </div>
                </div>
              </Paper>
            </div>

            <Paper
              style={{ padding: 30, ...(view === 0 && { display: 'none' }) }}
            >
              <p
                style={{
                  color: '#13131E',
                  fontSize: 18,
                  marginBottom: '40px',
                }}
              >
                {t('campaign:camapaign-content')}
              </p>
              <div
                style={{
                  display: 'flex',
                  width: 'calc(50% - 20px)',
                  flexDirection: 'column',
                  marginBottom: 25,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    color: '#13131E',
                    marginBottom: '15px',
                  }}
                >
                  {t('campaign:email-subject-line')}
                </p>
                <TextInput
                  name="subjectLine"
                  placeholder={t('campaign:email-subject')}
                  labelColor="#8181A5"
                  variant="filled"
                  disableUnderline
                />
              </div>
              <div style={{ display: 'flex', gap: '40px', marginBottom: 80 }}>
                <div style={{ width: '50%', marginBottom: 25 }}>
                  <p
                    style={{
                      fontSize: 16,
                      color: '#13131E',
                      marginBottom: '15px',
                    }}
                  >
                    {t('campaign:email-sender-name')}
                  </p>
                  <TextInput
                    name="senderName"
                    placeholder={t('campaign:store-name')}
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <p
                    style={{
                      fontSize: 16,
                      color: '#13131E',
                      marginBottom: '15px',
                    }}
                  >
                    {t('campaign:replay-to')}
                  </p>
                  <TextInput
                    name="replyTo"
                    placeholder="replay-to@email.com"
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
              </div>

              <p
                style={{
                  fontSize: 16,
                  color: '#13131E',
                  marginBottom: '18px',
                }}
              >
                {t('campaign:email-content')}
              </p>

              <EmailEditor
                style={{ display: isEnglish ? 'initial' : 'none' }}
                minHeight={isEnglish ? 500 : 0}
                ref={emailEditorEnglishRef}
                onLoad={onLoadEnglish}
                options={{ locale: 'en-US' }}
              />

              <EmailEditor
                style={{ display: !isEnglish ? 'initial' : 'none' }}
                minHeight={!isEnglish ? 500 : 0}
                ref={emailEditorArabicRef}
                onLoad={onLoadArabic}
                options={{ locale: 'ar-AE' }}
              />
            </Paper>

            <Box
              display="flex"
              style={{ gap: '1rem' }}
              justifyContent="flex-end"
            >
              <Button
                type="button"
                disabled={loading}
                style={{ ...(view === 1 && { backgroundColor: '#8181A5' }) }}
                loading={loading}
                bColor={view === 0 ? 'primary' : 'secondary'}
                fullwidth={0}
                padding="8px 80px"
                handleClick={async () => {
                  if (view === 0) {
                    setFieldTouched('campaignName', true, true);
                    setFieldTouched('targetCustomers', true, true);
                    setFieldTouched('couponValue', true, true);
                    setFieldTouched('sendTime', true, true);

                    try {
                      await validationSchema.validate(values, {
                        abortEarly: false,
                      });

                      setView(1);
                    } catch (error) {
                      const firstPageFields = [
                        'campaignName',
                        'targetCustomers',
                        'couponValue',
                        'sendTime',
                      ];
                      const hasFirstPageError = error.inner.some((error: any) =>
                        firstPageFields.includes(error.path)
                      );
                      if (!hasFirstPageError) {
                        setView(1);
                      }
                    }
                  } else {
                    setView(0);
                  }
                }}
              >
                {view === 0 ? t('next') : t('back')}
              </Button>
              <Button
                style={{
                  ...(view === 0 && { display: 'none' }),
                  ...((loading || accessDenied) && {
                    backgroundColor: '#e1d5d3',
                    color: 'white',
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                  }),
                }}
                type="submit"
                disabledLabel={t('growth-plan')}
                loading={loading}
                handleClick={submitForm}
                bColor="primary"
                color="white"
                fullwidth={0}
                padding="8px 80px"
              >
                {accessDenied && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      ...(isEnglish ? { right: 0 } : { left: 0 }),
                      color: 'white',
                      backgroundColor: '#ff6645',
                      borderRadius: '3px',
                      padding: '1px 3px',
                      fontSize: '8px',
                      margin: '3px 2px',
                    }}
                  >
                    {t('growth-plan')}
                  </div>
                )}
                {loading ? t('submitting') : t('save')}
              </Button>
            </Box>
          </div>
        )}
      </Formik>
    </div>
  );
};

interface IExcludeDiscountedProducts {
  active: boolean;
  loading: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ExcludeDiscountedProducts: FC<IExcludeDiscountedProducts> = ({
  active,
  loading,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  return (
    <Box my={3}>
      <p
        style={{
          marginTop: 30,
          marginBottom: 13,
          color: '#13131E',
          fontSize: '18px',
        }}
      >
        {t('reward:exclude-discounted-products')}
      </p>

      <Toggle
        label={t('reward:exclude-discounted-products-label')}
        checked={active}
        disabled={loading}
        handleChange={(event) =>
          setFieldValue('isExcludeDiscountedProducts', event.target.checked)
        }
      />
    </Box>
  );
};

interface ToggleProps {
  checked?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: any;
  disabled?: boolean;
}

function Toggle({
  checked,
  handleChange,
  label,
  disabled,
  ...rest
}: ToggleProps) {
  return (
    <FormGroup>
      <FormControlLabel
        style={{ fontSize: 11 }}
        disabled={disabled}
        control={
          <IOSSwitch
            checked={checked}
            onChange={handleChange}
            name="checked"
            {...rest}
          />
        }
        label={
          <span
            style={{
              fontSize: 15,
              color: '#414717',
              marginBottom: 10,
              display: 'inline-block',
            }}
          >
            {label}
          </span>
        }
      />
    </FormGroup>
  );
}

const dateValidation = () => {
  const date = new Date();

  date.setMinutes(date.getMinutes() - 1);

  return date;
};
