import { Router } from '@reach/router';
import React from 'react';
import { Route } from '../../components/Common';
import { NoIndex } from '../../components/Common/NoIndex';
import { Guard } from '../../components/Routes/Guard';
import NotFound from '../notFound';
import Customers from './customers';
import Profile from './profile';
import Segmentation from './segmentation';

const Index = () => {
  return (
    <Guard permission="CustomersModule">
      <NoIndex />
      <Router>
        <Route path={'/'} component={Segmentation} />
        <Route path={'/customer'} component={Profile} />
        <Route path={'/:segmentType'} component={Customers} />
        <NotFound default />
      </Router>
    </Guard>
  );
};

export default Index;
