import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { mainColor } from '../../../utils';
import { Variant } from './';

interface StyleProps {
  padding?: string;
  inputVariant?: Variant;
}
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      textAlign: 'center',
      '& input': {
        textAlign: 'center',
      },
      '& .MuiInputBase-root': {
        backgroundColor: ({ inputVariant }: StyleProps) =>
          inputVariant === 'filled' ? '#F5F5FA !important ' : '',
        padding: ({ padding }: StyleProps) => (padding ? padding : ''),
      },
      '& .MuiFilledInput-underline': {
        '&:before': {
          borderBottomColor: ({ inputVariant }: StyleProps) =>
            inputVariant === 'filled' ? 'transparent' : '',
        },
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      '& .MuiPickersDay-daySelected': {
        backgroundColor: mainColor,
      },
    },
  })
);
