import { Redirect, RouteComponentProps } from '@reach/router';
import React, { FunctionComponent } from 'react';
import { useGlobalState } from '../../GlobalState';

type RouteProps = {
  component: FunctionComponent;
  isSetup?: boolean;
} & RouteComponentProps;

export const Route: FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}) => <Component {...rest} />;

export const PrivateRoute = ({
  component: Component,
  isSetup,
  ...rest
}: RouteProps) => {
  const { user } = useGlobalState();

  if (!!user) {
    return <Route component={Component} {...rest}></Route>;
  } else {
    return <Redirect to="/signin" noThrow />;
  }
};

export const PublicRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { user } = useGlobalState();

  return !user ? (
    <Route component={Component} {...rest}></Route>
  ) : (
    <Redirect from={rest.path} to="/home" noThrow />
  );
};

export const SetupRoute = ({
  component: Component,
  isSetup,
  ...rest
}: RouteProps) => {
  const { user } = useGlobalState();

  if (user && !user?.completed)
    return (
      <Redirect
        to={`/setup/${user?.onlineConfiguration?.nextStep ?? 6}`}
        noThrow
      />
    );
  else return <Route component={Component} {...rest}></Route>;
};

export const CodeVerificationRoute = ({
  component: Component,
  isSetup,
  ...rest
}: RouteProps) => {
  const { user } = useGlobalState();
  if (user && user?.verified) {
    return <Redirect to="/setup/1" noThrow />;
  } else {
    return <Route component={Component} {...rest}></Route>;
  }
};

export const HomeRoute = ({
  component: Component,
  isSetup,
  ...rest
}: RouteProps) => {
  const { user } = useGlobalState();

  if (user && !user.verified) return <Redirect to="/code" noThrow />;
  if (user && user.completed && user?.verified) {
    return <Redirect to="/" noThrow />;
  } else {
    return <Route component={Component} {...rest}></Route>;
  }
};

export const IntegratedRoute = ({
  component: Component,
  isSetup,
  ...rest
}: RouteProps) => {
  const { user } = useGlobalState();
  if (user && !user.isIntegrated) {
    return <Redirect to="/home" noThrow />;
  } else {
    return <Route component={Component} {...rest}></Route>;
  }
};
