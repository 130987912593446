import React from 'react';
import {
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CustomButton } from './styled';
import { useGlobalState } from '../../GlobalState';

interface Props {
  type?: 'button' | 'reset' | 'submit' | undefined;
  disabled?: boolean;
  loading?: boolean;
  children: string | Array<string> | any;
  bColor: 'primary' | 'secondary';
  color?: string;
  handleClick?: (() => void) | (() => Promise<void>);
  fullwidth: 0 | 1;
  isUploader?: boolean;
  backcolor?: string;
  rouneded?: 0 | 1;
  padding?: string;
  startIcon?: any;
  endIcon?: any;
  style?: any;
  disabledLabel?: string;
  styleContainer?: any;
}

export const Button = ({
  type,
  disabled,
  disabledLabel = 'Values have not changed',
  loading,
  bColor,
  children,
  color,
  handleClick,
  fullwidth,
  isUploader,
  backcolor,
  padding = '0.6em 1em',
  rouneded = 0,
  startIcon,
  endIcon,
  style = {},
  styleContainer = {},
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Tooltip
        // @ts-ignore
        title={disabled ? disabledLabel : loading ? t('submitting') : ''}
      >
        <span
          className="flex center"
          style={{ width: fullwidth ? '100%' : 'auto', ...styleContainer }}
        >
          <CustomButton
            style={style}
            padding={padding}
            textcolor={color}
            type={type}
            variant="contained"
            color={bColor}
            backcolor={backcolor}
            disabled={disabled || loading}
            fullwidth={fullwidth}
            onClick={handleClick}
            component={isUploader ? 'label' : 'button'}
            rouneded={rouneded}
            startIcon={startIcon ? startIcon : ''}
            endIcon={endIcon ? endIcon : ''}
          >
            {children}
          </CustomButton>
        </span>
      </Tooltip>
    </>
  );
};

export const ActionButton = ({ handleClick }: any) => {
  const { isRtl } = useGlobalState();
  const { t } = useTranslation();

  return (
    <>
      <Hidden smDown>
        <Box position="relative">
          <Button
            type="button"
            bColor="primary"
            fullwidth={0}
            padding={isRtl ? '7px 24px 7px 48px' : '7px 48px 7px 24px'}
            handleClick={handleClick}
            endIcon={<ButtonIcon handleClick={handleClick} />}
          >
            {t('action')}
          </Button>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box position="relative" height={34} width={100}>
          <Tooltip title="Create New">
            <ButtonIcon handleClick={handleClick} />
          </Tooltip>
        </Box>
      </Hidden>
    </>
  );
};

const ButtonIcon = ({ handleClick }: any) => {
  const classes = useStyles();
  return (
    <IconButton onClick={handleClick} className={classes.actionButtonIcon}>
      <SettingsIcon />
    </IconButton>
  );
};

export const SettingsIcon = ({ fill = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="17.628"
      viewBox="0 0 4 17.628"
    >
      <g id="Group_55289" transform="translate(-146.5 -11.261)">
        <circle
          id="Oval_2"
          cx="2"
          cy="2"
          r="2"
          transform="translate(146.5 11.261)"
          fill={fill}
        />
        <circle
          id="Oval_2-2"
          cx="2"
          cy="2"
          r="2"
          transform="translate(146.5 18.075)"
          fill={fill}
        />
        <circle
          id="Oval_2-3"
          cx="2"
          cy="2"
          r="2"
          transform="translate(146.5 24.889)"
          fill={fill}
        />
      </g>
    </svg>
  );
};

const useStyles = makeStyles((theme) => ({
  actionButtonIcon: {
    backgroundColor: '#F35836',
    borderRadius: 4,
    position: 'absolute',
    padding: '12px 16px',
    top: 0,
    right: 0,
  },
}));
