import { makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils';
import { useGlobalState } from '../../GlobalState';
import { renderIcon } from '../../SVG';
import { DropMenu } from '../DropMenu';
import { Layout } from './styled';

type Props = {
  icon: string;
  label: string;
  value: number;
  bColor: string;
};

export const InfoBox = ({ label, value, icon, bColor }: Props) => {
  const { t } = useTranslation();
  const boxBackgroundColor = () => {
    switch (icon) {
      case 'collectedPoints':
        return 'rgba(94, 129, 244, 0.1)';
      case 'redeemedPoint':
        return 'rgba(244, 190, 94, 0.1)';
      case 'collectedRewards':
        return 'rgba(150, 152, 214, 0.1)';
      case 'usedCoupon':
        return 'rgba(255, 128, 139, 0.1)';
      default:
        return 'rgba(150, 152, 214, 0.1)';
    }
  };

  const classes = useStyle({ iconBColor: boxBackgroundColor() });

  return (
    <Layout className="card" iconBColor={boxBackgroundColor()} isInfo={true}>
      <div>
        <p>{t(`home:${icon}`)}</p>
        <p style={{ fontSize: '20px', color: '#292222' }}>
          {Number(value).toLocaleString('en-US')}
        </p>
      </div>
      <div className={`${classes.icon} icon-right`}>{renderIcon(icon)} </div>
    </Layout>
  );
};

type MemberProps = {
  value: Array<{ value: number; key: string }>;
  icon: string;
  bColor: string;
  label: string;
  hasDropdown: boolean;
  withAllPeriod: boolean;
};

const useStyle = makeStyles(() => ({
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    margin: '0 12px',
    padding: '1em',
    borderRadius: '8px',
    backgroundColor: ({ iconBColor }: any) => iconBColor && iconBColor,
  },
}));

const ALLPERIOD = 'all period';
export const MemberBox = ({
  value,
  icon,
  bColor,
  label,
  hasDropdown,
  withAllPeriod,
}: MemberProps) => {
  const { t } = useTranslation();
  const { isEnglish } = useGlobalState();
  const [newMeberDate, setNewMemberDate] = React.useState(
    withAllPeriod ? ALLPERIOD : 'yesterday'
  );

  const boxBackgroundColor = () => {
    switch (icon) {
      case 'allMembers':
        return 'rgba(109, 210, 48, 0.1)';
      case 'newMembers':
        return 'rgba(129, 129, 165, 0.1)';
      case 'generatedValue':
        return 'rgba(255, 102, 69, 0.1)';
      default:
        return 'rgba(129, 129, 165, 0.1)';
    }
  };

  const classes = useStyle({ iconBColor: boxBackgroundColor() });
  const date = !!value
    ? hasDropdown
      ? value.filter((val) => val.key === newMeberDate)[0].key
      : value[0].key
    : '';
  const currentValue = !!value
    ? hasDropdown
      ? value.filter((val) => val.key === newMeberDate)[0].value
      : value[0].value
    : '';

  const dateOptions = withAllPeriod
    ? [
        {
          title: t(ALLPERIOD),
          id: ALLPERIOD,
          handleClick: setNewMemberDate,
        },
        {
          title: t('yesterday'),
          id: 'yesterday',
          handleClick: setNewMemberDate,
        },
        { title: t('lastWeek'), id: 'week', handleClick: setNewMemberDate },
        { title: t('lastMonth'), id: 'month', handleClick: setNewMemberDate },
      ]
    : [
        {
          title: t('yesterday'),
          id: 'yesterday',
          handleClick: setNewMemberDate,
        },
        { title: t('lastWeek'), id: 'week', handleClick: setNewMemberDate },
        { title: t('lastMonth'), id: 'month', handleClick: setNewMemberDate },
      ];

  return (
    <Layout
      className="card"
      style={{
        padding: isEnglish ? '23px 30px 23px 12px' : '23px 12px 23px 30px',
      }}
      iconBColor={bColor}
      isInfo={false}
    >
      <div className="box" style={{ marginBottom: 0, alignItems: 'center' }}>
        <div className="left-box">
          <div className={classes.icon}>{renderIcon(icon)}</div>
          <div>
            <p style={{ fontSize: '18px', color: '#1C1D21', fontWeight: 500 }}>
              {t(`home:${icon}`)}
            </p>
            {hasDropdown ? (
              <DropMenu options={dateOptions} withoutBackdrop={true}>
                <p style={{ display: 'flex', textTransform: 'capitalize' }}>
                  {date !== 'yesterday' && date !== ALLPERIOD
                    ? t(`last${capitalize(date)}`)
                    : t(date)}
                  <ExpandMore fontSize="small" />
                </p>
              </DropMenu>
            ) : (
              <Typography>
                <p>{icon === 'allMembers' ? `${t('since')} ${date}` : date}</p>
              </Typography>
            )}
          </div>
        </div>
        <p style={{ fontSize: '20px', color: '#292222' }}>
          {icon === 'generatedValue'
            ? currentValue
            : Number(currentValue).toLocaleString('en-US')}
        </p>
      </div>
    </Layout>
  );
};
