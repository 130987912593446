import styled from '@emotion/styled';

export const Dialog = styled.div`
  > * {
    margin: 1.8em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div {
    > div {
      display: flex;
      align-items: center;
    }
  }

  .icon {
    margin-right: 1em;
    margin-left: 1em;
    padding: 0.4em;
  }

  h4 {
    font-weight: 500;
    font-size: 0.85rem;
  }
`;
