// @ts-nocheck
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '..';
import { useGlobalState } from '../../GlobalState';
import { RenderIcon } from '../../SVG';
import { ButtonLayout, useStyles } from './styled';

interface Props {
  open: boolean;
  onConfirm?: (body: any, resetForm: any) => Promise<void>;
  data?: any;
  handleClose: () => void;
  title: string;
  children: JSX.Element;
  maxWidth: 'xs' | 'xl' | 'sm' | 'md' | 'lg';
  fullWidth: boolean;
  resetForm?: any;
}

export function ConfirmationPopup({
  open,
  handleClose,
  children,
  title,
  maxWidth,
  fullWidth,
  onConfirm,
  popupTheme = {},
}: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRtl } = useGlobalState();

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div style={{ fontFamily: 'Poppins' }}>
        <DialogTitle id="form-dialog-title" style={popupTheme}>
          <h2 style={{ fontWeight: 400 }}>{title}</h2>
        </DialogTitle>

        <IconButton
          size="medium"
          onClick={handleClose}
          className="clickable"
          style={{
            position: 'absolute',
            right: !isRtl && '15px',
            left: isRtl && '15px',
            top: '15px',
          }}
        >
          <CrossIcon color={popupTheme?.cross} />
        </IconButton>

        <Divider variant="middle" />
        <DialogContent>
          <div className={classes.content}>{children}</div>
          {/* Confiramtion popup */}
          {onConfirm && (
            <DialogActions className={classes.action}>
              <Button color="secondary" handleClick={handleClose} fullWidth>
                {/* <div style={{ width: 80 }}> */}
                {t('no')}, {t('cancel')}
                {/* </div> */}
              </Button>
              <Button
                type="button"
                fullWidth={1}
                color="primary"
                handleClick={() => {
                  handleClose();
                  onConfirm();
                }}
              >
                {/* <div style={{ width: 80 }}> */}
                {t('yes')}, {t('confirm')}
                {/* </div> */}
              </Button>
            </DialogActions>
          )}
        </DialogContent>
      </div>
    </Dialog>
  );
}

export function Popup({
  open,
  handleClose,
  children,
  title,
  maxWidth,
  fullWidth,
  onConfirm,
  data,
  resetForm,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div>
        {!onConfirm && (
          <DialogTitle id="form-dialog-title">
            <h3
              style={{
                fontSize: '1rem',
                fontWeight: 500,
              }}
            >
              {title}
            </h3>
          </DialogTitle>
        )}
        <Box
          onClick={handleClose}
          className={`clickable ${classes.closeButton}`}
        >
          <RenderIcon logo={CrossIcon} withBorder padding="0.25rem" />
        </Box>
        {!onConfirm && <Divider variant="middle" />}
        <DialogContent>
          {children}

          {/* Confiramtion popup */}
          {onConfirm && (
            <DialogActions className={classes.action}>
              <ButtonLayout className="space-between">
                <Button
                  fullwidth={0}
                  padding="8px 5vw"
                  bColor="primary"
                  type="button"
                  handleClick={() => {
                    handleClose();
                    onConfirm(data, resetForm);
                  }}
                  color="white"
                >
                  {t('yes')}
                </Button>

                <p className="secondary clickable" onClick={handleClose}>
                  {t('no')}
                </p>
              </ButtonLayout>
            </DialogActions>
          )}
        </DialogContent>
      </div>
    </Dialog>
  );
}

const CrossIcon = ({ color = '#8181a5' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.773"
      height="12.774"
      viewBox="0 0 12.773 12.774"
    >
      <g
        transform="translate(-959.136 -1653.73)"
        style={{ isolation: 'isolate' }}
      >
        <g data-name="Group 9320" style={{ isolation: 'isolate' }}>
          <path
            fill={color}
            d="M965.522 1659.219l5.489-5.489.9.9-5.488 5.488 5.488 5.488-.9.9-5.489-5.488-5.488 5.488-.9-.9 5.488-5.488-5.488-5.488.9-.9z"
            data-name="Path 8176"
          ></path>
        </g>
      </g>
    </svg>
  );
};
