import styled from '@emotion/styled';
import { mq } from '../../../utils';
import { ThemeType } from '../../Root';

export const InputsContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  margin-top: 2rem;
  max-width: 90%;

  > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    grid-gap: 1em;
    position: relative;

    .delete-icon {
      position: absolute;

      top: 25%;
      transition: opacity 1s ease-in-out;
    }
  }

  ${mq('phone')} {
    max-width: unset;
    > div {
      .delete-icon {
        position: absolute;
        right: unset;
        left: 0;
        opacity: 0;
      }
    }
  }
`;

export const AddMore = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5em;
  margin-bottom: 27px;
  max-width: 90%;

  > div {
    display: flex;
    width: auto;
  }

  p {
    color: ${({ theme }: { theme: ThemeType }) => theme.colors.primary};
    font-weight: 400;
    font-size: 0.9rem !important;
  }

  ${mq('phone')} {
    max-width: unset;
  }
`;
