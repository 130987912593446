import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { dev } from '../../api';
import { useGlobalState } from '../../components/GlobalState';
import {
  ACTIONS,
  customersInitialState,
  CustomersTable,
  Header,
  reducer,
} from '../../components/Segmentation';
import { formatDate } from '../../utils';

let headerData = [
  { val: 'name' },
  { val: 'phoneNumber' },
  { val: 'email' },
  { val: 'segment' },
  { val: 'ordersCount' },
  { val: 'pointsCount' },
  { val: 'lastOrderDate' },
];

const Customers = ({ segmentType }: any) => {
  const [{ limit, search, filter, page, sort }, stateDispatch]: any =
    React.useReducer(reducer, customersInitialState);

  const { setAlert } = useGlobalState();

  const formatQueryDate = (date: any) =>
    date ? formatDate(date, 'yyyy-MM-dd', true) : date;

  const { t } = useTranslation();

  const queries = {
    search,
    registerFrom: formatQueryDate(filter.registerDate.startDate),
    registerTo: formatQueryDate(filter.registerDate.endDate),
    lastOrderFrom: formatQueryDate(filter.lastOrder.startDate),
    lastOrderTo: formatQueryDate(filter.lastOrder.endDate),
    sortBy: sort.by,
    sortType: sort.direction,
  };

  const {
    data: { data, totalPages, isSuccess, totalDocs } = {
      data: [],
      totalPages: 1,
      isSuccess: false,
      totalDocs: 0,
    },
    error,
  } = useSWR<any>(
    `/segment/customers/${segmentType}/${page}?limit=${limit.value}&&search=${queries.search}&&registerFrom=${queries.registerFrom}&&registerTo=${queries.registerTo}&&lastOrderFrom=${queries.lastOrderFrom}&&lastOrderTo=${queries.lastOrderTo}&&sortBy=${queries.sortBy}&&sortType=${queries.sortType}`
  );

  const birthday = 'birthdayCustomers';
  const isBirthdaySegment = segmentType === birthday;
  React.useEffect(() => {
    if (isBirthdaySegment) {
      if (!headerData.some((head) => head.val === birthday)) {
        headerData.push({ val: birthday });
      }
    } else {
      headerData = headerData.filter((head) => head.val !== birthday);
    }
  }, [isBirthdaySegment, segmentType]);

  const [customers, setCustomers] = React.useState([]);

  const handleClick = async (event: any, done: any) => {
    if (!filter?.registerDate?.checked) {
      delete queries.registerFrom;
      delete queries.registerTo;
    }

    if (!filter?.lastOrder?.checked) {
      delete queries.lastOrderFrom;
      delete queries.lastOrderTo;
    }

    const res = await dev.get(
      `/segment/export/customers/${segmentType}?search=${queries.search}&&registerFrom=${queries.registerFrom}&&registerTo=${queries.registerTo}&&lastOrderFrom=${queries.lastOrderFrom}&&lastOrderTo=${queries.lastOrderTo}&&sortBy=${queries.sortBy}&&sortType=${queries.sortType}`
    );

    setCustomers(res.data.data);

    if (res.data.data.length < 1) {
      setAlert(t('noDataToExport'), 'error');
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Header
          segmentType={segmentType}
          state={{ limit, search, filter }}
          dispatch={stateDispatch}
          allCustomers={totalDocs}
          dataToExport={customers}
          handleExportClick={handleClick}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomersTable
          items={data}
          requestSort={(by: string, direction: string) => {
            const isByChanged = by !== sort.by;
            stateDispatch({
              type: ACTIONS.SET_SORT,
              payload: { by, direction: isByChanged ? 'asc' : direction },
            });
          }}
          sortConfig={sort}
          headerData={headerData}
          page={page}
          handlePageChange={(_: any, page: number) => {
            stateDispatch({
              type: ACTIONS.SET_PAGE,
              payload: page,
            });
          }}
          totalPages={totalPages}
          loading={!isSuccess}
          error={error}
          errroMessage="Something went wrong"
          isBirthdaySegment={isBirthdaySegment}
        />
      </Grid>
    </Grid>
  );
};

export default Customers;
