import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { getPlatformsAPI, stepAPI } from '../api';
import { AsynchronousSelect, Button, TextInput } from '../components/Common';
import { NoIndex } from '../components/Common/NoIndex';
import { useGlobalState } from '../components/GlobalState';
import { SetupLayout } from '../components/StepsSetup';
import { usePost } from '../utils';

const LoyaltySetup = () => {
  const { user, isRtl } = useGlobalState();
  const { t } = useTranslation();
  const [{ loading, data }, submitForm] = usePost({
    apiFunction: stepAPI,
    updateUser: true,
    completed: true,
  });

  const validationSchema = yup.object({
    platform: yup
      .object({
        baseURL: yup.string().url(t('notValid')).required(t('requiredField')),
        id: yup.string().required(t('requiredField')),
      })
      .required(),
  });

  React.useEffect(() => {
    if (data && data.isIntegrated) {
      window.location.replace(data.redirectURL);
    }
  }, [data]);

  const initialOption = {
    baseURL: user?.link ?? '',
    storeName: '',
    ...user?.platform!,
  };

  return (
    <>
      <NoIndex />
      <SetupLayout firstTitle={t('onBording:startSettingUpYourLoyaltyProgram')}>
        <Formik
          initialValues={{
            platform: initialOption,
            storeId: '',
            managerToken: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async ({ platform }) => {
            await submitForm({
              merchantId: user?.idMerchant,
              body: {
                id: platform.id,
                url: platform.baseURL,
              },
            });
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <AsynchronousSelect
                  defaultPlaceholder={t('onBording:selectAnOption')}
                  name="platform.id"
                  label={t('onBording:selectYourStorePlatform')}
                  padding="12px"
                  getAPI={getPlatformsAPI}
                  disabled={false}
                  setInput={setFieldValue}
                  defaultValue={initialOption}
                  autoFocus
                  optionLabel={isRtl ? 'type_ar' : 'type_en'}
                />
                <TextInput
                  name="platform.baseURL"
                  label={t('onBording:urlStoreName')}
                  labelColor="#8181A5"
                  placeholder={t('onBording:enterYourStoreUrl')}
                />

                <Box width="100%" display="flex" justifyContent="flex-end">
                  <Button
                    type="submit"
                    loading={loading}
                    bColor="primary"
                    color="white"
                    fullwidth={0}
                    padding="8px 64px"
                  >
                    {loading ? t('submitting') : t('next')}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </SetupLayout>
    </>
  );
};

export default LoyaltySetup;
