export const FORGET_PASSWORD = 'forgotYourPassword';
export const mainColor = '#ff6645';
export const NUMBER_OF_DAYS_TO_SUBSCRIPTION_END_NOTIFICATION = 7;
export const paths = {
  dashboard: '/',
  segmentation: '/segmentation',
  customer: '/customer',

  // Programs sub pages start
  programs: '/programs',
  rewardSystem: '/reward-system',
  earningWays: '/earning-ways',
  referrals: '/referral',
  // Programs sub pages end

  settings: '/settings',
  branding: '/branding',
  helpCenter: '/help',
  campaign: 'campaign',

  reviews: '/reviews',

  // earning sub pages start

  30: '/twitter-like',
  26: '/twitter-share',
  24: '/twitter-follow',
  29: '/points-per-purchase',
  21: '/birthday',
  20: '/signup',
  27: '/instagram-follow',
  31: '/instagram-share',

  // Earning sub pages end

  // Settings sub pages start

  general: 'general',
  security: 'security',
  notifications: 'notifications',
  integrations: 'integrations',
  subscription: 'subscription',

  // Settings sub pages  end
};

export const rfm = {
  superfans: '3.5-4.0',
  loyalCustomers: '2.50-3.49',
  regularCustomers: '1.5-2.49',
  riskCustomers: '1.5 - Or less',
};

export const TRANS_STATUS: any = {
  1: 'Payment Succceeded',
  2: 'Payemnt Pedning',
  3: 'Payemnt Declined',
  4: 'Payemnt Refunded',
  5: 'Payemnt Cancelled',
  6: 'Payemnt Deferred',
  7: 'serverError',
};
