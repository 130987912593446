import styled from '@emotion/styled';
import React from 'react';

import { PageError } from './Common';

export default class ErrorBoundary extends React.Component {
  state = { error: '', errorInfo: '' };

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Layout>
          <PageError text={this.state.error?.toString()} />
        </Layout>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const Layout = styled.div`
  margin: auto;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
`;
