import { Box, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { PageError, Tabs } from '../../Common';
import { Pagination } from '../../Common/PaginationV2';
import { CouponsTable } from './couponsTable';
import { PointsTable } from './pointsTable';
import { ReferralsTable } from './referralsTable';
import { Layout, TableHeader } from './styled';

export const ActivitiesTable = () => {
  const { t } = useTranslation();

  const [couponsPageNo, setcouponsPageNo] = useState(1);
  const [pointsPageNo, setPointsPageNo] = useState(1);
  const [referralPageNo, setReferralPageNo] = useState(1);
  const [pointsTotalPages, setPointsTotalPages] = useState(null);
  const [couponsTotalPages, setCouponsTotalPages] = useState(null);
  const [referralsTotalPages, setReferralsTotalPages] = useState(null);

  const [value, setValue] = React.useState('pointsActivities');

  const paginationTotalPagesNumber = () => {
    switch (value) {
      case 'pointsActivities':
        return pointsTotalPages;
      case 'couponsActivities':
        return couponsTotalPages;
      case 'referrals':
        return referralsTotalPages;
    }
  };

  const paginationPageNumber = () => {
    switch (value) {
      case 'pointsActivities':
        return pointsPageNo;
      case 'couponsActivities':
        return couponsPageNo;
      case 'referrals':
        return referralPageNo;
    }
  };

  const { data, error, isValidating } = useSWR(
    `/merchant/activities-list/${paginationPageNumber()}`
  );

  useEffect(() => {
    if (
      !data ||
      !data?.isSuccess ||
      pointsTotalPages ||
      couponsTotalPages ||
      referralsTotalPages
    )
      return;

    setPointsTotalPages(data?.data?.pointsActivities?.totalPages);
    setCouponsTotalPages(data?.data?.couponsActivities?.totalPages);
    setReferralsTotalPages(data?.data?.referralActivities?.totalPages);
  }, [couponsTotalPages, data, pointsTotalPages, referralsTotalPages]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  if (error)
    return (
      <PageError
        buttonText={'home'}
        text={`Opps, ${JSON.stringify(error)} please try again later`}
      />
    );

  const paginationChangeHandler = (
    _: React.ChangeEvent<unknown>,
    page: number
  ) => {
    switch (value) {
      case 'pointsActivities':
        setPointsPageNo(page);
        break;
      case 'couponsActivities':
        setcouponsPageNo(page);
        break;
      case 'referrals':
        setReferralPageNo(page);
    }
  };

  return (
    <Layout>
      <TableHeader className="space-between">
        <h1>{t('home:latestActivities')}</h1>
        <Tabs
          values={[
            { val: 'pointsActivities', label: t('points') },
            { val: 'couponsActivities', label: t('coupons') },
            { val: 'referrals', label: t('referral:referrals') },
          ]}
          handleChange={handleChange}
          value={value}
        />
        <div>{'    '}</div>
      </TableHeader>
      {value === 'pointsActivities' && (
        <PointsTable
          data={data?.data?.pointsActivities.data ?? []}
          error={error}
          isValidating={isValidating}
        />
      )}
      {value === 'couponsActivities' && (
        <CouponsTable
          data={data?.data?.couponsActivities.data ?? []}
          error={error}
          isValidating={isValidating}
        />
      )}
      {value === 'referrals' && (
        <ReferralsTable
          data={data?.data?.referralActivities.data ?? []}
          error={error}
          isValidating={isValidating}
        />
      )}

      <Paper>
        <Box
          my={2}
          display="flex"
          justifyContent="center"
          width={'100%'}
          style={{ padding: 16 }}
        >
          {(pointsTotalPages || couponsTotalPages || referralsTotalPages) && (
            <Pagination
              count={paginationTotalPagesNumber()! ?? 1}
              page={paginationPageNumber()! ?? 1}
              onChange={paginationChangeHandler}
            />
          )}
        </Box>
      </Paper>
    </Layout>
  );
};
