import styled from '@emotion/styled';
import { Box, Checkbox, Grid, Typography } from '@material-ui/core';
import { useNavigate } from '@reach/router';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import * as yup from 'yup';
import { putEarningAPI } from '../../../../api';
import { Button, TextInput } from '../../../../components/Common';
import GrowthPlan from '../../../../components/Common/GrowthPlan';
import { useGlobalState } from '../../../../components/GlobalState';
import { EarnDescription } from '../../../../components/Programs';
import { mq, usePost } from '../../../../utils';

interface Props {
  currentMethodId?: number;
  currentMethod?: {
    idEarnWay: number;
    title_ar: string;
    title_en: string;
    name: string;
  };
}
export const EditForm: FC<Props> = ({ currentMethodId, currentMethod }) => {
  const { user, isEnglish } = useGlobalState();
  const { t } = useTranslation();
  const { data: { data } = { data: [] }, error }: any = useSWR(
    `/points-program/${user?.idMerchant}`
  );

  const [{ loading }, submitForm] = usePost({
    apiFunction: putEarningAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });
  const navigate = useNavigate();

  const canEarnPointsOnDelivery =
    !!user?.permissions?.EarningPointsModule?.[
      'earn points on delivery feature'
    ];

  const isNewOrder = currentMethodId == 29;
  const isSocial =
    currentMethodId == 24 ||
    currentMethodId == 27 ||
    currentMethodId == 32 ||
    currentMethodId == 33;

  const isSnapChat = currentMethodId == 33;

  const validationSchema = yup.object({
    points: yup
      .number()
      .typeError('points must be a number')
      .min(1)
      .required('No points provided'),
    ...(isNewOrder && {
      price: yup
        .number()
        .typeError('price must be a number')
        .min(1)
        .required('No points provided'),
    }),
    ...(isSocial && {
      wayUserName: isSnapChat
        ? yup.string().url(t('snapchatURLError')).required(t('requiredField'))
        : yup.string().required(t('requiredField')),
    }),
  });

  const action =
    (isEnglish ? currentMethod?.title_en : currentMethod?.title_ar) ?? '';

  const state = data.find((way: any) => way.idEarnWay == currentMethodId);

  return (
    <Formik
      initialValues={{
        points: state?.points ?? 1,
        price: state?.price ?? 1,
        countOrderWhenCompleted:
          state?.countOrderWhenCompleted === null
            ? null
            : state?.countOrderWhenCompleted
            ? true
            : false,
        wayUserName: state?.wayUserName ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (body) => {
        if (user)
          await submitForm({
            body: {
              status: 1,
              idEarnWay: currentMethodId,
              ...body,
              countOrderWhenCompleted:
                body.countOrderWhenCompleted === null
                  ? null
                  : body.countOrderWhenCompleted
                  ? 1
                  : 0,
            },
          });

        navigate('/programs/earning-ways');
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Layout>
            <Form className="mr-bottom">
              <div
                className="mr-bottom"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'fit-content',
                  margin: 'auto',
                }}
              >
                {isNewOrder && (
                  <div className="card">
                    <Typography variant="h3" gutterBottom>
                      {t('earningMethods:earningValue')}
                    </Typography>
                    <p className="secondary" style={{ fontSize: '13.5px' }}>
                      {t('earningMethods:pointsEarnedForEvery')}{' '}
                      <strong style={{ fontWeight: 700 }}>
                        {' '}
                        {values.price}{' '}
                        {user?.currency ? t(user.currency) : t('sar')}{' '}
                      </strong>{' '}
                      {t('spent')}
                    </p>
                    <div className="space-between" style={{ marginTop: '1em' }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            disableUnderline
                            name="price"
                            placeholder="1"
                            labelColor="#8181A5"
                            alignCenter={true}
                            withAdornment={
                              user?.currency ? t(user.currency) : t('sar')
                            }
                            adornmentPosition="end"
                            variant="filled"
                            padding="10px"
                            disabled={true}
                            type="number"
                            isCurrency
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            disableUnderline
                            name="points"
                            placeholder="1000"
                            labelColor="#8181A5"
                            alignCenter={true}
                            withAdornment={t('points')}
                            adornmentPosition="end"
                            variant="filled"
                            padding="10px"
                            type="number"
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <ActivationToggle
                      disabled={!canEarnPointsOnDelivery}
                      style={{
                        marginTop: 25,
                        marginBottom: 18,
                        display: 'flex',
                        gap: 7,
                        alignItems: 'center',
                      }}
                      title={t('earningMethods:orderCompleteToggle')}
                      onChange={(_, checked) =>
                        setFieldValue('countOrderWhenCompleted', checked)
                      }
                      value={!!values.countOrderWhenCompleted}
                    />
                    <Box
                      style={{ backgroundColor: '#F6F7F8', padding: '16px' }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        <span>{t('earningMethods:customersWillEarn')}</span>
                        <strong style={{ fontWeight: 700 }}>
                          {' '}
                          {values.points} {t('point')}{' '}
                        </strong>
                        <span>{t('earningMethods:forEvery')} </span>
                        <strong style={{ fontWeight: 700 }}>
                          {values.price}{' '}
                          {user?.currency ? t(user.currency) : t('sar')}{' '}
                        </strong>
                        {t('spent')}
                      </Typography>
                    </Box>
                  </div>
                )}

                {isSocial && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '37px',
                    }}
                  >
                    <div className="card">
                      <div style={{ marginBottom: 20 }}>
                        <h3 className="title">
                          {`${t(
                            currentMethodId == 24
                              ? 'twitter'
                              : currentMethodId == 27
                              ? 'instagram'
                              : currentMethodId == 33
                              ? 'snapchat'
                              : 'tiktok'
                          )} ${t('userName')}`}
                        </h3>
                      </div>
                      <div>
                        <TextInput
                          disableUnderline
                          name="wayUserName"
                          placeholder={
                            currentMethodId == 33
                              ? t('snapchatURL')
                              : t('userNameplaceholder')
                          }
                          labelColor="#8181A5"
                          alignCenter={false}
                          withAdornment={currentMethodId == 33 ? '' : t('@')}
                          adornmentPosition="start"
                          variant="filled"
                          padding="10px"
                        />
                      </div>
                    </div>
                    <div
                      className="card"
                      style={{
                        display: 'flex',
                        alignItems: 'end',
                        gap: '2rem',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div>
                        <h3 className="title">
                          {t('earningMethods:earningValue')}
                        </h3>
                        <EarnDescription
                          points={values.points}
                          action={t('theyFollow')}
                        />
                      </div>
                      <div>
                        <TextInput
                          disableUnderline
                          name="points"
                          placeholder="1000"
                          labelColor="#8181A5"
                          alignCenter={true}
                          withAdornment={t('points')}
                          adornmentPosition="end"
                          variant="filled"
                          padding="10px"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {(currentMethodId == 20 || currentMethodId == 21) && (
                  <div
                    className="card"
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      gap: '2rem',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div>
                      <h3 className="title">
                        {t('earningMethods:earningValue')}
                      </h3>
                      <EarnDescription points={values.points} action={action} />
                    </div>
                    <div>
                      <TextInput
                        disableUnderline
                        name="points"
                        placeholder="1000"
                        labelColor="#8181A5"
                        alignCenter={true}
                        withAdornment={t('points')}
                        adornmentPosition="end"
                        variant="filled"
                        padding="10px"
                        type="number"
                      />
                    </div>
                  </div>
                )}
                <div className="button">
                  <Button
                    type="submit"
                    loading={loading}
                    bColor="primary"
                    color="white"
                    fullwidth={0}
                  >
                    {loading ? t('submitting') : t('save')}
                  </Button>
                </div>
              </div>
            </Form>
          </Layout>
        );
      }}
    </Formik>
  );
};

const Layout = styled.div`
  .title {
    margin-bottom: 1em;
  }

  .adornment {
    font-weight: 400;
    font-size: 0.85rem;
  }
  .space-between {
    align-items: flex-start;
    .left {
      flex-basis: 50%;
      margin-right: 1em;
      .secondary {
        text-transform: lowercase;
        font-size: 0.7rem;
      }
      .title {
        margin-bottom: 10px;
      }
    }
    .right {
      flex: 1;
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      padding-left: 6em;
      padding-right: 6em;
    }
  }

  ${mq('tablet')} {
    .space-between {
      flex-direction: column;

      > * {
        flex-basis: 100%;
        width: 100%;
      }

      .right,
      .left {
        > * {
          margin-bottom: 1em;
        }
      }
    }
    .button {
      margin-top: 0;
      button {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;

const ActivationToggle = ({
  value,
  onChange,
  style,
  title,
  disabled,
}: {
  value: boolean;
  title: string;
  disabled: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  style?: React.CSSProperties | undefined;
}) => {
  return (
    <Box style={style}>
      <Checkbox
        checked={!!value}
        onChange={onChange}
        color="primary"
        size="small"
        style={{ padding: '0px' }}
        disabled={disabled}
      />
      <Typography style={{ fontSize: '13px' }}>{title}</Typography>
      <GrowthPlan />
    </Box>
  );
};
