import { useMediaQuery } from '@material-ui/core';
import { navigate, Router, useLocation } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, SystemHeader } from '../../components/Common';
import { NoIndex } from '../../components/Common/NoIndex';
import { Guard } from '../../components/Routes/Guard';
import { SideNav } from '../../components/Settings';
import { bp, paths } from '../../utils';
import General from './general';
import { Integrations } from './integrations';
import Notifications from './notifications';
import Securtiy from './security';
import { Layout } from './styled';
import Subscription from './subscription';

const Settings = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  React.useLayoutEffect(() => {
    const size = () => {
      if (window.innerWidth > bp.tablet)
        if (window.location.pathname === paths.settings)
          navigate(paths.settings + '/' + paths.general);
    };
    window.addEventListener('resize', size);

    size();

    return () => {
      window.removeEventListener('resize', size);
    };
  }, []);

  const headerTitle = () => {
    switch (pathname.replace('/settings/', '')) {
      case 'general':
        return t('settings:generalInformation');
      case 'security':
        return t('settings:security');
      case 'subscription':
        return t('settings:subscription');
      case 'notifications':
        return t('settings:notifications');
      case 'integrations':
        return t('settings:integration');

      default:
        return t('settings');
    }
  };

  const matches = useMediaQuery('(max-width:900)');
  return (
    <Guard permission="SettingsModule">
      <NoIndex />
      <Layout className="mr-bottom">
        <SystemHeader
          title={headerTitle()}
          withBack={false}
          withStatus={false}
          className="desktop"
        />
        {matches ? (
          <div>
            <Router>
              <Route path={'/'} component={SideNav} />
              <Route path={'/' + paths.general} component={General} />
              <Route path={'/' + paths.security} component={Securtiy} />
              <Route path={'/' + paths.subscription} component={Subscription} />
              <Route
                path={'/' + paths.notifications + '/*'}
                component={Notifications}
              />
              <Route
                path={'/' + paths.integrations + '/*'}
                component={Integrations}
              />
            </Router>
          </div>
        ) : (
          <div className="grid">
            <div className="left">
              <Router>
                <Route path={'/' + paths.general} component={General} />
                <Route path={'/' + paths.security} component={Securtiy} />
                <Route
                  path={'/' + paths.notifications}
                  component={Notifications}
                />
                <Route
                  path={'/' + paths.subscription}
                  component={Subscription}
                />
                <Route
                  path={'/' + paths.integrations + '/*'}
                  component={Integrations}
                />
              </Router>
            </div>
            <SideNav />
          </div>
        )}
      </Layout>
    </Guard>
  );
};

export default Settings;
