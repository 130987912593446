import {
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { subDays } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from '../../../../assets/svg/filter.svg';
import { ReactDatePicker, useFilterMenuStyles } from '../../../Common';
import { ACTIONS } from '../index';

export const Filter = ({
  handleClick,
  handleClose,
  anchorEl,
  dispatch,
  state: { filter },
}) => {
  const { t } = useTranslation();
  const classes = useFilterMenuStyles();

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FilterIcon />
      </IconButton>

      <Menu
        className={classes.popover}
        style={{ overflow: 'visible !important' }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch({
              type: ACTIONS.SET_FILTER_CHECKED,
              payload: {
                key: 'registerDate',
                value: !filter.registerDate.checked,
              },
            });
          }}
        >
          <Box>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 400,
                cursor: 'pointer',
              }}
            >
              <Checkbox
                color="primary"
                checked={filter.registerDate.checked}
                size="small"
                style={{ padding: 4 }}
              />
              {t('registeredSince')}
            </Typography>
          </Box>
        </MenuItem>
        {filter.registerDate.checked && (
          <MenuItem style={{ overflow: 'visible', lineHeight: 0.5 }}>
            <Box display="flex" p={1}>
              <ReactDatePicker
                selected={filter.registerDate.startDate}
                disabled={!filter.registerDate.checked}
                maxDate={subDays(filter.registerDate.endDate, 1)}
                placeholderText={t('from')}
                onChange={(date) => {
                  dispatch({
                    type: ACTIONS.SET_FILTER_VALUES,
                    payload: {
                      key: 'registerDate',
                      value: { startDate: date },
                    },
                  });
                }}
                className="date"
              />
              <ReactDatePicker
                selected={filter.registerDate.endDate}
                disabled={!filter.registerDate.checked}
                maxDate={new Date()}
                placeholderText={t('to')}
                minDate={filter.registerDate.startDate}
                onChange={(date) => {
                  dispatch({
                    type: ACTIONS.SET_FILTER_VALUES,
                    payload: {
                      key: 'registerDate',
                      value: { endDate: date },
                    },
                  });
                }}
                className="date"
              />
            </Box>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            dispatch({
              type: ACTIONS.SET_FILTER_CHECKED,
              payload: {
                key: 'lastOrder',
                value: !filter.lastOrder.checked,
              },
            });
          }}
        >
          <Box>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 400,
                cursor: 'pointer',
              }}
            >
              <Checkbox
                color="primary"
                checked={filter.lastOrder.checked}
                size="small"
                style={{ padding: 4 }}
              />
              {t('lastOrderDate')}
            </Typography>
          </Box>
        </MenuItem>
        {filter.lastOrder.checked && (
          <MenuItem style={{ overflow: 'visible', lineHeight: 0.5 }}>
            <Box display="flex" p={1}>
              <ReactDatePicker
                selected={filter.lastOrder.startDate}
                disabled={!filter.lastOrder.checked}
                placeholderText={t('from')}
                maxDate={subDays(filter.lastOrder.endDate, 1)}
                onChange={(date) => {
                  dispatch({
                    type: ACTIONS.SET_FILTER_VALUES,
                    payload: {
                      key: 'lastOrder',
                      value: { startDate: date },
                    },
                  });
                }}
                className="date"
              />
              <ReactDatePicker
                selected={filter.lastOrder.endDate}
                disabled={!filter.lastOrder.checked}
                maxDate={new Date()}
                minDate={filter.lastOrder.startDate}
                placeholderText={t('to')}
                onChange={(date) => {
                  dispatch({
                    type: ACTIONS.SET_FILTER_VALUES,
                    payload: {
                      key: 'lastOrder',
                      value: { endDate: date },
                    },
                  });
                }}
                className="date"
              />
            </Box>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
