import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { mq } from '../../../utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      transform: 'translateY(1%)',
    },

    item: {
      display: 'flex',
      padding: '1.2em 1.6em',
      fontSize: '0.75rem',
      alignItems: (props: { isCenter?: boolean }) =>
        props.isCenter ? 'center' : 'flex-start',
      cursor: 'auto',

      '&:hover': {
        backgroundColor: 'inherit',
      },
    },

    isNew: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '& .icon': {
        backgroundColor: 'white',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    divider: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

export const Item = styled.li<{ icon?: boolean; withoutMargin?: boolean }>`
  justify-content: center;
  align-items: flex-start;
  padding: 0.4em;
  border-radius: 8px;

  ${({ icon }) =>
    icon &&
    css`
      height: 35px;
      width: 35px;
      display: flex;
      background-color: rgba(129, 129, 165, 0.1);
      margin: 0 1em 0 0;
    `};

  &:hover {
    background-color: ${({ icon }) => icon && 'rgba(129, 129, 165, 0.2)'};
  }

  ${mq('tablet')} {
    span {
      display: none;
    }
  }
`;
