import { useNavigate } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../../../components/GlobalState';
import { ReactComponent as LeftIcon } from '../../../../assets/svg/arrow-left-solid.svg';
import { ReactComponent as RightIcon } from '../../../../assets/svg/arrow-right-solid.svg';
import { Paper } from '@material-ui/core';

export const Header = ({ title = '' }: { title?: string }) => {
  const { isEnglish } = useGlobalState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Paper
      elevation={0}
      style={{
        padding: '23px 16px',
        height: 70,
        display: 'flex',
        alignItems: 'center',
        gap: '33px',
        borderRadius: '4px',
        border: '1px solid #E8E8E8',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '48px',
          height: '48px',
          backgroundColor: 'rgba(129,129,165,0.1)',
          borderRadius: '6px',
          cursor: 'pointer',
        }}
        onClick={() => navigate('/programs/referral')}
      >
        {isEnglish ? <LeftIcon /> : <RightIcon />}
      </div>
      <p
        style={{
          fontSize: 20,
          fontWeight: 500,
          color: '#292222',
        }}
      >
        {title}
      </p>
    </Paper>
  );
};
