import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerActionButton } from './actionbutton';

const ProfileHeader = ({ idCustomer, blocked }: any) => {
  const { t } = useTranslation();
  return (
    <Paper>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">
          {t('segmentation:customerProfile')}{' '}
          {blocked ? (
            <span style={{ color: '#FF6645', textTransform: 'uppercase' }}>
              ( {t('blocked')} )
            </span>
          ) : (
            ''
          )}
        </Typography>
        <CustomerActionButton customerId={idCustomer} blocked={blocked} />
      </Box>
    </Paper>
  );
};

export default ProfileHeader;
