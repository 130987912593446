import styled from '@emotion/styled';
import { mq } from '../../../utils';

interface Props {
  padding: string;
  minHeight: string;
}
export const Header = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : '20px 0.75em')};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '80px')};

  > div {
    display: flex;
    align-items: center;

    > * {
      margin: 0 0.5em;
    }
  }


    font-weight: 500;
    text-transform: capitalize;
  }

  ${mq('tablet')} {
    min-height: unset;
    .title {
      margin-left: 0.1em;
    }
  }
`;
