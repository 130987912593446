import { Box } from '@material-ui/core';
import { Link } from '@reach/router';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { resetPasswordAPI } from '../api';
import PasswordLeft from '../assets/img/password-left.png';
import { ReactComponent as MailIcon } from '../assets/svg/mail.svg';
import { Button, TextInput } from '../components/Common';
import { NoIndex } from '../components/Common/NoIndex';
import { SignLayout } from '../components/SignLayout';
import { FORGET_PASSWORD, usePost, useStyles } from '../utils';

const ResetPasswordAPI = () => {
  const { t } = useTranslation();
  const [{ loading }, fetchData] = usePost({
    apiFunction: resetPasswordAPI,
    alertOnSuccess: t('public:youWillReciveEmailWithResetInstructions'),
  });
  const validationSchema = yup.object({
    email: yup.string().email().required(t('requiredField')),
  });

  const classes = useStyles();

  return (
    <>
      <NoIndex />
      <SignLayout
        firstTitle={t(`public:${FORGET_PASSWORD}`)}
        secondTitle={t('public:enterYourDetailsToResetThePassword')}
        subTitle={t('public:youWillReciveEmailWithResetInstructions')}
        bgImage={PasswordLeft}
      >
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (body, { resetForm }) => {
            await fetchData(body);
            resetForm();
          }}
        >
          {() => (
            <Form>
              <TextInput
                name="email"
                label={t('email')}
                placeholder="name@gmail.com"
                logo={MailIcon}
              />
              <Box>
                <Button
                  type="submit"
                  loading={loading}
                  bColor="primary"
                  color="white"
                  fullwidth={1}
                >
                  {loading ? t('submitting') : t('public:submit')}
                </Button>
                <Link to="/signin" className={classes.link}>
                  <Button
                    type="button"
                    bColor="secondary"
                    color="#8181A5"
                    fullwidth={1}
                  >
                    {t('public:signin')}
                  </Button>
                </Link>
              </Box>
            </Form>
          )}
        </Formik>
      </SignLayout>
    </>
  );
};

export default ResetPasswordAPI;
