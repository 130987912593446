export const setLocal = ({ key, value }: any) => {
  try {
    if (!value || value === 'null') localStorage.removeItem(key);
    else {
      localStorage.setItem(key, value);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getLocal = ({ key }: any): any => {
  try {
    let data = localStorage.getItem(key);
    if (!!data) {
      data = JSON.parse(data);
    }
    return data;
    // return JSON.parse(localStorage.getItem(key) || '');
  } catch (err) {}
};

export function localStorageProvider() {
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'));

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem('app-cache', appCache);
  });

  // We still use the map for write & read for performance.
  return map;
}
