import styled from '@emotion/styled';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { postExpiryAPI } from '../../api';
import { ReactComponent as EditLogo } from '../../assets/svg/edit.svg';
import { ReactComponent as WatchLogo } from '../../assets/svg/watch.svg';
import { RenderIcon } from '../../components/SVG';
import { usePost } from '../../utils';
import { Button, Popup, TextInput } from '../Common';
import { useGlobalState } from '../GlobalState';
import { Dialog } from './Dialog';
import styles from './PointExpiry.module.css';

export const PointExpiry = () => {
  const { user } = useGlobalState();
  const [{ loading }, submitForm] = usePost({
    apiFunction: postExpiryAPI,
    updateUser: true,
  });
  const { t } = useTranslation();

  const validationSchema = yup.object({
    days: yup
      .number()
      .typeError(t('fieldMustBeANumber'))
      .min(90, `${t('minNumber')} 90`)
      .required(t('requiredField')),
  });

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Popup
        open={open}
        handleClose={handleClose}
        title={t('earningMethods:waysToEarn')}
        maxWidth="sm"
        fullWidth={true}
      >
        <Dialog>
          <Formik
            initialValues={{
              days: user?.onlineConfiguration.expiryPointDaysNumber || 90,
            }}
            validationSchema={validationSchema}
            onSubmit={async (body) => {
              if (user)
                await submitForm({
                  body: {
                    ...body,
                    merchantId: user.idMerchant,
                  },
                });
              handleClose();
            }}
          >
            <Form>
              <FormLayout className="mr-bottom">
                <TextInput
                  name="days"
                  label={t('earningMethods:howManyDaysForPointExpiry')}
                  labelColor="#8181A5"
                />
                <div>
                  <Button
                    type="submit"
                    loading={loading}
                    bColor="primary"
                    color="white"
                    fullwidth={1}
                  >
                    {loading ? t('submitting') : t('save')}
                  </Button>
                </div>
              </FormLayout>
            </Form>
          </Formik>
        </Dialog>
      </Popup>
      <div className="grid">
        <div className="left">
          <h3 className="title">{t('earningMethods:pointsExpiry')}</h3>
          <p className="paragraph">
            {t('earningMethods:manageWhenCustomersPointsExpire')}
          </p>
        </div>
        <div className="right">
          <h3 className="title">{t('earningMethods:pointsExpiry')}</h3>
          <div
            className={`${styles.expire} card space-between actionable`}
            style={{ padding: '0.8rem' }}
          >
            <div className={'space-between'} style={{ gap: '0.5rem' }}>
              <RenderIcon
                logo={WatchLogo}
                bColor="#F5F5FA"
                color="#8181A5"
                padding="0.5rem"
              />
              <div style={{ marginLeft: '1rem' }}>
                <strong>{t('earningMethods:pointsExpiry')}</strong>
                <p className="paragraph secondary">
                  <strong>
                    {user?.onlineConfiguration.expiryPointDaysNumber}
                  </strong>{' '}
                  {t('earningMethods:daysStartingFromCollectionDay')}
                </p>
              </div>
            </div>
            <h3>
              <div
                className="center icon clickable"
                style={{ padding: '0.4em 2em' }}
                onClick={handleClickOpen}
              >
                <RenderIcon
                  logo={EditLogo}
                  bColor="#F5F5FA"
                  text={t('edit')}
                  color="#8181A5"
                  weight={400}
                />
              </div>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

const FormLayout = styled.div`
  width: 100%;

  button {
    width: 100%;
  }
`;
