import styled from '@emotion/styled';

export const Item = styled.div`
  background-color: white;
  border: 0.5px solid #eee;

  .inputs {
    padding: 15px 1.3em !important;
    h3 {
      font-size: 0.85rem !important;
      font-weight: 400 !important;
    }
    .secondary {
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
`;
