import styled from '@emotion/styled';

export const Img = styled.img`
  width: 35%;
  max-width: 800px;
  height: auto;
  border-radius: 1em;
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 4em;

  a {
    padding: 8px 12px;
    border-radius: 4px;
    text-transform: capitalize;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.33;
    color: white;
    cursor: pointer;
    background-color: #fc674c;
  }
`;
export const Text = styled.p`
  font-size: 1.5rem;
  line-height: 1.71;
  text-align: center;
  color: #354052;
  margin-bottom: 24px;
  max-width: 500px;
  margin: 1em auto;
`;
