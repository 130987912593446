import styled from '@emotion/styled';
import { mq } from '../../../utils';

export const Layout = styled.div`
  .card {
    padding: 0.75rem 1.5rem;
  }

  li {
    background: #8181a5;

    img {
      width: 100%;
      min-width: 40px;
      max-width: 70px;
      margin-right: 1rem;
    }
    p {
      color: #ffffff;
    }

    a {
      padding: 0.5rem 1.5rem !important;
      background: #ffffff;
      color: #292222;
      width: 80px;
      display: inline-block;
      width: 220px;
      white-space: nowrap;
    }
  }

  .last {
    margin: 0;
  }

  ${mq('tablet')} {
    .announcement,
    li {
      flex-direction: column !important;
    }

    p {
      margin-bottom: 1rem;
    }
  }
`;
