import { Box, useTheme } from '@material-ui/core';
import { Link } from '@reach/router';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

interface Props {
  aboutToExpire: boolean;
  numberOfRemainDaysForSubscription: number;
  isExpired: boolean;
}

export const Notification: FC<Props> = ({
  aboutToExpire,
  numberOfRemainDaysForSubscription,
  isExpired,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div className={styles.notification}>
      <Box
        bgcolor={theme.palette.primary.main}
        width="100%"
        py={1.5}
        px={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ gap: '10px' }}
      >
        <p style={{ color: 'white', fontSize: 14 }}>
          {aboutToExpire
            ? t('settings:aboutToExpire').replace(
                'XXX',
                numberOfRemainDaysForSubscription.toString()
              )
            : t(
                isExpired
                  ? 'settings:renewSubscriptionDesc'
                  : 'settings:activateSubscriptionDesc'
              )}
        </p>
        <Box
          color="white"
          border="1px white solid"
          borderRadius={8}
          display="flex"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
          bgcolor="#FF7E62"
        >
          <Link to="/settings/subscription" style={{ color: 'inherit' }}>
            <p className={styles.notificationButton}>
              {t(
                isExpired || aboutToExpire
                  ? 'settings:renewSubscription'
                  : 'settings:activateSubscription'
              )}
            </p>
          </Link>
        </Box>
      </Box>
    </div>
  );
};
