import React from 'react';
import { Props, SVG } from './styled';

export const Curve = ({ bColor, dir }: Props) => {
  return (
    <SVG
      dir={dir}
      bColor={bColor}
      xmlns="http://www.w3.org/2000/svg"
      width="173"
      height="506"
      viewBox="0 0 173 506"
    >
      {dir === 'left' ? (
        <>
          <defs>
            <clipPath id="clipPath">
              <rect
                id="Rectangle_866"
                data-name="Rectangle 866"
                width="173"
                height="506"
                transform="translate(0 225)"
                fill="#fff"
                stroke="#707070"
                strokeWidth="1"
              />
            </clipPath>
          </defs>
          <g id="BG" transform="translate(0 -225)" clipPath="url(#clip-path)">
            <path
              id="Path_Copy"
              data-name="Path Copy"
              d="M419.046,26.477C379.007,11.254,336.7-1.463,293.5.136,230,2.479,169.034,38.2,139.179,90.657,113.849,135.1,110.724,187.19,99.812,236.51,86.384,297.266,60.618,355.025,33.107,411.493,18.334,441.83,2.744,472.821.318,506.074c-3.362,46.169,20.233,92.174,57.238,122.883S143.7,675.871,193.028,679.4c26.248,1.88,53.677.136,76.99-11.29,24.667-12.089,42.647-33.934,67.414-45.851,22.95-11.045,49.8-12.589,75.563-11.154a358.634,358.634,0,0,1,98.649,19.619c36.015,12.643,69.5,30.964,105.391,43.789s76.081,19.982,112.8,9.283c34.525-10.055,61.781-34.47,86.357-59.194A1049.312,1049.312,0,0,0,926.33,494.366c22.177-31.073,42.82-63.853,52.623-100.067,18.752-69.239-7.05-147.353-64.279-194.638-47.417-39.175-111.051-56.178-173.34-66.5-56.8-9.41-122.48-9.991-174.767-34.515C516.7,75.234,471.332,46.368,419.046,26.477Z"
              transform="matrix(-0.602, 0.799, -0.799, -0.602, 316.524, 388.497)"
              fill="#8181A5"
              opacity="0.15"
            />
          </g>
        </>
      ) : (
        <>
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_866"
                data-name="Rectangle 866"
                width="95"
                height="278"
                transform="translate(-0.067)"
                fill="#eff1fe"
                stroke="#707070"
                strokeWidth="1"
              />
            </clipPath>
          </defs>
          <g id="BG" transform="translate(0.067)" clip-path="url(#clip-path)">
            <path
              id="Path_Copy"
              data-name="Path Copy"
              d="M229.949,363.555c-21.971,8.354-45.189,15.331-68.89,14.454-34.849-1.286-68.3-20.889-84.685-49.673-13.9-24.388-15.615-52.972-21.6-80.036-7.369-33.339-21.508-65.034-36.6-96.021-8.107-16.647-16.662-33.653-17.993-51.9C-1.67,75.045,11.277,49.8,31.583,32.948S78.856,7.2,105.923,5.266c14.4-1.032,29.455-.075,42.248,6.2,13.536,6.634,23.4,18.621,36.993,25.16,12.594,6.061,27.326,6.908,41.465,6.121a196.8,196.8,0,0,0,54.133-10.766c19.763-6.938,38.14-16.991,57.832-24.029s41.749-10.965,61.9-5.094c18.945,5.518,33.9,18.915,47.388,32.482A575.8,575.8,0,0,1,508.317,106.8c12.17,17.051,23.5,35.039,28.876,54.911,10.29,37.995-3.869,80.859-35.273,106.807-26.02,21.5-60.938,30.827-95.119,36.489-31.17,5.164-67.21,5.483-95.9,18.94C283.538,336.8,258.641,352.64,229.949,363.555Z"
              transform="matrix(0.602, 0.799, -0.799, 0.602, 223.194, -137.819)"
              fill="#ffe2dc"
            />
          </g>
        </>
      )}
    </SVG>
  );
};
