import React from 'react';
import { Link } from '@reach/router';
import { paths } from '../../../utils';

type Props = {
  children: any;
  to: string;
  className?: string;
  activeColor?: string;
  isNested?: boolean;
};
export const NavLink = ({
  className,
  isNested,
  activeColor,
  to,
  ...props
}: Props) => {
  return (
    <Link
      {...props}
      to={to === paths.programs ? paths.programs + paths.rewardSystem : to}
      getProps={({ isPartiallyCurrent, isCurrent, href, location }) => {
        // the object returned here is passed to the
        // anchor element's props

        const active =
          ((isCurrent && href === '/') ||
            (isPartiallyCurrent && href !== '/')) &&
          to !== paths.programs;

        const isCamaign = active && location.pathname.includes('campaign');

        return {
          className: !active
            ? className
            : `${className} active-link ${
                isCamaign ? 'active-link-campaign' : ''
              }`,
          style: {
            color: active ? '#222 !important' : '',
            backgroundColor: active ? 'rgba(255,102,69, 0.1)' : 'white',
          },
        };
      }}
    />
  );
};
