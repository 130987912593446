import { Tab, Tabs as MuiTabs } from '@material-ui/core';
import React from 'react';
import { uuid } from 'uuidv4';
import { useStyles } from './styled';

interface Props {
  values: Array<{ val: string; label: string }>;
  handleChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  value: string;
  bColor?: string;
  borderColor?: string;
}

export const Tabs = ({
  values,
  handleChange,
  value,
  bColor,
  borderColor,
}: Props) => {
  const classes = useStyles({ bColor, borderColor });

  return (
    <MuiTabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      className={classes.tabs}
      TabIndicatorProps={{
        style: { background: '', height: '0', top: '0' },
      }}
      centered
    >
      {values.map((val: any, idx: number) => (
        <Tab
          key={uuid()}
          value={val.val}
          label={val.label}
          className={classes.tab}
        />
      ))}
    </MuiTabs>
  );
};
