import { Paper } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Paper
        elevation={0}
        style={{
          padding: '23px 16px',
          height: 70,
          borderRadius: '4px',
          border: '1px solid #E8E8E8',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 40,
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: '#292222',
          }}
        >
          {t('reward:rewardSystem')}
        </p>
      </Paper>
    </div>
  );
};
