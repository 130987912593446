import { Router } from '@reach/router';
import React, { useState } from 'react';
import { Route } from '../components/Common';
import { useScript, useScriptWithUrl } from '../hooks/useScript';
import Branding from '../pages/branding';
import { Campaigns } from '../pages/campaigns';
import Home from '../pages/home';
import NotFound from '../pages/notFound';
import Notifications from '../pages/notifications';
import Programs from '../pages/programs';
import { Referral } from '../pages/programs/referral/index';
import { Reviews } from '../pages/reviews';
import segmentation from '../pages/segmentation';
import Settings from '../pages/settings';
import { paths } from '../utils';
import { Layout } from './PagesLayout';
import { RequireIntegration } from './Routes/authorization/require-integration';
import useSWR, { useSWRConfig } from 'swr';
import { useGlobalState } from './GlobalState';
import { setPhoneNumber } from '../api';
import { Button, Dialog, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const CompletedApp = () => {
  const { user } = useGlobalState();
  useBeamAndUsetiful(`${user?.idMerchant}-${user?.owner}`);
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data } = useSWR(`/settings/checkphoneNumber/${user?.idMerchant}`);
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  const isPersonalPhoneNumberAdded =
    data?.data?.isPersonalPhoneNumberAdded ?? false;

  const setPhoneNumberRequest = async () => {
    setLoading(true);
    setError(null);
    try {
      await setPhoneNumber(phone, user?.idMerchant!);
      mutate(`/settings/checkphoneNumber/${user?.idMerchant}`);
    } catch (error) {
      setError(
        (error as any)?.response?.data?.message ??
          (error as any)?.message ??
          'server error'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Dialog open={!isPersonalPhoneNumberAdded && data}>
        <div
          style={{
            height: '73px',
            backgroundColor: '#FF6645',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 25px',
          }}
        >
          <p style={{ color: 'white', fontWeight: 500, fontSize: '18px' }}>
            {t('phone-submission-header')}
          </p>
        </div>
        <form
          style={{
            padding: '20px 40px',
          }}
          onSubmit={(event) => {
            event.preventDefault();

            setPhoneNumberRequest();
          }}
        >
          <p
            style={{
              fontSize: '17px',
              fontWeight: 500,
              color: '#1A2D4C',
              marginBottom: '15px',
            }}
          >
            {t('phone-submission-label')}
          </p>
          <TextField
            placeholder="05XXXXXXXX"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              height: '50px',
              backgroundColor: '#F9F9FF',
              border: '1px solid #F1F1F5',
              borderRadius: '10px',
              fontSize: '15px',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '16px',
            }}
            required
            inputProps={{
              style: { textAlign: 'center', direction: 'ltr' },
            }}
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: '15px',
                color: '#292222',
                fontWeight: 500,
              },
            }}
          />
          {error && (
            <p
              style={{
                color: '#B00020',
                fontWeight: 500,
                fontSize: '12px',
                marginBottom: 16,
                textAlign: 'center',
              }}
            >
              {error}
            </p>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{ fontSize: '16px', padding: '12px 50px' }}
              disabled={loading}
              size="small"
              variant="contained"
              color="primary"
              type="submit"
            >
              {loading ? t('submitting') : t('save')}
            </Button>
          </div>
        </form>
      </Dialog>
      <Router>
        <Route path={paths.dashboard} component={Home} />
        <RequireIntegration path={paths.programs} component={Programs} />
        <RequireIntegration path={paths.programs + '/*'} component={Programs} />
        <RequireIntegration path={paths.branding} component={Branding} />
        <RequireIntegration path={paths.settings} component={Settings} />
        <RequireIntegration
          path={paths.notifications}
          component={Notifications}
        />

        <RequireIntegration
          path={paths.campaign + '/*'}
          component={Campaigns}
        />

        <RequireIntegration path={paths.settings + '/*'} component={Settings} />
        <RequireIntegration
          path={paths.segmentation + '/*'}
          component={segmentation}
        />
        <RequireIntegration
          path={paths.referrals + '/*'}
          component={Referral}
        />
        <RequireIntegration path={paths.reviews + '/*'} component={Reviews} />
        <NotFound default />
      </Router>
    </Layout>
  );
};

const useBeamAndUsetiful = (userInfo: string) => {
  useScript(
    'body',
    ` <!-- User segmentation start -->
    <script>
    window.usetifulTags = { userId : ${userInfo},
    };</script>
    <!-- User segmentation end -->
    <!-- Usetiful script start -->
                <script>
    (function (w, d, s) {
        var a = d.getElementsByTagName('head')[0];
        var r = d.createElement('script');
        r.async = 1;
        r.src = s;
        r.setAttribute('id', 'usetifulScript');
        r.dataset.token = "f6238612570cea11625ab0e20af5f0a3";
                            a.appendChild(r);
      })(window, document, "https://www.usetiful.com/dist/usetiful.js");</script>

    <!-- Usetiful script end -->
    `
  );
  useScript(
    'body',
    `   var beamer_config = {
    product_id: "RHwMZkQq49584" //DO NOT CHANGE: This is your product code on Beamer
  };`
  );
  useScriptWithUrl('https://app.getbeamer.com/js/beamer-embed.js');
};
