import { Redirect, RouteComponentProps, useLocation } from '@reach/router';
import React, { FC, FunctionComponent } from 'react';
import { useGlobalState } from '../../GlobalState';

interface Props {
  component: FunctionComponent;
}

export const NotRequireMobileVerification: FC<Props & RouteComponentProps> = ({
  component: Component,
  ...props
}) => {
  const { user } = useGlobalState();
  const location = useLocation();

  if (!user) {
    return <Redirect from={location.pathname} to="/signin" noThrow />;
  }

  if (user && user.verified && user.completed) {
    return <Redirect from={location.pathname} to="/" noThrow />;
  }

  if (user && user.verified && !user.completed) {
    return (
      <Redirect from={location.pathname} to="/platform-integration" noThrow />
    );
  }

  return <Component {...props} />;
};
