import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mainColor } from '../../../utils';

import { Layout } from './styled';
import { ReactComponent as BonatLogoPlugin } from '../../../assets/svg/plugin-bonat.svg';
import Plugin1 from '../../../assets/img/plugin-1.png';
import PluginFooter from '../../../assets/img/plugin-footer.png';
import { useTheme } from '@material-ui/core';
import webPreviewImage from '../../../assets/img/brand-backgroound-web.png';
import mobilePreviewImage from '../../../assets/img/brand-backgroound-mobile.png';

type Props = {
  bColor: string;
  buttonContent: string;
  buttonIcon: any;
  viewBonat: boolean;
};

export const Brand = ({
  bColor = mainColor,
  buttonContent,
  buttonIcon,
  viewBonat,
}: Props) => {
  const [preview, setPreview] = useState(0);
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyle();

  return (
    <div style={{ flexGrow: '1' }}>
      <div style={{ fontSize: '16px', color: '#292222' }}>
        {t('branding:preview')}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            position: 'relative',
            minHeight: preview === 0 ? 559 : 615,
            minWidth: preview === 0 ? 764 : 345,
          }}
        >
          <img
            src={preview === 0 ? webPreviewImage : mobilePreviewImage}
            alt="webPreviewImage"
          />
          <Layout bColor={bColor} left={preview === 1 ? '-35px' : '-52px'}>
            <header style={{ backgroundColor: bColor }}>
              <Box
                className={classes.header}
                bgcolor="white"
                borderRadius={4}
                width={400}
                border={`1px solid ${bColor}`}
              >
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span> {t('branding:youHave')}</span>{' '}
                  <span
                    style={{ fontSize: 32, fontWeight: 600, margin: '2px 8px' }}
                  >
                    00
                  </span>{' '}
                  <span>{t('branding:pointsNow')}</span>
                </p>
              </Box>
            </header>
            <main>
              <Box className="body column center" mt={4}>
                <img
                  src={Plugin1}
                  alt="Become a member"
                  width={200}
                  height={200}
                />
                <h2>{t('branding:becomeAMember')}</h2>
                <p style={{ fontSize: 12, fontWeight: 300 }}>
                  {t('branding:pluginDesc')}
                </p>
                <div style={{ width: 150, cursor: 'event-pointer' }}>
                  <button>{t('branding:joinNow')}</button>
                </div>
                <p style={{ fontSize: 12 }}>
                  {t('branding:alreadyHaveAnAccount')}{' '}
                  <span style={{ color: bColor }}>
                    {' '}
                    <span
                      style={{
                        color: theme.palette.primary.main,
                        fontWeight: 400,
                      }}
                    >
                      {t('branding:signin')}
                    </span>
                  </span>
                </p>
              </Box>
            </main>
            <footer>
              {viewBonat && (
                <p className="flex center" style={{ fontWeight: 400 }}>
                  {t('branding:poweredBy')}{' '}
                  <BonatLogoPlugin style={{ marginLeft: 6, marginRight: 6 }} />
                </p>
              )}

              <div className={`plugin-logo ${classes.logo}`}>
                <button
                  style={{ width: 'auto' }}
                  className="flex space-between"
                >
                  <img
                    src={buttonIcon}
                    alt=""
                    style={{ width: 20, height: 20, margin: 0, color: 'white' }}
                  />
                  <p
                    style={{
                      marginLeft: 8,
                      marginRight: 8,
                      color: 'white',
                      fontWeight: 400,
                      fontSize: 12,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {buttonContent}
                  </p>
                </button>
              </div>
            </footer>
          </Layout>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '50px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p
          onClick={() => setPreview(0)}
          style={{
            cursor: 'pointer',
            ...(preview === 0 && {
              color: '#FF6645',
              borderBottom: '#FF6645 solid 3px',
            }),
          }}
        >
          {t('branding:web')}
        </p>
        <p
          onClick={() => setPreview(1)}
          style={{
            cursor: 'pointer',
            ...(preview === 1 && {
              color: '#FF6645',
              borderBottom: '#FF6645 solid 3px',
            }),
          }}
        >
          {t('branding:mobile')}
        </p>
      </div>
    </div>
  );
};

const useStyle = makeStyles((theme: any) => {
  return {
    header: {
      transform: 'translateX(-50%)',
      position: 'absolute',
      top: '40%',
      left: '50%',
      padding: '12px',
      maxWidth: '90%',
    },
    logo: {
      position: 'absolute',
      height: 'fit-content',
      right: 0,
      bottom: -60,
    },
  };
});
