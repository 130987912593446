import { Box, Typography } from '@material-ui/core';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dev } from '../../../api';
import { useGlobalState } from '../../GlobalState';
import GrowthPlan from '../GrowthPlan';
import { Toggle } from '../Toggle';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';

interface Props {
  productsValues: { name: string; id: string }[];
  loading: boolean;
  isExcludedProducts: boolean;
  disabled: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ExcludeSpecificProducs: FC<Props> = memo(
  ({
    productsValues,
    isExcludedProducts,
    loading,
    setFieldValue,
    disabled,
  }) => {
    const { user, setAlert } = useGlobalState();
    const { t } = useTranslation();
    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [fetchMoreProductsLoading, setFetchMoreProductsLoading] =
      useState(false);
    const [page, setPage] = useState(1);
    const [noMore, setNoMore] = useState(false);
    const searchOptions = useRef(
      debounce((keyword: string, callback) => {
        dev
          .get(
            `/reward/merchantProducts/${user?.idMerchant}/ar?keyword=${keyword}`
          )
          .then(({ data }) =>
            callback(
              data.data.map((product: any) => ({
                label: product.name,
                value: product.id,
              }))
            )
          );
      }, 300)
    );

    const canExcludeProducts =
      user?.permissions?.['RewardsModule']?.['Exclude Specific Products'];

    const fetchMoreProducts = async () => {
      if (!!noMore) return;
      try {
        setFetchMoreProductsLoading(true);
        const { data } = await dev.get(
          `/reward/merchantProducts/${user?.idMerchant}/ar?page=${page}`
        );

        if (data.data.length === 0) {
          setNoMore(true);
        }

        setProductsOptions((prevProducts) => [
          ...prevProducts,
          ...(data?.data ?? []),
        ]);
        setPage((prevPage) => prevPage + 1);

        setFetchMoreProductsLoading(false);
      } catch (err) {
        const error =
          err?.response?.data?.message || err.message || err?.data?.message;
        setAlert(error, 'error');
      }
    };

    useEffect(() => {
      fetchMoreProducts();
    }, []);

    if (!isExcludedProducts) return null;
    return (
      <>
        <Typography
          variant="caption"
          style={{ fontSize: 14, color: '#8181a5' }}
        >
          {t('reward:exclude-specific-products-search-label')}
        </Typography>
        <AsyncSelect
          isMulti
          name="products"
          menuPlacement="bottom"
          isDisabled={!canExcludeProducts || disabled}
          cacheOptions={false}
          isLoading={fetchMoreProductsLoading}
          loadOptions={searchOptions.current}
          styles={{
            dropdownIndicator: (base, state) => ({
              ...base,
              transition: 'all .2s ease',
              transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
            }),
            option: (base, state) => ({
              ...base,
              color: '#111111',
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#FF6645',
              primary75: '#FF856A',
              primary50: '#FFA490',
              primary25: '#FFC3B5',
              neutral50: '#FF6645',
            },
          })}
          closeMenuOnSelect={false}
          onChange={(products) =>
            setFieldValue(
              'excludedProducts',
              products.map((product) => ({
                name: product.label,
                id: product.value,
              }))
            )
          }
          value={productsValues.map((product) => ({
            value: product.id,
            label: product.name,
          }))}
          loadingMessage={() => t('loadingMore')}
          isOptionDisabled={(option) => option.value === 'loading'}
          defaultOptions={[
            ...productsOptions.map((product) => ({
              value: product.id,
              label: product.name,
            })),
            ...(fetchMoreProductsLoading
              ? [
                  {
                    value: 'loading',
                    label: t('loadingMore'),
                  },
                ]
              : []),
          ]}
          onMenuScrollToBottom={fetchMoreProducts}
          placeholder={t('reward:product-name')}
          noOptionsMessage={() => t('reward:no-options')}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </>
    );
  }
);
