import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { patchPoints } from '../../../../api';
import { ReactComponent as PointActionIcon } from '../../../../assets/svg/point_action.svg';
import { paths, usePost } from '../../../../utils';
import { Button, TextInput } from '../../../Common';

export const statusInitial = {
  error: null,
  isSuccess: false,
  loading: false,
};

export const PointActionForm = ({ customerId, isAdd, handleClose }) => {
  const { t } = useTranslation();

  const [{ loading }, submitForm] = usePost({
    apiFunction: patchPoints,
    updateUser: false,
    to: paths.segmentation + '/allCustomers',
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const onConfirm = async (points) => {
    await submitForm({
      points: parseInt(isAdd ? points : -points),
      customerId,
    });
  };

  const validationSchema = yup.object({
    points: yup.number().required(t('requiredField')),
  });
  return (
    <Formik
      initialValues={{
        points: 0,
      }}
      validationSchema={validationSchema}
      onSubmit={async ({ points }) => {
        await onConfirm(points);
        handleClose();
      }}
    >
      {() => {
        return (
          <Form>
            <Box px={4} py={2}>
              <p className="bold-text" style={{ marginBottom: 24 }}>
                {t('segmentation:numberOfPoints')}
              </p>

              <TextInput
                name="points"
                fullWidth
                variant="filled"
                disableUnderline
                placeholder="0"
                startAdornment={<PointActionIcon />}
                endAdornment={t('points')}
                type="number"
                padding={8}
                textAlign="center"
                errorObj={{}}
                autoFocus
              />

              <Box textAlign="right" mt={8}>
                <Button
                  padding="12px 48px"
                  margin="16px  0 0 0"
                  size="small"
                  loading={loading}
                  type="submit"
                  bColor="primary"
                >
                  {loading ? t('submitting') : t('save')}
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
