import { dev, shared } from '.';

export const uploadImage = async ({ type, file }: any) => {
  try {
    const {
      data: { data },
    } = await dev.post('/image', file, {
      headers: { 'Content-Type': type },
    });

    return data[0].imageURL;
  } catch (err) {
    return err?.response?.data?.message || err.message || err?.data?.message;
  }
};

export const prepareCheckout = async ({ body }: any) =>
  await shared.post(`/checkout`, body);

export const updatePaymentStatus = async ({ id, resourcePath }: any) =>
  await shared.get(
    `/updatePaymentFromWeb?id=${id}&&resourcePath=${resourcePath}`
  );
