import { Button as CoreButton, Paper } from '@material-ui/core';
import { useNavigate } from '@reach/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import { patchNotificationAPI } from '../../api/notifications';
import { Popup } from '../../components/Common';
import { IOSSwitch } from '../../components/Common/Toggle/styled';
import { useGlobalState } from '../../components/GlobalState';
import { Guard } from '../../components/Routes/Guard';
import { usePost } from '../../utils';

type Notification = {
  description: string;
  enableEmail: boolean;
  enableSms: boolean;
  idNotification: number;
  name: string;
};

export default function Notifications() {
  const { isEnglish, user } = useGlobalState();
  const { t } = useTranslation();
  const { data } = useSWR('/merchant/notifications/settings', {});
  const { mutate } = useSWRConfig();
  const [, submitForm] = usePost({
    apiFunction: patchNotificationAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });
  const [activiationModal, setActiviationModal] = useState(false);
  const navigate = useNavigate();

  const notifications: Notification[] = data?.data ?? [];

  const accessDenied = !user?.permissions?.['NotificationsModule']?.['Edit'];

  const onToggle =
    (type: 'enableEmail' | 'enableSms', updatedNotification: Notification) =>
    async (value: boolean) => {
      try {
        await submitForm({
          body: {
            idNotification: updatedNotification.idNotification,
            ...(type === 'enableEmail'
              ? {
                  enableEmail: value,
                  enableSms: updatedNotification.enableSms,
                }
              : {
                  enableSms: value,
                  enableEmail: updatedNotification.enableEmail,
                }),
          },
          merchantId: user?.idMerchant,
        });

        mutate('/merchant/notifications/settings');
      } catch {
        setActiviationModal(true);
      }
    };

  return (
    <Guard permission="NotificationsModule">
      <div>
        <Popup
          open={activiationModal}
          handleClose={() => setActiviationModal(false)}
          title={t('activate-sms-service')}
          maxWidth="sm"
          fullWidth={true}
          data={[]}
          resetForm={() => {}}
        >
          <div style={{ margin: 'auto' }}>
            <p style={{ marginTop: '15px' }}>
              {t('activate-sms-service-desc')}
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '50px',
                marginBottom: '15px',
                marginTop: '25px',
              }}
            >
              <CoreButton
                style={{ fontSize: '14px', padding: '5px 27px' }}
                size="small"
                color="primary"
                variant="contained"
                onClick={() => navigate('/settings/integrations')}
              >
                {t('activate')}
              </CoreButton>
              <CoreButton
                style={{ fontSize: '14px', padding: '5px 27px' }}
                size="small"
                onClick={() => setActiviationModal(false)}
                variant="contained"
              >
                {t('cancel')}
              </CoreButton>
            </div>
          </div>
        </Popup>
        <Paper
          elevation={0}
          style={{
            padding: '23px 16px',
            height: 70,
            borderRadius: '4px',
            border: '1px solid #E8E8E8',
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <p
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: '#292222',
            }}
          >
            {t('notifications-managment')}
          </p>
        </Paper>
        <Paper
          elevation={0}
          style={{
            padding: '23px 16px',
            borderRadius: '4px',
            border: '1px solid #E8E8E8',
          }}
        >
          <p
            style={{
              fontSize: 17,
              fontWeight: 600,
              color: '#292222',
              marginBottom: 20,
            }}
          >
            {t('customer-notifications')}
          </p>

          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '22px' }}
          >
            {notifications.map((notification, idx) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flexGrow: 1 }}>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: '#292222',
                    }}
                  >
                    {t(`notification-${notification.idNotification}`)}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: '#8181A5',
                    }}
                  >
                    {t(`notification-desc-${notification.idNotification}`)}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '250px',
                    ...(isEnglish
                      ? { marginRight: 'calc(10% - 47.7px)' }
                      : { marginLeft: 'calc(10% - 47.7px)' }),
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '97.74px',
                    }}
                  >
                    {idx === 0 && (
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: '#292222',
                        }}
                      >
                        {t('email')}
                      </p>
                    )}
                    <ActivationToggle
                      disabled={true}
                      onChange={onToggle('enableEmail', notification)}
                      value={notification.enableEmail}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '97.74px',
                    }}
                  >
                    {idx === 0 && (
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: '#292222',
                        }}
                      >
                        {t('sms')}
                      </p>
                    )}
                    <ActivationToggle
                      disabled={accessDenied}
                      onChange={onToggle('enableSms', notification)}
                      value={notification.enableSms}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Paper>
      </div>
    </Guard>
  );
}

const ActivationToggle = ({
  value,
  onChange,
  disabled,
  style = {},
  size = 'medium',
}: {
  value: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small' | undefined;
  onChange?: (isActive: boolean) => void;
  style?: React.CSSProperties | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <IOSSwitch
      disabled={disabled}
      checked={value}
      onChange={(e, checked) => onChange?.(checked)}
      name="checked"
      size={size}
    />
  );
};
