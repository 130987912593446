import styled from '@emotion/styled';
import { mq } from '../../../utils';

export const Layout = styled.div`
  > * {
    margin-bottom: 5px;
  }

  grid-column: 2/4;
  display: flex;
  position: relative;

  .icon {
    margin-right: 1em;
    margin-left: 1em;
  }

  .absolute {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .more-info {
    margin-top: 1em;
  }

  h4 {
    font-weight: 500;
  }

  .list-card {
    flex: 1;
    .list {
      margin: 5px 0;
    }
  }
  p {
    font-size: 15px;
    font-weight: 500;

    span {
      font-weight: 400;
    }
  }

  ${mq('tablet')} {
    grid-column: -1/1;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;
