import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    padding: '16px 0',

    '& *': {
      cursor: 'default !important',
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0) !important',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '64px !important',
      height: '100%',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '64px !important',
      height: '100%',
      padding: '0px !important',
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: '0px !important',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px !important',
    },
    '& .MuiAccordion-root': {
      '&::before': {
        display: 'none',
      },
    },

    '& .MuiPaper-elevation1': {
      boxShadow: 'none !important',
    },
  },
  summary: {
    marginBottom: 16,
  },
}));

export function Accordion({
  DisplayedChildren,
  HiddenChildren,
  expanded,
}: {
  DisplayedChildren: any;
  HiddenChildren: any;
  expanded: boolean;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiAccordion expanded={expanded}>
        <AccordionSummary className={classes.summary}>
          {DisplayedChildren}
        </AccordionSummary>
        <AccordionDetails>{HiddenChildren}</AccordionDetails>
      </MuiAccordion>
    </div>
  );
}
