import { makeStyles } from '@material-ui/core';

export * from './customerActivityTable';
export * from './customerStatistics';
export * from './header';
export * from './personalInfo';
// export * from './actionbutton';
// export * from './pointActionForm';

export const useProfileStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 12,
    width: 16,
  },
  expandIcon: {
    cursor: 'pointer',
    position: 'absolute',
    padding: '8px 40px',
    border: '1px solid #F6F7F8',
    borderRadius: 24,
    top: '100%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    '& p': {
      margin: '0 8px',
    },
  },
  statsBox: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  label: {
    color: '#606084',
    fontSize: 13,
    fontWeight: 400,
  },
  value: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 400,
    margin: '0 4px',
  },
  starIcon: {
    marginRight: 8,
  },
}));
