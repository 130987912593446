//@ts-nocheck

import React from 'react';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import { uuid } from 'uuidv4';
import Cropper from 'react-easy-crop';
import { Box, useTheme } from '@material-ui/core';

import { Button, ImageLoader } from '../';
import { Layout } from './styled';
import { getCroppedImg } from './helper';
import { useGlobalState } from '../../GlobalState';

type Image = {
  img: string;
  file: string;
  id: number;
  type: string;
  loading: boolean;
};
type Props = {
  image: { image: Image; preview: Image };
  setImage: React.Dispatch<React.SetStateAction<any>>;
  id: string;
  hideInputOnLoad: boolean;
  rouneded: 0 | 1;
  width?: string;
  height?: string;
  loading?: boolean;
};
export const ImageUploaderWithCrop = ({
  image,
  setImage,
  id,
  hideInputOnLoad,
  rouneded,
  width = '60px',
  height = '60px',
  loading,
}: Props) => {
  function isImage(icon: string) {
    const ext = ['jpg', 'jpeg', 'bmp', 'gif', 'png', 'tiff', 'heif', 'webp'];
    return ext.some((el) => icon.endsWith(el));
  }
  const theme = useTheme();

  const { setAlert } = useGlobalState();

  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [rotation, setRotation] = React.useState(0);

  const onCropComplete = React.useCallback(
    async (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const onInteractionEnd = () => {
    showCroppedImage(croppedAreaPixels);
  };

  const showCroppedImage = React.useCallback(
    async (croppedAreaPixels) => {
      try {
        const isAFile = !!image.image.file;

        // const croppedImage = await getCroppedImg(
        //   image.image.img,
        //   croppedAreaPixels,
        //   rotation
        // );

        // const res =
        //   isAFile && (await dev.get(image.image.img, { responseType: 'blob' }));

        const croppedImage = await getCroppedImg(
          isAFile
            ? image.image.img
            : URL.createObjectURL(
                new Blob([image.image.img], { type: 'image/jpeg' })
              ),
          croppedAreaPixels,
          rotation
        );

        croppedImage.id = isAFile ? image.id : croppedImage;

        setImage({ ...image, image: croppedImage });
        console.log('donee', { croppedImage });
      } catch (e) {
        console.error(e);
      }
    },
    [image, rotation, setImage]
  );

  return (
    <Box display="flex" justifyContent="space-between">
      <Box
        position="relative"
        width={width}
        height={height}
        borderRadius={3}
        overflow="hidden"
        mr={4}
        border={`1px solid ${theme.palette.primary.main}`}
      >
        <Cropper
          image={image.preview.img}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onInteractionEnd={onInteractionEnd}
        />
      </Box>
      <Layout width={width} height={height}>
        <Button
          bColor="secondary"
          backcolor="#F6F7F8"
          type="button"
          color="black"
          fullwidth={0}
          isUploader={true}
          rouneded={rouneded ? 1 : 0}
        >
          {hideInputOnLoad && image.image.img ? (
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                position: 'relative',
              }}
            >
              <img
                src={image.image.img}
                style={{ width: '30px', height: '30px' }}
                alt="upload"
              />

              {image.image.loading && <ImageLoader />}
            </div>
          ) : (
            <BackupOutlinedIcon
              style={{
                color: '#8181A5',
                backgroundColor: 'transparent',
              }}
            />
          )}
          <input
            id={id}
            type="file"
            accept="image/*"
            onChange={(e: any) => {
              const target = e.target.files[0];

              if (isImage(target.type)) {
                const newImage = {
                  img: URL.createObjectURL(target),
                  id: uuid(),
                  type: target.type,
                  file: target,
                };

                setImage({
                  image: newImage,
                  preview: newImage,
                });
              } else
                setAlert(
                  "Your photos couldn't be uploaded. Photos should be saved as JPG, PNG, GIF, TIFF, HEIF or WebP files.",
                  'error'
                );
            }}
            style={{ display: 'none' }}
          />
        </Button>
      </Layout>
    </Box>
  );
};
