import { Link } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import Announcement from '../../../assets/img/announcement.png';
import { BoxLoader } from '../../Common';
import { Layout } from './styled';

interface Data {
  isExternalLink: boolean;
  id: string | number;
  description: string;
  to: string;
}
export const ActionRequired = () => {
  const { data: { data } = { data: [] }, error }: any =
    useSWR('/merchant/actions');
  const { t } = useTranslation();

  if (error)
    return (
      <h3 className="title">
        Opps, {JSON.stringify(error)} please try again later
      </h3>
    );

  if (Array.isArray(data)) {
    if (data?.length > 0)
      return (
        <Layout className="mr-bottom hehe">
          {data?.map((action: Data, idx: number) => (
            <li
              key={action.id}
              className={`space-between card ${
                idx === data?.length - 1 && 'last'
              }`}
            >
              x
              <div className="announcement flex center">
                <img src={Announcement} alt="Announcement" />
                <p>{action.description}</p>
              </div>
              {action.to &&
                (action.isExternalLink ? (
                  <a
                    href={action.to}
                    className="card flex center"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t('home:goToAdmin')}
                  </a>
                ) : (
                  <Link to={action.to} className="card flex center">
                    Activate
                  </Link>
                ))}
            </li>
          ))}
        </Layout>
      );
    else return <></>;
  }
  return (
    <>
      <BoxLoader width={400} height={30} />
      <BoxLoader width={400} height={30} />
    </>
  );
};
