import { Router } from '@reach/router';
import React from 'react';
import { Route } from '../../../components/Common';
import { Guard } from '../../../components/Routes/Guard';
import { EditRoutes } from './form';
import { Main } from './main';

export const EarningWays = () => {
  return (
    <Guard permission="EarningPointsModule">
      <Router>
        <Route path="/" component={Main} />
        <Route path="/edit/:id" component={EditRoutes} />
      </Router>
    </Guard>
  );
};
