import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../../../assets/svg/search.svg';
import { MuiSelect } from '../../../../components/Common';
import { ACTIONS } from '../index';
import { ExportCustomersButton } from './exportCustomersButton';
import { Filter } from './filterButton';

export const MobileHeader = ({
  state,
  dispatch,
  handleClick,
  handleClose,
  segmentType,
  allCustomers,
  anchorEl,
  dataToExport,
  handleExportClick,
}) => {
  const { t } = useTranslation();
  const LIMITOPTIONS = [
    {
      value: 5,
      label: `5 ${t('results')}`,
    },
    {
      value: 10,
      label: `10 ${t('results')}`,
    },
    {
      value: 15,
      label: `15 ${t('results')}`,
    },
    {
      value: 20,
      label: `20 ${t('results')}`,
    },
  ];
  const classes = useCustomersStyle();

  return (
    <Paper>
      <Grid container style={{ padding: '8px 16px' }} spacing={2}>
        <Grid
          item
          xs={12}
          className={`${classes.segmentHeader} flex align-center space-between`}
        >
          <Box display="flex" alignItems="center">
            <Typography component="h2" variant="h2">
              {t(`segmentation:${segmentType}`)} - {allCustomers}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              align="left"
              style={{
                marginRight: 5,
                fontSize: 14,
                color: '#8181A5',
                whiteSpace: 'nowrap',
              }}
            >
              {t('show')} :
            </Typography>
            <MuiSelect
              color="#8181A5"
              fontSize={14}
              disableUnderline
              name="show"
              optionLabel="label"
              optionValue="value"
              label=""
              options={LIMITOPTIONS}
              value={state.limit?.value}
              onChange={(e) => {
                const option = LIMITOPTIONS.filter(
                  (d) => d.value === e.target.value
                )[0];
                dispatch({ type: ACTIONS.SET_LIMIT, payload: option });
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="flex align-center space-between">
            <TextField
              name="search"
              placeholder={t('search')}
              onChange={(e) =>
                dispatch({
                  type: ACTIONS.SET_SEARCH,
                  payload: e.target.value,
                })
              }
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <Box px={1}>
                    <SearchIcon />
                  </Box>
                ),
              }}
            />
            <Box display="flex" alignItems="center">
              <Divider
                orientation="vertical"
                style={{ height: 20, background: '#eee', margin: '0 16px' }}
              />
              <Filter
                handleClick={handleClick}
                handleClose={handleClose}
                state={state}
                dispatch={dispatch}
                anchorEl={anchorEl}
              />
              <Divider
                orientation="vertical"
                style={{ height: 20, background: '#eee', margin: '0 16px' }}
              />
              <ExportCustomersButton
                dataToExport={dataToExport}
                handleExportClick={handleExportClick}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useCustomersStyle = makeStyles((theme) => ({
  segmentHeader: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start !important',
    },
  },
}));
