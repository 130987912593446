import merge from 'deepmerge';
import useSWR from 'swr';
import { useGlobalState } from '../components/GlobalState';

export const useUpdateSubscription = () => {
  const { setSubscription, updateUser } = useGlobalState();
  useSWR('/merchant/general-configuration', {
    onSuccess: ({ data }) => {
      setSubscription({
        end_date: data?.end_date,
        idsubscription_status: data?.idsubscription_status,
      });

      //@ts-ignore
      updateUser((user) => merge(user, { permissions: data.permissions }));
    },
  });
};
