import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundPage } from '../../components/Common';
import { NoIndex } from '../../components/Common/NoIndex';
import { Layout } from './styled';

type Props = {} & RouteComponentProps;

const NotFound: FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <>
      <NoIndex />
      <Layout className="center">
        <NotFoundPage
          title={t('pageNotFound')}
          isCenter={true}
          imgWidth={400}
        />
      </Layout>
    </>
  );
};

export default NotFound;
