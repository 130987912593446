import { Backdrop, Divider, Menu, MenuItem } from '@material-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';
import { useGlobalState } from '../../GlobalState';
import { RenderIcon, renderIcon } from '../../SVG';
import { CircularLoader } from '../Loaders';
import { NotFoundPage } from '../notFoundPage';
import { Item, useStyles } from './styled';

interface Props {
  children: any;
  options: Array<{
    title: string;
    title_ar?: string;
    icon?: string;
    message?: string;
    message_ar?: string;
    Logo?: React.FC;
    handleClick?: React.Dispatch<any>;
    id?: string;
    isnew?: 1 | 0;
    redirectUrl?: string;
  }>;
  withoutBackdrop?: boolean;
  isNotification?: boolean;
  isLoading?: boolean;
  handleClosing?: () => void;
  handleClicking?: () => void;
}

export function DropMenu({
  children,
  options,
  withoutBackdrop,
  isLoading,
  handleClosing,
  handleClicking,
  isNotification,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles({});
  const { t } = useTranslation();
  const { isEnglish } = useGlobalState();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    handleClicking && handleClicking();
  };

  const handleClose = () => {
    setAnchorEl(null);
    // make notifications read after close the menu
    handleClosing && handleClosing();
  };

  return (
    <>
      {!withoutBackdrop && (
        <Backdrop
          className={classes.backdrop}
          open={Boolean(anchorEl)}
          onClick={handleClose}
        ></Backdrop>
      )}
      <Item
        className="clickable"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {children}
      </Item>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={classes.menu}
        MenuListProps={{
          style: {
            padding: '0',
            maxHeight: '400px',
            overflowY: 'scroll',
          },
        }}
      >
        {isLoading ? (
          <MenuItem className={classes.item}>
            {/* <h3 className="title">Loading</h3> */}
            <CircularLoader />
          </MenuItem>
        ) : options.length < 1 ? (
          <NotFoundPage
            title={t('home:noActionHasBeenMadeLastday')}
            isCenter={true}
            padding="40px"
          />
        ) : (
          options.map((item, idx) => {
            return (
              <div key={uuid()}>
                <MenuItem
                  style={{ cursor: 'pointer' }}
                  onClick={(...props) => {
                    //@ts-expect-error

                    handleClose(props);
                    item.handleClick && item.handleClick(item.id);
                    if (item.redirectUrl) {
                      navigate(item.redirectUrl);
                    }
                  }}
                  className={`
                  ${classes.item}  ${item?.isnew === 1 ? classes.isNew : 'nono'}
                `}
                >
                  {item.icon && (
                    <div className="icon" style={{ marginRight: '0.5rem' }}>
                      {renderIcon(
                        item.icon
                          ? item.icon
                          : isNotification
                          ? 'usedCoupons'
                          : ''
                      )}
                    </div>
                  )}
                  <RenderIcon
                    logo={item.Logo && item.Logo}
                    text={isEnglish ? item.title : item.title_ar ?? item.title}
                    weight={500}
                    bColor="#F2F2F6"
                    padding="0.5rem"
                    subString={
                      item.message && isEnglish
                        ? item.message
                        : item?.message_ar ?? item.message
                    }
                  />
                </MenuItem>
                <Divider className={classes.divider} />
              </div>
            );
          })
        )}
      </Menu>
    </>
  );
}
