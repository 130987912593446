import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton, Toggle } from '..';
import { useGlobalState } from '../../GlobalState';
import { Header } from './styled';

export interface ActivePorps {
  isActive?: boolean;
  title: string;
  handleChange?: (event: any) => void;
  withBack: boolean;
  withStatus: boolean;
  RightElement?: React.FC;
  logo?: any;
  padding?: string;
  minHeight?: string;
  className?: string;
}
export const SystemHeader = ({
  isActive,
  title,
  handleChange,
  withBack,
  withStatus,
  RightElement,
  logo,
  padding,
  minHeight,
  className,
}: ActivePorps) => {
  const { t } = useTranslation();
  const { isEnglish } = useGlobalState();
  return (
    <Header
      className={`card ${className}`}
      padding={padding ? padding : ''}
      minHeight={minHeight ? minHeight : ''}
    >
      <div>
        {withBack && (
          <Box style={{ transform: `rotate(${isEnglish ? 0 : '180deg'})` }}>
            {' '}
            <BackButton />{' '}
          </Box>
        )}
        {logo && <img src={logo} alt="logo" />}
        <Typography variant="h4">{title}</Typography>
      </div>
      {RightElement && <RightElement />}

      {handleChange && (
        <Box
          bgcolor={withStatus ? 'rgba(109, 210,48, 0.2)' : ''}
          px={1}
          py={0.5}
        >
          <Toggle
            checked={isActive}
            handleChange={handleChange}
            label={
              <p style={{ color: withStatus ? '#52CF07' : '' }}>
                {isActive ? t('activated') : t('disabled')}
              </p>
            }
          />
        </Box>
      )}
    </Header>
  );
};
