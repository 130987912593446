import { dev } from './index';

export const getPlatformsAPI = async () => await dev.get('/e-commerce-types');

export const getBusinessQuestions = async () =>
  await dev.get('/e-commerce-types/business-questions-options');

export const stepAPI = async <T>({
  step,
  merchantId,
  body,
  rewardType,
}: {
  step: T;
  merchantId: T | undefined;
  body: { link: string; id: number };
  rewardType: string | number;
}) => {
  const url = rewardType
    ? `/merchant/1/${merchantId}/${rewardType}`
    : `/merchant/1/${merchantId}`;
  return await dev.post(url, body);
};
