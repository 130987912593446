import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    position: 'absolute',
    right: 0,
    top: '50%',
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
    backgroundColor: (props: { hexa: string }) => props.hexa,
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    cursor: 'pointer',
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& div': {
      width: 25,
      height: 15,
      borderRadius: '50%',
    },
  },
  popover: {
    position: 'absolute',
    zIndex: 2020202022,
    right: 0,

    [theme.breakpoints.down('md')]: {
      bottom: '100%',
    },
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));
