import styled from '@emotion/styled';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      padding: 0,
      paddingBottom: '1rem',
    },
    closeButton: {
      position: 'absolute',
      right: '15px',
      top: '15px',
    },

    content: {
      // minHeight: 100,
      padding: '10px 0 30px 0',
      '& p': {
        fontSize: 16,
        color: '#1C1D21',
        fontWeight: 300,
      },
    },
    visible: { overflow: 'visible !important' },
    closeIcon: {
      position: 'absolute',
      right: 15,
      top: 15,
    },
  })
);

export const ButtonLayout = styled.div`
  width: 100%;

  .button-container {
    flex-basis: 60%;
    text-transform: lowercase;
  }

  p {
    flex-basis: 30%;
    font-weight: 400;
  }
`;
