import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { mainColor } from '../../../utils';

interface StyledProps {
  pointer?: string;
}

export const Container = styled<any>('div')`
  position: relative;
  width: 100%;

  input {
    padding: 10px;
  }
  .center {
    input {
      text-align: center !important;
    }
  }

  label {
    * {
      white-space: nowrap;
    }
    .logo {
      min-width: unset !important;

      margin-right: 10px;
    }
  }
`;
export const Icon = styled('div')<StyledProps>`
  .logo {
    min-height: unset !important;
  }
`;

export const CssTextField = withStyles({
  root: {
    '& .MuiFilledInput-underline::before': {
      borderBottomColor: '#eee !important',
    },

    '& .MuiFilledInput-underline::after': {
      borderBottomColor: `${mainColor} !important`,
    },

    '& .MuiFilledInput-underline:hover:before': {
      borderBottomColor: `${mainColor} !important`,
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: '#eee !important',
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: `${mainColor} !important`,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: `1px solid ${mainColor} !important`,
    },

    '& .MuiFilledInput-root': {
      backgroundColor: '#F5F5FA',
    },
    '& .MuiInputAdornment-root p': {
      color: '#111',
      fontSize: '14px',
    },
  },
})(TextField);

export const useStyles = makeStyles((theme: Theme) => {
  return {
    passwordIcon: {
      position: 'absolute',
      right: '4%',
      top: '50%',
      height: '20px',
      width: '20px !important',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    },
    inputIcon: {
      position: 'relative',
    },
    underline: {
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
    icon: {
      position: 'absolute',
      right: 0,
      top: '48%',
      fontSize: '19px',
    },
  };
});
