import styled from '@emotion/styled';

import { mq } from '../../../utils';

export const Layout = styled.div`
  .active-link {
    background-color: white !important;
    color: red !important;
  }

  > div {
    border: 0.5px solid #eee;
    display: flex;
    flex-direction: column;

    a,
    .button {
      display: flex;
      font-size: 14px;
      color: #000;
      padding: 2em;
      border-radius: 8px;

      .space {
        margin-left: 1em;
      }

      p {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  ${mq('tablet')} {
  }
`;
