import React from 'react';

import { Button } from '../index';
import { Container, Img, Text } from './styled';
import errorImage from '../../../assets/img/undraw-empty.png';

type PAGEERROR = {
  text: string;
  withoutButton?: boolean;
  logout?: boolean;
  buttonText?: string;
  children?: React.ReactElement;
};

export function PageError({
  text = '404. Page Not Found',
  withoutButton,
  logout,
  buttonText,
  children,
}: PAGEERROR) {
  return (
    <Container>
      <Img src={errorImage} />
      <Text>{text}</Text>
      {!withoutButton && (
        <div>
          {logout ? (
            //@ts-ignore
            <Button text={buttonText ? buttonText : 'logout'} onClick={logout}>
              {children}
            </Button>
          ) : (
            <a href="/">Go Home</a>
          )}
        </div>
      )}
    </Container>
  );
}

export const ActionError = ({
  text = '404. Page Not Found',
  children,
}: {
  text: string;
  children: React.ReactElement;
}) => {
  return (
    <Container>
      <Img src={require(`../../../assets/img/undraw-empty.png`)} />
      <Text>{text}</Text>

      {children}
    </Container>
  );
};
