import { AxiosResponse } from 'axios';
import { useReducer } from 'react';

import merge from 'deepmerge';

import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../components/GlobalState';

const initialValue: State = {
  data: { data: null },
  loading: false,
  error: null,
  success: false,
};

export type Action =
  | { type: 'FETCHING' }
  | { type: 'FETCHED'; payload: any }
  | { type: 'ERROR'; payload: string };

export type State = {
  data: any;
  loading: boolean;
  error: null | string;
  success: boolean;
};

export const reducer = (state: State = initialValue, action: Action): State => {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case 'FETCHED':
      return {
        ...state,
        error: null,
        loading: false,
        data: action.payload,
        success: true,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};
type Post = (body: any) => Promise<AxiosResponse<any>>;

export function usePost({
  apiFunction,
  updateUser,
  completed,
  to,
  deletedKey,
  alertOnSuccess,
}: {
  apiFunction: Post;
  updateUser?: boolean;
  completed?: boolean;
  to?: string;
  deletedKey?: string;
  alertOnSuccess?: string;
}) {
  const [state, dispatch] = useReducer(reducer, initialValue);
  const { updateUser: setUser, setAlert } = useGlobalState();
  const { t } = useTranslation();

  const fetchData = async (body: any) => {
    try {
      dispatch({ type: 'FETCHING' });
      const {
        data: { data },
      } = await apiFunction(body);
      dispatch({ type: 'FETCHED', payload: data?.[0] });

      if (alertOnSuccess) {
        setAlert(alertOnSuccess, 'success');
      }

      if (updateUser) {
        setUser((prevUser) => {
          if (deletedKey) {
            //@ts-ignore
            delete prevUser[deletedKey];
          }
          const newUser = merge(prevUser!, data?.[0]);

          if (completed) {
            newUser.completed = true;
          }

          return newUser;
        });
      }

      return data?.[0] ?? true;
    } catch (err) {
      console.log('err', err);

      const error =
        err?.response?.data?.message || err.message || err?.data?.message;

      dispatchError(error);

      setAlert(t(`serverError:${error}`), 'error');
    }
  };

  const dispatchError = (err: any) => {
    return dispatch({
      type: 'ERROR',
      payload: err,
    });
  };

  return [state, fetchData, dispatchError] as const;
}
