import {
  Box,
  Grid,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import subscribtionImg from '../../assets/svg/subscription_gifts.svg';

import { updatePaymentStatus } from '../../api';
import { BoxLoader, RadioSelectionGroup } from '../../components/Common';
import { useGlobalState } from '../../components/GlobalState';
import { useUpdateSubscription } from '../../hooks/useUpdateSubscription';
import { getParam, isZidFunc, TRANS_STATUS } from '../../utils';

const Subscription = React.memo(() => {
  const { t } = useTranslation();
  const { user, isEnglish, setUser, setAlert } = useGlobalState();
  useUpdateSubscription();
  const FILTERATIONOPTIONS = [
    { title: t('settings:monthly'), id: 'monthly' },
    { title: t('yearly'), id: 'yearly' },
  ];
  const [filterByTime, setFilterByTime] = React.useState(FILTERATIONOPTIONS[0]);

  const {
    data: { data: plans, isSuccess: isSuccessPlans } = {
      data: [],
      isSuccess: false,
    },
  }: any = useSWR(`/settings/subscription/plans`, {
    revalidateOnFocus: true,
  });
  const {
    data: { data: currentSubscription, isSuccess: isSuccessSubscription } = {
      data: [],
      isSuccess: false,
    },
  }: any = useSWR(`/settings/subscription/${user?.idMerchant!}`, {
    revalidateOnFocus: true,
  });

  const id = getParam('id');
  const resourcePath = getParam('resourcePath');
  React.useEffect(() => {
    const updatePayemnt = async () => {
      try {
        await updatePaymentStatus({ id, resourcePath });

        setAlert(t('paymentSuccess'), 'success');

        setUser({ ...user!, idsubscription_status: 1 });

        //@ts-ignore
        window.history.replaceState(null, null, window.location.pathname);
      } catch (err) {
        setAlert(t(TRANS_STATUS[7]), 'error');
      }
    };
    if (!!id && !!resourcePath) {
      updatePayemnt();
    } else {
      //@ts-ignore
      window.history.replaceState(null, null, window.location.pathname);
    }
  }, [id, resourcePath, setAlert, setUser, t, user]);

  if (!isSuccessSubscription || !isSuccessPlans)
    return <BoxLoader width={600} height={400} />;

  const isInActive = currentSubscription.idsubscription_status === 4;
  const isExpired = currentSubscription.idsubscription_status === 3;
  const isActive = currentSubscription.idsubscription_status === 2;

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Paper>
            <Box
              px={4}
              py={2}
              justifyContent="space-between"
              alignItems="center"
              display="flex"
            >
              <Box>
                <Box display="flex" alignItems="center">
                  <Typography style={{ fontWeight: 500 }} variant="h6">
                    {t('settings:currentSubscription')}
                  </Typography>
                  <SubscriptionStatus
                    id={currentSubscription.idsubscription_status}
                  />
                </Box>
                <Box mt={2}>
                  {currentSubscription.idsubscription_status === 2 && (
                    <>
                      {currentSubscription?.name_ar && (
                        <Typography
                          variant="h5"
                          style={{ color: '#84C731', fontWeight: 600 }}
                          gutterBottom
                        >
                          {isEnglish
                            ? currentSubscription.name_en
                            : currentSubscription.name_ar}
                        </Typography>
                      )}
                      <p style={{ marginBottom: 4 }}>
                        {t('startsOn')}{' '}
                        <span style={{ fontWeight: 600 }}>
                          {format(
                            new Date(currentSubscription.start_date ?? null),
                            'dd.MM.yyyy'
                          )}
                        </span>
                      </p>
                      <p>
                        {t('endsOn')}{' '}
                        <span style={{ fontWeight: 600 }}>
                          {format(
                            new Date(currentSubscription?.end_date ?? null),
                            'dd.MM.yyyy'
                          )}
                        </span>
                      </p>
                    </>
                  )}
                </Box>
              </Box>

              <Hidden xsDown>
                <img
                  width="220"
                  height="160"
                  src={subscribtionImg}
                  alt="Subscription"
                />
              </Hidden>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h2" gutterBottom>
                {t('settings:subscriptionPlans')}
              </Typography>
            </Box>

            <RadioSelectionGroup
              options={FILTERATIONOPTIONS}
              selected={filterByTime}
              setSelected={setFilterByTime}
            />
          </Box>
          <Box>
            <p style={{ fontWeight: 400 }}>{t('settings:planSubtitle')}</p>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        {plans.map((d: any, idx: number) => (
          <Grid key={idx} item xs={12} md={12} lg={6}>
            <SubscriptionCard
              features={d.planFeature}
              title={isEnglish ? d.name_en : d.name_ar}
              price={
                filterByTime.id === 'monthly'
                  ? d.monthly_price ?? t('soon')
                  : d.yearly_price ?? t('soon')
              }
              subscriptionPeriod={filterByTime.id}
              canResubscribe={currentSubscription.canResubscribe}
              idSubscriptionPlan={d.idSubscriptionPlan}
              currentPlanName={
                isEnglish
                  ? currentSubscription.name_en
                  : currentSubscription.name_ar
              }
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
});

export default Subscription;

const useSubscriptionStyle = makeStyles((theme) => ({
  cardTitle: {
    position: 'relative',
    width: 'fit-content',
    '&::after': {
      position: 'absolute',
      content: theme.direction === 'ltr' ? "'SAR'" : "'ر.س'",
      top: 4,
      fontSize: 16,
      right: -34,
    },
    '&::before': {
      position: 'absolute',
      content:
        theme.direction === 'ltr' ? "'Exclusive of VAT'" : "'لا تشمل الضريبة'",
      bottom: 4,
      fontSize: 12,
      fontWeight: 400,
      right: theme.direction === 'ltr' ? -103 : -88,
    },
  },
  absoluteBox: {
    transform: 'translateX(-50%)',
  },
  commingSoon: {
    right: theme.direction === 'ltr' ? 0 : 'unset',
    left: theme.direction === 'ltr' ? 'unset' : 0,
    top: 0,
    position: 'absolute',
    '& text': {
      textAnchor: theme.direction === 'ltr' ? 'start' : 'end',
    },
  },
}));

const SubscriptionStatus = ({ id }: { id: number }) => {
  const { t } = useTranslation();
  const createStatus = () => {
    switch (id) {
      case 1:
        return {
          title: t('trial'),
          color: '#E57E09',
          bgColor: 'rgba(229,126 ,9, 0.2)',
        };

      case 2:
        return {
          title: t('active'),
          color: '#84C731',
          bgColor: 'rgba(109, 210, 84, 0.2)',
        };
      case 3:
        return {
          title: t('expired'),
          color: '#FF5A35',
          bgColor: 'rgba(255,102,69,0.2)',
        };
      case 4:
        return {
          title: t('inactive'),
          color: '#FF5A35',
          bgColor: 'rgba(255,102,69,0.2)',
        };

      default:
        return {
          title: t('active'),
          color: '#84C731',
          bgColor: 'rgba(109, 210, 84, 0.2)',
        };
    }
  };
  const { title, color, bgColor } = createStatus();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={bgColor}
      color={color}
      py={0.8}
      px={2}
      borderRadius={4}
      mx={3}
    >
      <span style={{ fontSize: 12, fontWeight: 600, verticalAlign: 'middle' }}>
        {title}
      </span>
    </Box>
  );
};

const SubscriptionCard = ({
  features,
  title,
  price = 'soon',
  idSubscriptionPlan,
  canResubscribe,
  subscriptionPeriod,
  currentPlanName,
}: {
  features: Array<{
    title_en: string;
    title_ar: string;
    is_enabled: boolean;
    idplanFeature: number;
  }>;
  title: string;
  price: string;
  idSubscriptionPlan: number;
  canResubscribe: boolean;
  subscriptionPeriod: string;
  currentPlanName: string;
}) => {
  const theme = useTheme();
  const isCurrent = currentPlanName === title;
  // @ts-ignore
  const hasPrice = !isNaN(price);
  const classes = useSubscriptionStyle();
  const { t } = useTranslation();
  const { isEnglish, user } = useGlobalState();
  const canGoToPayment = canResubscribe && isCurrent;

  return (
    <Box
      borderRadius={8}
      border={
        idSubscriptionPlan === 1
          ? '1px solid #8181A5'
          : `1px solid ${theme.palette.primary.main}`
      }
      textAlign="center"
      position="relative"
      overflow="hidden"
    >
      <Box
        border="1px solid #E5E5E5"
        p={4}
        borderRadius={8}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="h1"
          color="primary"
          className={hasPrice ? classes.cardTitle : ''}
        >
          {t(price)}
        </Typography>
      </Box>
      <Box p={4} borderRadius={8} bgcolor="white" position="relative">
        <Box
          position="absolute"
          top="-20px"
          left="50%"
          border="1px solid #E5E5E5"
          borderRadius="24px"
          minWidth="220px"
          py="12px"
          bgcolor={!isCurrent ? 'white' : theme.palette.primary.main}
          className={classes.absoluteBox}
          onClick={async () => {
            const isZid = isZidFunc(user?.EcommerceType?.id);
            const redirectURL = isZid
              ? 'https://web.zid.sa/market/app/708'
              : 'https://apps.salla.sa/ar/app/2012847716';

            window.open(redirectURL, '_blank');
          }}
          style={{
            cursor: 'pointer',
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: 500,
              color: !isCurrent ? 'black' : 'white',
            }}
          >
            {isCurrent
              ? canGoToPayment
                ? t('settings:reSubscribe')
                : t('settings:currentSubscription')
              : idSubscriptionPlan === 1
              ? t('getStarted')
              : t('settings:Upgrade to Growth')}
          </Typography>
        </Box>
        <List>
          {(idSubscriptionPlan === 1 ? basicFeatures : growthFeatures).map(
            (feature) => (
              <ListItem
                style={{
                  marginBottom: 8,
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: 13,
                    color: '#606084',
                    fontWeight: 400,
                  }}
                >
                  {t(`settings:${feature}`)}
                </span>
              </ListItem>
            )
          )}
        </List>
      </Box>
    </Box>
  );
};

const basicFeatures = [
  'Earn points per purchase',
  'Earn points by signup',
  'Percentage discount coupons',
  'Fixed discount coupons',
  'Free Shipping coupons',
  'Loyalty analytics',
];

const growthFeatures = [
  'Everything in Basic Plan',
  'Earn points by social media follow',
  'Free Product coupon',
  'Referral Program',
  'Customized plugin',
  'SMS and Email notifications',
];
