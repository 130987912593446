import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { changePasswordAPI } from '../../api';
import { Button, Popup, TextInput } from '../../components/Common';
import { useGlobalState } from '../../components/GlobalState';
import { paths, usePost } from '../../utils';
import { SecurityLayout } from './styled';

// currentPasswordIsIncorrect
const Security = () => {
  const { user } = useGlobalState();
  const { t } = useTranslation();
  const [{ loading }, submitForm] = usePost({
    apiFunction: changePasswordAPI,
    updateUser: false,
    to: paths.settings + '/' + paths.security,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const validationSchema = yup.object({
    currentPassword: yup
      .string()
      .required(t('requiredField'))
      .min(6, t('passwordIsTooShort'))
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    newPassword: yup
      .string()
      .required(t('requiredField'))
      .min(6, t('passwordIsTooShort'))
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  });

  const [openConfirmation, setConfiramtion] = React.useState(false);

  const onConfirm = async (body: any, resetForm: any) => {
    const res = await submitForm({
      body: {
        merchantId: user?.idMerchant,
        oldPassword: body.currentPassword,
        ...body,
      },
    });
    if (res) resetForm();
  };

  return (
    <SecurityLayout className="mr-bottom card">
      <div style={{ marginBottom: 0 }}>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
          }}
          onSubmit={() => {
            setConfiramtion(true);
          }}
          validationSchema={validationSchema}
        >
          {({ values, resetForm }) => (
            <>
              <Popup
                title="Alert"
                fullWidth={true}
                open={openConfirmation}
                maxWidth="sm"
                handleClose={() => setConfiramtion(false)}
                onConfirm={onConfirm}
                data={values}
                resetForm={resetForm}
              >
                <div style={{ padding: '2em 0' }}>
                  <p>{t('changesConfirmation')}</p>
                </div>
              </Popup>
              <Form>
                <section>
                  <div className="mr-bottom">
                    <h4 className="title">{t('settings:changePassword')}</h4>
                    <div className="grid">
                      <TextInput
                        type="current-password"
                        name="currentPassword"
                        label={t('settings:currentPassword')}
                        placeholder={t('settings:pleaseEnterCurrentPassword')}
                      />

                      <TextInput
                        type="new-password"
                        name="newPassword"
                        label={t('settings:newPassword')}
                        placeholder={t('settings:pleaseEnterNewPassword')}
                      />
                    </div>
                  </div>
                </section>
                <Grid container>
                  <Grid item xs={12} md={6} container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Button
                        type="submit"
                        loading={loading}
                        bColor="primary"
                        color="white"
                        fullwidth={1}
                      >
                        {loading ? t('submitting') : t('save')}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        backcolor="#8181A5"
                        type="button"
                        loading={loading}
                        bColor="primary"
                        color="white"
                        fullwidth={1}
                        handleClick={resetForm}
                      >
                        {t('cancel')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </SecurityLayout>
  );
};

export default Security;
