import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { mainColor, mq } from '../../utils';
import { NonAuthLayout } from '../Common';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      fontSize: '1.7rem',
    },

    secondTitle: {
      fontWeight: 400,
      fontSize: '1.1rem',
    },

    subTitle: {
      fontWeight: 300,
      fontSize: '0.85rem',
      color: mainColor,
    },
  })
);

export const Container = styled.section`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Layout = styled.section`
  ${NonAuthLayout};
  display: flex;
  background-color: white;
  flex: 1;
  ${mq('tablet')} {
    flex-direction: column;
  }
`;

export const Left = styled.div<{ isDone: boolean | undefined; isRtl: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: scroll;
  background-color: white;
  padding: ${({ isRtl }) => (isRtl ? '5em 6em 0 1em' : '5em 1em 0 6em')};
  form {
    max-width: 620px;
    > * {
      margin-bottom: 2em;
    }
    .button-container {
      width: fit-content;

      button {
        padding-left: 7em;
        padding-right: 7em;
      }
    }
  }

  ${mq('tablet')} {
    padding: 2em 1em 0 1em;
  }
  p {
    font-size: 0.85rem;
  }
  strong {
    font-weight: 500;
  }
  ${({ isDone }) =>
    isDone &&
    css`
      padding: 0 1em 0 6em;
      > div {
        margin: auto;
        text-align: center;
      }
    `};
`;

export const Right = styled.div<{ isTheme?: boolean }>`
  flex-basis: ${({ isTheme }) => (isTheme ? '55%' : '40%')};
  max-height: ${({ isTheme }) => (isTheme ? '' : '100%')};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: ${({ isTheme }) => (isTheme ? 'center' : 'flex-end')};
  position: relative;

  img {
    margin-right: 5%;
    margin-bottom: 5%;
    width: 100%;
  }
  ${mq('tablet')} {
    img {
      width: 80%;
      margin: 0;
    }
  }
`;

export const Content = styled.div`
  .title {
    margin-bottom: 20px !important;
  }
  > * {
    margin-bottom: 40px;
  }
`;

export const Header = styled.header<{ isRtl?: boolean }>`
  width: 100%;
  background-color: white;
  padding: ${({ isRtl }) => (isRtl ? '1em 6em 1em 1em' : '1em 1em 1em 6em')};
  > img {
    width: 100px;
  }
  ${mq('tablet')} {
    padding: 1em 1em;
  }
`;

export const ZidIntergationBox = styled.div`
  min-height: 90vh;
  width: 100%;
  height: 100%;
  padding: 1em 6em;
  background-color: white;

  p {
    color: #292222 !important;
    font-size: 18px;
    font-weight: 400;
    min-width: 350px;
    max-width: 360px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  img.zid_steps {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 250px;
    margin: auto;
  }

  ${mq('smalllabtop')} {
    .content_box {
      flex-direction: column;
    }
  }
  ${mq('tablet')} {
    padding: 1em;
  }
`;
