import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { useGlobalState } from '../../GlobalState';

export const Alert = () => {
  const { setAlert, alertText, alertType } = useGlobalState();

  return (
    <Snackbar
      style={{ zIndex: 999999999 }}
      open={!!(alertText && alertType)}
      onClose={() => setAlert('', null)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {!!(alertText && alertType) ? (
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {alertText}
        </MuiAlert>
      ) : (
        <div></div>
      )}
    </Snackbar>
  );
};

export default Alert;
