// @ts-nocheck

import { Box, InputAdornment } from '@material-ui/core';
import { FieldAttributes, useField } from 'formik';
import React from 'react';
import { ReactComponent as HidePasswordIcon } from '../../../assets/svg/hide-password.svg';
import { ReactComponent as ShowPasswordIcon } from '../../../assets/svg/show-password.svg';
import { RenderIcon } from '../../SVG';
import { Container, CssTextField, Icon, useStyles } from './styled';

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  logo?: React.FC;
  labelColor?: string;
  alignCenter?: boolean;
  withAdornment?: string | JSX.Element;
  adornmentPosition?: 'start' | 'end';
  variant?: 'filled' | 'outlined';
  padding?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  inputMode?: string;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  isCurrency?: boolean;
  inputProps?: any;
  inputPropsStyle?: React.CSSProperties | undefined;
  readOnly?: boolean;
};

export const TextInput: React.FC<FieldAttributes<Props>> = ({
  placeholder,
  label,
  type,
  logo: Logo,
  labelColor,
  alignCenter,
  withAdornment,
  adornmentPosition,
  variant,
  padding,
  disabled,
  autoFocus,
  inputMode,
  fullWidth = true,
  disableUnderline = false,
  isCurrency = false,
  style = {},
  readOnly = false,
  inputProps = {},
  inputPropsStyle = {},
  ...props
}) => {
  const [field, meta] = useField<Props>(props);
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const isPassword = type?.includes('password');
  return (
    <Container style={style}>
      <CssTextField
        {...field}
        id={field.name}
        autoFocus={autoFocus}
        type={!showPassword && isPassword ? 'password' : type}
        className={`${classes.inputIcon} ${classes.root} ${
          alignCenter ? 'center' : ''
        }`}
        label={
          label ? (
            <Box display="flex" alignItems="center">
              {Logo && <RenderIcon logo={Logo} />}
              <span style={{ margin: '0 12px' }}>{label}</span>
            </Box>
          ) : (
            ''
          )
        }
        placeholder={placeholder}
        helperText={errorText}
        error={!!errorText}
        variant={variant}
        disabled={disabled}
        fullWidth={fullWidth}
        InputLabelProps={{
          shrink: true,
          style: {
            color: labelColor ? labelColor : '#000',
            fontSize: '18px',
            fontWeight: 400,
            top: -10,
          },
        }}
        inputProps={{
          classes,
          autoComplete: isPassword && 'new-password',
          ...(isCurrency && { step: '0.01' }),
          style: disabled ? { cursor: 'not-allowed' } : {},
        }}
        InputProps={{
          className: classes.root,
          readOnly,
          disableUnderline,
          style: {
            backgroundColor: disabled
              ? '#d4d4df'
              : variant === 'filled'
              ? '#F6F7F8'
              : '',
            padding:
              variant === 'outlined' ? '0' : padding ? padding : '5px 0px',
            fontSize: '16px',
            ...inputPropsStyle,
          },
          startAdornment: withAdornment && adornmentPosition !== 'end' && (
            <InputAdornment position="start" style={{ margin: 0 }}>
              <span>{withAdornment}</span>
            </InputAdornment>
          ),
          endAdornment: (adornmentPosition === 'end' && withAdornment && (
            <InputAdornment position="end" style={{ margin: '0 8px' }}>
              <span>{withAdornment}</span>
            </InputAdornment>
          )) || (
            <Icon
              className={classes.passwordIcon}
              onClick={() => {
                isPassword && setShowPassword(!showPassword);
              }}
            >
              {isPassword && (
                <RenderIcon
                  logo={showPassword ? HidePasswordIcon : ShowPasswordIcon}
                />
              )}
              {Logo && !label && <RenderIcon logo={Logo} />}
            </Icon>
          ),
        }}
      />
    </Container>
  );
};
