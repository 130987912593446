import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ThemeType } from '../../Root';

export const Layout = styled<any>('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.8em 1.5em;
  border: 1px solid #d4d4e3;
  border-radius: 4px;
  text-align: center;
  position: relative;

  img {
    width: 70px;
  }

  > * {
    margin-bottom: 1em;
  }
  p {
    font-size: 13px;
  }
  h1 {
    font-weight: 300;
    font-size: 17px;
  }

  svg {
    position: absolute;
    right: 5%;
    top: 5%;
  }

  ${({ isSelected, theme }: { isSelected: boolean; theme: ThemeType }) =>
    isSelected &&
    css`
      border: 1px solid ${theme.colors.primary};
      box-shadow: 1px 1px 10px #ccc;
      circle {
        fill: ${theme.colors.primary} !important;
      }
    `};
`;
