import React from 'react';
import { Route } from '../../../components/Common';
import { Router } from '@reach/router';
import { Main } from './main';
import { Social } from './social';
import { Setup } from './setup';
import { Guard } from '../../../components/Routes/Guard';

export const Referral = () => {
  return (
    <Guard permission="ReferralModule">
      <Router>
        <Route path="/" component={Main} />
        <Route path="/social" component={Social} />
        <Route path="/setup" component={Setup} />
      </Router>
    </Guard>
  );
};
