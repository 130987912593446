import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropMenu } from '../';
import { ReactComponent as ManageAccountLogo } from '../../../assets/svg/manage-account.svg';
import { ReactComponent as NotificationsOffLogo } from '../../../assets/svg/notifications-off.svg';
import { ReactComponent as NotificationsOnLogo } from '../../../assets/svg/notifications-on.svg';
// import { ReactComponent as BillingLogo } from '../../../assets/svg/billing.svg';
import { ReactComponent as SignoutLogo } from '../../../assets/svg/sign-out.svg';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { mq } from '../../../utils';
import { Language, useGlobalState } from '../../GlobalState';
import { items } from '../../PagesLayout/helper';
import { RenderIcon } from '../../SVG';
import { MuiSelect } from '../SelectInput';
import { useNotifications } from './helper';

export const Header = () => {
  const { language, user, setLanguage, resetUser } = useGlobalState();
  const { width } = useWindowSize();

  const { t } = useTranslation();

  const {
    notifications,
    makeAllNotificationsRead,
    hasNewNotification,
    setHasNewNotification,
    loading,
  } = useNotifications();

  const currentPageTitle =
    window.location.pathname !== '/'
      ? items.filter((item) => window.location.pathname.includes(item.to))[0]
          ?.name
      : '';

  return (
    <Layout>
      <InnerLayout>
        <h2>{t(currentPageTitle)}</h2>
        <ul>
          <ChangeLanguage setLanguage={setLanguage} language={language} />

          <DropMenu
            options={notifications}
            isLoading={loading}
            handleClosing={makeAllNotificationsRead}
            handleClicking={() => setHasNewNotification(false)}
            isNotification={true}
          >
            <RenderIcon
              logo={
                hasNewNotification ? NotificationsOnLogo : NotificationsOffLogo
              }
              bColor="#F2F2F6"
              padding="0.35rem"
            />
          </DropMenu>
          <DropMenu
            options={[
              {
                title: t('manageAccount'),
                Logo: ManageAccountLogo,
                handleClick: () => {
                  navigate('/settings');
                },
              },
              // { title: 'Billing', Logo: BillingLogo },
              {
                title: t('signout'),
                Logo: SignoutLogo,
                handleClick: resetUser,
              },
            ]}
          >
            <RenderIcon
              text={width && width < 450 ? '' : user?.owner}
              logo={() => <img src={user?.imageUrl} alt="" />}
              bColor="#F2F2F6"
              padding="0.35rem"
              fontSize="0.75rem"
              weight={500}
              withArrow
            />
          </DropMenu>
        </ul>
      </InnerLayout>
    </Layout>
  );
};

const langauges: Array<{ name: string; id: Language }> = [
  { name: 'العربية', id: 'ar' },
  { name: 'English', id: 'en' },
];

const ChangeLanguage = ({ setLanguage, language }: any) => {
  return (
    <Box display="flex" alignItems="center" px={1}>
      <MuiSelect
        //@ts-ignore
        color="#8181A5"
        padding={0}
        fontSize={12}
        disableUnderline
        optionLabel="name"
        optionValue="id"
        options={langauges}
        value={language}
        defaultValue={language}
        onChange={(e: any) => {
          const option = langauges.filter((d) => d.id === e.target.value)[0];
          setLanguage(option.id);
        }}
      />
    </Box>
  );
};

const Layout = styled.nav`
  width: 100%;
  position: relative;

  ul,
  li {
    display: flex;
    align-items: center;
  }

  ul {
    list-style-type: none;
  }

  ${mq('tablet')} {
    padding-right: 0;
  }
`;

const InnerLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222 !important;
  position: relative;

  img {
    width: 25px;
    height: 25px;
  }

  h2 {
    font-weight: 500;
    font-size: 0.9rem;
  }
`;
