import styled from '@emotion/styled';
import React from 'react';

import { mq } from '../../../utils';
import RedeemedVsCollected from './redeemedVsCollected';
import UsedCoupons from './usedCoupons';

export const Charts = () => {
  return (
    <Layout>
      <RedeemedVsCollected />
      <UsedCoupons />
    </Layout>
  );
};

const Layout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(350px, 1fr)) !important;
  grid-gap: 1em;

  .chart {
    background-color: white;
    border-radius: 8px;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    min-height: 310px;

    .chart-header {
      padding: 1.4em 1.2em;
      display: flex;
      justify-content: space-between;
    }
  }

  ${mq('phone')} {
    .chart {
      grid-column: -1/1;
    }
    .chart-header {
      flex-direction: column;
      text-align: center;
    }
  }
`;
