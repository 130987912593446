import React from 'react';
import { Link } from '@reach/router';

import { paths } from '../../utils';
import { useTranslation } from 'react-i18next';

export const CustomerTableHyperLink = ({
  customerId,
  customerName,
  customer,
  hasVisit = false,
  blocked,
}: any) => {
  const style = { color: '#5E81F4' };
  const { t } = useTranslation();

  return hasVisit ? (
    <Link
      style={style}
      to={`${paths.segmentation}${paths.customer}?id=${customerId}&&name=${customerName}`}
      state={{ id: customerId, customer }}
    >
      <span>{customerName ?? 'Customer'}</span>
      <span style={{ color: '#FF6645', fontWeight: '500' }}>
        {blocked ? <span>{` (${t('blocked')})`}</span> : ''}
      </span>
    </Link>
  ) : (
    <p style={style}>{customerName ?? 'Customer'}</p>
  );
};
