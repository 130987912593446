import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StarIcon } from '../../../../assets/svg/star.svg';
import { useProfileStyles } from '../index';
import { CustomerAccordian } from './customerAccordian';

export const CustomerStatistics = ({ statsDetails }) => {
  const classes = useProfileStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.stats}>
      <Box py={2} px={4}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          style={{ flexWrap: 'wrap', gap: '1rem' }}
          mb={3.6}
        >
          <Box>
            <Typography variant="h2" style={{ fontWeight: 400 }} gutterBottom>
              {statsDetails?.name}
            </Typography>
            <Typography
              variant="h5"
              style={{ color: '#5E81F4', fontWeight: 400 }}
            >
              {t(statsDetails.segment)}
            </Typography>
          </Box>
          <Box
            maxHeight={40}
            bgcolor="#F1AF3B"
            color="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize={16}
            fontWeight={500}
            px={1.5}
            borderRadius={24}
          >
            <StarIcon className={classes.starIcon} />
            {t('segmentation:loyaltyScore')} {statsDetails.loyaltyScore}
          </Box>
        </Box>
        <CustomerAccordian {...statsDetails} />
      </Box>
    </Paper>
  );
};
