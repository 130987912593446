import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Logo from '../../assets/img/bonat-logo.png';
import { ReactComponent as SAFlagIcon } from '../../assets/svg/SAFlag.svg';
import { ReactComponent as USFlagIcon } from '../../assets/svg/USFlag.svg';
import { Curve } from '../Common';
import { useGlobalState } from '../GlobalState';
import { Content, Layout, Left, Right, useStyles } from './styled';

interface Props {
  bgImage: any;
  firstTitle: string;
  secondTitle?: string;
  subTitle?: string;
  children: JSX.Element;
}
export const SignLayout = ({
  bgImage,
  firstTitle,
  secondTitle,
  subTitle,
  children,
}: Props) => {
  const classes = useStyles();
  const { isEnglish, setLanguage } = useGlobalState();

  return (
    <Layout>
      <Left direction={isEnglish}>
        <Box
          position="absolute"
          top="15%"
          left="80%"
          border="1px solid #E7E7E8"
          bgcolor="white"
          borderRadius={24}
          px={2}
          py={0.1}
          style={{ cursor: 'pointer' }}
          display="flex"
          justifyContent="sapce-between"
          alignItems="center"
          zIndex={100}
          onClick={() => {
            setLanguage(isEnglish ? 'ar' : 'en');
          }}
        >
          <Box pr={1.5}>{isEnglish ? <SAFlagIcon /> : <USFlagIcon />}</Box>
          <p style={{ fontWeight: 500, fontSize: 14, cursor: 'pointer' }}>
            {isEnglish ? 'AR' : 'EN'}
          </p>
        </Box>
        <Curve bColor="#8181a5" dir="left" />
        <Box component="div" className="sign_left">
          <Content style={{ marginInline: '50px' }}>
            <img src={Logo} alt="Bonat" />
            <section>
              <Typography
                className={classes.title}
                variant="subtitle1"
                gutterBottom
              >
                {firstTitle}
              </Typography>
              <Typography
                className={classes.secondTitle}
                variant="h1"
                component="h2"
                gutterBottom
              >
                {secondTitle}
              </Typography>
              <Typography
                className={classes.subTitle}
                variant="caption"
                display="block"
                gutterBottom
              >
                {subTitle}
              </Typography>
            </section>
          </Content>
          {children}
        </Box>
      </Left>
      <Right>
        <img src={bgImage} alt="Sign Page" loading="lazy" />
      </Right>
    </Layout>
  );
};
