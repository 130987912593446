import { Box } from '@material-ui/core';
import { Link } from '@reach/router';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { sendCodeToMerchantPhone } from '../actions';
import Signleft from '../assets/img/sign-left.png';
import { ReactComponent as BusinessNameIcon } from '../assets/svg/business-name.svg';
import sallaImg from '../assets/img/salla-logo.png';
import zidImg from '../assets/img/zid-logo.png';
import { Button, PhoneNumber, TextInput } from '../components/Common';
import { NoIndex } from '../components/Common/NoIndex';
import { useGlobalState } from '../components/GlobalState';
import { SignLayout } from '../components/SignLayout';
import { useStyles } from '../utils';

const Signup = () => {
  const { setUser, setAlert } = useGlobalState();
  const classes = useStyles();
  const { t } = useTranslation();

  const validationSchema = yup.object({
    name: yup.string().required(t('requiredField')).min(5),
    email: yup.string().email().required(t('requiredField')),
    phoneNumber: yup.object({
      val: yup
        .string()
        .typeError('fieldMustBeANumber')
        .required(t('requiredField'))
        .min(7, `${t('minNumber')} 7`),
    }),
    newPassword: yup
      .string()
      .required(t('requiredField'))
      .min(6, `${t('minNumber')} 6`)
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    owner: yup.string().required(t('requiredField')).min(3),
  });

  const initialRes = { error: null, loading: false };
  const [res, setRes] = React.useState(initialRes);

  return (
    <>
      <NoIndex />
      <SignLayout
        firstTitle={t('public:welcomeToBonat')}
        secondTitle={t('public:signupToStartEngagingYourCustomers')}
        bgImage={Signleft}
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '40px',
              marginTop: '40px',
            }}
          >
            <img
              onClick={() => {
                window.open(
                  'https://apps.salla.sa/ar/app/2012847716',
                  '_blank'
                );
              }}
              src={sallaImg}
              style={{ cursor: 'pointer', width: '230px' }}
              alt={'salla'}
            />
            <div
              style={{ borderLeft: '2px solid #F0F0F3', alignSelf: 'stretch' }}
            ></div>
            <img
              onClick={() => {
                window.open('https://web.zid.sa/market/app/708', '_blank');
              }}
              src={zidImg}
              style={{ cursor: 'pointer', width: '230px' }}
              alt={'zid'}
            />
          </div>
          <p
            style={{
              textAlign: 'center',
              marginTop: '100px',
              fontSize: '14px',
              color: '#292222',
            }}
          >
            {t('public:have-account')}
          </p>

          <Link to="/signin" style={{ width: '100%', marginTop: '24px' }}>
            <Button
              style={{ width: '178px' }}
              fullwidth={0}
              type="button"
              bColor="primary"
              color="white"
            >
              {t('public:signin')}
            </Button>
          </Link>
        </div>
      </SignLayout>
    </>
  );
};

export default Signup;
