import { Box } from '@material-ui/core';
import { Link } from '@reach/router';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';

import { useSign } from '../actions';
import Signleft from '../assets/img/sign-left.png';
import { ReactComponent as MailIcon } from '../assets/svg/mail.svg';
import { Button, TextInput } from '../components/Common';
import { useGlobalState } from '../components/GlobalState';
import { SignLayout } from '../components/SignLayout';
import { FORGET_PASSWORD, getLocal, useStyles } from '../utils';
import { isProd } from '../config';
import { NoIndex } from '../components/Common/NoIndex';

const Signin = () => {
  const { setUser } = useGlobalState();

  const presistedUser = getLocal({ key: 'ebonat_user' });
  const {
    state: { loading },
    submitForm,
  } = useSign(presistedUser);
  const { t } = useTranslation();

  const validationSchema = yup.object({
    email: yup.string().email().required(t('requiredField')),
    password: yup
      .string()
      .required(t('requiredField'))
      .min(6, `${t('minNumber')} 6`)
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  });

  const classes = useStyles();

  return (
    <>
      {isProd ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bonat</title>
          <meta
            name="description"
            content="Bonat Customer Engaggemnt and Loyalty App"
          />
          <meta
            name="keywords"
            content="بونات المتاجر الالكترونية, بونات المتاجر, بونات متجر الكتروني, بونات تسجيل دخول, بونات زد, بونات سلة, لوحة تحكم بونات, داشبورد بونات Bonat, Bonat zid, bonat salla, bonat dashboard, bonat signin"
          />
        </Helmet>
      ) : (
        <NoIndex />
      )}
      <SignLayout
        firstTitle={t('public:welcomeToBonat')}
        secondTitle={t('public:signinToSeeLatestUpdates')}
        subTitle={t('public:enterYourDetailsToProceedFurther')}
        bgImage={Signleft}
      >
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={async (body, { resetForm }) => {
            const data = await submitForm(body, 'signin');
            if (data) {
              resetForm();
              setUser({ ...data, verified: true });
            } else {
            }
          }}
        >
          {() => (
            <Form style={{ marginInline: '50px' }}>
              <TextInput
                name="email"
                label={t('email')}
                placeholder="name@gmail.com"
                logo={MailIcon}
              />
              <TextInput
                type="current-password"
                name="password"
                label={t('password')}
                placeholder={t('public:enterYourPassword')}
              />

              <Box component="div">
                <Link to="/recover-password">
                  <span className={classes.anchor}>
                    {t(`public:${FORGET_PASSWORD}`)}
                  </span>
                </Link>
              </Box>
              <Box component="div">
                <Button
                  type="submit"
                  loading={loading}
                  bColor="primary"
                  color="white"
                  fullwidth={1}
                >
                  {loading ? t('submitting') : t('public:signin')}
                </Button>
                <Link to="/signup" className={classes.link}>
                  <Button
                    type="button"
                    bColor="secondary"
                    color="#8181A5"
                    fullwidth={1}
                  >
                    {t('public:signup')}
                  </Button>
                </Link>
              </Box>
            </Form>
          )}
        </Formik>
      </SignLayout>
    </>
  );
};

export default Signin;
