import { dev } from '../api';
import { useGlobalState } from '../components/GlobalState';

export const useFetcher = () => {
  const { setAlert } = useGlobalState();

  const fetcher = (url: string): Promise<{ data: Array<{}> }> =>
    dev
      .get(url)
      .then((res: { data: Array<{}> }) => res.data ?? { data: [] })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err.message || err?.data?.message;

        if (error !== 'unauthorized') {
          setAlert(error, 'error');
        }

        return error;
      });

  return fetcher;
};
