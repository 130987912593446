import { Hidden } from '@material-ui/core';
import React from 'react';
import { DesktopHeader } from './desktop';
import { MobileHeader } from './mobile';

export const Header = ({
  segmentType,
  state,
  dispatch,
  allCustomers,
  dataToExport,
  handleExportClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Hidden smDown>
        <DesktopHeader
          state={state}
          dispatch={dispatch}
          handleClick={handleClick}
          handleClose={handleClose}
          segmentType={segmentType}
          allCustomers={allCustomers}
          anchorEl={anchorEl}
          dataToExport={dataToExport}
          handleExportClick={handleExportClick}
        />
      </Hidden>
      <Hidden mdUp>
        <MobileHeader
          state={state}
          dispatch={dispatch}
          handleClick={handleClick}
          handleClose={handleClose}
          segmentType={segmentType}
          allCustomers={allCustomers}
          anchorEl={anchorEl}
          dataToExport={dataToExport}
          handleExportClick={handleExportClick}
        />
      </Hidden>
    </>
  );
};
