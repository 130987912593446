import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { uuid } from 'uuidv4';
import { ReactComponent as Branding } from '../../assets/svg/branding.svg';
import { ReactComponent as Campaign } from '../../assets/svg/campaign.svg';
import { ReactComponent as Coupon } from '../../assets/svg/coupon-link.svg';
import { ReactComponent as Customers } from '../../assets/svg/customers.svg';
import { ReactComponent as Dashboard } from '../../assets/svg/dashboard.svg';
import { ReactComponent as Point } from '../../assets/svg/point-link.svg';
import { ReactComponent as Referral } from '../../assets/svg/referral.svg';
import { ReactComponent as Programs } from '../../assets/svg/programs.svg';
import { ReactComponent as Reviews } from '../../assets/svg/reviews.svg';
import { ReactComponent as Settings } from '../../assets/svg/settings.svg';
import { ReactComponent as ReviewsSettings } from '../../assets/svg/reviews-settings.svg';
import { ReactComponent as ReviewsDashboard } from '../../assets/svg/reviews-dashboard.svg';
import { ReactComponent as ReviewsWidget } from '../../assets/svg/reviews-widget.svg';
import { ReactComponent as ReviewsEmail } from '../../assets/svg/reviews-email.svg';
import { ReactComponent as Notifications } from '../../assets/svg/notifications.svg';
import { mainColor, paths } from '../../utils';

// import { ReactComponent as Referral } from '../../assets/svg/referral-link.svg';

export const items = [
  {
    name: 'dashboard',
    logo: Dashboard,
    permission: 'HomeModule',
    id: uuid(),
    to: paths.dashboard,
  },
  {
    name: 'reviews',
    logo: Reviews,
    id: uuid(),
    permission: 'ReviewsModule',
    to: paths.reviews,
    isParent: true,
    items: [
      {
        name: 'reviews-dashboard',
        id: uuid(),
        to: paths.reviews + '/dashboard',
        logo: ReviewsDashboard,
        permission: 'ReviewsModule',
      },
      {
        name: 'reviews-settings',
        id: uuid(),
        to: paths.reviews + '/settings',
        logo: ReviewsSettings,
        permission: 'ReviewsModule',
      },
      {
        name: 'reviews-widget',
        id: uuid(),
        to: paths.reviews + '/widget',
        logo: ReviewsWidget,
        permission: 'ReviewsModule',
      },
      {
        name: 'reviews-email',
        id: uuid(),
        to: paths.reviews + '/email',
        logo: ReviewsEmail,
        permission: 'ReviewsModule',
      },
    ],
  },
  {
    name: 'programs',
    logo: Programs,
    id: uuid(),
    to: paths.programs,
    isParent: true,
    items: [
      {
        name: 'earningMethods',
        id: uuid(),
        to: paths.programs + paths.earningWays,
        logo: Coupon,
        permission: 'EarningPointsModule',
      },
      {
        name: 'loyaltyProgram',
        id: uuid(),
        to: paths.programs + paths.rewardSystem,
        logo: Point,
        permission: 'RewardsModule',
      },
      {
        name: 'referral',
        id: uuid(),
        to: paths.programs + paths.referrals,
        logo: Referral,
        permission: 'ReferralModule',
      },
    ],
  },
  {
    name: 'branding',
    logo: Branding,
    id: uuid(),
    to: paths.branding,
    permission: 'BrandingModule',
  },
  {
    name: 'campaign',
    logo: Campaign,
    id: uuid(),
    to: paths.campaign,
    permission: 'CampaignModule',
  },
  {
    name: 'segmentation',
    logo: Customers,
    id: uuid(),
    to: paths.segmentation,
    permission: 'CustomersModule',
  },
  {
    name: 'notifications',
    logo: Notifications,
    id: uuid(),
    to: paths.notifications,
    permission: 'NotificationsModule',
  },
  {
    name: 'settings',
    logo: Settings,
    id: uuid(),
    to: paths.settings,
    permission: 'SettingsModule',
  },
];

const drawerWidth = '240px';

export const usePageLayoutStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    notificationHeader: {
      zIndex: 99999999,

      [theme.breakpoints.down('sm')]: {
        '& p': {
          fontSize: 12,
        },
      },
    },
    renewButton: {
      padding: '8px 24px',
      color: 'inherit',
      whiteSpace: 'nowrap',

      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        padding: '8px 12px',
      },
    },
    hide: {
      display: 'none',
    },
    toolbar: {
      minWidth: 200,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      display: ({ showNotification }: any) => {
        return !showNotification ? 'flex' : 'none';
      },
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },

    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    appBar: {
      top: ({ showNotification }: any) => (!showNotification ? 0 : 55),

      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('md')]: {
        top: ({ showNotification }: any) => (!showNotification ? 0 : 80),
      },
      [theme.breakpoints.down('sm')]: {
        top: ({ showNotification }: any) => (!showNotification ? 0 : 64),
      },
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth})`,
      margin: `0 0 0 ${drawerWidth}`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: '100%',
      },
    },

    menuButton: {
      margin: ({ open }: any) =>
        open ? 0 : theme.direction === 'ltr' ? '0 0 0 70px' : '0 70px 0 0',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('md')]: {
        marginLeft: '0 !important',
      },
    },

    drawerPaper: {
      width: drawerWidth,
    },
    drawerOpen: {
      top: ({ showNotification }: any) => (!showNotification ? 0 : 55),
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      top: ({ showNotification }: any) => (!showNotification ? 0 : 55),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(8),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(10),
      },
      [theme.breakpoints.down('md')]: {
        top: ({ showNotification }: any) => (!showNotification ? 0 : 80),
      },
      [theme.breakpoints.down('sm')]: {
        top: ({ showNotification }: any) => (!showNotification ? 0 : 64),
      },
    },
    mobile: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      maxWidth: '1700px',
      margin: 'auto',
      overflowY: 'scroll',
      maxHeight: '100vh',
    },
    logoContainer: {
      marginBottom: '0px',
      width: 110,
      marginTop: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    logo: {
      width: '100%',
      maxWidth: 110,
    },
    logoSM: {
      width: '100%',
      maxWidth: 25,
    },
    listItem: {
      textAlign: 'left',
      padding: '4px 12px',
      borderRadius: '4px 0 0 4px',
      '& tspan': {
        fontWeight: 200,
      },

      '&:hover': {
        backgroundColor: 'rgba(255,102,69, 0.1) !important',
        '& span': {
          color: '#222',
        },
        '& tspan': {
          fill: mainColor,
        },
        '& path': {
          fill: mainColor,
        },
        '& #megaphone': {
          fill: mainColor,
          stroke: mainColor,
        },
        '& p': {
          color: '#222',
        },
      },
    },

    listItemText: {
      fontSize: '0.72rem !important',
      fontWeight: 400,
      color: '#8181A5',
    },
    nested: {
      paddingLeft: '21px',
      backgroundColor: 'white',
      marginBottom: '0',
      textAlign: 'left',
      padding: '4px 12px',
      borderRadius: '4px 0 0 4px',
      '& tspan': {
        fontWeight: 200,
      },

      '&:hover': {
        backgroundColor: 'rgba(255,102,69, 0.1) !important',
        '& span': {
          color: '#222',
        },
        '& tspan': {
          fill: mainColor,
        },
        '& path': {
          fill: mainColor,
        },
        '& #megaphone': {
          fill: mainColor,
          stroke: mainColor,
        },
        '& p': {
          color: '#222',
        },
      },
    },
  })
);
