import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {
  borderColor?: string;
  bColor?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        margin: '10px 0 0 0',
        justifyContent: 'center !important',
        width: '100%',
      },

      '& .Mui-selected': {
        color: '#222',
        fontWeight: '500',
        border: ({ borderColor }: Props) =>
          `1px solid ${borderColor ? borderColor : '#ECECF2'}`,
        borderRadius: '8px',
        backgroundColor: ({ bColor }: Props) => (bColor ? bColor : 'white'),

        [theme.breakpoints.down('sm')]: {
          backgroundColor: 'white',
        },
      },
    },

    tab: {
      minWidth: 'unset',
      color: '#8181A5',
      fontSize: '0.75rem',
      fontWeight: 'normal',
      border: '1px solid transparent',
      textTransform: 'capitalize',
      margin: '0 10px',
      padding: '0.5em 1em',
      minHeight: 'unset',
    },
  })
);
