import React from 'react';
import ContentLoader from 'react-content-loader';

export const BoxLoader = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => (
  <div style={{ flex: 1 }} data-testid="loading">
    <ContentLoader
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="rgba(0, 0, 0, 0.06)"
      foregroundColor="rgba(0, 0, 0, 0.2)"
    >
      <rect x="0" y="0" rx="5" ry="5" width={width} height={height} />
    </ContentLoader>
  </div>
);
