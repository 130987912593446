import styled from '@emotion/styled';
import { withStyles } from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import React from 'react';
import { mainColor, mq } from '../../../utils';

export const Layout = styled.div`
  background-color: white;
  grid-column: 3/4;
  display: flex;
  flex-direction: column;
  max-height: 450px;

  .card,
  .status {
    margin-bottom: 0;
    flex: 1;

    > * {
      margin-bottom: 1rem;
    }
  }

  .status {
    background-color: #f6f7f8;
    margin: 0.2em;
    padding: 1em 1em;
  }

  p {
    font-size: 0.8rem;
    font-weight: 400;
  }

  ${mq('tablet')} {
    grid-column: -1/1;
  }
`;

export const ColoredRadio = withStyles({
  root: {
    color: mainColor,
    fontSize: '0.8rem',
    '&$checked': {
      color: mainColor,
    },
    '& .MuiSvgIcon-root': {
      width: '0.8em',
      height: '0.8em',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);
