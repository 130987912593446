import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
    },
    subTitle: {
      fontWeight: 300,
      fontSize: '15px',
    },
    anchor: {
      fontSize: '14px !important',
      fontWeight: 400,
      color: '#777',
    },
    link: {
      width: '100% !important',
      marginLeft: '1rem',
      '& button': {
        width: '100%',
      },
    },
    linkFullWidth: {
      width: '100% !important',
      '& button': {
        width: '100%',
      },
    },
  })
);
