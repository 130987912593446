import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import styled from '@emotion/styled';

import { mainColor } from '../../../utils';

export const ColoredCheckbox = withStyles({
  root: {
    color: mainColor,
    '&$checked': {
      color: mainColor,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const Layout = styled.div<{ subTitle?: string }>`
  label {
    display: flex;
    align-items: flex-start;

    .MuiButtonBase-root {
      padding-top: 0;
    }

    .secondary {
      margin: 0 0 1em 0 !important;
    }
  }
`;
