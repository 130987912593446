import styled from '@emotion/styled';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from '@reach/router';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';

import { useWindowSize } from '../../hooks/useWindowSize';
import GrowthPlan from '../Common/GrowthPlan';
import { NavLink } from '../Common/index';
import { useGlobalState } from '../GlobalState';
import { RenderIcon } from '../SVG';
import { items, usePageLayoutStyle } from './helper';
import styles from './index.module.css';
import BonatLogoSM from '../../assets/img/bonat-logo-sm.png';
import BonatLogo from '../../assets/img/bonat-logo.png';
import { ReactComponent as VideoTutorial } from '../../assets/svg/exclamation-circle-solid.svg';
import { ReactComponent as SignoutLogo } from '../../assets/svg/sign-out.svg';
import { ReactComponent as NotificationsOffLogo } from '../../assets/svg/notifications-off.svg';
import { useCanView } from '../../hooks/useCanView';
import { User } from '../../reducers';

interface Props {
  isNavigationExpand: boolean;
  setIsNavigationExpand: (isNavigationExpand: boolean) => void;
}

export const Navigation: FC<Props> = ({
  isNavigationExpand,
  setIsNavigationExpand,
}) => {
  const { width } = useWindowSize();

  const isSmallScreen = width && width < 880;

  return (
    <div
      className={clsx(
        styles.navigation,
        isSmallScreen
          ? null
          : isNavigationExpand
          ? styles.navigationOpen
          : styles.navigationClose
      )}
    >
      <Drawer
        style={{ height: '100%', ...(isSmallScreen && { width: 220 }) }}
        open={isNavigationExpand}
        onClose={() => setIsNavigationExpand(!isNavigationExpand)}
        variant={isSmallScreen ? 'temporary' : 'permanent'}
        PaperProps={{ style: { position: 'relative' } }}
      >
        <Nav isOpen={isNavigationExpand} setOpen={() => {}} />
      </Drawer>
    </div>
  );
};

export const Nav = ({ isOpen, setOpen }: { isOpen: boolean; setOpen: any }) => {
  const { showNotification, resetUser, user, isEnglish } = useGlobalState();
  const [openProgram, setOpenProgram] = useState(false);
  const [openReviews, setOpenReviews] = useState(false);
  const { t } = useTranslation();
  const canView = useCanView();

  const classes = usePageLayoutStyle({
    open: isOpen,
    showNotification,
  });

  return (
    <Layout>
      <List
        style={{ height: '100%', padding: 0 }}
        className="column space-between"
      >
        <div style={{ width: '100%' }}>
          <ListItem button className={classes.logoContainer}>
            <Link to="/">
              {isOpen ? (
                <img
                  key="larg"
                  src={BonatLogo}
                  alt="Bonat"
                  style={{ width: 110 }}
                />
              ) : (
                <img
                  key="sm"
                  src={BonatLogoSM}
                  alt="Bonat-sm"
                  style={{ width: 25 }}
                />
              )}
            </Link>
          </ListItem>

          {items.map((item, index) => {
            if (
              item.permission &&
              !canView(item.permission as keyof User['permissions'])
            )
              return null;

            return (
              <React.Fragment key={uuid()}>
                <Tooltip title={t(item.name) ?? ''}>
                  <div
                    onClick={(e) => {
                      if (item.items && item.name === 'programs')
                        setOpenProgram(!openProgram);
                      else if (item.items && item.name === 'reviews')
                        setOpenReviews(!openReviews);
                      else setOpen(false);
                    }}
                  >
                    {!item.items ? (
                      <ListItem
                        button
                        component={NavLink}
                        isNested={!!item.items}
                        to={item.to}
                        className={classes.listItem}
                        data-testid={item.name}
                      >
                        {(item.name === 'campaign' ||
                          item.name === 'notifications') &&
                          isOpen && (
                            <GrowthPlan
                              style={{
                                position: 'absolute',
                                top: 0,
                                ...(isEnglish ? { right: 0 } : { left: 0 }),
                              }}
                            />
                          )}
                        <ListItemIcon>
                          <RenderIcon logo={item?.logo} />
                        </ListItemIcon>
                        <ListItemText
                          primary={t(item.name)}
                          classes={{ primary: classes.listItemText }}
                        />
                        {item.items &&
                          (openProgram ? (
                            <ExpandLess fontSize="small" />
                          ) : (
                            <ExpandMore fontSize="small" />
                          ))}
                      </ListItem>
                    ) : (
                      <ListItem
                        button
                        className={classes.listItem}
                        data-testid={item.name}
                      >
                        <ListItemIcon>
                          <RenderIcon logo={item?.logo} />
                        </ListItemIcon>
                        <ListItemText
                          primary={t(item.name)}
                          classes={{ primary: classes.listItemText }}
                        />
                        {openProgram ? (
                          <ExpandLess htmlColor="#8181A5" fontSize="small" />
                        ) : (
                          <ExpandMore htmlColor="#8181A5" fontSize="small" />
                        )}
                      </ListItem>
                    )}
                  </div>
                </Tooltip>
                {item.items && (
                  <Collapse
                    in={item.name === 'programs' ? openProgram : openReviews}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.items.map((item, index) => {
                        if (
                          item.permission &&
                          !canView(item.permission as keyof User['permissions'])
                        )
                          return null;
                        return (
                          <Tooltip title={t(item.name) ?? ''}>
                            <div>
                              <ListItem
                                key={uuid()}
                                button
                                className={`${classes.nested} ${classes.listItem}`}
                                // @ts-expect-error
                                component={NavLink}
                                to={item.to}
                                onClick={() => {
                                  setOpen(false);
                                }}
                                data-testid={item.name}
                              >
                                {item.name === 'referral' && isOpen && (
                                  <GrowthPlan
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      ...(isEnglish
                                        ? { right: 0 }
                                        : { left: 0 }),
                                    }}
                                  />
                                )}
                                <ListItemIcon style={{ minWidth: 40 }}>
                                  <RenderIcon logo={item?.logo} />
                                </ListItemIcon>
                                <ListItemText
                                  primary={t(item.name)}
                                  classes={{ primary: classes.listItemText }}
                                />
                              </ListItem>
                            </div>
                          </Tooltip>
                        );
                      })}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div style={{ width: '100%' }}>
          <Tooltip title={t('bonatUpdates') ?? ''}>
            <ListItem
              style={{ padding: '4px 13px' }}
              button
              className={`${classes.listItem} beamerTrigger`}
            >
              <ListItemIcon>
                <RenderIcon logo={NotificationsOffLogo} />
              </ListItemIcon>

              <SignoutButton>{t('bonatUpdates')}</SignoutButton>
            </ListItem>
          </Tooltip>

          <Tooltip title={t('video-tutorial') ?? ''}>
            <ListItem
              style={{ padding: '4px 13px' }}
              button
              className={classes.listItem}
              // @ts-ignore
              onClick={() => {
                window.open(
                  'https://loom.com/share/folder/3ecf7ad55b434a96a878265ba0240f3c ',
                  '_blank'
                );
              }}
            >
              <ListItemIcon>
                <RenderIcon logo={VideoTutorial} />
              </ListItemIcon>

              <SignoutButton>{t('video-tutorial')}</SignoutButton>
            </ListItem>
          </Tooltip>
          <Tooltip title={t('signout') ?? ''}>
            <ListItem
              style={{ padding: '4px 13px' }}
              button
              className={classes.listItem}
              // @ts-ignore
              onClick={resetUser}
            >
              <ListItemIcon>
                <RenderIcon logo={SignoutLogo} />
              </ListItemIcon>

              <SignoutButton>{t('signout')}</SignoutButton>
            </ListItem>
          </Tooltip>
        </div>
      </List>
    </Layout>
  );
};

const SignoutButton = styled.p`
  font-size: 0.72rem;
  font-weight: 400;
  color: rgb(129, 129, 165);
  &:hover {
    color: #111;
  }
`;

const Layout = styled.section`
  height: 100%;
  overflow-x: hidden;

  a.active-link {
    span {
      color: #000;
    }
  }
`;
