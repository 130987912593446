import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import useSWR from 'swr';
import { useGlobalState } from '../../GlobalState';
import GrowthPlan from '../GrowthPlan';
import { Toggle } from '../Toggle';

interface Props {
  categoriesValue: { id: string; name: string }[];
  loading: boolean;
  isExcludedCategories: boolean;
  disabled?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ExcludeSpecificCategories: FC<Props> = ({
  categoriesValue,
  isExcludedCategories,
  loading,
  setFieldValue,
  disabled = false,
}) => {
  const { user } = useGlobalState();
  const { t } = useTranslation();
  const { data } = useSWR(`/reward/merchantCategories/${user?.idMerchant}/ar`);

  const catagoriesOptions = data?.data ?? [];

  const canExcludeCategories =
    user?.permissions?.['RewardsModule']?.['Exclude Specific Categories'];

  if (!isExcludedCategories) return null;

  return (
    <>
      <Typography variant="caption" style={{ fontSize: 14, color: '#8181a5' }}>
        {t('reward:exclude-specific-categories-search-label')}
      </Typography>
      <Select
        isMulti
        name="products"
        menuPlacement="bottom"
        isDisabled={!canExcludeCategories || loading || disabled}
        styles={{
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#FF6645',
            primary75: '#FF856A',
            primary50: '#FFA490',
            primary25: '#FFC3B5',
          },
        })}
        closeMenuOnSelect={false}
        onChange={(categories) =>
          setFieldValue(
            'excludedCategories',
            categories.map((category) => ({
              id: category.value,
              name: category.label,
            }))
          )
        }
        value={categoriesValue.map((category) => ({
          label: category.name,
          value: category.id,
        }))}
        options={
          catagoriesOptions?.map((category: any) => ({
            value: category.id,
            label: category.name,
          })) ?? []
        }
        placeholder={t('reward:category-name')}
        noOptionsMessage={() => t('reward:no-options')}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </>
  );
};
