import React from 'react';
import notFoundImage from '../../../assets/img/404.png';
import { Layout } from './styled';

interface Props {
  title: string;
  isCenter?: boolean;
  padding?: string;
  imgWidth?: number;
}

export const NotFoundPage = ({ title, isCenter, padding, imgWidth }: Props) => {
  return (
    <Layout isCenter={!!isCenter} padding={padding} imgWidth={imgWidth}>
      <div>
        <img src={notFoundImage} alt="404" />
        <h3 className="title">{title}</h3>
      </div>
    </Layout>
  );
};
