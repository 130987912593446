import { navigate } from '@reach/router';
import React from 'react';
import { ReactComponent as BackArrowLogo } from '../../../assets/svg/back-arrow.svg';
import { RenderIcon } from '../../SVG';

export const BackButton = () => {
  return (
    <div
      className="clickable"
      onClick={() => navigate(-1)}
      style={{ marginLeft: '0' }}
    >
      <RenderIcon logo={BackArrowLogo} padding="0.5rem" bColor="#F5F5FA" />
    </div>
  );
};
