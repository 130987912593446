import { Hidden } from '@material-ui/core';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExportIcon } from '../../../../assets/svg/export.svg';
import { Button } from '../../../../components/Common';

export const ExportCustomersButton = ({ dataToExport, handleExportClick }) => {
  const { t } = useTranslation();
  const csvRef = React.useRef();

  React.useEffect(() => {
    if (dataToExport.length > 0 && csvRef.current && csvRef.current.link) {
      setTimeout(() => {
        csvRef.current.link.click();
      });
    }
  }, [dataToExport]);

  return (
    <>
      <Button
        startIcon={<ExportIcon />}
        backcolor="transparent"
        noShadow
        color="secondary"
        handleClick={handleExportClick}
      >
        <Hidden smDown>
          <p style={{ color: '#8181A5', fontWeight: 400 }}>
            {t('segmentation:exportCustomers')}
          </p>
        </Hidden>
      </Button>
      <div style={{ display: 'none' }}>
        {Array.isArray(dataToExport) && (
          <CSVLink ref={csvRef} data={dataToExport}></CSVLink>
        )}
      </div>
    </>
  );
};
