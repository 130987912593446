import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { IOSSwitch } from './styled';

interface ToggleProps {
  checked?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: any;
  disabled?: boolean;
  size?: 'medium' | 'small' | undefined;
}

export function Toggle({
  checked,
  handleChange,
  label,
  disabled,
  size = 'medium',
  ...rest
}: ToggleProps) {
  return (
    <FormGroup>
      <FormControlLabel
        style={{ fontSize: 11 }}
        disabled={disabled}
        control={
          <IOSSwitch
            checked={checked}
            onChange={handleChange}
            name="checked"
            size={size}
            {...rest}
          />
        }
        label={
          label ? (
            <span
              style={{
                fontSize: 16,
                marginBottom: 10,
                display: 'inline-block',
              }}
            >
              {label}
            </span>
          ) : null
        }
      />
    </FormGroup>
  );
}
