import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { VerticalTabs } from '../../../Common';
import { ACTIONS, customersInitialState, reducer, tabs } from './helper';
import { CustomerActivityTable } from './table';

const headerData = {
  earning: [
    { val: 'earnDate', dataMap: 'earnDate' },
    { val: 'orderId', dataMap: 'orderId' },
    { val: 'earnMethod', dataMap: 'earnMethod' },
    { val: 'earnedPoints', dataMap: 'earnedPoints' },
  ],
  redemption: [
    { val: 'redeemDate', dataMap: 'redeemDate' },
    { val: 'orderId', dataMap: 'orderId' },
    { val: 'code', dataMap: 'code' },
    { val: 'reward', dataMap: 'reward' },
    { val: 'usedPoints', dataMap: 'usedPoints' },
    { val: 'usedDate', dataMap: 'usedDate' },
  ],
  deducted: [
    { val: 'dedected-earnDate', dataMap: 'earnDate' },
    { val: 'dedected-activityPoint', dataMap: 'activityPoint' },
    { val: 'dedected-status', dataMap: 'status' },
    { val: 'dedected-orderId', dataMap: 'orderId' },
  ],
};

export const CustomerActivity = ({ customerId }) => {
  const { t } = useTranslation();

  const [{ page, sort }, stateDispatch] = React.useReducer(
    reducer,
    customersInitialState
  );

  const queries = {
    sortBy: sort.by,
    sortType: sort.direction,
  };

  const [activeTab, setActiveTab] = React.useState(tabs[0]);
  const handleChange = (newValue) => {
    setActiveTab(newValue);
    stateDispatch({
      type: ACTIONS.SET_PAGE,
      payload: 1,
    });
  };

  const {
    data: { data, totalPages, isSuccess } = { data: [], totalPages: 1 },
    error,
  } = useSWR(
    `/customers/${customerId}/${activeTab.url}/${page}?sortBy=${queries.sortBy}&&sortType=${queries.sortType}`
  );

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={4} lg={3}>
        <VerticalTabs
          title={t('transactionHistory')}
          activeTab={activeTab}
          handleChange={handleChange}
          tabs={tabs}
        />
      </Grid>
      <Grid item sm={12} md={8} lg={9}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomerActivityTable
              items={data}
              requestSort={(by, direction) => {
                const isByChanged = by !== sort.by;
                stateDispatch({
                  type: ACTIONS.SET_SORT,
                  payload: { by, direction: isByChanged ? 'asc' : direction },
                });
              }}
              sortConfig={sort}
              headerData={headerData[activeTab.url]}
              page={page}
              handlePageChange={(_, page) => {
                stateDispatch({
                  type: ACTIONS.SET_PAGE,
                  payload: page,
                });
              }}
              totalPages={totalPages}
              loading={!isSuccess}
              error={error}
              errroMessage="Something went wrong"
              isBirthdaySegment={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
