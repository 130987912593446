import styled from '@emotion/styled';
import React from 'react';

export const ImageLoader = () => {
  return (
    <ImgLoader>
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </ImgLoader>
  );
};

const ImgLoader = styled.div`
  position: absolute;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translateX(-50%);

  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    opacity: 0.85;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
`;
