import React from 'react';
import { useTranslation } from 'react-i18next';

export const RewardTaps = ({
  onChange,
  activeTap,
}: {
  onChange: (id: number | null) => void;
  activeTap: number | null;
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: '70px' }}>
      <p
        style={{
          fontSize: 20,
          fontWeight: 500,
          color: '#13131E',
          marginBottom: '24px',
        }}
      >
        {t('rewards')}
      </p>
      <div style={{ display: 'flex', alignItems: 'center', gap: '60px' }}>
        <p
          onClick={() => onChange(null)}
          style={{
            color: activeTap === null ? '#FF6645' : '#13131E',
            fontSize: '16px',
            fontWeight: activeTap === null ? '500' : '400',
            cursor: 'pointer',
            paddingBottom: '6.5px',
            ...(activeTap === null && { borderBottom: '2px solid #FF6645' }),
          }}
        >
          {t('reward:all-rewards')}
        </p>
        <p
          onClick={() => onChange(1)}
          style={{
            color: activeTap === 1 ? '#FF6645' : '#13131E',
            fontSize: '16px',
            fontWeight: activeTap === 1 ? '500' : '400',
            cursor: 'pointer',
            paddingBottom: '6.5px',
            ...(activeTap === 1 && { borderBottom: '2px solid #FF6645' }),
          }}
        >
          {t('reward:fixedAmountDiscount')}
        </p>
        <p
          onClick={() => onChange(2)}
          style={{
            color: activeTap === 2 ? '#FF6645' : '#13131E',
            fontSize: '16px',
            fontWeight: activeTap === 2 ? '500' : '400',
            cursor: 'pointer',
            paddingBottom: '6.5px',
            ...(activeTap === 2 && { borderBottom: '2px solid #FF6645' }),
          }}
        >
          {t('reward:percentageDiscount')}
        </p>
        <p
          onClick={() => onChange(3)}
          style={{
            color: activeTap === 3 ? '#FF6645' : '#13131E',
            fontSize: '16px',
            fontWeight: activeTap === 3 ? '500' : '400',
            cursor: 'pointer',
            paddingBottom: '6.5px',
            ...(activeTap === 3 && { borderBottom: '2px solid #FF6645' }),
          }}
        >
          {t('reward:freeShipping')}
        </p>
        <p
          onClick={() => onChange(4)}
          style={{
            color: activeTap === 4 ? '#FF6645' : '#13131E',
            fontSize: '16px',
            fontWeight: activeTap === 4 ? '500' : '400',
            cursor: 'pointer',
            paddingBottom: '6.5px',
            ...(activeTap === 4 && { borderBottom: '2px solid #FF6645' }),
          }}
        >
          {t('reward:freeProduct')}
        </p>
      </div>
    </div>
  );
};
