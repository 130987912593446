import axios from 'axios';
import axiosRetry from 'axios-retry';

import { CONFIGRATION, ENV } from '../config';
axios.defaults.withCredentials = true;

export const { serverEndPoint, serverEndPointShared, firebaseEnv, bonatPay } =
  CONFIGRATION.env[ENV];

export * from './programs';
export * from './segmentation';
export * from './settings';
export * from './setupLoyalty';
export * from './shared';
export * from './user';

const config = {
  dev: {
    baseURL: serverEndPoint + '/api/v1',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
  shared: {
    baseURL: serverEndPointShared + '/api/v4',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
};

export const dev = axios.create({ ...config.dev });
export const shared = axios.create({ ...config.shared });

// [dev].forEach((api) => {
//   api.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     async function (error) {
//       // Do something with response error
//       // if (
//       //   error?.response?.status === 401 &&
//       //   window.location.pathname !== '/login' &&
//       //   error.response.config.url !== '/merchant/info'
//       // ) {
//       //   localStorage.clear();
//       //   window.location.pathname = '/login';
//       // }
//       // return Promise.reject(error.response);
//     }
//   );
// });

export const fetcher = (url: string): Promise<{ data: Array<{}> }> =>
  dev
    .get(url)
    .then((res: { data: Array<{}> }) => res.data ?? { data: [] })
    .catch(
      (err) => err?.response?.data?.message || err.message || err?.data?.message
    );

axiosRetry(dev, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1500;
  },
});
