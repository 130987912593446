import styled from '@emotion/styled';
import React from 'react';
import ContentLoader from 'react-content-loader';

export * from './box';
export * from './boxes';
export * from './circular';
export * from './grid';
export * from './imageLoader';
export * from './statisitcs';
export * from './verticalList';

export const SVG = styled(ContentLoader)`
  display: flex;
  margin: 70px 0 64px 0;
  height: ${({ height }) => height && height};
`;

export const SegmentationLoader = () => (
  <SVG height="1000" width="1000">
    <rect x="0" y="0" rx="5" ry="5" width="500" height="200" />
    <rect x="520" y="0" rx="5" ry="5" width="500" height="200" />
    <rect x="0" y="220" rx="5" ry="5" width="500" height="150" />
    <rect x="0" y="240" rx="5" ry="5" width="180" height="220" />
    <rect x="0" y="260" rx="5" ry="5" width="500" height="150" />
    <rect x="0" y="520" rx="5" ry="5" width="180" height="220" />
    <rect x="200" y="520" rx="5" ry="5" width="180" height="220" />
  </SVG>
);
