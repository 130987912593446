import React from 'react';

type Props = {
  fill: string;
};

export const Pezel = ({ fill = '#fff' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.417"
      height="36.252"
      viewBox="0 0 15.417 36.252"
    >
      <path
        id="Union_30"
        data-name="Union 30"
        d="M18,0H36S29.118,1.71,24.618,5.408A24.967,24.967,0,0,0,18,14.791ZM11.382,5.408C6.882,1.71,0,0,0,0H18V14.791A24.945,24.945,0,0,0,11.382,5.408Z"
        transform="matrix(0.017, 1, -1, 0.017, 14.789, 0)"
        fill={fill}
      />
    </svg>
  );
};
