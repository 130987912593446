import React, { FC, useState } from 'react';
import {
  getDiffDays,
  NUMBER_OF_DAYS_TO_SUBSCRIPTION_END_NOTIFICATION,
} from '../../utils';
import { useGlobalState } from '../GlobalState';
import { Header } from './header';
import styles from './index.module.css';
import { Main } from './main';
import { Navigation } from './navigation';
import { Notification } from './notification';

interface Props {}

export const Layout: FC<Props> = ({ children }) => {
  const [isNavigationExpand, setIsNavigationExpand] = useState(() => {
    const userIsExandValue = localStorage.getItem('isNavigationExpand');

    if (userIsExandValue === null) {
      return true;
    } else {
      return JSON.parse(userIsExandValue);
    }
  });
  const { subscription } = useGlobalState();

  const today = new Date();

  const numberOfRemainDaysForSubscription = getDiffDays({
    start: subscription.end_date ? new Date(subscription.end_date) : today,
    end: today,
  });

  const aboutToExpire =
    numberOfRemainDaysForSubscription <
      NUMBER_OF_DAYS_TO_SUBSCRIPTION_END_NOTIFICATION &&
    subscription.idsubscription_status !== 4;

  const isExpired = subscription.idsubscription_status === 3;
  const isInActive = subscription.idsubscription_status === 4;
  const showSubscriptionNotification = isExpired || isInActive || aboutToExpire;

  const setIsNavigationExpandHandler = (value: boolean) => {
    localStorage.setItem('isNavigationExpand', JSON.stringify(value));
    setIsNavigationExpand(value);
  };

  return (
    <div className={styles.container}>
      {showSubscriptionNotification && (
        <Notification
          aboutToExpire={aboutToExpire}
          isExpired={isExpired}
          numberOfRemainDaysForSubscription={numberOfRemainDaysForSubscription}
        />
      )}
      <div className={styles.container2}>
        <Navigation
          isNavigationExpand={isNavigationExpand}
          setIsNavigationExpand={setIsNavigationExpandHandler}
        />
        <div className={styles.container3}>
          <Header
            isNavigationExpand={isNavigationExpand}
            setIsNavigationExpand={setIsNavigationExpandHandler}
          />
          <Main>{children}</Main>
        </div>
      </div>
    </div>
  );
};
