export * from './customersTable';
export * from './header';

export const ACTIONS = {
  SET_SEARCH: 'SET_SEARCH',
  SET_FILTER_CHECKED: 'SET_FILTER_CHECKED',
  SET_FILTER_VALUES: 'SET_FILTER_VALUES',
  SET_LIMIT: 'SET_LIMIT',
  SET_PAGE: 'SET_PAGE',
  SET_SORT: 'SET_SORT',
};

export const customersInitialState = {
  search: '',
  page: 1,
  sort: {
    by: '',
    direction: '',
  },
  filter: {
    registerDate: {
      checked: false,
      startDate: undefined,
      endDate: undefined,
    },
    lastOrder: {
      checked: false,
      startDate: undefined,
      endDate: undefined,
    },
  },
  limit: { value: 10, label: '10 Results' },
};

export const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_SEARCH:
      return {
        ...state,
        search: payload,
      };

    case ACTIONS.SET_PAGE:
      return {
        ...state,
        page: payload,
      };

    case ACTIONS.SET_SORT:
      return {
        ...state,
        sort: { ...state.sort, ...payload },
      };

    case ACTIONS.SET_FILTER_CHECKED:
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.key]: {
            ...state.filter[payload.key],
            checked: payload.value,
          },
        },
      };
    case ACTIONS.SET_FILTER_VALUES:
      const newValue = {
        ...state.filter[payload.key],
        ...payload.value,
      };

      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.key]: newValue,
        },
      };

    case ACTIONS.SET_LIMIT:
      return { ...state, limit: payload };
    default:
      return state;
  }
};
