import { useEffect } from 'react';

export const useScript = (selector: string = 'body', content: string) => {
  useEffect(() => {
    const element: any = document.querySelector(selector);
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.innerHTML = content;
    element.appendChild(script);

    return () => {
      element.removeChild(script);
    };
  }, [content, selector]);
};

export const useScriptWithUrl = (
  url: string,
  selector: string = 'body',
  async: boolean = true
) => {
  useEffect(() => {
    const element: any = document.querySelector(selector);
    const script = document.createElement('script');
    script.src = url;
    script.async = async;
    element.appendChild(script);
    return () => {
      element.removeChild(script);
    };
  }, [url, selector, async]);
};
