import { createMuiTheme, Button } from '@material-ui/core';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { mainColor, mq } from '../../../utils';

interface StyledProps {
  textcolor?: string;
  fullwidth: 0 | 1;
  component: string;
  backcolor?: string;
  rouneded: 0 | 1;
  padding?: string;
}

export const CustomButton = styled(Button)<StyledProps>`
  padding: ${({ padding }) => (padding ? padding : '10px 0')};
  color: ${({ textcolor }) => (textcolor ? textcolor : 'white')} !important;
  font-size: 16px;
  font-weight: 400;
  width: ${({ fullwidth }) => (fullwidth ? '100%' : 'auto')};
  background-color: ${({ backcolor }) => (backcolor ? backcolor : '')};
  box-shadow: ${({ backcolor }) =>
    backcolor === 'transparent'
      ? 'none'
      : `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)`} !important;

  opacity: 0.95;

  span {
    white-space: nowrap;
  }

  ${({ rouneded, backcolor }) =>
    rouneded &&
    css`
      border-radius: 50%;
      box-shadow: none;
      border: 0.5px dashed #eee;
      opacity: 0.9;

      &:hover {
        opacity: 1;
        background-color: ${backcolor};
      }
    `};

  &:hover {
    opacity: 1;
    background-color: ${({ backcolor }) => (backcolor ? backcolor : '')};
  }

  &:disabled {
    opacity: 0.8;
    background-color: ${({ backcolor }) => (backcolor ? backcolor : mainColor)};
  }

  ${mq('phone')} {
    width: 100%;
  }
`;

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: '#ECECEC',
    },
  },
});
