import React from 'react';
import ContentLoader from 'react-content-loader';

export const StatisticsLoader = () => (
  <ContentLoader
    viewBox="0 0 380 70"
    backgroundColor="rgba(0, 0, 0, 0.06)"
    foregroundColor="rgba(0, 0, 0, 0.2)"
    data-testid="loading"
  >
    <rect x="0" y="0" rx="5" ry="5" width="85" height="30" />
    <rect x="95" y="0" rx="5" ry="5" width="85" height="30" />
    <rect x="190" y="0" rx="5" ry="5" width="85" height="30" />
    <rect x="285" y="0" rx="5" ry="5" width="85" height="30" />
    <rect x="0" y="40" rx="5" ry="5" width="180" height="30" />
    <rect x="190" y="40" rx="5" ry="5" width="180" height="30" />
  </ContentLoader>
);
