import { Fade, Paper, Popper, TextField } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import {
  patchReviewsEmailAPI,
  postReviewsEmailTestAPI,
} from '../../api/reviews';
import { IOSSwitch } from '../../components/Common/Toggle/styled';
import { usePost } from '../../utils';
import * as yup from 'yup';
import { Button, TextInput } from '../../components/Common';
import emailPreview from '../../assets/img/email-preview.png';
import { ReactComponent as Stars } from '../../assets/svg/stars-larg.svg';
import { ReactComponent as EmailPezel } from '../../assets/svg/email-pezel.svg';
import { useGlobalState } from '../../components/GlobalState';

const validEmailReg =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

interface Props extends RouteComponentProps {}

export const Email: FC<Props> = () => {
  const { t } = useTranslation();
  const { data } = useSWR('/reviews/requestEmail');
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [sendTo, setSendTo] = useState('');
  const { isEnglish } = useGlobalState();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const [{ loading }, submitForm] = usePost({
    apiFunction: patchReviewsEmailAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const [{ loading: isLoadingTestEmail }, submitTestEmail] = usePost({
    apiFunction: postReviewsEmailTestAPI,
    updateUser: false,
    alertOnSuccess: t('reviews:email-sent'),
  });

  const emailSettings = data?.data?.reviewRequestEmail;
  const coupon = data?.data?.couponSettings;

  const validationSchema = yup.object({
    enableFirstEmail: yup.bool().required(t('requiredField')),
    enableSecondEmail: yup.bool().required(t('requiredField')),
    subject: yup.string().required(t('requiredField')),
    senderName: yup.string().required(t('requiredField')),
    replyTo: yup.string().email(t('invalidEmail')).required(t('requiredField')),
    title: yup.string().required(t('requiredField')),
    subtitle: yup.string().required(t('requiredField')),
    button: yup.string().required(t('requiredField')),
    emailBody: yup.string().required(t('requiredField')),
  });

  const rewardMessage = () => {
    if (!coupon) return "You'll get rewarded 20% discount for your review";

    switch (coupon.idRewardTypes) {
      case 1:
        return t('reviews:reward-fixed', { reward: coupon.discountAmount });
      case 2:
        return t('reviews:reward-precentage', {
          reward: coupon.discountAmount,
        });
      case 3:
        return t('reviews:reward-shipping');
    }
  };

  return (
    <div>
      <Paper
        elevation={0}
        style={{
          padding: '23px 16px',
          height: 70,
          borderRadius: '4px',
          border: '1px solid #E8E8E8',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 39,
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: '#292222',
          }}
        >
          {t('reviews:email-setting')}
        </p>
      </Paper>
      <Formik
        enableReinitialize
        initialValues={{
          enableFirstEmail: emailSettings?.enableFirstEmail ?? false,
          enableSecondEmail: emailSettings?.enableSecondEmail ?? false,
          subject: emailSettings?.subject ?? '',
          senderName: emailSettings?.senderName ?? '',
          replyTo: emailSettings?.replyTo ?? '',
          title: emailSettings?.title ?? '',
          subtitle: emailSettings?.subtitle ?? '',
          button: emailSettings?.button ?? '',
          emailBody: emailSettings?.emailBody ?? '',
        }}
        onSubmit={async (body: any) => {
          await submitForm(body);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          setFieldValue,
          submitForm,
          validateField,
          errors,
          setFieldTouched,
          touched,
        }) => (
          <div style={{ display: 'flex', gap: 36 }}>
            <Form style={{ width: '365px' }}>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: '#292222',
                  marginBottom: 16,
                }}
              >
                {t('reviews:widget-setting')}
              </p>
              <Paper
                elevation={0}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #E8E8E8',
                  marginBottom: 23,
                  padding: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 25,
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: '#292222',
                        marginBottom: 8,
                      }}
                    >
                      {t('reviews:first-email')}
                    </p>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#292222',
                      }}
                    >
                      {t('reviews:after-7-days-of-delivery')}
                    </p>
                  </div>
                  <ActivationToggle
                    value={values.enableFirstEmail}
                    onChange={(isActive) =>
                      setFieldValue('enableFirstEmail', isActive)
                    }
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: '#292222',
                        marginBottom: 8,
                      }}
                    >
                      {t('reviews:second-email')}
                    </p>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#292222',
                      }}
                    >
                      {t('reviews:after-14-days-of-deliver')}
                    </p>
                  </div>
                  <ActivationToggle
                    value={values.enableSecondEmail}
                    onChange={(isActive) =>
                      setFieldValue('enableSecondEmail', isActive)
                    }
                  />
                </div>
              </Paper>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: '#292222',
                  marginBottom: 16,
                }}
              >
                {t('reviews:email-info')}
              </p>
              <Paper
                elevation={0}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #E8E8E8',
                  marginBottom: 23,
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 4,
                    }}
                  >
                    {t('reviews:subject')}
                  </p>
                  <TextInput
                    name="subject"
                    placeholder={t('reviews:how-was-your-order?')}
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 4,
                    }}
                  >
                    {t('reviews:sender-name')}
                  </p>
                  <TextInput
                    name="senderName"
                    placeholder={t('reviews:sender-name')}
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 4,
                    }}
                  >
                    {t('reviews:reply-to')}
                  </p>
                  <TextInput
                    name="replyTo"
                    placeholder={'example@gmail.com'}
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
              </Paper>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: '#292222',
                  marginBottom: 16,
                }}
              >
                {t('reviews:email-design')}
              </p>
              <Paper
                elevation={0}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #E8E8E8',
                  marginBottom: 23,
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 4,
                    }}
                  >
                    {t('reviews:title')}
                  </p>
                  <TextInput
                    name="title"
                    placeholder={t('reviews:share-your-experience-with-us')}
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 4,
                    }}
                  >
                    {t('reviews:subtitle')}
                  </p>
                  <TextInput
                    name="subtitle"
                    placeholder={t(
                      'reviews:how-was-your-experience-ordering-from-our-store'
                    )}
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 4,
                    }}
                  >
                    {t('reviews:button')}
                  </p>
                  <TextInput
                    name="button"
                    placeholder={t('reviews:write-a-review')}
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
              </Paper>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  loading={loading}
                  bColor="primary"
                  color="white"
                  fullwidth={0}
                  padding={'8px 80px'}
                >
                  {loading ? t('submitting') : t('save')}
                </Button>
              </div>
            </Form>

            <div style={{ flexGrow: 1 }}>
              <div
                style={{
                  marginBottom: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ fontSize: '16px', color: '#292222' }}>
                  {t('branding:preview')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 55,
                    width: 181,
                    backgroundColor: '#fff',
                    borderRadius: 8,
                    cursor: 'pointer',
                    border: '1px solid #E8E8E8',
                  }}
                  onClick={(event) =>
                    setAnchorEl(anchorEl ? null : event.currentTarget)
                  }
                >
                  <p
                    style={{
                      fontSize: '16px',
                      color: '#FF6645',
                      fontWeight: 500,
                    }}
                  >
                    {t('reviews:send-test-email')}
                  </p>
                </div>
                <Popper
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  placement="bottom"
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <div style={{ position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            top: '-7px',
                            right: '-16px',
                          }}
                        >
                          <EmailPezel />
                        </div>
                        <Paper
                          elevation={2}
                          style={{
                            position: 'absolute',
                            padding: '16px 20px',
                            borderRadius: '4px',
                            top: '23px',
                            ...(isEnglish
                              ? { right: '-70px' }
                              : { left: '-70px' }),
                            width: 450,
                          }}
                        >
                          <p
                            style={{
                              fontSize: '14px',
                              color: '#292222',
                              fontWeight: 400,
                              marginBottom: 10,
                            }}
                          >
                            {t('reviews:send-to')}
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 17,
                            }}
                          >
                            <TextField
                              placeholder="example@gmail.com"
                              value={sendTo}
                              onChange={(e) => setSendTo(e.target.value)}
                              style={{
                                height: '50px',
                                backgroundColor: '#F9F9FF',
                                border: '1px solid #F1F1F5',
                                borderRadius: '10px',
                                fontSize: '15px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  direction: 'ltr',
                                },
                              }}
                              fullWidth
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  fontSize: '15px',
                                  color: '#292222',
                                  fontWeight: 500,
                                },
                              }}
                            />
                            <Button
                              handleClick={async () => {
                                await submitTestEmail({
                                  sendTo,
                                  emailBody: values.emailBody,
                                  senderName: values.senderName,
                                  subject: values.subject,
                                });
                                setAnchorEl(null);
                              }}
                              disabled={!sendTo.match(validEmailReg)}
                              disabledLabel=""
                              loading={isLoadingTestEmail}
                              bColor="primary"
                              color="white"
                              fullwidth={0}
                            >
                              {loading ? t('submitting') : t('reviews:send')}
                            </Button>
                          </div>
                        </Paper>
                      </div>
                    </Fade>
                  )}
                </Popper>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Paper
                  elevation={0}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #E8E8E8',
                    padding: '16px',
                    width: '698px',
                    height: coupon?.isCouponEnabled ? '844px' : '704px',
                  }}
                >
                  <p
                    style={{
                      fontSize: '28px',
                      color: '#292222',
                      fontWeight: 400,
                      textAlign: 'center',
                      marginTop: 44,
                      marginBottom: 25,
                    }}
                  >
                    {values.title || t('reviews:share-your-experience-with-us')}
                  </p>
                  <p
                    style={{
                      fontSize: '24px',
                      color: '#292222',
                      fontWeight: 400,
                      textAlign: 'center',
                      marginBottom: 80,
                    }}
                  >
                    {values.subtitle ||
                      t(
                        'reviews:how-was-your-experience-ordering-from-our-store'
                      )}
                  </p>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={emailPreview} alt="emailPreview" />
                  </div>

                  {coupon?.isCouponEnabled && (
                    <div
                      style={{
                        backgroundColor: '#FFD3C9',
                        borderRadius: 10,
                        marginTop: 48,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 100,
                      }}
                    >
                      <p
                        style={{
                          fontSize: '24px',
                          color: '#292222',
                          fontWeight: 500,
                        }}
                      >
                        {rewardMessage()}
                      </p>
                    </div>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 48,
                    }}
                  >
                    <Stars />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 32,
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 55,
                        backgroundColor: '#FF6645',
                        borderRadius: 8,
                        width: 181,
                      }}
                    >
                      <p
                        style={{
                          fontSize: '16px',
                          color: '#FFFFFF',
                          fontWeight: 500,
                        }}
                      >
                        {values.button || t('reviews:write-a-review')}
                      </p>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

const ActivationToggle = ({
  value,
  onChange,
  disabled,
  style = {},
  size = 'medium',
}: {
  value: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small' | undefined;
  onChange?: (isActive: boolean) => void;
  style?: React.CSSProperties | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <IOSSwitch
      disabled={disabled}
      checked={value}
      onChange={(e, checked) => onChange?.(checked)}
      name="checked"
      size={size}
    />
  );
};
