import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import brandingAr from './ar/branding.json';
import campaingAr from './ar/campaigns.json';
import commonAr from './ar/common.json';
import earningMethodsAr from './ar/earningMethods.json';
import homeAr from './ar/home.json';
import onBordingAr from './ar/onBording.json';
import publicAr from './ar/public.json';
import rewardSystemAr from './ar/rewardSystem.json';
import segmentationAr from './ar/segmentation.json';
import serverErrorsAr from './ar/server-errors.json';
import settingsAr from './ar/settings.json';
import brandingEn from './en/branding.json';
import campaingEn from './en/campaigns.json';
import commonEn from './en/common.json';
import earningMethodsEn from './en/earningMethods.json';
import homeEn from './en/home.json';
import onBordingEn from './en/onBording.json';
import publicEn from './en/public.json';
import rewardSystemEn from './en/rewardSystem.json';
import segmentationEn from './en/segmentation.json';
import serverErrorsEn from './en/server-errors.json';
import settingsEn from './en/settings.json';
import referralEn from './en/referral.json';
import referralAr from './ar/referral.json';
import reviewsAr from './ar/reviews.json';
import reviewsEN from './en/reviews.json';

const resources = {
  en: {
    common: commonEn,
    public: publicEn,
    onBording: onBordingEn,
    home: homeEn,
    settings: settingsEn,
    branding: brandingEn,
    segmentation: segmentationEn,
    earningMethods: earningMethodsEn,
    reward: rewardSystemEn,
    serverError: serverErrorsEn,
    campaign: campaingEn,
    referral: referralEn,
    reviews: reviewsEN,
  },
  ar: {
    common: commonAr,
    public: publicAr,
    onBording: onBordingAr,
    home: homeAr,
    settings: settingsAr,
    branding: brandingAr,
    segmentation: segmentationAr,
    earningMethods: earningMethodsAr,
    reward: rewardSystemAr,
    serverError: serverErrorsAr,
    campaign: campaingAr,
    referral: referralAr,
    reviews: reviewsAr,
  },
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    defaultNS: 'common',

    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: false,
    },
  });

export default i18n;
