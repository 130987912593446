import { mainColor } from '../utils';

export const singReducer = (
  state: State = authInitialValue,
  action: Action
): State => {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case 'FETCHED':
      return {
        ...state,
        error: null,
        loading: false,
        user: action.payload,
        success: true,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      };
  }
};

export const initialUser: User = {
  end_date: '',
  idsubscription_status: 1,
  platform: { id: 1, name: 'Shopify' },
  owner: '',
  name: '',
  name_ar: '',
  currency: 'sar',
  phoneNumber: { val: 0 },
  idStatus: 0,
  idMerchant: 0,
  email: '',
  EcommerceType: { id: 3, type: 'Zid' },
  idOnlineLoyaltyType: 1,
  isIntegrated: false,
  imageUrl: '',
  permissions: {
    HomeModule: {
      view: true,
    },
    ReviewsModule: {
      Edit: true,
      View: true,
    },
    EarningPointsModule: {
      Edit: true,
      View: true,
      Signup: true,
      'Tiktok Follow': true,
      'Twitter Follow': true,
      'Instagram Follow': true,
      'Points per Purchase': true,
      'Celebrate a Birthday': true,
      'earn points on delivery feature': true,
    },
    RewardsModule: {
      Edit: true,
      View: true,
      'Exclude Specific Products': true,
      'Exclude Specific Categories': true,
    },
    ReferralModule: {
      Edit: true,
      View: true,
    },
    BrandingModule: {
      Edit: true,
      View: true,
      'Edit Button Icon': true,
      'Edit Remove Powered by Bonat': true,
    },
    CampaignModule: {
      Edit: true,
      View: true,
    },
    CustomersModule: {
      Edit: true,
      View: true,
    },
    NotificationsModule: {
      Edit: true,
      View: true,
    },
    SettingsModule: {
      Edit: true,
      View: true,
    },
  },
  completed: false,
  onlineConfiguration: {
    nextStep: 1,
    expiryPointDaysNumber: 90,
    language: 'en',
    notifictionSetting: [{}],
  },
  theme: {
    brandTheme: {
      storeName: 'Bonat',
      brandColor: { primary: mainColor },
      placement: 'left',
      coverImageURL:
        'https://bonat-plugin.s3-eu-west-1.amazonaws.com/img/roses.png',
      iconImageURL: '',
    },
  },
  link: '',
};

export type User = {
  end_date: string;
  idsubscription_status: 1 | 2 | 3 | 4;
  platform: { id: number; name: string };
  programData?: any;
  verified?: boolean;
  owner: string;
  currency: string;
  name: string;
  name_ar?: string;
  phoneNumber: { val: number };
  idStatus: number;
  idMerchant: number;
  email: string;
  EcommerceType?: { id: number; type: string };
  idOnlineLoyaltyType: 1 | 2;
  isIntegrated: boolean;
  imageUrl: string;
  completed: boolean;
  onlineConfiguration: {
    businessInfo?: any;
    nextStep: number;
    expiryPointDaysNumber: number;
    language: string;
    notifictionSetting: Array<{}>;
    phoneNumber?: {
      val?: number;
      country?: string;
      countryCode?: string;
      dialCode?: number;
    };
  };
  theme: {
    brandTheme: {
      storeName: string;
      brandColor: { primary: string };
      placement: string;
      iconImageURL: string;
      coverImageURL: string;
    };
  };
  permissions: {
    HomeModule: {
      view: boolean;
    };
    ReviewsModule: {
      Edit: boolean;
      View: boolean;
    };
    EarningPointsModule: {
      Edit: boolean;
      View: boolean;
      Signup: boolean;
      'Tiktok Follow': boolean;
      'Twitter Follow': boolean;
      'Instagram Follow': boolean;
      'Points per Purchase': boolean;
      'Celebrate a Birthday': boolean;
      'earn points on delivery feature': boolean;
      'Snapchat Follow'?: boolean;
    };
    RewardsModule: {
      Edit: boolean;
      View: boolean;
      'Exclude Specific Products': boolean;
      'Exclude Specific Categories': boolean;
    };
    ReferralModule: {
      Edit: boolean;
      View: boolean;
    };
    BrandingModule: {
      Edit: boolean;
      View: boolean;
      'Edit Button Icon': boolean;
      'Edit Remove Powered by Bonat': boolean;
    };
    CampaignModule: {
      Edit: boolean;
      View: boolean;
    };
    CustomersModule: {
      Edit: boolean;
      View: boolean;
    };
    NotificationsModule: {
      Edit: boolean;
      View: boolean;
    };
    SettingsModule: {
      Edit: boolean;
      View: boolean;
    };
  };
  link: string;
  password?: string;
  viewCampaign?: boolean;
};

export type Action =
  | { type: 'FETCHING' }
  | { type: 'FETCHED'; payload: User }
  | { type: 'ERROR'; payload: string };

export const authInitialValue: State = {
  user: initialUser,
  loading: false,
  error: null,
  success: false,
};

export type State = {
  user: User;
  loading: boolean;
  error: null | string;
  success: boolean;
};
