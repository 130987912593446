import { Box, Grid, Paper } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { patchReviewsWidgetAPI } from '../../api/reviews';
import { IOSSwitch } from '../../components/Common/Toggle/styled';
import { useGlobalState } from '../../components/GlobalState';
import { usePost } from '../../utils';
import * as yup from 'yup';
import { SketchPicker } from 'react-color';
import { useStyles } from '../../components/Common/ColorPicker/styled';
import { Button, RadioButtons, TextInput } from '../../components/Common';
import webPreviewImage from '../../assets/img/brand-backgroound-web.png';
import mobilePreviewImage from '../../assets/img/brand-backgroound-mobile.png';
import { ReactComponent as Stars } from '../../assets/svg/stars.svg';
import { ReactComponent as StarsAvatar } from '../../assets/svg/stars-avatar.svg';
import { ReactComponent as BonatLogo } from '../../assets/svg/bonat-logo.svg';
import { Pezel } from '../../components/Pezel';

const reviewIcons: { [key: string]: any } = {
  icon1: {
    black: require('../../assets/svg/review-icon1.svg').default,
    orange: require('../../assets/svg/review-icon1-orange.svg').default,
  },
  icon2: {
    black: require('../../assets/svg/review-icon2.svg').default,
    orange: require('../../assets/svg/review-icon2-orange.svg').default,
  },
  icon3: {
    black: require('../../assets/svg/review-icon3.svg').default,
    orange: require('../../assets/svg/review-icon3-orange.svg').default,
  },
  icon4: {
    black: require('../../assets/svg/review-icon4.svg').default,
    orange: require('../../assets/svg/review-icon4-orange.svg').default,
  },
  icon5: {
    black: require('../../assets/svg/review-icon5.svg').default,
    orange: require('../../assets/svg/review-icon5-orange.svg').default,
  },
};

interface Props extends RouteComponentProps {}

export const Widget: FC<Props> = () => {
  const { isEnglish, user } = useGlobalState();
  const { t } = useTranslation();
  const { data } = useSWR('/reviews/widgets');
  const [{ loading }, submitForm] = usePost({
    apiFunction: patchReviewsWidgetAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const [preview, setPreview] = useState(0);

  const tabPosistions = [
    { title: t('reviews:left'), id: 'left' },
    { title: t('reviews:right'), id: 'right' },
  ];

  const widget = data?.data;

  const validationSchema = yup.object({
    isEnabled: yup.bool().required(t('requiredField')),
    primaryColor: yup.string().required(t('requiredField')),
    tabPosition: yup.string().required(t('requiredField')),
    tabText: yup.string().required(t('requiredField')),
    buttonText: yup.string().required(t('requiredField')),
    tabIcon: yup.string().required(t('requiredField')),
  });

  return (
    <div>
      <Paper
        elevation={0}
        style={{
          padding: '23px 16px',
          height: 70,
          borderRadius: '4px',
          border: '1px solid #E8E8E8',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 39,
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: '#292222',
          }}
        >
          {t('reviews:widget')}
        </p>
      </Paper>
      <Formik
        enableReinitialize
        initialValues={{
          isEnabled: widget?.isEnabled ?? false,
          primaryColor: widget?.primaryColor ?? '#ff6645',
          tabPosition: widget?.tabPosition ?? 'right',
          tabText: widget?.tabText ?? 'Reviews',
          buttonText: widget?.buttonText ?? 'Write a review',
          tabIcon: widget?.tabIcon ?? 'icon1',
        }}
        onSubmit={async (body: any) => {
          await submitForm(body);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          setFieldValue,
          submitForm,
          validateField,
          errors,
          setFieldTouched,
          touched,
        }) => (
          <div style={{ display: 'flex', gap: 36 }}>
            <Form style={{ width: '365px' }}>
              <Paper
                elevation={0}
                style={{
                  padding: '16px',
                  borderRadius: '4px',
                  border: '1px solid #E8E8E8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 30,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: '#292222',
                  }}
                >
                  {t('reviews:enable-widget')}
                </p>
                <ActivationToggle
                  value={values.isEnabled}
                  onChange={(isActive) => setFieldValue('isEnabled', isActive)}
                />
              </Paper>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: '#292222',
                  marginBottom: 16,
                }}
              >
                {t('reviews:widget-setting')}
              </p>
              <Paper
                elevation={0}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #E8E8E8',
                  marginBottom: 30,
                }}
              >
                <div style={{ padding: 16 }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 10,
                    }}
                  >
                    {t('reviews:primary-color')}
                  </p>
                  <ColorPicker
                    color={values.primaryColor}
                    setColor={(color) => setFieldValue('primaryColor', color)}
                  />
                </div>
                <div style={{ borderTop: '1px solid #F6F7F8' }}></div>
                <div style={{ padding: 16 }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 10,
                    }}
                  >
                    {t('reviews:review-tab-position')}
                  </p>

                  <RadioButtons
                    name="tabPosition"
                    options={tabPosistions}
                    value={values.tabPosition}
                    setValue={(tabPlacement: string) =>
                      setFieldValue('tabPosition', tabPlacement)
                    }
                  />
                </div>
                <div style={{ borderTop: '1px solid #F6F7F8' }}></div>
                <div style={{ padding: 16 }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 5,
                    }}
                  >
                    {t('reviews:reviews-tab-text')}
                  </p>
                  <TextInput
                    name="tabText"
                    placeholder={t('reviews:reviews-tab-text')}
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
                <div style={{ borderTop: '1px solid #F6F7F8' }}></div>
                <div style={{ padding: 16 }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 5,
                    }}
                  >
                    {t('reviews:button-text')}
                  </p>
                  <TextInput
                    name="buttonText"
                    placeholder={t('reviews:button-text')}
                    labelColor="#8181A5"
                    variant="filled"
                    disableUnderline
                  />
                </div>
                <div style={{ borderTop: '1px solid #F6F7F8' }}></div>
                <div style={{ padding: 16 }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: '#292222',
                      marginBottom: 5,
                    }}
                  >
                    {t('reviews:review-tab-icon')}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {Object.entries(reviewIcons).map(([name, icon]) => (
                      <div
                        onClick={() => {
                          setFieldValue('tabIcon', name);
                        }}
                        style={{
                          width: '50px',
                          height: '50px',
                          cursor: 'pointer',
                          backgroundColor:
                            values.tabIcon === name ? '#F6F7F8' : '#f4f4ff',
                          padding: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border:
                            values.tabIcon === name
                              ? '2px #FF6645 solid'
                              : '1px #F0F0F3 solid',
                          borderRadius: '4px',
                        }}
                      >
                        <img src={icon.black} alt={name}></img>
                      </div>
                    ))}
                  </div>
                </div>
              </Paper>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  loading={loading}
                  bColor="primary"
                  color="white"
                  fullwidth={0}
                  padding={'8px 80px'}
                >
                  {loading ? t('submitting') : t('save')}
                </Button>
              </div>
            </Form>

            <div style={{ flexGrow: 1 }}>
              <div style={{ fontSize: '16px', color: '#292222' }}>
                {t('branding:preview')}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    minHeight: preview === 0 ? 559 : 615,
                    minWidth: preview === 0 ? 764 : 345,
                    direction: 'ltr',
                  }}
                >
                  <img
                    src={preview === 0 ? webPreviewImage : mobilePreviewImage}
                    alt="webPreviewImage"
                  />
                  <div
                    style={{
                      backgroundColor: values.primaryColor,
                      borderRadius: 46,
                      width: 138,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 5,
                      border: '1px solid #f0ecec',
                      transform: 'rotate(-90deg)',
                      position: 'absolute',
                      bottom: '90px',
                      ...(values.tabPosition === 'left'
                        ? { left: -43 }
                        : { right: -43 }),
                    }}
                  >
                    <p
                      style={{
                        color: '#fff',
                        fontSize: 10,
                        fontWeight: 500,
                      }}
                    >
                      {values.tabText}
                    </p>
                    <img src={reviewIcons[values.tabIcon].orange} alt="icon" />
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      backgroundColor: '#fff',
                      borderRadius: 10,
                      width: '282px',
                      height: 440,
                      ...(values.tabPosition === 'left'
                        ? { bottom: '31px', left: 50 }
                        : { bottom: '31px', right: 50 }),
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        ...(values.tabPosition === 'left'
                          ? { left: '-14px', bottom: '24px' }
                          : {
                              right: '-14px',
                              bottom: '24px',
                              transform: 'rotate(180deg)',
                            }),
                      }}
                    >
                      <Pezel fill="#fff" />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 98,
                        direction: 'ltr',
                        gap: 7,
                      }}
                    >
                      <p
                        style={{
                          color: '#292222',
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        5.0
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 5,
                        }}
                      >
                        <Stars />
                        <p
                          style={{
                            color: '#292222',
                            fontSize: 10,
                            fontWeight: 400,
                          }}
                        >
                          Based on 25 reviews
                        </p>
                      </div>
                    </div>
                    <div style={{ borderTop: '2px solid #F6F7F8' }}></div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 15,
                        marginBottom: 35,
                      }}
                    >
                      <p
                        style={{
                          color: '#fff',
                          backgroundColor: values.primaryColor,
                          borderRadius: '25px',
                          padding: '7px 15px',
                          fontSize: 9,
                          fontWeight: 500,
                        }}
                      >
                        {values.buttonText}
                      </p>
                    </div>
                    <ReviewAvatar color={values.primaryColor} />
                    <ReviewAvatar color={values.primaryColor} />
                    <ReviewAvatar color={values.primaryColor} />
                    <div
                      style={{
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#F5F5FA',
                        borderBottomRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        height: '22px',
                        gap: 4,
                        direction: 'ltr',
                        bottom: 0,
                        width: '100%',
                      }}
                    >
                      <p
                        style={{
                          color: '#1A1919',
                          fontSize: 9,
                          fontWeight: 400,
                        }}
                      >
                        Powered by
                      </p>
                      <BonatLogo />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '50px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p
                  onClick={() => setPreview(0)}
                  style={{
                    cursor: 'pointer',
                    ...(preview === 0 && {
                      color: '#FF6645',
                      borderBottom: '#FF6645 solid 3px',
                    }),
                  }}
                >
                  {t('branding:web')}
                </p>
                <p
                  onClick={() => setPreview(1)}
                  style={{
                    cursor: 'pointer',
                    ...(preview === 1 && {
                      color: '#FF6645',
                      borderBottom: '#FF6645 solid 3px',
                    }),
                  }}
                >
                  {t('branding:mobile')}
                </p>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

const ActivationToggle = ({
  value,
  onChange,
  disabled,
  style = {},
  size = 'medium',
}: {
  value: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small' | undefined;
  onChange?: (isActive: boolean) => void;
  style?: React.CSSProperties | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <IOSSwitch
      disabled={disabled}
      checked={value}
      onChange={(e, checked) => onChange?.(checked)}
      name="checked"
      size={size}
    />
  );
};

type IColorPicker = {
  color: string;
  setColor: (color: string) => void;
};
export const ColorPicker = ({ color, setColor }: IColorPicker) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (color: any) => {
    setColor(color.hex);
  };

  const classes = useStyles({ hexa: color });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ position: 'relative' }}
    >
      <Box
        mr={2}
        style={{ display: 'inline-block', border: '1px solid #cbcbcb' }}
        width={30}
        height={25}
        bgcolor={color}
        borderRadius={4}
      ></Box>
      <TextInput
        disableUnderline
        fullWidth={false}
        name="primaryColor"
        placeholder="#000000"
        labelColor="#8181A5"
      />
      <div className={classes.main} style={{ position: 'unset' }}>
        <div className={classes.swatch} onClick={handleClick}>
          <div className={classes.color} />
        </div>
        {isOpen ? (
          <div className={classes.popover}>
            <div className={classes.cover} onClick={handleClose} />
            <SketchPicker color={color} onChange={handleChange} />
          </div>
        ) : null}
      </div>
    </Box>
  );
};

const ReviewAvatar = ({ color = '#ff6645' }) => (
  <div
    style={{
      display: 'flex',
      gap: 6,
      direction: 'ltr',
      justifyContent: 'center',
      marginBottom: 20,
    }}
  >
    <div style={{ marginTop: 6 }}>
      <Avatar color={color} />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        direction: 'ltr',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          direction: 'ltr',
          gap: 40,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            direction: 'ltr',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <p
            style={{
              fontSize: '12px',
              color: '#292222',
              fontWeight: 400,
            }}
          >
            Ahmed Ramzy
          </p>
          <StarsAvatar />
        </div>
        <div
          style={{
            fontSize: '10px',
            color: '#8181A5',
            fontWeight: 500,
          }}
        >
          1/11/2022
        </div>
      </div>
      <div
        style={{
          fontSize: '10px',
          color: '#292222',
          fontWeight: 700,
          direction: 'ltr',
        }}
      >
        It was perfect
      </div>
    </div>
  </div>
);

const Avatar = ({ color = '#ff6645' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_58577"
        data-name="Group 58577"
        transform="translate(-764 -475)"
      >
        <circle
          id="Ellipse_1036"
          data-name="Ellipse 1036"
          cx="12"
          cy="12"
          r="12"
          transform="translate(764 475)"
          fill={color}
        />
        <text
          id="A"
          transform="translate(780 492)"
          fill="#fff"
          font-size="14"
          font-family="Poppins-Regular, Poppins"
        >
          <tspan x="0" y="0">
            A
          </tspan>
        </text>
      </g>
    </svg>
  );
};
