import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

interface Props {
  view: number;
  setView: React.Dispatch<React.SetStateAction<number>>;
}

export const Steps: FC<Props> = ({ view, setView }) => {
  const { t } = useTranslation();
  return (
    <Stepper className={styles.stepper} activeStep={view} alternativeLabel>
      {['campaign-detail', 'content'].map((label, index) => {
        return (
          <Step
            // onClick={() => setView(index)}
            key={label}
            completed={index === 0 && view === 1}
          >
            <StepLabel>{t(`campaign:${label}`)}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
