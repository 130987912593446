import { Box, Divider, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DateIcon } from '../../../assets/svg/date.svg';
import { ReactComponent as GenderIcon } from '../../../assets/svg/gender.svg';
import { ReactComponent as EmailIcon } from '../../../assets/svg/message.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/svg/phone_number.svg';
import { useGlobalState } from '../../GlobalState';
import { useProfileStyles } from './index';

export const PersonalInfo = ({ personalInfo }: any) => {
  const { t } = useTranslation();
  const classes = useProfileStyles();
  const { isEnglish } = useGlobalState();

  return (
    <Paper>
      <Box px={1} py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box px={2} pb={3}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Box display="flex" alignItems="center">
                    <PhoneIcon className={classes.icon} />
                    <p className={classes.label}>{personalInfo?.phoneNumber}</p>
                  </Box>
                </Grid>
                {personalInfo.email && (
                  <Grid item sm={12}>
                    <Box display="flex" alignItems="center">
                      <EmailIcon className={classes.icon} />
                      <p
                        style={{
                          // @ts-ignore
                          whiteSpace: 'no-wrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                        className={classes.label}
                      >
                        {personalInfo.email}
                      </p>
                    </Box>
                  </Grid>
                )}

                {personalInfo.gender && (
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <GenderIcon className={classes.icon} />
                      <p className={classes.label}>{t(personalInfo?.gender)}</p>
                    </Box>
                  </Grid>
                )}
                {personalInfo.birthday && (
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <DateIcon className={classes.icon} />
                      <p className={classes.label}>{personalInfo.birthday}</p>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Divider light />
          </Grid>
          <Grid item xs={12}>
            <Box px={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <p className={classes.label}>
                      {t('segmentation:memberSince')}:
                    </p>
                    <p className={classes.value}>{personalInfo?.joined}</p>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <p className={classes.label}>
                      {' '}
                      {t('segmentation:lastActivity')}:
                    </p>
                    <p className={classes.value}>
                      {isEnglish
                        ? personalInfo?.lastVisit_en
                        : personalInfo?.lastVisit_ar}
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
