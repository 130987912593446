import { CircularProgress } from '@material-ui/core';
import { Router } from '@reach/router';
import React, { FC, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { getInfoAPI } from '../../api';

import CodeVerification from '../../pages/codeVerification';
import LoyaltySetup from '../../pages/loyaltySetup';
import NewPassword from '../../pages/newPassword';
import NotFound from '../../pages/notFound';
import ResetPasswordAPI from '../../pages/resetPassword';
import Signin from '../../pages/signin';
import Signup from '../../pages/signup';
import { setLocal } from '../../utils';
import { CompletedApp } from '../App';
import { useGlobalState } from '../GlobalState';
import { NotRequireAuth } from './authorization/not-require-auth';
import { NotRequireMobileVerification } from './authorization/not-require-mobile-verification';
import { RequireAuth } from './authorization/require-auth';
import { RequireMobileVerification } from './authorization/require-mobile-verification';

export const Routes: FC = React.memo(() => {
  const { setUser } = useGlobalState();
  const { data } = useSWR('/merchant/info');

  useEffect(() => {
    const merchant = data?.data?.[0];

    if (merchant === 'unauthorized') {
      setLocal({ key: 'ebonat_user' });
    } else {
      setUser(merchant);
    }
  }, [data]);

  return (
    <Router>
      <RequireAuth path="/*" component={CompletedApp} />
      <NotRequireAuth path="/signin" component={Signin} />
      <NotRequireAuth path="/signup" component={Signup} />
      <NotRequireAuth path="/recover-password" component={ResetPasswordAPI} />
      <NotRequireAuth
        path="/password/reset/:idMerchant/:token"
        component={NewPassword}
      />
      <NotRequireMobileVerification path="/code" component={CodeVerification} />
      <RequireMobileVerification
        path="/platform-integration"
        component={LoyaltySetup}
      />
      <NotFound default />
    </Router>
  );
});
