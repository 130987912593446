import styled from '@emotion/styled';

export const Layout = styled.div`
  .title {
    font-size: 14px;
    font-weight: 500;
  }

  .secondary {
    font-size: 14px;
    font-weight: 400;
  }
`;
