import React from 'react';
import { NoIndex } from '../../components/Common/NoIndex';
import {
  ActionRequired,
  ActivitiesTable,
  Charts,
  Statistics,
} from '../../components/Home/index';
import { Guard } from '../../components/Routes/Guard';
import { useUpdateSubscription } from '../../hooks/useUpdateSubscription';
import { Container } from './styled';

export const Home = () => {
  useUpdateSubscription();

  return (
    <Guard permission="HomeModule">
      <NoIndex />
      <Container data-testid="home">
        <ActionRequired />
        <Statistics />
        <Charts />
        <ActivitiesTable />
      </Container>
    </Guard>
  );
};

export default Home;
