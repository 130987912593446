import { Divider, Menu, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton, Popup } from '../../../Common';
import { BlockForm } from './BlockForm';
import { PointActionForm } from './pointActionForm';

export const CustomerActionButton = ({ customerId, blocked }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [actionType, setActionType] = React.useState('add');
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOption = (actionType) => {
    setActionType(actionType);
    setOpenPopup(true);
  };

  const isAdd = actionType === 'add';

  const createOptions = () => {
    switch (actionType) {
      case 'add':
        return { title: t('segmentation:add_points') };
      case 'remove':
        return { title: t('segmentation:remove_points') };
      case 'unblock':
        return { title: t('unblock-customer') };
      case 'block':
        return { title: t('block-customer') };
      default:
        return { title: t('segmentation:add_points') };
    }
  };

  return (
    <>
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title={createOptions().title}
        maxWidth="sm"
        fullWidth={true}
        popupTheme={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          cross: 'white',
        }}
        data={[]}
        resetForm={() => {}}
      >
        {actionType === 'block' || actionType === 'unblock' ? (
          <BlockForm
            customerId={customerId}
            blocked={actionType === 'unblock'}
            handleClose={() => setOpenPopup(false)}
          />
        ) : (
          <PointActionForm
            customerId={customerId}
            isAdd={isAdd}
            handleClose={() => setOpenPopup(false)}
          />
        )}
      </Popup>

      <ActionButton handleClick={handleClick} />

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClickOption('add');
          }}
        >
          {t('segmentation:add_points')}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClickOption('remove');
          }}
        >
          {t('segmentation:remove_points')}
        </MenuItem>
        <Divider />
        <MenuItem
          style={{ color: blocked ? '#84C731' : '#FF6645' }}
          onClick={() => {
            handleClickOption(blocked ? 'unblock' : 'block');
          }}
        >
          {blocked ? t('unblock') : t('block')}
        </MenuItem>
      </Menu>
    </>
  );
};
