import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import React from 'react';
import { useStyles } from './styled';

type SelectProps = {
  id: number;
  name?: string;
  value?: string;
};

type Props = {
  items: Array<SelectProps>;
  label?: string;
  id: string;
  name: string;
  variant?: 'filled' | 'outlined' | 'standard';
  padding?: string;
  disabled: boolean;
  multiple?: boolean;
  isCenter: boolean;
  fontSize?: number;
};

export const SelectInput: React.FC<FieldAttributes<Props>> = ({
  items,
  label,
  id,
  variant,
  padding,
  disabled,
  multiple,
  isCenter,
  fontSize = 14,
  ...props
}: Props) => {
  const classes = useStyles({
    padding,
    isFilled: variant === 'filled',
    isCenter,
  });
  const [field, meta] = useField<Props>(props);

  const error = meta.error && meta.touched ? meta.error : '';

  const selectRef = React.useRef<HTMLElement>(null);

  return (
    <Box className={classes.box}>
      <FormControl fullWidth>
        <InputLabel id={`select-${id}`} className={classes.label}>
          {label}
        </InputLabel>
        <Select
          style={{ fontSize }}
          disableUnderline
          ref={selectRef}
          {...field}
          className={classes.select}
          labelId={`select-${id}`}
          id="demo-simple-select"
          label={label}
          fullWidth
          variant={variant}
          disabled={disabled}
          error={!!error}
          inputProps={{
            style: {
              fontSize: fontSize ? fontSize : 12,
            },
          }}
        >
          {items.map((item) => (
            <MenuItem
              style={{ fontSize }}
              key={item.id}
              //@ts-expect-error
              value={item}
            >
              {item.name ?? item.value}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

interface SelectType {
  name: string;
  disabled: boolean;
}

interface AsyncProps {
  label?: string;
  name: string;
  padding?: string;
  getAPI: () => Promise<any>;
  multiple?: boolean;
  disabled: boolean;
  autoFocus?: boolean;
  setInput?: any;
  defaultValue?: any;
  fontSize?: number;
  optionLabel?: string;
  defaultPlaceholder?: string;
}

export const AsynchronousSelect = ({
  label,
  padding,
  getAPI,
  multiple,
  disabled,
  autoFocus,
  setInput,
  defaultValue,
  fontSize = 14,
  optionLabel = 'name',
  defaultPlaceholder = '',
  ...props
}: AsyncProps) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<SelectType[]>([]);
  const loading = open && options.length === 0;
  const [field, meta] = useField<Props>(props);
  const { setFieldValue } = useFormikContext();
  const error = meta.error && meta.touched ? meta.error : '';
  let [resError, setResError] = React.useState<any>(null);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let res: any = await getAPI();
      if (typeof res === 'object') {
        const {
          data: { data },
        } = res;

        if (active) {
          setOptions(
            Object.keys(data).map((key) => {
              return data[key];
            }) as SelectType[]
          );
          if (data.length < 1) {
            setOptions(options);
          }
        }
      } else {
        setResError(res);
        setOpen(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [getAPI, loading, options]);

  // Use if we need to clear prevois results when re-click the search

  const onChange = React.useCallback(
    (event, item) => {
      if (field.name === 'platform.id') {
        if (!item.baseURL) {
          item.baseURL = '';
        }
        setInput('platform', item);
      } else setFieldValue(field.name, item);
    },
    [field.name, setFieldValue, setInput]
  );

  const classes = useStyles({ isCenter: false });

  return (
    <Box className={classes.box}>
      <Autocomplete
        getOptionDisabled={(option) => option?.disabled}
        multiple={multiple}
        className={classes.async}
        classes={{ paper: classes.paper }}
        id="asynchronous-demo"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disabled={disabled}
        onChange={onChange}
        getOptionSelected={(option, value) => option?.name === value?.name}
        getOptionLabel={(option) => option[optionLabel] ?? defaultPlaceholder}
        defaultValue={defaultValue}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            autoFocus={autoFocus}
            error={!!error || !!resError}
            helperText={error || resError}
            {...params}
            label={label}
            variant="standard"
            fullWidth
            defaultValue={defaultValue}
            InputLabelProps={{
              style: {
                color: ' rgb(129, 129, 165)',
                fontSize: '12px',
                fontWeight: 300,
                transform: 'scale(1) !important',
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize,
                padding,
                backgroundColor: '#F5F5FA',
              },

              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export * from './select';
