import { Router } from '@reach/router';
import React, { FC } from 'react';
import { NoIndex } from '../../components/Common/NoIndex';
import { Guard } from '../../components/Routes/Guard';
import { Create } from './Create';
import { List } from './List';

interface Props {}

export const Campaigns: FC<Props> = () => {
  return (
    <Guard permission="CampaignModule">
      <NoIndex />
      <Router>
        <List path="/" />
        <Create path="/new" />
      </Router>
    </Guard>
  );
};
