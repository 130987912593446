import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { ReactComponent as TotalCustomerIcon } from '../../assets/svg/total_customers.svg';
import { ReactComponent as YellowSmileIcon } from '../../assets/svg/yellow_smile.svg';
import { SegmentationLoader } from '../../components/Common';
import {
  getIcon,
  SegmentCard,
  StatsCard,
  useStyles,
} from '../../components/Segmentation';

const Segmentation = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: { data } = { data: [] } } = useSWR<any>('/segment/list');

  if (!data?.length)
    return (
      <Box width="100%" height="100%">
        <SegmentationLoader />
      </Box>
    );

  const { generalCount, customSegments, segments }: any = data[0];

  return (
    <Grid container spacing={8} className={classes.container}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <StatsCard
              to={'allCustomers'}
              state={{
                label: t('segmentation:allCustomers'),
              }}
              label={t('segmentation:allCustomers')}
              value={Number(generalCount?.totalCustomers ?? 0).toLocaleString(
                'en-Us'
              )}
              logo={TotalCustomerIcon}
            >
              {}
            </StatsCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <StatsCard
              to={`newCustomers`}
              state={{
                label: t('segmentation:thisMonth'),
              }}
              label={t('segmentation:thisMonth')}
              value={Number(generalCount?.thisMonth).toLocaleString('en-Us')}
              logo={YellowSmileIcon}
            >
              {}
            </StatsCard>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Hidden xsDown>
          <Typography
            variant="h4"
            style={{ fontWeight: 400, marginBottom: 24 }}
          >
            {t('segmentation:customersByLoyaltySegments')}
          </Typography>
        </Hidden>
        <Grid container spacing={3}>
          {segments.map((segment: { segmentType: string }, idx: number) => (
            <Grid key={idx} item xs={12} md={6}>
              <SegmentCard
                to={`${segment.segmentType}`}
                state={{
                  label: t(segment.segmentType),
                }}
                logo={getIcon(segment.segmentType)}
                label={segment.segmentType}
                description={segment.segmentType}
                height="100%"
                isCustom={false}
                {...segment}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Hidden xsDown>
          <Typography
            variant="h4"
            style={{ fontWeight: 400, marginBottom: 24 }}
          >
            {t('segmentation:otherSegments')}
          </Typography>
        </Hidden>
        <Grid container spacing={3}>
          {customSegments.map(
            (segment: { segmentType: string }, idx: number) => (
              <Grid key={idx} item xs={12} md={6}>
                <SegmentCard
                  to={`${segment.segmentType}`}
                  state={{
                    label: t(segment.segmentType),
                  }}
                  logo={getIcon(segment.segmentType)}
                  label={segment.segmentType}
                  description={segment.segmentType}
                  {...segment}
                  isCustom={true}
                  height="100%"
                />
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Segmentation;
