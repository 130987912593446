import { css, Global } from '@emotion/core';
import React from 'react';
import { mainColor, mq } from '../../utils';

export const NonAuthLayout = (props: any) => css``;

export const AuthLayout = (props: any) =>
  css`
    color: yellow;
  `;

interface Props {
  isRtl: boolean;
}
export const GlobalStyle = ({ isRtl }: Props) => {
  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          letter-spacing: 0.5px !important;
          font-family: ${isRtl ? 'Cairo' : 'Poppins'}, sans-serif !important;
          direction: ${isRtl ? 'rtl' : 'ltr'};
        }

        ::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        .MuiInputLabel-shrink {
          transform-origin: ${isRtl ? 'top right' : 'top left'} !important;
        }

        body {
          direction: ${isRtl ? 'rtl' : 'ltr'};
          min-height: 100vh;
          background-color: rgba(246, 247, 248, 1);
          max-width: 100vw;
          overflow-x: hidden;
        }

        html {
          direction: ${isRtl ? 'rtl' : 'ltr'};

          ${mq('largeScreen')} {
            font-size: 19px;
          }
          ${mq('labtop')} {
            font-size: 18px;
          }
          ${mq('tablet')} {
            font-size: 17px;
          }
          ${mq('phone')} {
            font-size: 16px;
          }
          ${mq('smallPhone')} {
            font-size: 15px;
          }
        }

        #root {
          min-height: 100vh;
        }

        .input-placeholder::placeholder {
          color: '#8181A5';
          font-size: 16px;
        }
        .MuiDialog-paper {
          width: min(90vw, 620px);
        }

        .beamer_icon.active,
        #beamerIcon.active {
          top: 2px !important;
          right: 4px !important;
        }

        .react-tel-input > .special-label {
          display: none;
        }

        a {
          text-decoration: none;
          display: inline-block;
        }

        .blockbuilder-branding {
          display: none;
        }

        .MuiPickersSlideTransition-transitionContainer > * {
          font-size: 17px;
        }

        .MuiPickersCalendarHeader-dayLabel {
          font-size: 12px;
        }

        .MuiTypography-body2 {
          font-size: 16px;
        }
        .MuiPickersClockNumber-clockNumber {
          font-size: 18px;
        }

        .react-datepicker__current-month {
          font-size: 13px;
        }

        .react-datepicker__day-name {
          font-size: 11px;
        }
        .react-datepicker__day {
          font-size: 11px;
        }
        .react-datepicker-time__header {
          font-size: 13px;
        }

        .react-datepicker__time-list-item {
          font-size: 11px;
        }
        .react-datepicker__navigation {
          top: 10px;
        }

        .datetimepicker {
          width: 100%;
          height: 48px;
          padding: 10px;
          text-align: center;
          font-size: 16px;
          border: none;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          background-color: rgb(246, 247, 248);
        }

        .datetimepicker:focus {
          outline: none;
        }
        .datetimepicker:disabled {
          color: #aaa;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: #111;
        }

        p {
          color: #292222;
          font-weight: 400;
          font-size: 14px;
        }

        li {
          list-style-type: none;
        }

        strong {
          font-weight: 500;
          color: #000;
        }
        .MuiSvgIcon-fontSizeSmall {
          font-size: 24px;
        }

        .MuiListItemIcon-root {
          min-width: 47px;
        }

        ::placeholder {
          color: #aaa !important;
          opacity: 1 !important;
          font-weight: 400;
          font-size: 14px;
        }

        .MuiAlert-icon {
          width: unset !important;
        }

        .error {
          color: #f44336;
        }
        .MuiFilledInput-input {
          padding: 10px;
        }

        .MuiSnackbar-root,
        .MuiAlert-root,
        .MuiAlert-filledError {
          /* width: 100% !important; */
          display: flex;
          align-items: center;
        }

        .MuiPickersDay-daySelected {
          background-color: ${mainColor};

          &:hover {
            background-color: ${mainColor};
          }
        }

        .MuiStepIcon-root {
          font-size: 24px !important;
        }
        .MuiStepLabel-label {
          font-size: 15px;
        }

        .active-link {
          tspan {
            fill: ${mainColor} !important;
          }
          path {
            fill: ${mainColor} !important;
          }
        }
        .active-link-campaign {
          path {
            fill: ${mainColor} !important;
            stroke: ${mainColor} !important;
          }
        }

        .active-nested {
          span {
            color: ${mainColor} !important;
          }
        }

        .hidden {
          display: none;
        }
        .show {
          display: inherit;
        }

        .primary {
          color: ${mainColor} !important;
        }
        .secondary {
          color: #8181a5 !important;
        }

        .flex {
          display: flex !important;
        }

        .center {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        .space-between {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .column {
          flex-direction: column;
        }

        .card {
          box-shadow: rgb(0 0 0 / 4%) 0px 2px 1px -1px,
            rgb(0 0 0 / 3%) 0px 1px 1px 0px, rgb(0 0 0 / 4%) 0px 1px 3px 0px !important;
          background-color: white;
          border-radius: 8px;
          padding: 1.5em 1em;

          h3 {
            font-weight: 500;
          }
        }

        .MuiTableRow-root.campaign-list th:first-child {
          border-top-left-radius: ${isRtl ? 'none' : '10px'};
          border-bottom-left-radius: ${isRtl ? 'none' : '10px'};
          border-top-right-radius: ${isRtl ? '10px' : 'none'};
          border-bottom-right-radius: ${isRtl ? '10px' : 'none'};
        }
        .MuiTableRow-root.campaign-list th:last-child {
          border-top-right-radius: ${isRtl ? 'none' : '10px'};
          border-bottom-right-radius: ${isRtl ? 'none' : '10px'};
          border-top-left-radius: ${isRtl ? '10px' : 'none'};
          border-bottom-left-radius: ${isRtl ? '10px' : 'none'};
        }

        .MuiTableRow-root.campaign-list td:last-child {
          border-top-right-radius: ${isRtl ? 'none' : '10px'};
          border-bottom-right-radius: ${isRtl ? 'none' : '10px'};
          border-top-left-radius: ${isRtl ? '10px' : 'none'};
          border-bottom-left-radius: ${isRtl ? '10px' : 'none'};
        }

        .MuiStepIcon-text {
          font-size: 13px;
        }

        .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
          margin-top: 5px;
        }
        .MuiStepper-root {
          padding: 0px;
        }

        .icon {
          background-color: #f5f5fa;
          height: fit-content;
          width: fit-content;
          padding: 0.75em;
          border-radius: 8px;
        }

        .clickable {
          cursor: pointer;
        }

        .grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          grid-gap: 0 2em;

          > div {
            height: fit-content;
          }

          .left,
          .right {
            height: fit-content;
            > * {
              margin-bottom: 15px;
            }
          }

          .title {
            font-weight: 500;
          }
        }

        .mr-bottom {
          > * {
            margin-bottom: 2em;
          }
        }

        .mr-bottom-1 {
          > * {
            margin-bottom: 1em;
          }
        }

        .mr-bottom-1-5 {
          > * {
            margin-bottom: 1.5em;
          }
        }

        .mobile {
          display: none !important;
        }

        ${mq('tablet')} {
          * {
            white-space: normal;
          }
          .desktop {
            display: none !important;
          }
          .mobile {
            display: block !important;
          }
        }

        .MuiPaper-root-45 {
          box-shadow: rgb(0 0 0 / 4%) 0px 2px 1px -1px,
            rgb(0 0 0 / 3%) 0px 1px 1px 0px, rgb(0 0 0 / 4%) 0px 1px 3px 0px !important;
        }
        .reactEasyCrop_CropArea {
          width: 100% !important;
          height: 100% !important;
        }

        .css-yf72mx .mdl-demo .arrow:after {
          right: ${isRtl ? 'unset' : '10px'} !important;
          left: ${isRtl ? '10px' : 'unset'} !important;
        }

        .MuiDivider-vertical {
          height: 20 px;
          background: rgb(238, 238, 238);
          margin: 0 px 16 px;
          display: block;
        }

        li button.MuiPaginationItem-root {
          font-size: 14px !important;
        }

        .MuiFormHelperText-root {
          font-size: 14px !important;
        }

        .MuiListItem-root {
          font-size: 14px;
        }
      `}
    />
  );
};
