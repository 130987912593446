import React from 'react';
import { SketchPicker } from 'react-color';
import { useStyles } from './styled';

type Props = {
  state: {
    displayColorPicker: boolean;
    color: {
      hex: string;
    };
  };
  setState: (state: any) => void;
};
export const ColorPicker = ({ state, setState }: Props) => {
  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state.displayColorPicker });
  };

  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
  };

  const handleChange = (color: any) => {
    setState({ ...state, color: { hex: color.hex } });
  };

  const classes = useStyles({ hexa: state.color.hex });

  return (
    <div className={classes.main}>
      <div className={classes.swatch} onClick={handleClick}>
        <div className={classes.color} />
      </div>
      {state.displayColorPicker ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClose} />
          <SketchPicker
            //@ts-ignore
            color={state.color.hex}
            onChange={handleChange}
          />
        </div>
      ) : null}
    </div>
  );
};
