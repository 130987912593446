import styled from '@emotion/styled';

export const Layout = styled.section`
  padding: 2em 0 1em 0;
  font-weight: 300;

  > * {
    margin-bottom: 1.5em !important;
  }

  .title {
    font-weight: 500;
    font-size: 0.9rem;
  }

  .last {
    font-weight: 400;
  }
`;
