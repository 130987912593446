import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useGlobalState } from '../../../components/GlobalState';
import { Guard } from '../../../components/Routes/Guard';
import { AddReward } from './add-reward';
import { Header } from './header';
import { RewardForm } from './reward-form';
import { RewardTaps } from './reward-tabs';
import { RewardsGrid } from './rewards';

export const RewardSystem = () => {
  const { t } = useTranslation();
  const { user } = useGlobalState();
  const [rewardTypeFilter, setRewardTypeFilter] = useState<number | null>(null);
  const { data: { data } = { data: [] } }: any = useSWR(
    `/reward/${user?.idMerchant}`
  );
  const [selectedReward, setSelectedReward] = useState<null | any>(null);
  const [addRewardOpen, setAddRewardOpen] = useState(false);
  const [rewardFormOpen, setRewardFormOpen] = useState(false);
  const [addRewardType, setAddRewardType] = useState<null | number>(null);

  const rewards = useMemo(() => {
    if (!rewardTypeFilter) return data;

    return data?.filter(
      (reward: any) => reward.idRewardTypes === rewardTypeFilter
    );
  }, [data, rewardTypeFilter]);

  return (
    <Guard permission="RewardsModule">
      <div>
        <Header />
        <AddReward
          onClick={() => setAddRewardOpen(true)}
          addRewardOpen={addRewardOpen}
          onClose={() => setAddRewardOpen(false)}
          onCreate={(id) => {
            setSelectedReward(null);
            setAddRewardType(id);
            setAddRewardOpen(false);
            setRewardFormOpen(true);
          }}
        />
        <RewardTaps
          activeTap={rewardTypeFilter}
          onChange={setRewardTypeFilter}
        />
        <RewardsGrid
          rewards={rewards}
          onUpdate={(reward) => {
            setSelectedReward(reward);
            setAddRewardType(null);
            setRewardFormOpen(true);
          }}
        />
        <RewardForm
          open={rewardFormOpen}
          addRewardType={addRewardType}
          reward={selectedReward}
          onBack={() => {
            setRewardFormOpen(false);
            setAddRewardOpen(true);
          }}
          onClose={() => setRewardFormOpen(false)}
        />
      </div>
    </Guard>
  );
};
