import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import { useNavigate, RouteComponentProps } from '@reach/router';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import { useGlobalState } from '../../components/GlobalState';
import noReviews from '../../assets/img/no-rewards.png';
import { usePost } from '../../utils';
import { patchReviewsStatusAPI } from '../../api/reviews';
import { Pagination } from '../../components/Common/PaginationV2';

interface Props extends RouteComponentProps {}

export const Dashboard: FC<Props> = () => {
  const { isEnglish, setAlert } = useGlobalState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: statisticsData } = useSWR('/reviews/statistics');
  const { data: settings } = useSWR('/reviews/settings');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('unpublished');
  const { data } = useSWR(`/reviews/${page}?search=${search}&status=${status}`);
  const { mutate } = useSWRConfig();

  const [allReviews, setAllReviews] = useState<any[]>([]);
  const [selectedReviews, setselectedReviews] = useState<any>({});

  const reviews = useMemo(() => data?.data, [data]);
  const totalPages = data?.totalPages;
  const statistics = statisticsData?.data;
  const isEnabled = settings?.data?.isEnabled;

  const [{ loading }, submitForm] = usePost({
    apiFunction: patchReviewsStatusAPI,
    updateUser: false,
    alertOnSuccess:
      status === 'unpublished'
        ? t('reviews:published-success')
        : t('reviews:unpublished-success'),
  });

  useEffect(() => {
    if (!reviews) return;
    setAllReviews(reviews);
    setPage(1);
  }, [reviews]);

  useEffect(() => {
    if (status === 'pending') return setselectedReviews({});

    setselectedReviews((prevSelectedReviews: any) =>
      allReviews?.reduce(
        (total, review) => ({
          ...total,
          [review.idReviewsRequest]: prevSelectedReviews[
            review.idReviewsRequest
          ]
            ? prevSelectedReviews[review.idReviewsRequest]
            : false,
        }),
        {}
      )
    );
  }, [allReviews]);

  return (
    <div>
      <Paper
        elevation={0}
        style={{
          padding: '23px 16px',
          height: 70,
          borderRadius: '4px',
          border: '1px solid #E8E8E8',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 32,
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: '#292222',
          }}
        >
          {t('reviews-dashboard')}
        </p>
      </Paper>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 48,
          marginTop: 32,
          marginBottom: 32,
        }}
      >
        <Paper
          elevation={0}
          style={{
            padding: '21px 24px',
            borderRadius: '4px',
            border: '1px solid #E8E8E8',
            width: 406,
          }}
        >
          <p
            style={{
              fontSize: 16,
              color: '#292222',
              fontWeight: 500,
              marginBottom: 16,
            }}
          >
            {t('reviews:collected-reviews')}
          </p>
          <p style={{ fontSize: 16, color: '#292222', fontWeight: 500 }}>
            {statistics?.totalReviews}
          </p>
        </Paper>
        <Paper
          elevation={0}
          style={{
            padding: '21px 24px',
            borderRadius: '4px',
            border: '1px solid #E8E8E8',
            width: 406,
          }}
        >
          <p
            style={{
              fontSize: 16,
              color: '#292222',
              fontWeight: 500,
              marginBottom: 16,
            }}
          >
            {t('reviews:pending-reviews')}
          </p>
          <p style={{ fontSize: 16, color: '#292222', fontWeight: 500 }}>
            {statistics?.pendingRequests}
          </p>
        </Paper>
      </div>

      {!isEnabled && (
        <Paper
          elevation={0}
          style={{
            padding: '32px 16px',
            borderRadius: '4px',
            border: '1px solid #E8E8E8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: 32,
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: '#292222',
              marginBottom: 26,
            }}
          >
            {t('reviews:collect-reviews-label')}
          </p>
          <Button
            style={{ fontSize: 16, fontWeight: 500 }}
            color="primary"
            variant="contained"
            onClick={() => navigate('/reviews/settings')}
          >
            {t('reviews:collect-reviews')}
          </Button>
        </Paper>
      )}

      <Paper
        elevation={0}
        style={{
          padding: '32px 16px',
          borderRadius: '4px',
          border: '1px solid #E8E8E8',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 48,
            justifyContent: 'center',
          }}
        >
          <TextField
            placeholder={t(
              'reviews:search-by-customer-name,-mobile-or-order-id'
            )}
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              height: '50px',
              backgroundColor: '#F9F9FF',
              border: '1px solid #F1F1F5',
              borderRadius: '10px',
              fontSize: '15px',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            inputProps={{
              style: {
                textAlign: 'center',
                direction: 'ltr',
              },
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ marginInlineStart: 10 }}
                >
                  <Search htmlColor="#8181A5" />
                </InputAdornment>
              ),
              style: {
                fontSize: '15px',
                color: '#292222',
                fontWeight: 500,
              },
            }}
          />
          <div
            style={{
              padding: 8,
              display: 'flex',
              alignItems: 'center',
              gap: 32,
              height: '50px',
              backgroundColor: '#F9F9FF',
              border: '1px solid #F1F1F5',
              borderRadius: '10px',
              minWidth: isEnglish ? '400px' : '350px',
              justifyContent: 'center',
            }}
          >
            <p
              onClick={() => setStatus('pending')}
              style={{
                cursor: 'pointer',
                color: status === 'pending' ? '#FFFFFF' : '#8181A5',
                fontSize: 16,
                fontWeight: 500,
                padding: '4px 10px',
                backgroundColor: status === 'pending' ? '#FF6645' : 'unset',
                borderRadius: 10,
              }}
            >
              {t('reviews:pending')}
            </p>
            <p
              onClick={() => setStatus('unpublished')}
              style={{
                cursor: 'pointer',
                color: status === 'unpublished' ? '#FFFFFF' : '#8181A5',
                fontSize: 16,
                fontWeight: 500,
                padding: '4px 10px',
                backgroundColor: status === 'unpublished' ? '#FF6645' : 'unset',
                borderRadius: 10,
              }}
            >
              {t('reviews:unpublished')}
            </p>
            <p
              onClick={() => setStatus('published')}
              style={{
                cursor: 'pointer',
                color: status === 'published' ? '#FFFFFF' : '#8181A5',
                fontSize: 16,
                fontWeight: 500,
                padding: '4px 10px',
                backgroundColor: status === 'published' ? '#FF6645' : 'unset',
                borderRadius: 10,
              }}
            >
              {t('reviews:published')}
            </p>
          </div>
          {status !== 'pending' && (
            <Button
              style={{
                fontSize: 14,
                fontWeight: 500,
                padding: '12px 40px',
                minWidth: 165,
                whiteSpace: 'nowrap',
              }}
              color="primary"
              variant="contained"
              disabled={
                !Object.values(selectedReviews).some(
                  (selected: any) => selected
                ) || loading
              }
              onClick={async () => {
                const reviewsRequestIds = Object.entries(selectedReviews)
                  .filter(([_, isEnabled]) => isEnabled)
                  .map(([orderId, _]) => orderId);

                try {
                  await submitForm({ reviewsRequestIds });
                  setPage(1);
                  mutate(`/reviews/1?search=${search}&status=${status}`);
                } catch (error) {
                  setAlert(error?.message! ?? '', 'error');
                }
              }}
            >
              {loading
                ? status === 'published'
                  ? t('reviews:unpublishing')
                  : t('reviews:publishing')
                : status === 'unpublished'
                ? `${t('reviews:publish')}${
                    Object.entries(selectedReviews).filter(
                      ([_, isEnabled]) => isEnabled
                    )?.length
                      ? ` (${
                          Object.entries(selectedReviews).filter(
                            ([_, isEnabled]) => isEnabled
                          )?.length
                        })`
                      : ''
                  }`
                : `${t('reviews:unpublish')}${
                    Object.entries(selectedReviews).filter(
                      ([_, isEnabled]) => isEnabled
                    )?.length
                      ? ` (${
                          Object.entries(selectedReviews).filter(
                            ([_, isEnabled]) => isEnabled
                          )?.length
                        })`
                      : ''
                  }`}
            </Button>
          )}
        </div>

        <Table style={{ marginTop: 32 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {status !== 'pending' && (
                <TableCell style={{ borderBottom: 'none' }}></TableCell>
              )}
              <TableCell
                align="center"
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#292222',
                  borderBottom: 'none',
                }}
              >
                {t('reviews:order-id')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#292222',
                  borderBottom: 'none',
                }}
              >
                {t('reviews:order-date')}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#292222',
                  borderBottom: 'none',
                }}
              >
                {t('reviews:customer-name')}
              </TableCell>

              {status !== 'pending' && (
                <TableCell
                  align="center"
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#292222',
                    borderBottom: 'none',
                  }}
                >
                  {t('reviews:rating-feedback')}
                </TableCell>
              )}
              <TableCell
                align="center"
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#292222',
                  borderBottom: 'none',
                }}
              >
                {t('reviews:status')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allReviews?.map((review: any) => (
              <TableRow key={review.idReviewsRequest}>
                <TableCell
                  style={{
                    borderBottom: 'none',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#292222',
                  }}
                  align="center"
                >
                  {status !== 'pending' &&
                    selectedReviews.hasOwnProperty(review.idReviewsRequest) && (
                      <Checkbox
                        size="small"
                        checked={selectedReviews[review.idReviewsRequest]}
                        onChange={(_, checked) =>
                          setselectedReviews((prevSelectedReviews: any) => ({
                            ...prevSelectedReviews,
                            [review?.idReviewsRequest]: checked,
                          }))
                        }
                        color="primary"
                      />
                    )}
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 'none',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#292222',
                  }}
                  align="center"
                >
                  {review.orderNumber}
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 'none',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#292222',
                  }}
                  align="center"
                >
                  {new Date(review?.orderDate).toISOString().split('T')[0]}
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 'none',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#292222',
                  }}
                  align="center"
                >
                  {review.name}
                </TableCell>
                {status !== 'pending' && (
                  <TableCell
                    style={{
                      borderBottom: 'none',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#292222',
                    }}
                    align="center"
                  >
                    <Stars rate={review?.rate} />
                    <Tooltip title={review?.message ?? ''}>
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color: '#292222',
                          maxWidth: '250px',
                          margin: '9px auto 0px auto',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          direction: /^[A-Za-z0-9]*$/.test(
                            review?.ratingComment?.comment?.split(' ')?.[0] ??
                              ''
                          )
                            ? 'ltr'
                            : 'rtl',
                        }}
                      >
                        {review?.message}
                      </p>
                    </Tooltip>
                  </TableCell>
                )}
                <TableCell
                  style={{
                    borderBottom: 'none',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#292222',
                  }}
                  align="center"
                >
                  {t(`reviews:${review.status}`)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {allReviews?.length === 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: 43,
              gap: '24px',
            }}
          >
            <img src={noReviews} />
            <p>{t('reviews:no-reviews')}</p>
          </div>
        )}

        {totalPages > 1 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '40px 0px 20px 0px',
            }}
          >
            <Pagination
              count={totalPages ?? 0}
              page={page}
              onChange={(_, page) => setPage(page)}
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

const Stars = ({ rate }: { rate: any }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        direction: 'ltr',
      }}
    >
      {Array.from({ length: 5 }).map((_, idx) =>
        idx < rate ? (
          <Star fontSize="small" htmlColor="#FFC107" />
        ) : (
          <StarBorder fontSize="small" htmlColor="#FFC107" />
        )
      )}
    </div>
  );
};
