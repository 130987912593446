import { Box } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Link, useParams } from '@reach/router';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { newPasswordAPI } from '../api';
import PasswordLeft from '../assets/img/password-left.png';
import { Button, TextInput } from '../components/Common';
import { SignLayout } from '../components/SignLayout';
import { usePost, useStyles } from '../utils';
import { NoIndex } from '../components/Common/NoIndex';

const NewPassword = () => {
  const { t } = useTranslation();
  const [{ loading, success }, fetchData] = usePost({
    apiFunction: newPasswordAPI,
  });

  const { idMerchant, token } = useParams();

  const classes = useStyles();

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t('requiredField'))
      .min(6, `${t('minNumber')} 6`)
      .matches(/[a-zA-Z]/, t('public:password-latin-error')),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), ''], t('public:passwordConfiramtion-error')),
  });

  return (
    <>
      <NoIndex />
      <SignLayout
        firstTitle={t(`public:welcomeToBonat`)}
        secondTitle={t('public:reset-password')}
        subTitle={t('public:set-confirm-password')}
        bgImage={PasswordLeft}
      >
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async ({ password }, { resetForm }) => {
            await fetchData({ password, idMerchant, token });
            resetForm();
          }}
        >
          {() => (
            <Form>
              {!success && (
                <>
                  <TextInput
                    name="password"
                    type="current-password"
                    label={t('public:new-password')}
                  />
                  <TextInput
                    name="passwordConfirmation"
                    type="current-password"
                    label={t('public:confirm-password')}
                  />
                </>
              )}

              {!!success && (
                <Box>
                  <MuiAlert severity="success">
                    {t('public:passwordChangeSuccess')}
                  </MuiAlert>
                </Box>
              )}

              <Box>
                {!!success ? (
                  <Link to="/signin" replace className={classes.linkFullWidth}>
                    <Button
                      type="button"
                      bColor="primary"
                      color="white"
                      fullwidth={1}
                    >
                      {t('public:signin')}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    type="submit"
                    loading={loading}
                    bColor="primary"
                    color="white"
                    fullwidth={1}
                  >
                    {loading ? t('submitting') : t('public:submit')}
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </SignLayout>
    </>
  );
};

export default NewPassword;
