import { dev } from './index';

export const patchReviewsSettingsAPI = async (body: any) =>
  await dev.patch('/reviews/settings', body);

export const patchReviewsWidgetAPI = async (body: any) =>
  await dev.patch('/reviews/widgets', body);

export const patchReviewsEmailAPI = async (body: any) =>
  await dev.patch('/reviews/requestEmail', body);

export const patchReviewsStatusAPI = async (body: any) =>
  await dev.patch('/reviews/ReviewsStatus', body);

export const postReviewsEmailTestAPI = async (body: any) =>
  await dev.post('/reviews/testRequestEmail', body);
