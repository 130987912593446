import { dev } from './index';

export const postRewardAPI = async ({ rewardType, merchantId, body }: any) =>
  await dev.post(`/reward/${merchantId}/${rewardType}`, body);

// Ways to earn page

export const putEarningAPI = async ({ body: { idEarnWay, ...rest } }: any) =>
  await dev.put(`/points-program/${idEarnWay}`, rest);

export const postExpiryAPI = async ({ body: { merchantId, ...rest } }: any) =>
  await dev.patch(`/points-program/point-expiry/${merchantId}`, rest);

export const getCategories = async () => {
  try {
    return await dev.get('/categories');
  } catch (err) {
    return err?.response?.data?.message || err.message || err?.data?.message;
  }
};
// Referrals

export const putReferralAPI = async ({ body: { idCampaign, ...rest } }: any) =>
  await dev.put(`/referral/${idCampaign}`, rest);

export const patchReferralSharingAPI = async ({
  body: { merchantId, ...rest },
}: any) => await dev.patch(`/referral/share-options/${merchantId}`, rest);

export const postRewardTypeAPI = async ({
  body: { merchantId, ...rest },
}: any) => await dev.post(`/reward/Add/${merchantId}`, rest);

export const postReferralSocialAPI = async ({
  body: { merchantId, ...rest },
}: any) => await dev.patch(`/referral/share-options/${merchantId}`, rest);

export const postReferralCreateAPI = async ({ body: { ...rest } }: any) =>
  await dev.post(`/referral`, rest);

export const postReferralUpdateAPI = async ({
  body: { idCampaign, ...rest },
}: any) => await dev.post(`/referral/${idCampaign}`, rest);

export const updateRewardStatus = async ({
  body: { idCampaign, ...rest },
}: any) => await dev.patch(`/reward/updateRewardStatus/${idCampaign}`, rest);
