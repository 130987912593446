import {
  Pagination as MuiPagination,
  UsePaginationProps,
} from '@material-ui/lab';
import React, { FC } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';

interface Props {
  count: number;
  page: number;
  onChange: UsePaginationProps['onChange'];
}

export const Pagination: FC<Props> = ({ count, page, onChange }) => {
  const { width } = useWindowSize();

  const isMobile = width && width < 508;

  return (
    <MuiPagination
      color="primary"
      count={count}
      variant="outlined"
      shape="rounded"
      {...(isMobile && { siblingCount: 0, boundaryCount: 1 })}
      size={isMobile ? 'small' : 'large'}
      page={page}
      onChange={onChange}
    />
  );
};
