import styled from '@emotion/styled';
import { useParams } from '@reach/router';
import React from 'react';
import { SystemHeader } from '../../../../components/Common';
import { useGlobalState } from '../../../../components/GlobalState';
import { earningWays } from '../../../../components/Programs';
import { mq } from '../../../../utils';
import { EditForm } from './Form';

export const EditRoutes = () => {
  const { isEnglish } = useGlobalState();
  const params = useParams();

  const currentMethodId = params.id;

  const currentMethod = earningWays.find(
    (way) => way.idEarnWay == currentMethodId
  );

  return (
    <Layout className="mr-bottom">
      <SystemHeader
        title={
          (isEnglish ? currentMethod?.title_en : currentMethod?.title_ar) ?? ''
        }
        withBack
        withStatus
      />
      <EditForm
        currentMethodId={currentMethodId}
        currentMethod={currentMethod}
      />
    </Layout>
  );
};

const Layout = styled.div`
  .grid {
    .left {
      grid-column: 1/3;
    }
  }

  ${mq('tablet')} {
    .grid {
      .left {
        grid-column: -1/1;
      }
    }
  }
`;
