import { makeStyles } from '@material-ui/core';

export * from '../PagesLayout';
export * from './Accordian';
export * from './Alert';
export * from './AreaChart';
export * from './BackButton';
export * from './Box';
export * from './Brand';
export * from './Button';
export * from './Card';
export * from './ChartHelpers';
export * from './Checkbox';
export * from './ColorPicker';
export * from './CustomerHyperLink';
export * from './DatePicker';
export * from './DropMenu';
export * from './ExportData';
export * from './GlobalStyle';
export * from './Header/index';
export * from './ImageUploader';
export * from './LineChart';
export * from './Loaders';
export * from './NavLink';
export * from './notFoundPage';
export * from './PageError';
export * from './Pagination';
export * from './PhoneNumberInput';
export * from './Popup';
export * from './ProgressBar';
export * from './RadioButtons';
export * from './RadioGroup';
export * from './ReactDatePicker';
export * from './RewardSystemForm';
export * from './Routes';
export * from './SelectInput';
export * from './SideCurve';
export * from './SystemCard';
export * from './SystemHeader';
export * from './Table';
export * from './Tabs';
export * from './TextInput';
export * from './Toggle';
export * from './TreeSelect';
export * from './VerticalTabs';

export const useFilterMenuStyles = makeStyles(() => ({
  popover: {
    overflow: 'visible',
    '& .MuiPopover-paper': {
      overflow: 'visible',
    },
    '& li': { minWidth: 200 },

    '& .date': {
      color: '#999',
      'border-radius': '3px',
      padding: '3px',
      border: '1px solid #999',
      margin: '0 5px',
    },
  },
}));
