import {
  Checkbox,
  Input,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles({
  icon: {
    width: '24px !important',
    height: '24px !important',
  },
  select: {
    fontSize: 12,
    padding: '0 12px',
  },
});

export const MuiSelect = ({
  startAdornment,
  label,
  labelIcon,
  name,
  options,
  errorObj,
  color,
  fontSize = 14,
  disableUnderline,
  optionLabel = 'nameEn',
  optionValue = 'id',
  backgroundColor = 'transparent',
  selectAllKey,
  fullWidth,
  onChange,
  setValue,
  padding,
  placehoder,
  multiple,
  ...props
}: any) => {
  const selectRef = React.useRef(null);

  const classes = useStyles();
  const isAllSelected =
    Array.isArray(props.value) && props.value.includes('allCustomers');

  React.useEffect(() => {
    if (isAllSelected) {
      const values = options.map((seg: any) => seg.value);
      setValue(name, values);
    }
  }, [isAllSelected, name, options, setValue]);

  return (
    <div>
      <Select
        style={{ fontSize }}
        ref={selectRef}
        fullWidth={fullWidth}
        disableUnderline={disableUnderline}
        input={<Input />}
        multiple={multiple}
        name={name}
        onChange={onChange}
        classes={{
          select: classes.select,
          icon: classes.icon,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
          },
          getContentAnchorEl: null,
        }}
        label={
          labelIcon ? (
            <label>
              <span>{labelIcon}</span>
              {label}
            </label>
          ) : (
            label
          )
        }
        InputProps={{
          classes,
          style: {
            fontSize: fontSize ? fontSize : 12,
            color: color && color,
            backgroundColor,
            padding: padding && padding,
            borderRadius: 8,
          },
          startAdornment: !!startAdornment && <div>{startAdornment}</div>,
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            fontSize,
            fontWeight: 400,
            color: '#292222',
          },
        }}
        {...props}
      >
        {placehoder && (
          <MenuItem value="gender" disabled selected>
            {placehoder}
          </MenuItem>
        )}

        {multiple
          ? options.map((option: any) => {
              return (
                <MenuItem key={option[optionValue]} value={option[optionValue]}>
                  <Checkbox
                    checked={
                      props.value.indexOf(option[optionValue]) > -1
                      //  ||isAllSelected
                    }
                    color="primary"
                  />
                  {option[optionLabel]}
                </MenuItem>
              );
            })
          : options.map((option: any) => (
              <MenuItem
                key={option[optionValue]}
                value={option[optionValue]}
                style={{ fontSize }}
              >
                {option[optionLabel]}
              </MenuItem>
            ))}
      </Select>
    </div>
  );
};
