//@ts-nocheck
import {
  Box,
  CircularProgress,
  Paper,
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTableCell, TableLayout, useTableStyles } from './styled';

export * from './styled';

export function Table({
  withoutAction,
  backgroundColor = '#8181A5',
  withBorder = false,
  headPadding,
  color = 'white',
  headerData,
  children,
  noData,
  noDataMessasge,
  requestSort = () => {},
  sortConfig,
  numSelected,
  reverseSelectedAll,
  rowCount,
  handleSelectAllClick,
  menuOptions,
  loading = true,
  error = null,
  errroMessage = '',
  height = 53,
}: any) {
  const { t } = useTranslation();

  const classes = useTableStyles({ backgroundColor, color, withBorder });

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.by === name ? sortConfig.direction : undefined;
  };

  return (
    <TableLayout>
      <TableContainer component={Paper}>
        <MuiTable className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              {headerData.map((head, idx) => {
                return (
                  <StyledTableCell
                    padding={headPadding}
                    isLeft={head.left}
                    key={idx}
                    onClick={() =>
                      requestSort(
                        head.val,
                        sortConfig.direction === 'asc' ? 'desc' : 'asc'
                      )
                    }
                    className={`${getClassNamesFor(head.val)} sortable`}
                  >
                    {t(head.val)}
                  </StyledTableCell>
                );
              })}
              {!withoutAction && (
                <StyledTableCell>{t('action')}</StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          {children}
        </MuiTable>
        {noData && (
          <Box
            width={1}
            height={100}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography varient="h2">
                {error ? errroMessage : t('noAvailableData')}
              </Typography>
            )}
          </Box>
        )}
      </TableContainer>
    </TableLayout>
  );
}
