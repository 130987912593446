import React from 'react';
import { useTranslation } from 'react-i18next';
import { SystemHeader } from '../../../../components/Common';
import {
  ActiveEarningItems,
  PointExpiry,
} from '../../../../components/Programs';
import { Layout } from './styled';

export const Main = () => {
  const { t } = useTranslation();

  return (
    <Layout className="mr-bottom">
      <SystemHeader
        title={t('earningMethods:earningMethods')}
        withBack={false}
        withStatus={false}
      />
      <div className="grid border-bottom" style={{ minHeight: '50vh' }}>
        <div className="left mr-bottom">
          <h3 className="title">{t('earningMethods:earningMethods')}</h3>
          <p className="paragraph">
            {t('earningMethods:enableDifferentTypesOfEarning')}
          </p>
        </div>
        <ActiveEarningItems />
      </div>
      <PointExpiry />
    </Layout>
  );
};
