import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';

export const RadioButtons = ({
  value,
  setValue,
  options = [],
  direction = '',
  name,
}: any) => {
  const classes = useStyles();

  return (
    <RadioGroup
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        direction: direction && direction,
      }}
      row
      aria-label="placement"
      name={name}
      value={value}
      onChange={(e: any) => {
        setValue(e.target.value);
      }}
    >
      {options.map((option: any) => {
        return (
          <FormControlLabel
            key={option.id}
            className={classes.smallRadioButton}
            color="primary"
            value={option.id}
            control={<Radio color="primary" size="small" />}
            label={<p style={{ fontSize: '15px' }}>{option.title}</p>}
          />
        );
      })}
    </RadioGroup>
  );
};

const useStyles = makeStyles((theme) => ({
  smallRadioButton: {
    '& svg': {
      width: '0.75em',
      height: '0.75em',
      marginLeft: 0,
    },
    '& label': {
      fontSize: 14,
    },
  },
}));
