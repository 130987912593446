import React from 'react';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import { uuid } from 'uuidv4';

import { Button, ImageLoader } from '../';
import { Layout } from './styled';
import { useGlobalState } from '../../GlobalState';
import { useTranslation } from 'react-i18next';

type Props = {
  image: {
    img: string;
    file: string;
    id: number;
    type: string;
    loading: boolean;
  };
  setImage: React.Dispatch<React.SetStateAction<any>>;
  id: string;
  hideInputOnLoad: boolean;
  rouneded: 0 | 1;
  width?: string;
  height?: string;
  loading?: boolean;
  withLargePreview?: boolean;
};
export const ImageUploader = ({
  image,
  setImage,
  id,
  hideInputOnLoad,
  rouneded,
  width,
  height,
  loading,
  withLargePreview = true,
}: Props) => {
  function isImage(icon: string) {
    const ext = ['jpg', 'jpeg', 'bmp', 'gif', 'png', 'tiff', 'heif', 'webp'];
    return ext.some((el) => icon.endsWith(el));
  }

  const { t } = useTranslation();
  const { setAlert } = useGlobalState();

  return (
    <Layout width={width} height={height}>
      {withLargePreview && image.img && (
        <div className="image">
          <img src={image.img} alt="preview" />
        </div>
      )}
      <Button
        bColor="secondary"
        backcolor="#F6F7F8"
        type="button"
        color="black"
        fullwidth={0}
        isUploader={true}
        rouneded={rouneded ? 1 : 0}
      >
        {hideInputOnLoad && image.img ? (
          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              position: 'relative',
            }}
          >
            <img
              src={image.img}
              style={{ width: '30px', height: '30px' }}
              alt="upload"
            />
            {image.loading && <ImageLoader />}
          </div>
        ) : (
          <BackupOutlinedIcon
            style={{
              color: '#8181A5',
              backgroundColor: 'transparent',
            }}
          />
        )}
        <input
          id={id}
          type="file"
          accept="image/*"
          onChange={(e: any) => {
            const target = e.target.files[0];

            if (target.size > 2000000)
              setAlert(t('branding:image-size-exceed'), 'error');
            else if (isImage(target.type)) {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              canvas.width = 200;
              canvas.height = 200;

              const img = new Image();

              img.onload = function () {
                ctx?.drawImage(img, 0, 0, 200, 200);
                canvas.toBlob((blob) => {
                  if (blob)
                    setImage({
                      img: URL.createObjectURL(blob),
                      id: uuid(),
                      type: blob.type,
                      file: blob,
                    });
                });
              };

              img.src = URL.createObjectURL(target);
            } else setAlert(t('branding:image-ext'), 'error');
          }}
          style={{ display: 'none' }}
        />
      </Button>
    </Layout>
  );
};

export * from './upladerWithCrop';
