import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { ChartSVG } from '../../SVG';
import { formatDate } from '../../../utils';

export const AreaChart = ({
  data,
  dateFormat,
  width,
  height,
  margin,
  xScale,
  enableHorizantalScroll,
  children,
}) => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const xAxis = d3
      .axisBottom(xScale)
      .tickSize(-height)
      .tickSizeOuter(0)
      .tickFormat((d) => {
        return formatDate(new Date(d), dateFormat, true);
      })
      .tickSizeOuter(0);

    svg.select('.x-axis').call(xAxis).select('path').style('stroke-width', 0);
    svg
      .selectAll('text')

      .style('font-size', '0.7rem')
      .style('transform', 'translate(0,5px)');

    d3.selectAll('.tick')
      .selectAll('line')
      .attr('stroke', '#ddd')
      .attr('stroke-width', 0.5)
      .attr('stroke-dasharray', 5);
  }, [data, dateFormat, height, svgRef, xScale]);

  return (
    <Layout enableHorizantalScroll={enableHorizantalScroll}>
      <div className="tooltip"></div>
      <ChartSVG
        ref={svgRef}
        height={height}
        width={width}
        viewBox={`0 0 ${width} ${height}`}
      >
        <g
          transform={`translate(0 ${height - margin.top})`}
          className="x-axis"
        ></g>
        <g
          transform={`translate(${margin.left + margin.right} 0)`}
          className="y-axis"
        ></g>
        <g transform={`translate(${margin.left} -${margin.top})`}>{children}</g>
      </ChartSVG>
    </Layout>
  );
};

const Layout = styled.div`
  flex: 1;
  position: relative;
  overflow-y: visible;

  ${({ enableHorizantalScroll }) =>
    enableHorizantalScroll &&
    css`
      max-width: 600px;
      overflow-x: scroll;
    `};

  .tooltip {
    z-index: 1000000;
    position: absolute;
    background-color: white;
    padding: 2em 4em;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    opacity: 0;

    p.gray {
      color: #333;
      font-weight: 500;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      color: #1c1d21;
    }

    strong {
      color: #000;
    }
  }
`;
