import React from 'react';
import { Helmet } from 'react-helmet';

export const NoIndex = ({ title = 'Bonat' }: { title?: string }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};
