import styled from '@emotion/styled';

export const Layout = styled.div<{
  isCenter: boolean;
  padding?: string;
  imgWidth?: number;
}>`
  flex-direction: column;
  display: flex;
  align-items: ${({ isCenter }) => (isCenter ? 'center' : 'flex-start')};
  justify-content: ceter;
  padding: ${({ padding }) => (padding ? padding : '40px')};
  width: 100% !important;
  margin: auto;
  min-width: inherit;
  text-align: center;

  > div {
    flex-direction: column;
  }

  .title {
    margin-top: 1em;
    font-size: 0.9rem;
    font-weight: 400;
  }
  img {
    width: ${({ imgWidth }) => (imgWidth ? `${imgWidth}px` : '300px')};
  }
`;
