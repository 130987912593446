import { useReducer } from 'react';
import {
  dev,
  sendCodeToMerchantPhoneAPI,
  shared,
  signinAPI,
  signoutAPI,
  signupAPI,
} from '../api';
import { useGlobalState } from '../components/GlobalState';
import { authInitialValue, singReducer } from '../reducers';
import { setLocal } from '../utils';
import { initialUser } from '../reducers';

export const sendCodeToMerchantPhone = async (body: any) => {
  try {
    const {
      data: { data },
    } = await sendCodeToMerchantPhoneAPI(body);
    return data[0];
  } catch (err) {
    return err;
  }
};

export let useSign = (user: any) => {
  const { setAlert } = useGlobalState();
  const [state, dispatch] = useReducer(singReducer, {
    ...authInitialValue,
    user,
  });

  [dev, shared].forEach((api) => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        // Do something with response error
        if (error && error.response && error.response.status === 401) {
          setUser(null);
        }
        return Promise.reject(error);
      }
    );
  });

  const setUser = (data?: any) => {
    dispatch({ type: 'FETCHED', payload: data });
    setLocal({ key: 'ebonat_user', value: JSON.stringify(data) });
  };

  const submitForm = async (body: any, type: 'signin' | 'signup') => {
    const submitAPI = type === 'signin' ? signinAPI : signupAPI;
    try {
      dispatch({ type: 'FETCHING' });
      const response = await submitAPI(body);

      const user = response?.data?.data?.[0] || initialUser;
      setUser(user);
      return user;
    } catch (err) {
      const error = err?.response?.data?.message || err?.data?.message;

      dispatch({
        type: 'ERROR',
        payload: error,
      });

      setAlert(error, 'error');
    }
  };

  return { state, submitForm, setUser };
};

export const signout = async () => {
  try {
    localStorage.clear();
    const res = await signoutAPI();
    return res;
  } catch (err) {
    // alert('Network connection faild, please try again later');
    return err;
  }
};
