import {
  Box,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../../../assets/svg/search.svg';
import { MuiSelect } from '../../../../components/Common';
import { getIcon } from '../../index';
import { ACTIONS } from '../index';
import { ExportCustomersButton } from './exportCustomersButton';
import { Filter } from './filterButton';

export const DesktopHeader = ({
  state,
  dispatch,
  handleClick,
  handleClose,
  segmentType,
  allCustomers,
  anchorEl,
  dataToExport,
  handleExportClick,
}) => {
  const { t } = useTranslation();
  const LIMITOPTIONS = [
    {
      value: 5,
      label: `5 ${t('results')}`,
    },
    {
      value: 10,
      label: `10 ${t('results')}`,
    },
    {
      value: 15,
      label: `15 ${t('results')}`,
    },
    {
      value: 20,
      label: `20 ${t('results')}`,
    },
  ];

  return (
    <Paper>
      <Grid container style={{ padding: 16 }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Box display="flex" alignItems="center">
            {getIcon(segmentType, 45)}
            <Typography variant="h4">
              {t(`segmentation:${segmentType}`)} - {allCustomers}
            </Typography>
          </Box>

          <Divider
            orientation="vertical"
            style={{ height: 20, background: '#eee', margin: '0 16px' }}
          />
          <TextField
            name="search"
            placeholder={t('search')}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.SET_SEARCH,
                payload: e.target.value,
              })
            }
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <Box px={1}>
                  <SearchIcon />
                </Box>
              ),
            }}
          />
          <Divider
            orientation="vertical"
            style={{ height: 20, background: '#eee', margin: '0 16px' }}
          />
          <Filter
            handleClick={handleClick}
            handleClose={handleClose}
            state={state}
            dispatch={dispatch}
            anchorEl={anchorEl}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            className="flex align-center"
            style={{ justifyContent: 'flex-end' }}
            spacing={4}
          >
            <Grid item>
              <ExportCustomersButton
                dataToExport={dataToExport}
                handleExportClick={handleExportClick}
              />
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="flex-end">
                <Typography
                  align="left"
                  style={{
                    marginRight: 5,
                    fontSize: 14,
                    color: '#8181A5',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('show')} :
                </Typography>
                <MuiSelect
                  color="#8181A5"
                  fontSize={14}
                  disableUnderline
                  name="limit"
                  optionLabel="label"
                  optionValue="value"
                  label=""
                  options={LIMITOPTIONS}
                  value={state?.limit?.value}
                  onChange={(e) => {
                    const option = LIMITOPTIONS.filter(
                      (d) => d.value === e.target.value
                    )[0];
                    dispatch({ type: ACTIONS.SET_LIMIT, payload: option });
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
