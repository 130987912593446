import React from 'react';
import { Router } from '@reach/router';
import { NoIndex } from '../../components/Common/NoIndex';
import { Settings } from './settings';
import { Dashboard } from './dashboard';
import { Widget } from './widget';
import { Email } from './email';
import { Guard } from '../../components/Routes/Guard';

export const Reviews = () => {
  return (
    <Guard permission="ReviewsModule">
      <NoIndex />
      <Router>
        <Dashboard path="/dashboard" />
        <Settings path="/settings" />
        <Widget path="/widget" />
        <Email path="/email" />
      </Router>
    </Guard>
  );
};
