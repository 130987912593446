import {
  createMuiTheme,
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import React from 'react';

interface Props {
  children: JSX.Element;
  isRtl: Boolean;
}

declare module '@material-ui/core/styles' {
  interface Theme {
    colors: {
      brand: string;
      brand100: string;
      brandAccent: string;
      brandAccent300: string;
    };
  }

  interface ThemeOptions {
    colors?: {
      brand?: string;
      brand100?: string;
      brandAccent?: string;
      brandAccent300?: string;
    };
  }
}

const themeObject = createTheme({
  colors: {
    brand: 'rgb(255 102 69 / 100%)',
    brand100: 'rgb(255 102 69 / 10%)',
    brandAccent: 'rgb(129 129 165 / 100%)',
    brandAccent300: 'rgb(129 129 165 / 30%)',
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#FF6645',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      contrastText: '#FF6645',

      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  // @ts-ignore
  typography: {
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontSize: 36,
      fontWeight: 700,
    },
    h2: {
      fontSize: 26,
      fontWeight: 500,
    },
    h3: {
      fontSize: 22,
      fontWeight: 500,
    },
    h4: {
      fontSize: 20,
      fontWeight: 500,
    },
    h5: {
      fontSize: 18,
      fontWeight: 500,
    },
    h6: {
      fontSize: 17,
      fontWeight: 400,
    },
  },
});

const ltrTheme = createMuiTheme({ ...themeObject, direction: 'ltr' });
const rtlTheme = createMuiTheme({ ...themeObject, direction: 'rtl' });

export function MUITheme({ children, isRtl }: Props) {
  React.useLayoutEffect(() => {
    document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
  }, [isRtl]);

  return (
    <MuiThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
      {children}
    </MuiThemeProvider>
  );
}
