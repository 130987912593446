export const bp: any = {
  smallPhone: 450,
  phone: 700,
  tablet: 900,
  smalllabtop: 1350,
  labtop: 1600,
  screen: 2500,
  largeScreen: 4000,
};

export type Props =
  | 'smallPhone'
  | 'phone'
  | 'tablet'
  | 'smalllabtop'
  | 'labtop'
  | 'screen'
  | 'largeScreen';

export const mq = (n: Props) => {
  const bpArray = Object.keys(bp).map((key) => [key, bp[key]]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (max-width: ${size}px)`];
    return acc;
  }, []);

  return result;
};
