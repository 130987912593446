import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FriendReferralLogo } from '../../../../assets/svg/friend-referral.svg';
import { useGlobalState } from '../../../../components/GlobalState';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { Toggle } from '../../../../components/Common';
import useSWR, { useSWRConfig } from 'swr';
import { useNavigate } from '@reach/router';
import { usePost } from '../../../../utils';
import { postReferralUpdateAPI } from '../../../../api';

export const Body = () => {
  const { t } = useTranslation();
  const { isEnglish, user } = useGlobalState();
  const { width } = useWindowSize();
  const { data } = useSWR('/referral');
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const [, submitForm] = usePost({
    apiFunction: postReferralUpdateAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const accessDenied = !user?.permissions?.['ReferralModule']?.['Edit'];

  const referral = data?.data;
  const isUpdateReferral =
    referral &&
    (referral.isActive ||
      referral.referredDiscount ||
      referral.referrerDiscount ||
      referral.idRewardTypes == '3' ||
      referral.idCampaign);

  const onToggle = async (isChecked: boolean) => {
    if (!isUpdateReferral) {
      return navigate('/programs/referral/setup');
    }

    await submitForm({
      body: {
        ...referral,
        isActive: isChecked,
      },
    });

    mutate(`/referral`);
  };

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '35px',
        gap: '40px',
        ...(width && width < 890 && { flexWrap: 'wrap' }),
      }}
    >
      <div style={{ ...(width && width > 889 && { flexBasis: '25%' }) }}>
        <p style={{ fontSize: '20px', fontWeight: 500, color: '#13131E' }}>
          {t('referral:referral')}
        </p>
        <p style={{ fontSize: '15px', fontWeight: 400, color: '#13131E' }}>
          {t('referral:referral-detail')}
        </p>
      </div>
      <div style={{ flexGrow: 1 }}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 500,
            color: '#13131E',
            marginBottom: 15,
          }}
        >
          {t('referral:referral-reward')}
        </p>
        <div style={{ position: 'relative' }}>
          <ActivationToggle
            value={referral?.isActive}
            disabled={accessDenied}
            onChange={onToggle}
            style={{
              position: 'absolute',
              top: '5px',
              ...(isEnglish ? { right: '5px' } : { left: '5px' }),
            }}
          />
          <Paper
            onClick={() => navigate('/programs/referral/setup')}
            elevation={0}
            style={{
              padding: '22px 12.5px 12.5px 12.5px',
              display: 'flex',
              gap: '20px',
              borderRadius: '4px',
              border: '1px solid #E8E8E8',
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                width: '60px',
                height: '60px',
                backgroundColor: '#F5F5FA',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FriendReferralLogo />
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  marginBottom: 10,
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: isEnglish ? '91px' : '128px',
                    fontSize: '16px',
                    color: '#1C1D21',
                    fontWeight: '500',
                  }}
                >
                  {t('referral:referrer')}
                </div>
                <div
                  style={{ borderRight: '2px solid #707070', height: '16px' }}
                ></div>
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    marginInlineStart: '24px',
                  }}
                >
                  <span
                    style={{
                      color: '#292222',
                      fontWeight: '500',
                      fontSize: '15px',
                    }}
                  >
                    {referral?.idRewardTypes == '3'
                      ? ''
                      : `${referral?.referrerDiscount ?? ''}${
                          referral?.idRewardTypes == '1'
                            ? user?.currency
                              ? t(user.currency)
                              : ' ' + t('sar')
                            : `${referral ? '%' : '_%'}`
                        }`}
                  </span>
                  <span style={{ fontSize: '15px', color: '#8181A5' }}>
                    {referral?.idRewardTypes == '3'
                      ? t('reward:freeShipping')
                      : t('referral:off-coupon')}
                  </span>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: isEnglish ? '91px' : '128px',
                    fontSize: '16px',
                    color: '#1C1D21',
                    fontWeight: '500',
                  }}
                >
                  {t('referral:referred')}
                </div>
                <div
                  style={{ borderRight: '2px solid #707070', height: '16px' }}
                ></div>
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    marginInlineStart: '24px',
                  }}
                >
                  <span
                    style={{
                      color: '#292222',
                      fontWeight: '500',
                      fontSize: '15px',
                    }}
                  >
                    {referral?.idRewardTypes == '3'
                      ? ''
                      : `${referral?.referredDiscount ?? ''}${
                          referral?.idRewardTypes == '1'
                            ? user?.currency
                              ? t(user.currency)
                              : ' ' + t('sar')
                            : `${referral ? '%' : '_%'}`
                        }`}
                  </span>
                  <span style={{ fontSize: '15px', color: '#8181A5' }}>
                    {referral?.idRewardTypes == '3'
                      ? t('reward:freeShipping')
                      : t('referral:off-coupon')}
                  </span>
                </div>
              </div>
              <div
                style={{
                  fontSize: '15px',
                  color: '#8181A5',
                  marginTop: 25,
                  display: 'flex',
                  gap: '5px',
                  flexWrap: 'wrap',
                }}
              >
                {referral?.idRewardTypes != '3' ? (
                  <>
                    <span>{t('referral:referral-reward-detail1')}</span>
                    <span style={{ color: '#292222', fontWeight: '500' }}>
                      {`${referral?.referrerDiscount ?? ''}${
                        referral?.idRewardTypes == '1'
                          ? user?.currency
                            ? t(user.currency)
                            : ' ' + t('sar')
                          : `${referral ? '%' : '_%'}`
                      }`}
                    </span>
                    <span>{t('referral:referral-reward-detail2')}</span>
                    <span style={{ color: '#292222', fontWeight: '500' }}>
                      {`${referral?.referredDiscount ?? ''}${
                        referral?.idRewardTypes == '1'
                          ? user?.currency
                            ? t(user.currency)
                            : ' ' + t('sar')
                          : `${referral ? '%' : '_%'}`
                      }`}
                    </span>
                    <span>{t('referral:referral-reward-detail3')}</span>
                  </>
                ) : (
                  <>
                    <span>{t('referral:referral-reward-detail1')}</span>
                    <span style={{ color: '#292222', fontWeight: '500' }}>
                      {t('reward:freeShipping')}
                    </span>
                    <span>{t('referral:referral-reward-detail2-2')}</span>
                    <span style={{ color: '#292222', fontWeight: '500' }}>
                      {t('reward:freeShipping')}
                    </span>
                  </>
                )}
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};

const ActivationToggle = ({
  value,
  onChange,
  disabled,
  style = {},
}: {
  value: boolean;
  disabled: boolean;
  onChange: (isActive: boolean) => void;
  style?: React.CSSProperties | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      style={{ borderRadius: 4, minWidth: 140, ...style }}
      bgcolor={value && !disabled ? 'rgba(109, 210,48, 0.2)' : '#F2F2F6'}
      px={1}
      py={0.5}
    >
      <Toggle
        disabled={disabled}
        checked={!!value}
        handleChange={(event: any) => {
          onChange(event.target.checked);
        }}
        label={
          <p style={{ color: value && !disabled ? '#52CF07' : '#8181A5' }}>
            {value ? t('activated') : t('disabled')}
          </p>
        }
      />
    </Box>
  );
};
