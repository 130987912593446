import styled from '@emotion/styled';
import { mainColor, mq } from '../../../utils';

export const Layout = styled.section<{ bColor: string; left: string }>`
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  height: 500px;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 4px 4px 2em #eee;
  background-color: white;
  border-radius: 4px;
  left: ${(props) => props.left};
  bottom: -19px;
  scale: 0.65;
  max-width: 432px;
  min-width: 300px;

  > * {
    padding: 0.75rem;
  }

  p {
    font-size: 0.75rem;
  }

  h1 {
    font-weight: 500;
  }

  button {
    background-color: ${({ bColor }) => (bColor ? bColor : mainColor)};
    border: none;
    color: white;
    width: 180px;
    padding: 12px;
    border-radius: 20px;
  }

  img {
    width: 120px;
    height: 120px;
    margin: 0 0 5px 0;
  }

  header {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    padding: 2.4em 1em;
    position: relative;
  }

  main {
    width: 100%;
    flex: 1;
    padding: 0;
  }

  .plugin_nav {
    background-color: ${({ bColor }) => (bColor ? bColor : mainColor)};

    p {
      color: white;
      padding: 0.75rem;
      font-weight: 500;
    }
  }

  div.body {
    padding: 0 1rem;
    > * {
      margin-top: 0.75rem;
    }

    p {
      font-size: 13px;
    }

    h2 {
      font-weight: 500;
      font-size: 0.9rem;
    }
  }

  footer {
    width: 100%;
    padding: 0.5rem;
    box-shadow: 1px 1px 2em #eee;
    postion: relative;

    .plugin-logo {
      position: absolute;
      top: auto;
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      path {
        fill: ${({ bColor }) => (bColor ? bColor : mainColor)};
      }
      path.font {
        fill: white;
      }
    }

    p {
      font-size: 11px;
    }
  }

  ${mq('phone')} {
    margin-bottom: 5rem;
  }
`;
