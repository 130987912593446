import { Divider } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GeneralSettingsLogo } from '../../../assets/svg/general-settings.svg';
import { ReactComponent as NotificationsOffLogo } from '../../../assets/svg/notifications-off.svg';
import { ReactComponent as IntegrationsLogo } from '../../../assets/svg/integrations.svg';
import { ReactComponent as SecuritySettings } from '../../../assets/svg/security-settings.svg';
import { ReactComponent as SubscriptionLogo } from '../../../assets/svg/subscription.svg';
import { paths } from '../../../utils';
import { NavLink, SystemHeader } from '../../Common';
import { RenderIcon } from '../../SVG';
import { Layout } from './styled';

export const SideNav = () => {
  const { t } = useTranslation();
  return (
    <Layout className="right mr-bottom">
      <SystemHeader
        withStatus={false}
        withBack={false}
        title={t('settings')}
        className="mobile"
      />
      <div className="card" style={{ padding: '0' }}>
        <NavLink to={paths.general}>
          <RenderIcon logo={GeneralSettingsLogo} />
          <div className="space">
            {t('settings:generalInformation')}
            <p className="secondary">{t('settings:generalInformationDesc')}</p>
          </div>
        </NavLink>
        <Divider light variant="middle" />
        <NavLink to={paths.security}>
          <RenderIcon logo={SecuritySettings} />
          <div className="space">
            {t('settings:security')}
            <p className="secondary">{t('settings:changePassword')}</p>
          </div>
        </NavLink>
        <Divider light variant="middle" />

        <NavLink to={paths.subscription}>
          <RenderIcon logo={SubscriptionLogo} />
          <div className="space">
            {t('settings:subscription')}{' '}
            <p className="secondary">
              {t('settings:subscriptionDetailsAndPlan')}
            </p>
          </div>
        </NavLink>
        <Divider light variant="middle" />

        <NavLink to={paths.notifications}>
          <RenderIcon logo={NotificationsOffLogo} />
          <div className="space">
            {t('settings:notifications')}{' '}
            <p className="secondary">{t('settings:notificationsDesc')}</p>
          </div>
        </NavLink>
        <Divider light variant="middle" />

        <NavLink to={paths.integrations}>
          <RenderIcon logo={IntegrationsLogo} />
          <div className="space">
            {t('settings:integration')}{' '}
            <p className="secondary">{t('settings:integrationDesc')}</p>
          </div>
        </NavLink>
      </div>
    </Layout>
  );
};
