import { TableBody } from '@material-ui/core';
import { Link } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, paths, useMemorization } from '../../../utils';
import { PageError, Table } from '../../Common';
import { useGlobalState } from '../../GlobalState';
import { StatusBox, StyledTableCell, StyledTableRow } from './styled';

type TableData = {
  name: string;
  rewardName_en?: string;
  rewardName_ar?: string;
  idCustomer: number;
  activityDate: Date;
  segment: string;
  status: string;
  type: {
    type: string;
    orderId?: number;
    type_ar?: string;
    type_en?: string;
    idCoupon?: string;
    name?: string;
  };
  points: number;
  total: number;
};

export const PointsTable = ({ data, error, isValidating }: any) => {
  const { isEnglish } = useGlobalState();
  const { t } = useTranslation();

  const headerData = useMemorization(
    [
      { label: t('member'), val: 'name' },
      { label: t('date&Time'), val: 'activityDate' },
      { label: t('segment'), val: 'segment' },
      { label: t('status'), val: 'status' },
      { label: t('type'), val: 'type' },
      { label: t('activityPoint'), val: 'points' },
      { label: t('totalPoints'), val: 'total' },
    ],
    [t]
  );

  if (error)
    return (
      <PageError
        buttonText={'home'}
        text={`Opps, ${JSON.stringify(error)} please try again later`}
      />
    );

  return (
    <Table
      loading={isValidating}
      withoutAction={true}
      requestSort={() => {}}
      sortConfig={() => {}}
      headerData={headerData}
      noData={!data}
      noDataMessasge={t('home:noActionHasBeenMade')}
    >
      <TableBody>
        {data?.map((row: TableData, idx: number) => {
          const isRedeemed = row.status === 'redeemed';
          const to = `${paths.segmentation}${paths.customer}?id=${row.idCustomer}&&name=${row.name}`;

          return (
            <StyledTableRow key={idx}>
              <StyledTableCell
                to={to}
                //@ts-ignore
                component={Link}
                state={row}
              >
                <span style={{ color: '#5E81F4' }}>
                  {row.name ?? 'Customer'}
                </span>
                <p>
                  {t('ID')} {row.idCustomer}
                </p>
              </StyledTableCell>

              <StyledTableCell>
                {formatDate(
                  new Date(row.activityDate),
                  'EEEE, dd.MM.yyyy',
                  isEnglish
                )}
                <p>
                  {formatDate(new Date(row.activityDate), 'hh:mm', isEnglish)}
                </p>
              </StyledTableCell>
              <StyledTableCell>{t(row.segment)}</StyledTableCell>
              <StyledTableCell style={{ padding: 0 }}>
                <StatusBox status={row.status}>
                  {!isRedeemed
                    ? t(row.status)
                    : isEnglish
                    ? row.rewardName_en
                    : row.rewardName_ar}
                  {isRedeemed && (
                    <p style={{ fontSize: 11, color: '#666' }}>
                      {t('couponCode')} : {'  '} {row.type.idCoupon}
                    </p>
                  )}
                </StatusBox>
              </StyledTableCell>
              <StyledTableCell>
                {row.type.type_en
                  ? isEnglish
                    ? row.type.type_en
                    : row.type.type_ar
                  : t(`home:${row.type.type}`)}
                {row.type.orderId && (
                  <p>
                    {t('home:orderID')} {row.type.orderId}
                  </p>
                )}
              </StyledTableCell>
              <StyledTableCell>
                {Number(row.points).toLocaleString('en-US')}
              </StyledTableCell>
              <StyledTableCell>
                {Number(row.total).toLocaleString('en-US')}
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
