import { Box, Paper, TableRow } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTableCell, Table as MuiTable } from '../../../Common';
import { useGlobalState } from '../../../GlobalState';

export const CustomerActivityTable = ({
  items = [],
  requestSort,
  sortConfig,
  headerData,
  page,
  handlePageChange,
  totalPages,
  loading,
  error,
  errroMessage,
}) => {
  const rowCount = items.length;
  const { isEnglish } = useGlobalState();
  const { t } = useTranslation();

  items = items.map((item) => ({
    ...item,
    earnedPoints: Number(item.earnedPoints).toLocaleString('en-US'),
  }));

  const showValue = (header, data) => {
    switch (header) {
      case 'reward':
        return isEnglish ? data.description_en : data.description_ar;
      case 'earnMethod':
        return isEnglish ? data.earnMethod_en : data.earnMethod_ar;
      case 'status':
        return isEnglish ? data.status_en : data.status_ar;
      case 'usedDate':
        if (data[header] === 'Expired') return t('expired');
        else return data[header];

      default:
        return data[header];
    }
  };

  return (
    <>
      <Paper>
        <MuiTable
          requestSort={requestSort}
          sortConfig={sortConfig}
          headerData={headerData}
          noData={items.length < 1}
          noDataMessasge="No branches"
          handleSelectAllClick={() => {}}
          numSelected={0}
          rowCount={rowCount}
          loading={loading}
          error={error}
          headPadding="16px 0px !important"
          errroMessage={errroMessage}
          withoutAction={true}
        >
          {items.map((row, idx) => {
            const padding = '16px 0px !important';

            return (
              <TableRow key={idx} style={{ cursor: 'default' }}>
                {headerData.map((data) => {
                  return (
                    <StyledTableCell
                      style={{
                        ...(data.dataMap === 'usedDate' &&
                          row.usedDate === 'Expired' && { color: 'red' }),
                      }}
                      key={data.val}
                      noWrap
                      padding={padding}
                    >
                      {showValue(data.dataMap, row)}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </MuiTable>
      </Paper>
      <Paper>
        <Box
          my={2}
          display="flex"
          justifyContent="center"
          width={'100%'}
          style={{ padding: 16 }}
        >
          <Pagination
            color="primary"
            count={totalPages}
            variant="outlined"
            shape="rounded"
            size="large"
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Paper>
    </>
  );
};
