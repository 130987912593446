import { Dialog, IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Common';
import CloseIcon from '@material-ui/icons/Close';
import freeProductImgScr from '../../../assets/svg/free-product.svg';
import percentageImgScr from '../../../assets/svg/percentage.svg';
import amountImgScr from '../../../assets/svg/amount.svg';
import shippingImgScr from '../../../assets/svg/shipping.svg';
import { useGlobalState } from '../../../components/GlobalState';

export const AddReward = ({
  onClick,
  addRewardOpen,
  onClose,
  onCreate,
}: {
  addRewardOpen: boolean;
  onClick: (() => void) | (() => Promise<void>);
  onClose: (() => void) | (() => Promise<void>);
  onCreate: ((id: number) => void) | ((id: number) => Promise<void>);
}) => {
  const { t } = useTranslation();
  const { isEnglish } = useGlobalState();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: '#13131E',
            marginBottom: '12px',
          }}
        >
          {t('reward:redeemPoints')}
        </p>
        <p
          style={{
            fontSize: 15,
            fontWeight: 400,
            color: '#13131E',
          }}
        >
          {t('reward:redeemedDesc')}
        </p>
      </div>
      <div>
        <Button
          handleClick={onClick}
          bColor="primary"
          color="white"
          style={{ padding: '10px 30px' }}
          fullwidth={0}
        >
          {t('reward:addReward')}
        </Button>
      </div>
      <Dialog open={addRewardOpen} onClose={onClose}>
        <div
          style={{
            ...(isEnglish ? { marginLeft: 'auto' } : { marginRight: 'auto' }),
            padding: '4px 15px',
          }}
        >
          <IconButton onClick={onClose} aria-label="delete" size="small">
            <CloseIcon fontSize="inherit" htmlColor="#292222" />
          </IconButton>
        </div>

        <p
          style={{
            fontSize: '20px',
            color: '#292222',
            fontWeight: 500,
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          {t('reward:selectRewardYouWouldLikeToAdd')}
        </p>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            padding: '20px',
            gap: '30px',
            justifyItems: 'center',
            margin: '0 auto 25px auto',
            width: 'fit-content',
          }}
        >
          <div
            onClick={() => onCreate(1)}
            style={{
              width: '200px',
              height: '147px',
              backgroundColor: 'rgba(107, 107, 107, 0.1)',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '15px',
              padding: '10px',
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            <img style={{ width: '50px', height: '50px' }} src={amountImgScr} />
            <p style={{ fontSize: '18px', fontWeight: 500, color: '#292222' }}>
              {t('reward:fixedAmountDiscount')}
            </p>
          </div>
          <div
            onClick={() => onCreate(2)}
            style={{
              width: '200px',
              height: '147px',
              backgroundColor: 'rgba(107, 107, 107, 0.1)',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '15px',
              padding: '10px',
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            <img
              style={{ width: '50px', height: '50px' }}
              src={percentageImgScr}
            />
            <p style={{ fontSize: '18px', fontWeight: 500, color: '#292222' }}>
              {t('reward:percentageDiscount')}
            </p>
          </div>
          <div
            onClick={() => onCreate(3)}
            style={{
              width: '200px',
              height: '147px',
              backgroundColor: 'rgba(107, 107, 107, 0.1)',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '15px',
              padding: '10px',
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            <img
              style={{ width: '50px', height: '50px' }}
              src={shippingImgScr}
            />
            <p style={{ fontSize: '18px', fontWeight: 500, color: '#292222' }}>
              {t('reward:freeShipping')}
            </p>
          </div>
          <div
            onClick={() => onCreate(4)}
            style={{
              width: '200px',
              height: '147px',
              backgroundColor: 'rgba(107, 107, 107, 0.1)',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '15px',
              padding: '10px',
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            <img
              style={{ width: '50px', height: '50px' }}
              src={freeProductImgScr}
            />
            <p style={{ fontSize: '18px', fontWeight: 500, color: '#292222' }}>
              {t('reward:freeProduct')}
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
