import {
  Button as CoreButton,
  Dialog,
  IconButton,
  Paper,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import { getSenderNameAPI, putSMSPlatformAPI } from '../../api';
import msegatImage from '../../assets/img/integration-msegat.png';
import unifonicImage from '../../assets/img/integration-unifonic.png';
import whatsappImage from '../../assets/img/integration-whatsapp.png';
import { ReactComponent as IntegrationLinkIcon } from '../../assets/svg/integration-link.svg';
import { ReactComponent as CorrectIcon } from '../../assets/svg/correct.svg';
import { ReactComponent as WarningIcon } from '../../assets/svg/warning-icon.svg';
import { useGlobalState } from '../../components/GlobalState';

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const Integrations = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [connectingError, setConnectingError] = useState(false);
  const { data } = useSWR('/settings/sms/platforms');
  const { user } = useGlobalState();
  const [msegatSuccess, setMsegatSuccess] = useState(false);
  const [isMsegatConnectLoading, setIsMsegatConnectLoading] = useState(false);
  const { mutate } = useSWRConfig();
  const [senderNames, setSenderNames] = useState<
    { SenderID: string; Status: string }[]
  >([]);
  const [senderNameModal, setSenderNameModal] = useState(false);
  const [sender, setSender] = useState('');

  const msegatData = useMemo(
    () => data?.data?.find((platform: any) => platform.Name === 'msegat'),
    [data]
  );

  useEffect(() => {
    setUserName(msegatData?.username);
    setApiKey(msegatData?.apiKey);
    setSender(msegatData?.senderName);
  }, [msegatData]);

  const onClose = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setSenderNameModal(false);
      setMsegatSuccess(false);
      setIsMsegatConnectLoading(false);
      setConnectingError(false);
    }, 500);
  };

  const connectMsegat = async () => {
    try {
      setConnectingError(false);
      setIsMsegatConnectLoading(true);
      await putSMSPlatformAPI({
        merchantId: user?.idMerchant,
        username: userName,
        apiKey,
        idSMSPlatform: 1,
      });
      await delay(2000);
      const { data } = await getSenderNameAPI();

      setSenderNames(data?.data);
      setSenderNameModal(true);
      mutate('/settings/sms/platforms');
    } catch {
      setConnectingError(true);
    } finally {
      setIsMsegatConnectLoading(false);
    }
  };

  const setSenderName = async () => {
    try {
      setConnectingError(false);
      setIsMsegatConnectLoading(true);
      await putSMSPlatformAPI({
        merchantId: user?.idMerchant,
        senderName: sender,
        idSMSPlatform: 1,
      });
      setMsegatSuccess(true);
      mutate('/settings/sms/platforms');

      setTimeout(() => {
        onClose();
      }, 4000);
    } catch {
      setConnectingError(true);
    } finally {
      setIsMsegatConnectLoading(false);
    }
  };

  return (
    <Paper
      elevation={0}
      style={{
        padding: '30px 25px',
        borderRadius: '4px',
        border: '1px solid #E8E8E8',
      }}
    >
      <Dialog open={isModalOpen} onClose={onClose}>
        {msegatSuccess ? (
          <div
            style={{
              height: 400,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <CorrectIcon />
            <p
              style={{
                fontSize: '25px',
                color: '#1A2D4C',
                fontWeight: 600,
                marginTop: '43px',
              }}
            >
              {t('settings:connection-success')}
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#8181A5',
                fontWeight: 400,
                marginTop: '8px',
              }}
            >
              {t('settings:connection-success-promo')}
            </p>
          </div>
        ) : senderNameModal ? (
          <>
            <div
              style={{
                height: '73px',
                backgroundColor: '#FF6645',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 25px',
              }}
            >
              <p style={{ color: 'white', fontWeight: 500, fontSize: '18px' }}>
                {t('settings:connect-msegat')}
              </p>
              <IconButton
                style={{
                  borderRadius: 8,
                  padding: 8,
                  border: '1px solid',
                  borderColor: '#fff',
                }}
                onClick={onClose}
                size="small"
              >
                <CloseIcon style={{ width: 17, height: 17 }} htmlColor="#fff" />
              </IconButton>
            </div>
            <p
              style={{
                backgroundColor: '#F2F2F2',
                fontSize: '15px',
                fontWeight: 500,
                textTransform: 'uppercase',
                padding: '14px 25px',
              }}
            >
              <span>{t('settings:msegat-sender') + ' '}</span>
              <span
                onClick={() => {
                  const url = 'https://msegat.com/index.php?action=46';
                  window.open(url, '_blank');
                }}
                style={{
                  color: '#5E81F4',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                {t('settings:msegat-sender-create')}
              </span>
            </p>
            <p
              style={{
                marginTop: '23px',
                marginBottom: '30px',
                color: '#1A2D4C',
                fontSize: '15px',
                fontWeight: 500,
                padding: '0px 25px',
              }}
            >
              {t('settings:sender-name-place')}
            </p>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '22px',
                padding: '0px 25px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p
                  style={{
                    fontSize: '17px',
                    width: '170px',
                    fontWeight: 500,
                    color: '#1A2D4C',
                  }}
                >
                  {t('settings:sender-name')}
                </p>
                <div style={{ width: '100%' }}>
                  <Select
                    placeholder={t('settings:sender-name-place')}
                    value={sender}
                    onChange={(e) => setSender(e?.target?.value as string)}
                    style={{
                      height: '50px',
                      backgroundColor: '#F9F9FF',
                      border: '1px solid #F1F1F5',
                      borderRadius: '10px',
                      fontSize: '15px',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                        direction: 'ltr',
                        fontSize: '15px',
                        color: '#292222',
                        fontWeight: 500,
                      },
                    }}
                    fullWidth
                    disableUnderline
                  >
                    {senderNames.map((sender) => (
                      <MenuItem value={sender.SenderID}>
                        {sender.SenderID}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '28px',
                marginBottom: '15px',
                marginTop: '39px',
                padding: '0px 25px',
              }}
            >
              {connectingError && (
                <div style={{ display: 'flex', alignItems: 'center', gap: 9 }}>
                  <WarningIcon />
                  <p
                    style={{
                      fontSize: '15px',
                      fontWeight: 500,
                      color: '#FF6645',
                    }}
                  >
                    {t('settings:connection-failed')}
                  </p>
                </div>
              )}
              <CoreButton
                style={{ fontSize: '16px', padding: '12px 50px' }}
                disabled={isMsegatConnectLoading || !sender}
                size="small"
                onClick={setSenderName}
                variant="contained"
                color="primary"
              >
                {isMsegatConnectLoading
                  ? t('settings:connecting')
                  : t('settings:connect')}
              </CoreButton>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                height: '73px',
                backgroundColor: '#FF6645',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 25px',
              }}
            >
              <p style={{ color: 'white', fontWeight: 500, fontSize: '18px' }}>
                {t('settings:connect-msegat')}
              </p>
              <IconButton
                style={{
                  borderRadius: 8,
                  padding: 8,
                  border: '1px solid',
                  borderColor: '#fff',
                }}
                onClick={onClose}
                size="small"
              >
                <CloseIcon style={{ width: 17, height: 17 }} htmlColor="#fff" />
              </IconButton>
            </div>
            <p
              style={{
                backgroundColor: '#F2F2F2',
                fontSize: '15px',
                fontWeight: 500,
                textTransform: 'uppercase',
                padding: '14px 25px',
              }}
            >
              <span>{t('settings:msegat-account') + ' '}</span>
              <span
                onClick={() => {
                  const url = 'https://msegat.com/index.php?source=bonat';
                  window.open(url, '_blank');
                }}
                style={{
                  color: '#5E81F4',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                {t('settings:msegat-account-create')}
              </span>
            </p>
            <p
              style={{
                marginTop: '23px',
                marginBottom: '30px',
                color: '#1A2D4C',
                fontSize: '15px',
                fontWeight: 500,
                padding: '0px 25px',
              }}
            >
              {t('settings:required-data')}
            </p>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '22px',
                padding: '0px 25px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p
                  style={{
                    fontSize: '17px',
                    width: '170px',
                    fontWeight: 500,
                    color: '#1A2D4C',
                  }}
                >
                  {t('settings:username')}
                </p>
                <div style={{ width: '100%' }}>
                  <TextField
                    placeholder={t('settings:username-placeholder')}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    style={{
                      height: '50px',
                      backgroundColor: '#F9F9FF',
                      border: '1px solid #F1F1F5',
                      borderRadius: '10px',
                      fontSize: '15px',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    inputProps={{
                      style: { textAlign: 'center', direction: 'ltr' },
                    }}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: '15px',
                        color: '#292222',
                        fontWeight: 500,
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p
                  style={{
                    fontSize: '17px',
                    width: '170px',
                    fontWeight: 500,
                    color: '#1A2D4C',
                  }}
                >
                  {t('settings:api-key')}
                </p>
                <div style={{ width: '100%' }}>
                  <TextField
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    placeholder={t('settings:api-key-placeholder')}
                    style={{
                      height: '50px',
                      backgroundColor: '#F9F9FF',
                      border: '1px solid #F1F1F5',
                      borderRadius: '10px',
                      fontSize: '15px',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    fullWidth
                    inputProps={{
                      style: { textAlign: 'center', direction: 'ltr' },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: '15px',
                        color: '#292222',
                        fontWeight: 500,
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '28px',
                marginBottom: '15px',
                marginTop: '39px',
                padding: '0px 25px',
              }}
            >
              {connectingError && (
                <div style={{ display: 'flex', alignItems: 'center', gap: 9 }}>
                  <WarningIcon />
                  <p
                    style={{
                      fontSize: '15px',
                      fontWeight: 500,
                      color: '#FF6645',
                    }}
                  >
                    {t('settings:connection-failed')}
                  </p>
                </div>
              )}
              <CoreButton
                style={{ fontSize: '16px', padding: '12px 50px' }}
                disabled={isMsegatConnectLoading}
                size="small"
                onClick={connectMsegat}
                variant="contained"
                color="primary"
              >
                {isMsegatConnectLoading
                  ? t('settings:connecting')
                  : t('settings:connect')}
              </CoreButton>
            </div>
          </>
        )}
      </Dialog>

      <p
        style={{
          fontSize: '17px',
          fontWeight: 600,
          color: '#292222',
        }}
      >
        {t('settings:integrations-services')}
      </p>
      <p
        style={{
          fontSize: '20px',
          fontWeight: 600,
          color: '#292222',
          marginTop: 18,
        }}
      >
        {t('settings:sms')}
      </p>
      <p
        style={{
          fontSize: '14px',
          fontWeight: 400,
          color: '#8181A5',
        }}
      >
        {t('settings:sms-desc')}
      </p>

      <div style={{ display: 'flex', marginTop: 20, gap: 13 }}>
        <IntegrationCard
          onClick={() => setIsModalOpen(true)}
          imagePath={msegatImage}
          isConnected={!!msegatData?.isConnected}
        />
        {/* <IntegrationCard imagePath={unifonicImage} isConnected={false} /> */}
      </div>

      {/* <p
        style={{
          fontSize: '20px',
          fontWeight: 600,
          color: '#292222',
          marginTop: 18,
        }}
      >
        {t('settings:whatsapp')}
      </p>
      <p
        style={{
          fontSize: '14px',
          fontWeight: 400,
          color: '#8181A5',
        }}
      >
        {t('settings:whatsapp-desc')}
      </p>
      <IntegrationCard
        imagePath={whatsappImage}
        isConnected={false}
        style={{ marginTop: 20 }}
      /> */}
    </Paper>
  );
};

type IIntegrationCard = {
  isConnected: boolean;
  imagePath: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
};

const IntegrationCard = ({
  imagePath,
  isConnected,
  onClick,
  style,
}: IIntegrationCard) => {
  const { t } = useTranslation();
  const { isEnglish } = useGlobalState();

  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: '#FFFFFF',
        border: '1px solid #E7E7E8',
        borderRadius: '4px',
        width: '277px',
        height: '144px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        ...style,
      }}
    >
      {!!isConnected && (
        <div
          style={{
            position: 'absolute',
            top: 10,
            ...(isEnglish ? { right: 10 } : { left: 10 }),
            color: 'white',
            backgroundColor: '#84C731',
            borderRadius: '12px',
            fontSize: 11,
            padding: '2px 10px',
          }}
        >
          {t('settings:connected')}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <img src={imagePath} />
      </div>

      <div
        style={{
          height: 29,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '14px',
          borderTop: '1px solid #E7E7E8',
        }}
      >
        <IntegrationLinkIcon />
        <p style={{ color: '#606084', fontSize: '15px' }}>
          {isConnected
            ? t('settings:edit-connection')
            : t('settings:connect-service')}
        </p>
      </div>
    </div>
  );
};
