import { Redirect, RouteComponentProps, useLocation } from '@reach/router';
import React, { FC, FunctionComponent } from 'react';
import { useGlobalState } from '../../GlobalState';

interface Props {
  component: FunctionComponent;
}

export const RequireMobileVerification: FC<Props & RouteComponentProps> = ({
  component: Component,
  ...props
}) => {
  const { user } = useGlobalState();
  const location = useLocation();

  if (!user) {
    return <Redirect to="/signin" from={location.pathname} noThrow />;
  }

  if (user && user.verified && user.completed) {
    return <Redirect to="/" from={location.pathname} noThrow />;
  }

  if (user && !user.verified && !user.completed) {
    return (
      <Redirect to="/mobile-verification" from={location.pathname} noThrow />
    );
  }

  return <Component {...props} />;
};
