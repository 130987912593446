import { Paper } from '@material-ui/core';
import React from 'react';
import { Body } from './body';
import { Header } from './header';

export const Main = () => {
  return (
    <div>
      <Header />
      <Body />
    </div>
  );
};
