import React from 'react';
import { useTranslation } from 'react-i18next';

export default function GrowthPlan({ style = {} }) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        color: 'white',
        backgroundColor: '#FF6645',
        borderRadius: '3px',
        padding: '1px 3px',
        fontSize: '8px',
        margin: '3px 2px',
        width: 'max-content',
        ...style,
      }}
    >
      {t('growth-plan')}
    </div>
  );
}
