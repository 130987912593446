import styled from '@emotion/styled';

import { mq } from '../../../utils';

export interface Props {
  bColor: string;
  dir: 'left' | 'right';
}

export const SVG = styled.svg<Props>`
  position: fixed;
  left: ${({ dir }) => (dir === 'right' ? '95%' : '0')};
  top: 50%;
  transform: translateY(-50%);
  fill: ${({ bColor }: Props) => bColor} !important;

  path {
    fill: ${({ bColor }: Props) => bColor} !important;
  }

  ${mq('tablet')} {
    display: none;
  }
`;
