import { Box, Typography } from '@material-ui/core';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dev } from '../../../api';
import { useGlobalState } from '../../GlobalState';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';

interface Props {
  productValue: { name: string; id: string };
  loading: boolean;
  disabled: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const SelectSpecificProducs: FC<Props> = memo(
  ({ productValue, loading, setFieldValue, disabled }) => {
    const { user, setAlert } = useGlobalState();
    const { t } = useTranslation();
    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [fetchMoreProductsLoading, setFetchMoreProductsLoading] =
      useState(false);
    const [page, setPage] = useState(1);
    const [noMore, setNoMore] = useState(false);
    const searchOptions = useRef(
      debounce((keyword: string, callback) => {
        dev
          .get(
            `/reward/merchantProducts/${user?.idMerchant}/ar?keyword=${keyword}`
          )
          .then(({ data }) =>
            callback(
              data.data.map((product: any) => ({
                label: product.name,
                value: product.id,
              }))
            )
          );
      }, 300)
    );

    const fetchMoreProducts = async () => {
      if (!!noMore) return;
      try {
        setFetchMoreProductsLoading(true);
        const { data } = await dev.get(
          `/reward/merchantProducts/${user?.idMerchant}/ar?page=${page}`
        );

        if (data.data.length === 0) {
          setNoMore(true);
        }

        setProductsOptions((prevProducts) => [
          ...prevProducts,
          ...(data?.data ?? []),
        ]);
        setPage((prevPage) => prevPage + 1);

        setFetchMoreProductsLoading(false);
      } catch (err) {
        const error =
          err?.response?.data?.message || err.message || err?.data?.message;
        setAlert(error, 'error');
      }
    };

    useEffect(() => {
      fetchMoreProducts();
    }, []);

    return (
      <AsyncSelect
        name="products"
        menuPlacement="bottom"
        isDisabled={disabled}
        cacheOptions={false}
        isLoading={fetchMoreProductsLoading}
        loadOptions={searchOptions.current}
        styles={{
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
          }),
          option: (base, state) => ({
            ...base,
            color: '#111111',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#FF6645',
            primary75: '#FF856A',
            primary50: '#FFA490',
            primary25: '#FFC3B5',
            neutral50: '#FF6645',
          },
        })}
        closeMenuOnSelect={true}
        onChange={(product) =>
          setFieldValue('free_product', {
            id: product?.value,
            name: product?.label,
          })
        }
        value={{
          value: productValue.id,
          label: productValue.name,
        }}
        loadingMessage={() => t('loadingMore')}
        isOptionDisabled={(option) => option.value === 'loading'}
        defaultOptions={[
          ...productsOptions.map((product) => ({
            value: product.id,
            label: product.name,
          })),
          ...(fetchMoreProductsLoading
            ? [
                {
                  value: 'loading',
                  label: t('loadingMore'),
                },
              ]
            : []),
        ]}
        onMenuScrollToBottom={fetchMoreProducts}
        placeholder={t('reward:product-name')}
        noOptionsMessage={() => t('reward:no-options')}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    );
  }
);
