import styled from '@emotion/styled';
import { useTheme } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { formatDate } from '../../../../utils';
import { BoxLoader } from '../../../Common';
import { AreaChartV2 } from '../../../Common/AreaChartV2';
import { Tooltip } from '../../../Common/ChartsTooltip';
import { useGlobalState } from '../../../GlobalState';

const UsedCoupons = () => {
  const { isEnglish } = useGlobalState();
  const { t } = useTranslation();
  const theme = useTheme();
  const { data, error }: any = useSWR('/merchant/couponChart');

  const fetchedData = data?.data ?? [];

  const yAxis = useMemo(
    () => [
      {
        fill: theme.colors.brand100,
        stroke: theme.colors.brand,
        key: 'uesdCoupons',
      },
    ],
    [theme]
  );

  const customTooltip = useCallback(
    ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Tooltip>
            <p className="gray">{label}</p>
            <p style={{ color: payload[0].color }}>
              {t('home:usedCoupons')} <strong>{payload[0].value}</strong>
            </p>
          </Tooltip>
        );
      }

      return null;
    },
    [t]
  );

  const formatedData = useMemo(
    () =>
      fetchedData?.map?.((record: any) => ({
        ...record,
        date: formatDate(new Date(record.date), 'dd MMMM', isEnglish),
      })) ?? [],
    [fetchedData, isEnglish]
  );

  if (error || !Array.isArray(fetchedData))
    return (
      <h3 className="title">
        Opps, {JSON.stringify(error) || fetchedData} please try again later
      </h3>
    );

  return (
    <Layout className="chart c-chart card">
      <div className="chart-header">
        <h3>{t('home:usedCoupons')}</h3>
      </div>
      {Array.isArray(fetchedData) && fetchedData?.length > 0 ? (
        <AreaChartV2
          xAxis="date"
          yAxis={yAxis}
          data={formatedData}
          toolTipContent={customTooltip}
        />
      ) : (
        <BoxLoader width={500} height={280} />
      )}
    </Layout>
  );
};

export default React.memo(UsedCoupons);

const Layout = styled.div`
  h3 {
    font-weight: 500;
    font-size: 1rem;
  }
`;
