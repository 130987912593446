import { dev } from './index';
import { prepareCheckout } from './shared';

export const putNotificationSettingsAPI = async ({
  body: { merchantId, ...rest },
}: any) => await dev.patch(`/settings/notifications/${merchantId}`, rest);

export const putDeactivateAcountAPI = async ({
  merchantId,
}: {
  merchantId: number;
}) => await dev.patch(`/settings/deactive-account/${merchantId}`);

export const subscription = async (body: any) =>
  await prepareCheckout({ body });

export const putSMSPlatformAPI = async ({ merchantId, ...rest }: any) =>
  await dev.patch(`/settings/sms/platforms/${merchantId}`, rest);

export const getSenderNameAPI = async () =>
  await dev.get(`/settings/sms/senderNames`);
