import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Logo from '../../assets/img/bonat-logo.png';
import Done from '../../assets/img/done.png';
import SetupLeft from '../../assets/img/setup-left.png';
import { ReactComponent as SAFlagIcon } from '../../assets/svg/SAFlag.svg';
import { ReactComponent as USFlagIcon } from '../../assets/svg/USFlag.svg';
import { Curve } from '../Common';
import { useGlobalState } from '../GlobalState';
import {
  Container,
  Content,
  Header,
  Layout,
  Left,
  Right,
  useStyles,
} from './styled';

interface Props {
  firstTitle: string;
  secondTitle?: string;
  subTitle?: string;
  hint?: string;
  children: JSX.Element;
  progress?: number;
  isTheme?: boolean;
  values?: {
    businessName: string;
    bColor: string;
    logo: string;
    coverImage: string;
  };
  isDone?: boolean;
}

export const SetupLayout = ({
  firstTitle,
  secondTitle,
  subTitle,
  children,
  hint,
  progress,
  isTheme,
  values = {
    businessName: 'Bonat Store',
    bColor: '',
    logo: '',
    coverImage: '',
  },
  isDone,
}: Props) => {
  const classes = useStyles();
  const { isRtl, isEnglish, setLanguage } = useGlobalState();

  return (
    <Container>
      <Header isRtl={isRtl}>
        <img src={Logo} alt="Bonat" />
      </Header>
      <Layout>
        <Left isDone={isDone} isRtl={isRtl}>
          <Box component="div">
            <Content>
              <section>
                <Typography
                  variant="h4"
                  component="h1"
                  className={`${classes.title}  title`}
                >
                  {firstTitle}
                </Typography>
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  className={classes.secondTitle}
                >
                  {secondTitle}
                </Typography>

                <Typography
                  variant="subtitle1"
                  gutterBottom
                  className={classes.subTitle}
                >
                  {subTitle}
                </Typography>
                {hint && <p dangerouslySetInnerHTML={{ __html: hint }} />}
              </section>
            </Content>
            {children}
          </Box>
        </Left>
        <Right isTheme={isTheme}>
          <Box
            position="absolute"
            top="10%"
            left="70%"
            border="1px solid #E7E7E8"
            bgcolor="white"
            borderRadius={24}
            px={2}
            py={0.1}
            style={{ cursor: 'pointer' }}
            display="flex"
            justifyContent="sapce-between"
            alignItems="center"
            zIndex={100000}
            onClick={() => {
              setLanguage(isEnglish ? 'ar' : 'en');
            }}
          >
            <Box pr={1.5}>{isEnglish ? <SAFlagIcon /> : <USFlagIcon />}</Box>
            <p style={{ fontWeight: 500, fontSize: 14, cursor: 'pointer' }}>
              {isEnglish ? 'AR' : 'EN'}
            </p>
          </Box>

          <Curve bColor="#FFE2DC" dir={isRtl ? 'left' : 'right'} />

          <img src={isDone ? Done : SetupLeft} alt="" />
        </Right>
      </Layout>
    </Container>
  );
};

export * from './step_1';
export * from './step_2';

export type StepProps = {
  step: number;
  setUser: (body: any) => void;
};

export const industryOptions = [
  { name: 'electronics', id: 1 },
  { name: 'education', id: 2 },
];

export const avgCheckOptions = [
  { name: '500 SR - 1000 SR', id: 1 },
  { name: '1000 SR - 10000 SR', id: 2 },
  { name: 'More than 10000', id: 3 },
];

export const customerPurchaseOptions = [
  { name: 'weekly', id: 1 },
  { name: 'monthly', id: 2 },
  { name: 'annually', id: 3 },
];
