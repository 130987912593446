import { Paper } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SocialIcon } from '../../../../assets/svg/bullhorn-solid.svg';
import { useNavigate } from '@reach/router';

export const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Paper
      elevation={0}
      style={{
        padding: 23,
        height: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '4px',
        border: '1px solid #E8E8E8',
      }}
    >
      <p
        style={{
          fontSize: 20,
          fontWeight: 500,
          color: '#292222',
        }}
      >
        {t('referral:referral-system')}
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '30px',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
          }}
          onClick={() => navigate('/programs/referral/social')}
        >
          <SocialIcon style={{ width: '22px', height: '22px', color: 'red' }} />
          <span style={{ fontSize: '16px', fontWeight: 500, color: '#292222' }}>
            {t('referral:social-sharing')}
          </span>
        </div>
      </div>
    </Paper>
  );
};
