import { User } from '../reducers';
import { useGlobalState } from '../components/GlobalState';

export const useCanView = () => {
  const { user } = useGlobalState();

  const canView = (permission: keyof User['permissions']) =>
    permission === 'HomeModule'
      ? user?.permissions[permission]?.view
      : user?.permissions[permission]?.View;

  return canView;
};
