import { Box, Paper, TableRow } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomerTableHyperLink,
  StyledTableCell,
  Table as MuiTable,
} from '../../../components/Common';
import { formatDate, paths } from '../../../utils';
import { useGlobalState } from '../../GlobalState';

export const CustomersTable = ({
  items = [],
  requestSort,
  sortConfig,
  headerData,
  page,
  handlePageChange,
  totalPages,
  loading,
  error,
  errroMessage,
  isBirthdaySegment,
}) => {
  const rowCount = items.length;
  const { t } = useTranslation();
  const { isEnglish } = useGlobalState();

  return (
    <>
      <Paper>
        <MuiTable
          requestSort={requestSort}
          sortConfig={sortConfig}
          headerData={headerData}
          noData={items.length < 1}
          noDataMessasge="No branches"
          handleSelectAllClick={() => {}}
          numSelected={0}
          rowCount={rowCount}
          loading={loading}
          error={error}
          headPadding="16px 0px !important"
          errroMessage={errroMessage}
          withoutAction={true}
        >
          {items.map((row, idx) => {
            const padding = '16px 16px !important';
            const to = `${paths.segmentation}${paths.customer}?id=${row.idCustomer}&&name=${row.name}`;
            const state = { id: row.idCustomer, customer: row };

            return (
              <TableRow key={idx} style={{ cursor: 'default' }}>
                <StyledTableCell noWrap padding={padding}>
                  <RenderImage person={row} />
                </StyledTableCell>

                <StyledTableCell
                  noWrap
                  padding={padding}
                  to={to}
                  component={Link}
                  state={state}
                >
                  <span style={{ color: '#5E81F4' }}>{row?.phoneNumber}</span>
                </StyledTableCell>
                <StyledTableCell
                  noWrap
                  padding={padding}
                  state={state}
                  to={to}
                  component={Link}
                >
                  {row?.email}
                </StyledTableCell>
                <StyledTableCell
                  noWrap
                  padding={padding}
                  state={state}
                  to={to}
                  component={Link}
                >
                  {t(row?.segment)}
                </StyledTableCell>

                <StyledTableCell
                  noWrap
                  padding={padding}
                  state={state}
                  to={to}
                  component={Link}
                >
                  {Number(row?.ordersCount).toLocaleString('en-US')}
                </StyledTableCell>
                <StyledTableCell
                  noWrap
                  padding={padding}
                  state={state}
                  to={to}
                  component={Link}
                >
                  {Number(row?.pointsCount).toLocaleString('en-US')}
                </StyledTableCell>

                <StyledTableCell
                  noWrap
                  padding={padding}
                  state={state}
                  to={to}
                  component={Link}
                >
                  {!!row?.lastOrderDate
                    ? formatDate(
                        new Date(row?.lastOrderDate),
                        'dd.MM.yyyy',
                        isEnglish
                      )
                    : '-'}
                </StyledTableCell>
                {isBirthdaySegment && (
                  <StyledTableCell
                    noWrap
                    padding={padding}
                    state={state}
                    to={to}
                    component={Link}
                  >
                    {formatDate(
                      new Date(row?.birthday ?? null),
                      'dd.MM.yyyy',
                      isEnglish
                    )}
                  </StyledTableCell>
                )}
              </TableRow>
            );
          })}
        </MuiTable>
      </Paper>
      <Paper>
        <Box
          my={2}
          display="flex"
          justifyContent="center"
          width={'100%'}
          style={{ padding: 16 }}
        >
          <Pagination
            color="primary"
            count={totalPages}
            variant="outlined"
            shape="rounded"
            size="large"
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Paper>
    </>
  );
};

const RenderImage = ({ person }) => {
  return (
    <Box display="flex" justifyContent="start" alignContent="center">
      <CustomerTableHyperLink
        customerId={person.idCustomer}
        customerName={person['name'] ?? ''}
        hasVisit={true}
        customer={person}
        blocked={person.blocked}
      />
    </Box>
  );
};
