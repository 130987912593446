import { FormControl, MenuItem, Paper, Select } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useGlobalState } from '../../components/GlobalState';
import { usePost } from '../../utils';
import * as yup from 'yup';
import { patchReviewsSettingsAPI } from '../../api/reviews';
import { IOSSwitch } from '../../components/Common/Toggle/styled';
import { Button, TextInput } from '../../components/Common';

const autoPublishTypes = [
  {
    name: 'all-Reviews',
    value: 'all',
  },
  {
    name: '4-Stars-and-above',
    value: '4-Stars-and-above',
  },
  {
    name: '3-Stars-and-above',
    value: '3-Stars-and-above',
  },
  {
    name: '2-Stars-and-above',
    value: '2-Stars-and-above',
  },
  {
    name: 'none',
    value: 'none',
  },
];

const rewardTypes = [
  {
    arName: 'خصم مبلغ ثابت',
    enName: 'Fixed Amount Discount',
    value: 1,
  },
  { arName: 'خصم نسبة مئوية', enName: 'Percentage Discount', value: 2 },
  { arName: 'شحن مجاني', enName: 'Free Shipping', value: 3 },
];

interface Props extends RouteComponentProps {}

export const Settings: FC<Props> = () => {
  const { isEnglish, user } = useGlobalState();
  const { t } = useTranslation();
  const { data } = useSWR('/reviews/settings');
  const [{ loading }, submitForm] = usePost({
    apiFunction: patchReviewsSettingsAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const settings = data?.data;

  const validationSchema = yup.object({
    autoPublishedReviews: yup.string().required(t('requiredField')),
    isEnabled: yup.bool().required(t('requiredField')),
    couponSettings: yup.object({
      discountAmount: yup
        .number()
        .nullable()
        .notRequired()
        .when('idRewardTypes', {
          is: (idRewardTypes) => idRewardTypes == '1' || idRewardTypes == '2',
          then: yup
            .number()
            .min(1, t('minNumberValidation') + '1')
            .required(t('requiredField')),
        })
        .when('idRewardTypes', {
          is: (idRewardTypes) => idRewardTypes == '2',
          then: yup
            .number()
            .max(100, t('maxNumberValidation') + '100')
            .min(1, t('minNumberValidation') + '1')
            .required(t('requiredField')),
        }),
      expiryPeriod: yup
        .number()
        .min(30, t('minNumberValidation') + '30')
        .required(t('requiredField')),
      idRewardTypes: yup.number().required(t('requiredField')),
      isCouponEnabled: yup.bool().required(t('requiredField')),
    }),
  });

  return (
    <div>
      <Paper
        elevation={0}
        style={{
          padding: '23px 16px',
          height: 70,
          borderRadius: '4px',
          border: '1px solid #E8E8E8',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 32,
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: '#292222',
          }}
        >
          {t('reviews:review-setting')}
        </p>
      </Paper>
      <Formik
        enableReinitialize
        initialValues={{
          autoPublishedReviews: settings?.autoPublishedReviews ?? 'all',
          isEnabled: settings?.isEnabled ?? false,
          couponSettings: {
            discountAmount: settings?.couponSettings?.discountAmount ?? '',
            expiryPeriod: settings?.couponSettings?.expiryPeriod ?? '',
            idRewardTypes: settings?.couponSettings?.idRewardTypes ?? 1,
            isCouponEnabled: settings?.couponSettings?.isCouponEnabled ?? false,
          },
        }}
        onSubmit={async (body: any) => {
          await submitForm(body);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          setFieldValue,
          submitForm,
          validateField,
          errors,
          setFieldTouched,
          touched,
        }) => (
          <Form style={{ maxWidth: 720, margin: 'auto' }}>
            <Paper
              elevation={0}
              style={{
                borderRadius: '4px',
                border: '1px solid #E8E8E8',
              }}
            >
              <div
                style={{
                  padding: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#292222',
                  }}
                >
                  {t('reviews:enable-review-system')}
                </p>
                <ActivationToggle
                  value={values.isEnabled}
                  onChange={(isActive) => setFieldValue('isEnabled', isActive)}
                />
              </div>
              <div style={{ borderTop: '2px solid #F6F7F8' }}></div>
              <div
                style={{
                  padding: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '52px',
                }}
              >
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#292222',
                    minWidth: '180px',
                  }}
                >
                  {t('reviews:auto-publish-reviews')}
                </p>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.autoPublishedReviews}
                    variant="filled"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    style={{
                      backgroundColor: 'rgb(246, 247, 248)',
                      fontSize: 16,
                      height: 49,
                    }}
                    onChange={(event) => {
                      setFieldValue('autoPublishedReviews', event.target.value);
                    }}
                  >
                    {autoPublishTypes.map((type) => (
                      <MenuItem value={type.value}>
                        {t(`reviews:${type.name}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Paper>
            <p
              style={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#292222',
                marginTop: '32px',
                marginBottom: '12px',
              }}
            >
              {t('reviews:coupons')}
            </p>
            <p
              style={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#8181A5',
                marginBottom: '16px',
              }}
            >
              {t('reviews:incentivize-your-customers-to-review-their-orders')}
            </p>
            <Paper
              elevation={0}
              style={{
                borderRadius: '4px',
                border: '1px solid #E8E8E8',
              }}
            >
              <div
                style={{
                  padding: '25px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#292222',
                  }}
                >
                  {t('reviews:enable-coupons')}
                </p>

                <ActivationToggle
                  value={values.couponSettings.isCouponEnabled}
                  onChange={(isActive) =>
                    setFieldValue('couponSettings.isCouponEnabled', isActive)
                  }
                />
              </div>
              <div style={{ borderTop: '2px solid #F6F7F8' }}></div>
              <div
                style={{
                  padding: '25px 25px 12.5px 25px',
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <div
                  style={{
                    minWidth: 180,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#292222',
                    }}
                  >
                    {t('reviews:coupon-value')}
                  </p>
                </div>

                <FormControl style={{ width: '100%' }}>
                  <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.couponSettings.idRewardTypes}
                    variant="filled"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    style={{
                      backgroundColor: 'rgb(246, 247, 248)',
                      fontSize: 16,
                      height: 49,
                    }}
                    onChange={(event) => {
                      setFieldValue(
                        'couponSettings.idRewardTypes',
                        event.target.value
                      );
                    }}
                  >
                    {rewardTypes.map((type) => (
                      <MenuItem value={type.value}>
                        {isEnglish ? type.enName : type.arName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {values.couponSettings.idRewardTypes != '3' && (
                  <TextInput
                    name="couponSettings.discountAmount"
                    placeholder="10"
                    labelColor="#8181A5"
                    withAdornment={
                      values.couponSettings.idRewardTypes === 2
                        ? '%'
                        : user?.currency
                        ? user?.currency
                        : t('sar')
                    }
                    adornmentPosition="end"
                    alignCenter={true}
                    variant="filled"
                    disableUnderline
                    type="number"
                    isCurrency={values.couponSettings.idRewardTypes === 1}
                  />
                )}
              </div>
              <div
                style={{
                  padding: '12.5px 25px 25px 25px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <div
                  style={{
                    minWidth: 180,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#292222',
                    }}
                  >
                    {t('reviews:coupon-expiry')}
                  </p>
                </div>

                <TextInput
                  name="couponSettings.expiryPeriod"
                  placeholder="30"
                  labelColor="#8181A5"
                  withAdornment={t('days')}
                  adornmentPosition="end"
                  alignCenter={true}
                  variant="filled"
                  disableUnderline
                  type="number"
                />
              </div>
            </Paper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '32px',
              }}
            >
              <Button
                type="submit"
                disabled={loading}
                loading={loading}
                bColor="primary"
                color="white"
                fullwidth={0}
                padding="8px 80px"
              >
                {loading ? t('submitting') : t('save')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const ActivationToggle = ({
  value,
  onChange,
  disabled,
  style = {},
  size = 'medium',
}: {
  value: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small' | undefined;
  onChange?: (isActive: boolean) => void;
  style?: React.CSSProperties | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <IOSSwitch
      disabled={disabled}
      checked={value}
      onChange={(e, checked) => onChange?.(checked)}
      name="checked"
      size={size}
    />
  );
};
