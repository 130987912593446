import { globalHistory } from '@reach/router';
import { ThemeProvider } from 'emotion-theming';
import React, { FC, Suspense, useEffect } from 'react';
import ReactGA from 'react-ga';
import { SWRConfig } from 'swr';
import { useExpireSession } from '../hooks/useExpireSession';
import { useFetcher } from '../hooks/useFetcher';
import { localStorageProvider } from '../utils';
import { Alert, CircularLoader, GlobalStyle } from './Common';
import ErrorBoundary from './ErrorBoundary';
import { GlobalStateProvider, useGlobalState } from './GlobalState';
import { Routes } from './Routes';
import { MUITheme, RTL } from './UTILS';

export type ThemeType = {
  colors: {
    primary: string;
  };
  isRtl: boolean;
};

export const Root: FC = () => {
  return (
    <GlobalStateProvider>
      <Suspense fallback={<CircularLoader />}>
        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
      </Suspense>
    </GlobalStateProvider>
  );
};

const Router = React.memo(() => {
  const { language } = useGlobalState();
  const fetcher = useFetcher();
  const theme: ThemeType = {
    colors: {
      primary: '#FF6645',
    },
    isRtl: language === 'ar',
  };

  useEffect(() => {
    globalHistory.listen(({ location }) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }, []);

  return (
    <RTL>
      <ThemeProvider theme={theme}>
        <GlobalStyle isRtl={language === 'ar'} />
        <MUITheme isRtl={language === 'ar'}>
          <SWRConfig
            value={{
              fetcher,
              provider: localStorageProvider,
            }}
          >
            <Alert />
            <Routes />
          </SWRConfig>
        </MUITheme>
      </ThemeProvider>
    </RTL>
  );
});
