import { Grid } from '@material-ui/core';
import equal from 'deep-equal';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { putStettingsAPI, uploadImage } from '../../api';
import { ReactComponent as MailIcon } from '../../assets/svg/mail.svg';
import { ReactComponent as NameIcon } from '../../assets/svg/name.svg';
import { ReactComponent as PhoneIcon } from '../../assets/svg/phone.svg';
import { ReactComponent as StoreIcon } from '../../assets/svg/store_name.svg';
import {
  Button,
  ImageUploader,
  PhoneNumber,
  Popup,
  TextInput,
} from '../../components/Common';
import { useGlobalState } from '../../components/GlobalState';
import { usePost } from '../../utils';
import { GeneralLayout } from './styled';

const General = () => {
  const { t } = useTranslation();
  const { user } = useGlobalState();

  const [logo, setLogo] = React.useState({
    img: '',
    file: '',
    id: 0,
    type: '',
    loading: false,
  });
  const validationSchema = yup.object({
    email: yup.string().email().required(t('requiredField')),
    phoneNumber: yup.object({
      val: yup
        .string()
        .typeError('phone number must be a number')
        .required('No mobile number provided.')
        .min(7, 'Phone number must be at least 7 numbers'),
    }),
    owner: yup.string().required(t('requiredField')).min(3),
    storeName: yup.string().required(t('requiredField')).min(3),
  });

  const [{ loading }, submitForm, dispatchError] = usePost({
    apiFunction: putStettingsAPI,
    updateUser: true,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const [openConfirmation, setConfiramtion] = React.useState(false);

  const onConfirm = async (body: {
    owner: string;
    email: string;
    storeName: string;
    phoneNumber: {
      val: number;
      dialCode: number;
      countryCode: string;
      country: string;
    };
  }) => {
    setLogo({ ...logo, loading: true });
    const imageData = new FormData();

    imageData.append('image', logo.file);
    const image =
      !!logo.type &&
      (await uploadImage({
        type: logo.type,
        file: imageData,
      }));

    setLogo({ ...logo, loading: false });

    const res = await submitForm({
      body: {
        ...body,
        merchantId: user?.idMerchant,
        imageURL: image,
      },
    });

    if (res) {
      setLogo({
        img: '',
        file: '',
        id: 0,
        type: '',
        loading: false,
      });
    } else if (image) {
      dispatchError(image);
    }
  };

  return (
    <GeneralLayout className="mr-bottom card">
      <div>
        <div className="image-loader mr-bottom-1">
          <ImageUploader
            width="75px"
            height="75px"
            image={logo}
            setImage={setLogo}
            hideInputOnLoad={true}
            id="logo"
            rouneded={1}
            withLargePreview={false}
          />
          <h4 className="title">{t('settings:profileImage')}</h4>
          <p className="secondary">{t('settings:imageTypes')}</p>
        </div>
        <Formik
          initialValues={{
            owner: user?.owner || '',
            email: user?.email || '',
            storeName: user?.theme?.brandTheme?.storeName || '',
            phoneNumber: {
              val: user?.onlineConfiguration?.phoneNumber?.val || 0,
              dialCode:
                user?.onlineConfiguration?.phoneNumber?.dialCode || '966',
              countryCode:
                user?.onlineConfiguration?.phoneNumber?.countryCode || 'sa',
              country:
                user?.onlineConfiguration?.phoneNumber?.country ||
                'Saudi Arabia',
            },
          }}
          onSubmit={() => {
            setConfiramtion(true);
          }}
          validationSchema={validationSchema}
        >
          {({ values, resetForm, initialValues, setFieldValue }) => (
            <>
              <Popup
                title="Alert"
                fullWidth={true}
                open={openConfirmation}
                maxWidth="sm"
                handleClose={() => setConfiramtion(false)}
                onConfirm={onConfirm}
                data={values}
              >
                <div style={{ padding: '2em 0' }}>
                  <p>{t('changesConfirmation')}</p>
                </div>
              </Popup>
              <Form>
                <section className="grid mr-bottom">
                  <TextInput
                    name="owner"
                    label={t('fullname')}
                    placeholder={t('mohammedAhmed')}
                    logo={NameIcon}
                    autoFocus
                  />
                  <TextInput
                    name="storeName"
                    label={t('storeName')}
                    placeholder={t('storeName')}
                    logo={StoreIcon}
                  />

                  <TextInput
                    name="email"
                    label={t('email')}
                    placeholder="name@gmail.com"
                    logo={MailIcon}
                  />
                  <PhoneNumber
                    logo={PhoneIcon}
                    name="phoneNumber.val"
                    setValue={(val: string) =>
                      setFieldValue('phoneNumber', val)
                    }
                  />
                </section>
                <Grid container>
                  <Grid item xs={12} md={6} container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Button
                        type="submit"
                        disabled={equal(initialValues, values) && !logo.type}
                        loading={loading}
                        bColor="primary"
                        color="white"
                        fullwidth={1}
                      >
                        {loading ? t('submitting') : t('save')}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        backcolor="#8181A5"
                        type="button"
                        disabled={false}
                        bColor="primary"
                        color="white"
                        fullwidth={1}
                        handleClick={resetForm}
                      >
                        {t('cancel')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </GeneralLayout>
  );
};

export default General;
