import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { mq } from '../../utils';

export const Layout = styled.section`
  .left {
    grid-column: 1/3;
  }
  .right {
    grid-column: 3/4;
  }

  ${mq('tablet')} {
    .left,
    .right {
      grid-column: -1/1;
    }
    .right {
      grid-row: 1;
    }
  }

  .grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }

 padding-bottom: 0;
  }
`;

const base = css`
  .card {
    padding: 1.5rem 0.75rem;
  }

  .title {
    font-weight: 600;
    font-size: 0.85rem;
  }
  .button {
    display: flex;
    button {
      padding-left: 5rem;
      padding-right: 5rem;
    }
    section + section {
      margin-left: 1em;
    }
  }

  ${mq('phone')} {
    .button {
      flex-direction: column;

      section {
        margin-left: 0 !important;
        margin-bottom: 1rem;
      }
    }
  }
`;

export const GeneralLayout = styled.div`
  ${base};
  .image-loader {
    display: grid;
    place-items: center;
    margin-bottom: 2em;

    p {
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  section {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 0 6em;
  }
`;

export const SecurityLayout = styled.div`
  ${base};
  .grid {
    grid-gap: 2em;
  }
`;

export const NotificationsLayout = styled('div')`
  ${base};

  .button {
    margin-top: 4em;
  }
  .main {
    margin-bottom: 0;
    background-color: white;
    border-radius: 8px;
    ${mq('phone')} {
      padding: 1em;
    }
  }
`;
