import { Box } from '@material-ui/core';
import { useNavigate } from '@reach/router';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useSign } from '../actions';
import CodeRight from '../assets/img/password-left.png';
import { Button, TextInput } from '../components/Common';
import { NoIndex } from '../components/Common/NoIndex';
import { useGlobalState } from '../components/GlobalState';
import { SignLayout } from '../components/SignLayout';
import { getLocal, setLocal, useStyles } from '../utils';

const CodeVerification = () => {
  const { user, resetUser, setUser } = useGlobalState();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    code: yup.string().required(t('requiredField')).min(4).max(4),
  });

  const presistedUser = getLocal({ key: 'ebonat_user' });
  const {
    state: { loading },
    submitForm,
  } = useSign(presistedUser);

  const classes = useStyles();

  const signOut = () => {
    setLocal({ key: 'ebonat_user' });
    resetUser();
    navigate('/signin', { replace: true });
  };

  return (
    <>
      <NoIndex />
      <SignLayout
        firstTitle={t('public:verification')}
        secondTitle={`${t('public:enterTheSentCodeTo')} ${
          user?.phoneNumber ? user?.phoneNumber.toString().slice(-4) : ''
        }
     ▪️▪️▪️▪️▪️▪️▪️▪️▪️`}
        bgImage={CodeRight}
      >
        <Formik
          initialValues={{
            code: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (body, { resetForm }) => {
            const data = await submitForm(
              { ...user, phoneNumber: { val: user?.phoneNumber! }, ...body },
              'signup'
            );
            if (data) {
              resetForm();
              setUser({ ...data, verified: true });
            }
          }}
        >
          {() => (
            <Form>
              <TextInput
                name="code"
                label={t('public:verfCode')}
                placeholder="* * * *"
              />

              <Box component="div">
                <Button
                  type="submit"
                  loading={loading}
                  bColor="primary"
                  color="white"
                  fullwidth={1}
                >
                  {loading ? t('submitting') : t('public:confirm')}
                </Button>
                <Box className={classes.link}>
                  <Button
                    handleClick={signOut}
                    type="button"
                    bColor="secondary"
                    color="#8181A5"
                    fullwidth={1}
                  >
                    {t('public:signin')}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </SignLayout>
    </>
  );
};

export default CodeVerification;
