import { Grid } from '@material-ui/core';
import React from 'react';
import { ReactComponent as BirthdayIcon } from '../../assets/svg/dashboard_birthday.svg';
import { ReactComponent as LostCustmerIcon } from '../../assets/svg/dashboard_lostCustomers.svg';
import { ReactComponent as LoyalCustomerIcon } from '../../assets/svg/dashboard_loyalCustomers.svg';
import { ReactComponent as NewCustomerIcon } from '../../assets/svg/dashboard_newCustomers.svg';
import { ReactComponent as RegularIcon } from '../../assets/svg/dashboard_regularCustomers.svg';
// GET ICOM
import { ReactComponent as SuperFansIcon } from '../../assets/svg/dashboard_superfans.svg';

export * from './customers';
export * from './profile';
export * from './segments';

export const segments = {
  birthday: 'birthdayCustomers',
  riskCustomers: 'riskCustomers',
  loyalCustomers: 'loyalCustomers',
  superFan: 'superFan',
  superFans: 'superfans',
  lostCustomers: 'lostCustomers',
  newCustomers: 'newCustomers',
  allCustomers: 'allCustomers',
  regularCustomers: 'regularCustomers',
};

export const getIcon = (type: string, size?: number) => {
  const margin = '0 8px';
  switch (type) {
    case segments.birthday:
      return <BirthdayIcon style={{ width: size, height: size, margin }} />;
    case segments.loyalCustomers:
      return (
        <LoyalCustomerIcon style={{ width: size, height: size, margin }} />
      );
    case segments.superFan:
      return <SuperFansIcon style={{ width: size, height: size, margin }} />;
    case segments.riskCustomers:
      return <LostCustmerIcon style={{ width: size, height: size, margin }} />;
    case segments.newCustomers:
      return <NewCustomerIcon style={{ width: size, height: size, margin }} />;

    case segments.regularCustomers:
      return <RegularIcon style={{ width: size, height: size, margin }} />;

    default:
      return <BirthdayIcon style={{ width: size, height: size, margin }} />;
  }
};

export const CustomerIcon = ({ color = '#ff6645' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.438"
      height="16.032"
      viewBox="0 0 15.438 16.032"
    >
      <g
        data-name="Group 55282"
        transform="translate(-919.74 -970.606)"
        style={{ isolation: 'isolate' }}
      >
        <path
          d="M927.765 971.163a4.122 4.122 0 011.512 1.512 4.192 4.192 0 010 4.176 4.127 4.127 0 01-1.512 1.511 4.075 4.075 0 01-2.087.557 4.609 4.609 0 00-2.375.641 4.746 4.746 0 00-1.735 1.734 4.62 4.62 0 00-.64 2.375h-1.188a5.888 5.888 0 011.726-4.175 6.08 6.08 0 011.874-1.28 4.22 4.22 0 01-1.327-1.475 4.2 4.2 0 01.065-4.064 4.122 4.122 0 011.512-1.512 4.19 4.19 0 014.175 0zm-3.581 1.029a2.994 2.994 0 00-1.076 1.077 3 3 0 000 2.988 3 3 0 001.076 1.076 3 3 0 002.987 0 3.01 3.01 0 001.077-1.076 3 3 0 000-2.988 3 3 0 00-1.077-1.077 3 3 0 00-2.987 0zm7.079 6.727l3.562 3.562a1.133 1.133 0 01.353.827 1.192 1.192 0 01-.353.843l-2.134 2.134a1.191 1.191 0 01-.844.353 1.133 1.133 0 01-.826-.353l-3.562-3.562v-2.617a1.2 1.2 0 011.187-1.187zm-2.617 3.321l3.21 3.21 2.134-2.134-3.21-3.21h-2.134zm1.6-1.364a.582.582 0 11-.417-.176.573.573 0 01.422.176z"
          data-name="Path 33254"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};

export const IconBox = ({ backgroundColor, children, size = 60 }: any) => {
  return (
    <Grid
      item
      style={{
        backgroundColor,
        width: size,
        height: size,
        borderRadius: 8,
      }}
      className="flex center"
    >
      {children}
    </Grid>
  );
};
