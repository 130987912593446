import React, { useCallback, useMemo } from 'react';
import { firebaseEnv } from '../../../api';
import { firebaseInstance, rtdb } from '../../firebase';
import { useGlobalState } from '../../GlobalState';

export const useNotifications = () => {
  const { user } = useGlobalState();
  const [notifications, setNotifications] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const checkNewNotification = (notifications: [{ isnew: 1 | 0 }]) => {
    if (notifications.length < 1) return false;
    return notifications.some((notification) => notification.isnew === 1);
  };

  const [hasNewNotification, setHasNewNotification] = React.useState(
    checkNewNotification(
      // @ts-expect-error
      notifications
    )
  );

  const dataURL = useMemo(
    () => `/notifications/${firebaseEnv}/${user?.idMerchant}/`,
    [user]
  );

  const makeAllNotificationsRead = useCallback(() => {
    firebaseInstance.auth().onAuthStateChanged(function (user) {
      if (user) {
        notifications.forEach((notification: any) => {
          if (notification.isnew === 1) {
            rtdb
              .ref(`${dataURL}/${notification.id}`)
              .update({ ...notification, isnew: 0 });
          }
        });

        setHasNewNotification(false);
      } else {
      }
    });
  }, [dataURL, notifications]);

  const onValueChange = useCallback((snapshot: any) => {
    let docs: any = [];
    snapshot.forEach((doc: any) => {
      docs.push({ ...doc.val(), id: doc.key });
    });

    console.log('snapShot', snapshot);

    docs.sort((a: any, b: any) => b.createdDate - a.createdDate);
    setNotifications(docs);
    setHasNewNotification(checkNewNotification(docs));
    setLoading(false);
  }, []);

  React.useEffect(() => {
    setLoading(true);

    rtdb.ref(dataURL).limitToFirst(100).on('value', onValueChange);
    return () => {
      rtdb.ref(dataURL).off('value', onValueChange);
      makeAllNotificationsRead();
    };
  }, [dataURL, onValueChange]);

  return {
    notifications,
    makeAllNotificationsRead,
    hasNewNotification,
    setHasNewNotification,
    loading,
  };
};
