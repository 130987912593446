import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import React, { FC } from 'react';
import { ReactComponent as Menu } from '../../assets/svg/menu.svg';
import { Header as HeaderContent } from '../Common/index';
import { RenderIcon } from '../SVG';
import styles from './index.module.css';

interface Props {
  isNavigationExpand: boolean;
  setIsNavigationExpand: (isNavigationExpand: boolean) => void;
}

export const Header: FC<Props> = ({
  isNavigationExpand,
  setIsNavigationExpand,
}) => {
  return (
    <div className={styles.header}>
      <AppBar
        position="relative"
        elevation={0}
        style={{
          backgroundColor: 'white',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setIsNavigationExpand(!isNavigationExpand)}
            edge="start"
          >
            <RenderIcon logo={Menu} bColor="#F2F2F6" padding="0.55rem" />
          </IconButton>
          <HeaderContent />
        </Toolbar>
      </AppBar>
    </div>
  );
};
