import { Paper, TextField } from '@material-ui/core';
import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { postReferralSocialAPI } from '../../../api';
import { ReactComponent as LeftIcon } from '../../../assets/svg/arrow-left-solid.svg';
import { ReactComponent as RightIcon } from '../../../assets/svg/arrow-right-solid.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/svg/sharring-twitter.svg';
import WhatsAppIcon from '../../../assets/svg/sharring-whatsapp.svg';
import { Button } from '../../../components/Common';
import { IOSSwitch } from '../../../components/Common/Toggle/styled';
import { useGlobalState } from '../../../components/GlobalState';
import { usePost } from '../../../utils';
import { Header } from './components/header';

interface Option {
  isActive?: boolean;
  message?: string;
  type?: 'twitter' | 'whatsapp';
}

export const Social = () => {
  const { isEnglish, user } = useGlobalState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useSWR('/referral/share-options');
  const [whatsappOption, setWhatsappOption] = useState<Option>();
  const [twitterOption, setTwitterOption] = useState<Option>();
  const [{ loading }, submitForm] = usePost({
    apiFunction: postReferralSocialAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const accessDenied = !user?.permissions?.['ReferralModule']?.['Edit'];

  useEffect(() => {
    const sharingOptions: Option[] = data?.data?.sharingOpt ?? [];

    const twitterOption = sharingOptions.find(
      (option: Option) => option.type === 'twitter'
    );
    const whatsAppOption = sharingOptions.find(
      (option: Option) => option.type === 'whatsapp'
    );

    setWhatsappOption(
      whatsAppOption ?? { isActive: false, message: '', type: 'whatsapp' }
    );
    setTwitterOption(
      twitterOption ?? { isActive: false, message: '', type: 'twitter' }
    );
  }, [data]);

  const onToggle =
    (type: Option['type']) =>
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (type === 'twitter') {
        setTwitterOption((option) => ({ ...option, isActive: checked }));
      } else {
        setWhatsappOption((option) => ({ ...option, isActive: checked }));
      }
    };

  const onTextChange =
    (type: Option['type']) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const message = event.target.value;
      if (type === 'twitter') {
        setTwitterOption((option) => ({
          ...option,
          message,
        }));
      } else {
        setWhatsappOption((option) => ({
          ...option,
          message,
        }));
      }
    };

  const onSave = async () => {
    await submitForm({
      body: {
        sharingOpt: [twitterOption, whatsappOption],
        merchantId: user?.idMerchant,
      },
    });
    navigate('/programs/referral');
  };

  return (
    <div>
      <Header title={t('referral:social-sharing')} />

      <Paper
        elevation={0}
        style={{
          padding: '18px 21px',
          marginTop: 36,
          borderRadius: '4px',
          border: '1px solid #E8E8E8',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '16px',
            borderBottom: '1px dashed #8181A5',
            marginBottom: '12.5px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '14.1px',
              width: '170px',
            }}
          >
            <TwitterIcon />
            <p
              style={{ color: '#13131E', fontWeight: '500', fontSize: '18px' }}
            >
              {t('referral:twitter')}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}
          >
            <ActiveBadge
              disabled={accessDenied}
              isActive={!!twitterOption?.isActive}
            />
            <IOSSwitch
              checked={!!twitterOption?.isActive}
              onChange={onToggle('twitter')}
              disabled={accessDenied}
            />
          </div>
        </div>
        <p
          style={{
            color: '#13131E',
            fontWeight: '500',
            fontSize: '16px',
            marginBottom: '13px',
          }}
        >
          {t('referral:tweet-content')}
        </p>

        <TextField
          variant="standard"
          style={{ borderRadius: '4px' }}
          onChange={onTextChange('twitter')}
          fullWidth
          value={twitterOption?.message ?? ''}
          placeholder={t('referral:input-placeholder')}
          InputProps={{
            style: {
              fontSize: '16px',
              padding: '17px 25px',
              backgroundColor: '#F5F5FA',
              color: '#13131E',
              height: 60,
              borderRadius: '4px',
            },
            disableUnderline: true,

            classes: {
              input: 'input-placeholder',
            },
          }}
        />
        <div
          style={{
            color: '#8181A5',
            fontSize: '14px',
            marginTop: '14px',
            display: 'flex',
            gap: '5px',
          }}
        >
          <span>{t('referral:default-content1')}</span>
          <span>{user?.name}</span>
          <span>{t('referral:default-content2')}</span>
        </div>
      </Paper>
      <Paper
        elevation={0}
        style={{
          padding: '18px 21px',
          marginTop: 36,
          borderRadius: '4px',
          border: '1px solid #E8E8E8',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '16px',
            borderBottom: '1px dashed #8181A5',
            marginBottom: '12.5px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '14.1px',
              width: '170px',
            }}
          >
            <img src={WhatsAppIcon} alt="whatsapp" />
            <p
              style={{ color: '#13131E', fontWeight: '500', fontSize: '18px' }}
            >
              {t('referral:whatsapp')}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}
          >
            <ActiveBadge
              disabled={accessDenied}
              isActive={!!whatsappOption?.isActive}
            />
            <IOSSwitch
              checked={!!whatsappOption?.isActive}
              onChange={onToggle('whatsapp')}
              disabled={accessDenied}
            />
          </div>
        </div>
        <p
          style={{
            color: '#13131E',
            fontWeight: '500',
            fontSize: '16px',
            marginBottom: '13px',
          }}
        >
          {t('referral:message-title')}
        </p>

        <TextField
          variant="standard"
          value={whatsappOption?.message ?? ''}
          style={{ borderRadius: '4px' }}
          onChange={onTextChange('whatsapp')}
          fullWidth
          placeholder={t('referral:input-placeholder')}
          InputProps={{
            style: {
              fontSize: '16px',
              padding: '17px 25px',
              backgroundColor: '#F5F5FA',
              color: '#13131E',
              height: 60,
              borderRadius: '4px',
            },
            disableUnderline: true,

            classes: {
              input: 'input-placeholder',
            },
          }}
        />

        <div
          style={{
            color: '#8181A5',
            fontSize: '14px',
            marginTop: '14px',
            display: 'flex',
            gap: '5px',
          }}
        >
          <span>{t('referral:default-content1')}</span>
          <span>{user?.name}</span>
          <span>{t('referral:default-content2')}</span>
        </div>
      </Paper>

      <div
        style={{
          display: 'flex',
          marginTop: '36px',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          handleClick={onSave}
          loading={loading}
          bColor="primary"
          color="white"
          fullwidth={0}
        >
          {loading ? t('submitting') : t('save')}
        </Button>
      </div>
    </div>
  );
};

const ActiveBadge = ({
  isActive,
  style = {},
  disabled,
}: {
  isActive: boolean;
  style?: React.CSSProperties | undefined;
  disabled: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: '500',
        padding: '5px 19px',
        textAlign: 'center',
        width: '130px',
        ...(isActive && !disabled
          ? { color: '#6DD230', backgroundColor: '#E3F7D8' }
          : {
              backgroundColor: 'rgba(129,129,165,0.1)',
              color: '#778CA2',
            }),
        ...style,
      }}
    >
      {isActive ? t('referral:activated') : t('referral:deactivated')}
    </div>
  );
};
