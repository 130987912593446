import { Button } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';
import { patchBlock } from '../../../../api';
import { paths, usePost } from '../../../../utils';

interface Props {
  customerId: string;
  blocked: boolean;
  handleClose: () => void;
}

export const BlockForm: FC<Props> = ({ customerId, blocked, handleClose }) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  const [{ loading }, submitForm] = usePost({
    apiFunction: patchBlock,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const onConfirm = async () => {
    try {
      await submitForm({ customerId, block: !blocked });
      mutate(`/customers/${customerId}`);
      handleClose();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div style={{ margin: 'auto' }}>
      {blocked ? (
        <p style={{ marginTop: '15px' }}>{t('segmentation:unblock-message')}</p>
      ) : (
        <>
          <p style={{ marginTop: '15px' }}>
            {t('segmentation:block-message-1')}
          </p>
          <p>{t('segmentation:block-message-2')}</p>
        </>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '50px',
          marginBottom: '15px',
          marginTop: '25px',
        }}
      >
        <Button
          style={{ fontSize: '14px', padding: '5px 27px' }}
          size="small"
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={onConfirm}
        >
          {loading ? t('submitting') : t('segmentation:confirm')}
        </Button>
        <Button
          style={{ fontSize: '14px', padding: '5px 27px' }}
          size="small"
          disabled={loading}
          onClick={handleClose}
          variant="contained"
        >
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};
