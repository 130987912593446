// @ts-nocheck
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const getFormatedYupErrors = (yupErros) => {
  let errors = {};
  yupErros.inner.forEach((e) => {
    errors = { ...errors, [e.path]: e.errors[0] };
  });

  return errors;
};

export const useNumberValidation = (type, min) => {
  const { t } = useTranslation();
  return yup
    .number()
    .min(min, `${type} ${t('minNumberValidation')} ${min}`)
    .required(`${type} ${t('requiredField')}`)
    .typeError('Please Enter valid number');
};

export const numberValidationWithMaxAndMin = (
  type,
  min,
  max,
  withPercentage = false,
  t
) => {
  const minLabel = withPercentage ? min.toFixed(2) + '%' : min;
  const maxLabel = withPercentage ? max.toFixed(2) + '%' : max;
  return yup
    .number()
    .min(min, `${type} ${t('minNumberValidation')} ${minLabel}`)
    .max(max, `${type} ${t('maxNumberValidation')} ${maxLabel}`)
    .required(`${type} ${t('requiredField')}`)
    .typeError('Please Enter valid number');
};

export const intergerNumberValidationWithMaxAndMin = (
  type,
  min,
  max,
  withPercentage = false,
  t
) => {
  const minLabel = withPercentage ? min.toFixed(2) + '%' : min;
  const maxLabel = withPercentage ? max.toFixed(2) + '%' : max;
  return yup
    .number()
    .integer(t('integerNumbers'))
    .min(min, `${type} ${t('minNumberValidation')} ${minLabel}`)
    .max(max, `${type} ${t('maxNumberValidation')} ${maxLabel}`)
    .required(`${type} ${t('requiredField')}`)
    .typeError('Please Enter valid number');
};

export const stringValidation = (type) => {
  return yup
    .string()
    .min(3, `${type} must contain at least 3 characters`)
    .required(`${type} ${t('requiredField')}`)
    .typeError(`${type} must contain at least 3 characters`);
};

export const passwordValidation = () => {
  return yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    );
};

export const emailValidation = () => {
  return yup
    .string()
    .email()
    .required('Email field is required')
    .typeError(`Email must contain at least 3 characters`);
};

export const phoneRegex = RegExp(/^(966)?0?5\d{8}$/);

export const phoneValidation = () => {
  return yup
    .string()
    .matches(phoneRegex, 'Invalid phone number')
    .required('Phone number field is required')
    .typeError('Phone number field is required');
};

export const moudlesValidation = () => {
  return yup
    .object()
    .shape({
      rewards: yup.object(),
      coupons: yup.object(),
      gifts: yup.object(),
      customers: yup.object(),
      outreach: yup.object(),
      feedback: yup.object(),
    })
    .atLeastOneOf([
      'rewards',
      'coupons',
      'gifts',
      'customers',
      'outreach',
      'feedback',
    ]);
};
