import React from 'react';
import { useTranslation } from 'react-i18next';
import noRewards from '../../../assets/img/no-rewards.png';
import freeProductImgScr from '../../../assets/svg/free-product.svg';
import percentageImgScr from '../../../assets/svg/percentage.svg';
import amountImgScr from '../../../assets/svg/amount.svg';
import shippingImgScr from '../../../assets/svg/shipping.svg';
import { useGlobalState } from '../../../components/GlobalState';
import { IOSSwitch } from '../../../components/Common/Toggle/styled';
import { usePost } from '../../../utils';
import { updateRewardStatus } from '../../../api';
import { useSWRConfig } from 'swr';

export const RewardsGrid = ({
  rewards,
  onUpdate,
}: {
  rewards: any;
  onUpdate: ((reward: any) => void) | ((reward: any) => Promise<void>);
}) => {
  const { t } = useTranslation();
  const { isEnglish, user } = useGlobalState();
  const [{ loading, error }, submitForm] = usePost({
    apiFunction: updateRewardStatus,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });
  const { mutate } = useSWRConfig();

  const iconSrc = (id: number) => {
    switch (id) {
      case 1:
        return amountImgScr;
      case 2:
        return percentageImgScr;
      case 3:
        return shippingImgScr;
      case 4:
        return freeProductImgScr;
    }
  };

  return (
    <div style={{ marginTop: '50px' }}>
      {rewards?.length ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, 300px)',
            gap: '25px',
          }}
        >
          {rewards?.map((reward: any) => (
            <div key={reward.idCampaign} style={{ position: 'relative' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '3px',
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
              >
                <ActivationToggle
                  value={!!reward.idCampaignStatus}
                  onChange={async (isActive) => {
                    await submitForm({
                      body: { isActive, idCampaign: reward.idCampaign },
                    });
                    mutate(`/reward/${user?.idMerchant}`);
                  }}
                />
              </div>
              <div
                onClick={() => onUpdate(reward)}
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  width: '302px',
                  height: '210px',
                  gap: '16px',
                  border: '1px solid rgb(232, 232, 232)',
                  cursor: 'pointer',
                }}
              >
                <img
                  style={{ width: '64px', height: '64px' }}
                  src={iconSrc(reward.idRewardTypes)}
                />
                <p
                  style={{
                    color: '#13131E',
                    fontSize: '16px',
                    fontWeight: 400,
                  }}
                >
                  {isEnglish ? reward.title_en : reward.title_ar}
                </p>
                <p
                  style={{
                    color: '#13131E',
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  {isEnglish ? reward.description_en : reward.description_ar}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <img src={noRewards} />
          <p>{t('reward:no-reward')}</p>
        </div>
      )}
    </div>
  );
};

const ActivationToggle = ({
  value,
  onChange,
  disabled,
  style = {},
  size = 'medium',
}: {
  value: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small' | undefined;
  onChange?: (isActive: boolean) => void;
  style?: React.CSSProperties | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <IOSSwitch
      disabled={disabled}
      checked={value}
      onChange={(e, checked) => onChange?.(checked)}
      name="checked"
      size={size}
    />
  );
};
