import {
  Box,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AllCutomersIcon } from '../../assets/svg/allCustomers.svg';
import { ReactComponent as RFMIcon } from '../../assets/svg/rfm.svg';
import { ReactComponent as ThisMonthCustomers } from '../../assets/svg/thisMonthCustomers.svg';
import { rfm } from '../../utils';

export const StatsCard = ({
  label,
  value,
  logo: Logo,
  children,
  to,
  state,
}) => {
  return (
    <Paper spacing={2}>
      <Box px={2} py={3}>
        <Link to={to} state={state} style={{ width: '100%' }}>
          <Grid container justify="space-between">
            <Grid item style={{ alignItems: 'flex-start' }}>
              <Typography
                variant="h4"
                style={{
                  color: '#8181A5',
                  fontWeight: 400,
                  marginBottom: 12,
                }}
              >
                {label}
              </Typography>

              <Typography variant="h4" style={{ fontWeight: 700 }}>
                {value}
              </Typography>
            </Grid>
            <Logo />
          </Grid>
          {children}
        </Link>
      </Box>
    </Paper>
  );
};

export const SegmentCard = ({
  to,
  state,
  logo: Logo,
  label = '',
  segmentType,
  isCustom,
  height,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const style = { marginBottom: 16 };
  const isNewCustomer = segmentType === 'newCustomers';

  return (
    <Paper style={{ height: height ? height : '' }}>
      <Box p={4} className={classes.card}>
        <Link to={to} state={state} style={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              {Logo}
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <div>
                <Typography variant="h3" style={style}>
                  {t(`segmentation:${label}`)}
                </Typography>
                <p
                  style={{
                    ...style,
                    color: '#8181A5',
                    fontWeight: 200,
                    fontSize: 13,
                    lineHeight: 1.6,
                  }}
                >
                  {t(`segmentation:${label.replace(' ', '')}Desc`)}
                </p>
              </div>
              <Hidden mdUp>
                <Box py={2}>
                  <Divider />
                </Box>
              </Hidden>
              <Box px={3} py={2}>
                <Grid container spacing={3}>
                  <Grid xs={12} md={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.boxValue}
                    >
                      {isCustom ? <ThisMonthCustomers /> : <RFMIcon />}

                      <Box px={2}>
                        <p>
                          {isCustom
                            ? t('segmentation:thisMonth')
                            : t('segmentation:loyaltyScore')}
                        </p>
                        <Typography variant="h4">
                          {isCustom
                            ? Number(rest.thisMonth).toLocaleString('en-Us')
                            : t(`segmentation:${rfm[segmentType]}`)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid xs={12} md={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.boxValue}
                    >
                      {isNewCustomer ? (
                        <ThisMonthCustomers />
                      ) : (
                        <AllCutomersIcon />
                      )}

                      <Box px={2}>
                        <p>
                          {isNewCustomer
                            ? t('segmentation:previousMonth')
                            : t('segmentation:allCustomers')}
                        </p>
                        <Typography variant="h4">
                          {Number(
                            isNewCustomer
                              ? rest.previousMonth
                              : rest.allCustomers
                          ).toLocaleString('en-Us')}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Link>
      </Box>
    </Paper>
  );
};

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      '& *': {
        textAlign: 'center',
      },
    },
  },
  card: {
    width: '100%',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      // paddingRight: 0,
    },
  },
  boxValue: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  secondStats: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginRight: 16,
    },
  },
}));
