import {
  createMuiTheme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { mainColor } from '../../../utils';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: '#ECECEC',
    },
  },
});

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '1rem 0.5rem',
      '& ul': {
        justifyContent: 'center',
        flexWrap: 'nowrap',
      },
      '& li:nth-of-type(1)': {
        marginRight: 'auto',
      },
      '& li:last-child': {
        marginLeft: 'auto',
      },
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);
