import { RouteComponentProps } from '@reach/router';
import React, { FC, FunctionComponent } from 'react';

interface Props {
  component: FunctionComponent;
}

export const RequireIntegration: FC<Props & RouteComponentProps> = ({
  component: Component,
  ...props
}) => {
  // if (user && !user.isIntegrated) {
  //   return <Redirect from={location.pathname} to="/" noThrow />;
  // }

  return <Component {...props} />;
};
