import styled from '@emotion/styled';

export const Layout = styled.div<{ width?: string; height?: string }>`
  display: flex;
  align-items: center;

  label {
    height: ${({ height }) => (height ? height : '60px')};
    width: ${({ width }) => (width ? width : '60px')};
  }
`;
