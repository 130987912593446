import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../reducers';
import { PageError } from '../Common';
import { useGlobalState } from '../GlobalState';

type Props = {
  permission: keyof User['permissions'];
};

export const Guard: FC<Props> = ({ permission, children }) => {
  const { user } = useGlobalState();
  const { t } = useTranslation();

  const canView =
    permission === 'HomeModule'
      ? user?.permissions[permission]?.view
      : user?.permissions[permission]?.View;

  if (!canView) return <PageError text={t('504')} withoutButton />;

  return <>{children}</>;
};
