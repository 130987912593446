import { Router } from '@reach/router';
import React from 'react';
import { Route } from '../../components/Common';
import { NoIndex } from '../../components/Common/NoIndex';
import { paths } from '../../utils';
import NotFound from '../notFound';
import { EarningWays } from './earningWays';
import { Referral } from './referral';
import { RewardSystem } from './rewardSystem';

const Programs = () => {
  return (
    <>
      <NoIndex />
      <Router>
        <Route path={paths.rewardSystem + '/*'} component={RewardSystem} />
        <Route path={paths.earningWays + '/*'} component={EarningWays} />
        <Route path={paths.referrals + '/*'} component={Referral} />
        <NotFound default />
      </Router>
    </>
  );
};

export default Programs;
