import { Box, Checkbox, Grid, Paper } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import * as yup from 'yup';

import { patchBranding } from '../api/branding';
import {
  Brand,
  Button,
  ColorPicker,
  RadioButtons,
  SystemHeader,
  TextInput,
  Toggle,
} from '../components/Common';
import { useGlobalState } from '../components/GlobalState';
import { usePost } from '../utils';
import styles from './branding.module.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import { NoIndex } from '../components/Common/NoIndex';
import GrowthPlan from '../components/Common/GrowthPlan';
import { Guard } from '../components/Routes/Guard';

const brandIcons: { [key: string]: any } = {
  icon1: {
    black: require('../assets/svg/icon1.svg').default,
    white: require('../assets/svg/icon1-white.svg').default,
  },
  icon2: {
    black: require('../assets/svg/icon2.svg').default,
    white: require('../assets/svg/icon2-white.svg').default,
  },
  icon3: {
    black: require('../assets/svg/icon3.svg').default,
    white: require('../assets/svg/icon3-white.svg').default,
  },
  icon4: {
    black: require('../assets/svg/icon4.svg').default,
    white: require('../assets/svg/icon4-white.svg').default,
  },
  icon5: {
    black: require('../assets/svg/icon5.svg').default,
    white: require('../assets/svg/icon5-white.svg').default,
  },
};

const Branding = () => {
  const { user, isEnglish } = useGlobalState();
  const { data }: any = useSWR(`/settings/branding/${user?.idMerchant}`);

  const branding = data?.data;

  const { mutate } = useSWRConfig();
  const { t } = useTranslation();
  const [buttonAccordionOpen, setButtonAccordionOpen] = useState(false);
  const [textAccordionOpen, setTextAccordionOpen] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(
    branding?.brandTheme?.buttonIcon ?? 'icon1'
  );
  const [viewBonat, setViewBonat] = useState(
    branding?.brandTheme?.viewBonatLabel
  );
  const spaceFormRef = useRef<HTMLDivElement>(null);

  const [{ loading }, submitForm] = usePost({
    apiFunction: patchBranding,
    updateUser: true,
    to: window.location.href,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const canNotAccessPowerBy =
    !user?.permissions?.['BrandingModule']?.['Edit Remove Powered by Bonat'];

  const canNotAccessButtonIcon =
    !user?.permissions?.['BrandingModule']?.['Edit Button Icon'];

  const validationSchema = yup.object({
    sideSpacing: yup
      .number()
      .typeError(t('fieldMustBeANumber'))
      .min(1, `${t('minNumber')} 1`)
      .max(200, `${t('maxNumber')} 200`)
      .required(t('requiredField')),
    mobileSideSpacing: yup
      .number()
      .typeError(t('fieldMustBeANumber'))
      .min(1, `${t('minNumber')} 1`)
      .max(200, `${t('maxNumber')} 200`)
      .required(t('requiredField')),
    bottomSpacing: yup
      .number()
      .typeError(t('fieldMustBeANumber'))
      .min(1, `${t('minNumber')} 1`)
      .max(200, `${t('maxNumber')} 200`)
      .required(t('requiredField')),
    mobileBottomSpacing: yup
      .number()
      .typeError(t('fieldMustBeANumber'))
      .min(1, `${t('minNumber')} 1`)
      .max(200, `${t('maxNumber')} 200`)
      .required(t('requiredField')),
  });

  const [color, setColor] = React.useState({
    displayColorPicker: false,
    color: { hex: branding?.brandTheme?.brandColor?.primary },
  });

  const PLACEMENTOPTIONS = useMemo(
    () => [
      { title: t('branding:bottomLeft'), id: 'left' },
      { title: t('branding:bottomRight'), id: 'right' },
    ],
    [t]
  );

  const [placement, setPlacement] = React.useState({
    webPlacement: branding?.brandTheme?.webPlacement ?? PLACEMENTOPTIONS[0].id,
    mobilePlacement:
      branding?.brandTheme?.mobilePlacement ?? PLACEMENTOPTIONS[0].id,
  });

  const [buttonText, setButtonText] = React.useState(
    branding?.brandTheme?.buttonText ?? 'المكافات'
  );
  const [buttonText_en, setButtonText_en] = React.useState(
    branding?.brandTheme?.buttonText_en ?? 'Rewards'
  );

  useEffect(() => {
    if (!branding) return;

    setButtonText_en(branding?.brandTheme?.buttonText_en ?? 'Rewards');
    setButtonText(branding?.brandTheme?.buttonText ?? 'المكافات');
    setPlacement({
      webPlacement:
        branding?.brandTheme?.webPlacement ?? PLACEMENTOPTIONS[0].id,
      mobilePlacement:
        branding?.brandTheme?.mobilePlacement ?? PLACEMENTOPTIONS[0].id,
    });
    setColor({
      displayColorPicker: false,
      color: { hex: branding?.brandTheme?.brandColor?.primary },
    });
    setViewBonat(branding?.brandTheme?.viewBonatLabel);
    setButtonIcon(branding?.brandTheme?.buttonIcon ?? 'icon1');
  }, [PLACEMENTOPTIONS, branding, data]);

  return (
    <Guard permission="BrandingModule">
      <NoIndex />
      <div className={styles.container}>
        <SystemHeader
          title={t('branding')}
          withBack={true}
          withStatus={false}
          padding="1rem"
        />
        <div className={styles.main}>
          <div className={styles.form}>
            <Formik
              enableReinitialize
              initialValues={{
                color: branding?.brandTheme?.brandColor?.primary ?? '#000',
                buttonText,
                buttonText_en,
                enablePlugin: branding?.brandTheme?.enablePlugin ?? false,
                sideSpacing: branding?.brandTheme?.sideSpacing ?? '1',
                mobileSideSpacing:
                  branding?.brandTheme?.mobileSideSpacing ?? '1',
                bottomSpacing: branding?.brandTheme?.bottomSpacing ?? '100',
                mobileBottomSpacing:
                  branding?.brandTheme?.mobileBottomSpacing ?? '100',
                buttonIcon: branding?.brandTheme?.buttonIcon ?? 'icon1',
                viewBonatLabel: branding?.brandTheme?.viewBonatLabel,
              }}
              onSubmit={async (body) => {
                await submitForm({
                  idMerchant: user?.idMerchant,
                  body: JSON.stringify({
                    backgroundColor: {
                      primary: color.color.hex,
                      secondary: '',
                    },
                    ...placement,
                    enablePlugin: body.enablePlugin,
                    buttonText: body.buttonText,
                    buttonText_en: body.buttonText_en,
                    bottomSpacing: body.bottomSpacing,
                    mobileBottomSpacing: body.mobileBottomSpacing,
                    sideSpacing: body.sideSpacing,
                    mobileSideSpacing: body.mobileSideSpacing,
                    ...(!canNotAccessButtonIcon && {
                      buttonIcon: body.buttonIcon,
                    }),
                    ...(!canNotAccessPowerBy && {
                      viewBonatLabel: body.viewBonatLabel,
                    }),
                  }),
                });

                mutate(`/settings/branding/${user?.idMerchant}`);
              }}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue, errors }) => {
                return (
                  <Form
                    onSubmitCapture={() => {
                      const spacingError = Object.keys(errors).some(
                        (error) =>
                          error === 'bottomSpacing' || error === 'sideSpacing'
                      );

                      if (spacingError) {
                        setButtonAccordionOpen(true);

                        setTimeout(() => {
                          spaceFormRef?.current?.scrollIntoView?.({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'start',
                          });
                        }, 1000);
                      }
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '17px',
                      }}
                    >
                      <Paper
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          minWidth: '360px',
                          padding: '10px 18px',
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <p style={{ fontSize: '16px' }}>
                            {t('branding:enablePlugin')}
                          </p>
                          <Toggle
                            label={''}
                            checked={values.enablePlugin}
                            disabled={false}
                            handleChange={(event) => {
                              setFieldValue(
                                'enablePlugin',
                                event.target.checked
                              );
                            }}
                          />
                        </Box>
                      </Paper>

                      <Paper
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          minWidth: '360px',
                          padding: '10px 18px',
                        }}
                      >
                        <Box>
                          <div style={{ position: 'relative' }}>
                            <p style={{ fontSize: '16px' }}>
                              {t('branding:pluginBrandColor')}
                            </p>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box
                                mr={2}
                                style={{ display: 'inline-block' }}
                                width={30}
                                height={25}
                                bgcolor={color.color.hex}
                                borderRadius={4}
                              ></Box>
                              <TextInput
                                disableUnderline
                                fullWidth={false}
                                name="color"
                                placeholder="#000000"
                                labelColor="#8181A5"
                              />
                              <ColorPicker state={color!} setState={setColor} />
                            </Box>
                          </div>
                        </Box>
                      </Paper>

                      <Paper
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          minWidth: '360px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            margin: '10px 18px',
                          }}
                          onClick={() =>
                            setTextAccordionOpen((prevState) => !prevState)
                          }
                        >
                          <p style={{ fontSize: '16px' }}>
                            {t('branding:buttonTextContext')}
                          </p>
                          <div style={{ display: 'flex' }}>
                            {textAccordionOpen ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </div>
                        </div>
                        <Collapse in={textAccordionOpen}>
                          <div style={{ margin: '10px 18px' }}>
                            <p style={{ fontSize: '16px' }}>
                              {t('branding:buttonContentText')}
                            </p>

                            <TextInput
                              variant="filled"
                              type="text"
                              name="buttonText"
                              label=""
                              placeholder={'Rewards & Discount'}
                            />
                          </div>

                          <div style={{ borderTop: '#EEEEF1 solid 1px' }} />

                          <div style={{ margin: '10px 18px' }}>
                            <p style={{ fontSize: '16px' }}>
                              {t('branding:buttonContentTextEn')}
                            </p>
                            <TextInput
                              variant="filled"
                              type="text"
                              name="buttonText_en"
                              label=""
                              placeholder={'Rewards & Discount'}
                            />
                          </div>
                          <div style={{ borderTop: '#EEEEF1 solid 1px' }} />

                          <div
                            style={{
                              padding: '10px 18px',
                              position: 'relative',
                            }}
                          >
                            <GrowthPlan
                              style={{
                                position: 'absolute',
                                top: 0,
                                ...(isEnglish ? { right: 0 } : { left: 0 }),
                              }}
                            />
                            <p style={{ fontSize: '16px' }}>
                              {t('branding:button-icon')}
                            </p>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {Object.entries(brandIcons).map(
                                ([name, icons]) => (
                                  <div
                                    onClick={() => {
                                      if (canNotAccessButtonIcon) return;
                                      setFieldValue('buttonIcon', name);
                                      setButtonIcon(name);
                                    }}
                                    style={{
                                      width: '50px',
                                      height: '50px',
                                      cursor: canNotAccessButtonIcon
                                        ? 'not-allowed'
                                        : 'pointer',
                                      backgroundColor:
                                        values.buttonIcon === name
                                          ? '#F6F7F8'
                                          : '#f4f4ff',
                                      padding: '5px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      border:
                                        values.buttonIcon === name
                                          ? '2px #FF6645 solid'
                                          : '1px #F0F0F3 solid',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    <img src={icons.black} alt={name}></img>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Collapse>
                      </Paper>

                      <Paper
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          minWidth: '360px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            margin: '10px 18px',
                          }}
                          onClick={() =>
                            setButtonAccordionOpen((prevState) => !prevState)
                          }
                        >
                          <p style={{ fontSize: '16px' }}>
                            {t('branding:buttonPlacement')}
                          </p>
                          <div style={{ display: 'flex' }}>
                            {buttonAccordionOpen ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </div>
                        </div>
                        <Collapse in={buttonAccordionOpen}>
                          <div style={{ borderTop: '#EEEEF1 solid 1px' }} />
                          <div style={{ margin: '10px 18px' }}>
                            <p style={{ fontSize: '16px' }}>
                              {t('branding:webPlacement')}
                            </p>

                            <RadioButtons
                              name="webPlacement"
                              options={PLACEMENTOPTIONS}
                              value={placement.webPlacement}
                              setValue={(webPlacement: string) =>
                                setPlacement({ ...placement, webPlacement })
                              }
                              direction="ltr"
                            />
                          </div>
                          <div
                            ref={spaceFormRef}
                            style={{ margin: '10px 18px' }}
                          >
                            <p
                              style={{ fontSize: '16px', marginBottom: '7px' }}
                            >
                              {t('branding:webSpacing')}
                            </p>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '20px',
                              }}
                            >
                              <div>
                                <p style={{ fontSize: '15px' }}>
                                  {t('branding:side-spacing')}
                                </p>
                                <TextInput
                                  disableUnderline
                                  variant="filled"
                                  fullWidth={false}
                                  name="sideSpacing"
                                  placeholder="#000000"
                                  labelColor="#8181A5"
                                  type="number"
                                  alignCenter
                                />
                              </div>
                              <div>
                                <p style={{ fontSize: '15px' }}>
                                  {t('branding:bottom-spacing')}
                                </p>
                                <TextInput
                                  type="number"
                                  variant="filled"
                                  disableUnderline
                                  fullWidth={false}
                                  name="bottomSpacing"
                                  placeholder="#000000"
                                  labelColor="#8181A5"
                                  alignCenter
                                />
                              </div>
                            </div>
                          </div>

                          <div style={{ borderTop: '#EEEEF1 solid 1px' }} />

                          <div style={{ margin: '10px 18px' }}>
                            <p style={{ fontSize: '16px' }}>
                              {t('branding:mobilePlacement')}
                            </p>

                            <RadioButtons
                              name="mobilePlacement"
                              options={PLACEMENTOPTIONS}
                              value={placement.mobilePlacement}
                              setValue={(mobilePlacement: string) =>
                                setPlacement({
                                  ...placement,
                                  mobilePlacement,
                                })
                              }
                              direction="ltr"
                            />
                          </div>

                          <div
                            ref={spaceFormRef}
                            style={{ margin: '10px 18px' }}
                          >
                            <p
                              style={{ fontSize: '16px', marginBottom: '7px' }}
                            >
                              {t('branding:mobileSpacing')}
                            </p>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '20px',
                              }}
                            >
                              <div>
                                <p style={{ fontSize: '15px' }}>
                                  {t('branding:side-spacing')}
                                </p>
                                <TextInput
                                  disableUnderline
                                  variant="filled"
                                  fullWidth={false}
                                  name="mobileSideSpacing"
                                  placeholder="#000000"
                                  labelColor="#8181A5"
                                  type="number"
                                  alignCenter
                                />
                              </div>
                              <div>
                                <p style={{ fontSize: '15px' }}>
                                  {t('branding:bottom-spacing')}
                                </p>
                                <TextInput
                                  type="number"
                                  variant="filled"
                                  disableUnderline
                                  fullWidth={false}
                                  name="mobileBottomSpacing"
                                  placeholder="#000000"
                                  labelColor="#8181A5"
                                  alignCenter
                                />
                              </div>
                            </div>
                          </div>
                        </Collapse>
                      </Paper>

                      <Paper
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          minWidth: '360px',
                          padding: '10px 18px',
                          position: 'relative',
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <GrowthPlan
                            style={{
                              position: 'absolute',
                              top: 0,
                              ...(isEnglish ? { right: 0 } : { left: 0 }),
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: '5px',
                              width: '100%',
                            }}
                          >
                            <p style={{ fontSize: '16px' }}>
                              {t('branding:remove-powerby')}
                            </p>
                            <Checkbox
                              checked={!values.viewBonatLabel}
                              disabled={canNotAccessPowerBy}
                              onChange={(_, checked) => {
                                setFieldValue(
                                  'viewBonatLabel',
                                  !values.viewBonatLabel
                                );
                                setViewBonat((old: boolean) => !old);
                              }}
                              color="primary"
                              size="small"
                              style={{
                                padding: '0px',
                                marginInlineEnd: isEnglish ? '49px' : '35px',
                              }}
                            />
                          </div>
                        </Box>
                      </Paper>

                      <Grid
                        item
                        xs={12}
                        justify="flex-end"
                        style={{ display: 'flex', marginBottom: 40 }}
                      >
                        <Button
                          type="submit"
                          loading={loading}
                          bColor="primary"
                          color="white"
                          fullwidth={0}
                          padding={'8px 80px'}
                        >
                          {loading ? t('submitting') : t('save')}
                        </Button>
                      </Grid>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>

          <Brand
            viewBonat={viewBonat}
            buttonIcon={brandIcons[buttonIcon].white}
            bColor={color.color.hex}
            buttonContent={isEnglish ? buttonText_en : buttonText}
          />
        </div>
      </div>
    </Guard>
  );
};

export default Branding;
