import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { mainColor } from '../../../utils';

export const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 2,
      borderRadius: 5,
      flexGrow: 'unset',
      padding: 1,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'dark' ? 700 : 200],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: mainColor,
    },
  })
)(LinearProgress);
