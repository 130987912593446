import { Checkbox, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextInput } from '../../../components/Common';
import { IOSSwitch } from '../../../components/Common/Toggle/styled';
import { useGlobalState } from '../../../components/GlobalState';
import {
  intergerNumberValidationWithMaxAndMin,
  isZidFunc,
  numberValidationWithMaxAndMin,
  usePost,
} from '../../../utils';
import { ExcludeSpecificCategories } from '../../../components/Common/ExcludeSpecificCategories';
import GrowthPlan from '../../../components/Common/GrowthPlan';
import { ExcludeSpecificProducs } from '../../../components/Common/ExcludeSpecificProducs';
import { postRewardTypeAPI } from '../../../api';
import { useSWRConfig } from 'swr';
import * as yup from 'yup';
import { SelectSpecificProducs } from '../../../components/Common/SelectSpecificProducs';

export const RewardForm = ({
  open,
  onClose,
  addRewardType,
  onBack,
  reward,
}: {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  addRewardType: null | number;
  reward: any;
}) => {
  const { isEnglish } = useGlobalState();
  const { t } = useTranslation();

  const form = () => {
    if (addRewardType) {
      if (addRewardType === 1)
        return <FixedForm reward={null} onClose={onClose} />;
      if (addRewardType === 2)
        return <PercentageForm reward={null} onClose={onClose} />;
      if (addRewardType === 3)
        return <ShippingForm reward={null} onClose={onClose} />;
      if (addRewardType === 4)
        return <ProductForm reward={null} onClose={onClose} />;
    } else if (reward) {
      if (reward.idRewardTypes === 1)
        return <FixedForm reward={reward} onClose={onClose} />;
      if (reward.idRewardTypes === 2)
        return <PercentageForm reward={reward} onClose={onClose} />;
      if (reward.idRewardTypes === 3)
        return <ShippingForm reward={reward} onClose={onClose} />;
      if (reward.idRewardTypes === 4)
        return <ProductForm reward={reward} onClose={onClose} />;
    }

    return null;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '4px 15px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={onBack}
        >
          {isEnglish ? (
            <ArrowLeftIcon htmlColor="#292222" />
          ) : (
            <ArrowRightIcon htmlColor="#292222" />
          )}
          <p
            style={{
              color: '#292222',
              fontSize: '16px',
              fontWeight: 500,
              marginBottom: 4.1,
            }}
          >
            {t('back')}
          </p>
        </div>
        <IconButton
          style={{ padding: '5px' }}
          onClick={onClose}
          aria-label="delete"
          size="small"
        >
          <CloseIcon htmlColor="#292222" fontSize="inherit" />
        </IconButton>
      </div>
      <div>{form()}</div>
    </Dialog>
  );
};

const FixedForm = ({
  reward,
  onClose,
}: {
  reward: any;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { user } = useGlobalState();
  const [minOrder, setMinOrder] = useState(!!reward?.minOrderAmount);
  const { mutate } = useSWRConfig();

  const canExcludeCategories =
    user?.permissions['RewardsModule']['Exclude Specific Categories'];
  const canExcludeProducts =
    user?.permissions['RewardsModule']['Exclude Specific Products'];

  const isCreate = !!!reward;
  const isZid = isZidFunc(user?.EcommerceType?.id);

  const [{ loading, error }, submitForm] = usePost({
    apiFunction: postRewardTypeAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const validationSchema = yup.object({
    pointAmount: intergerNumberValidationWithMaxAndMin(
      t('Points'),
      1,
      10000,
      false,
      t
    ),
    discountAmount: numberValidationWithMaxAndMin(
      t('Discount'),
      1,
      10000,
      false,
      t
    ),
    expiryPeriod: yup
      .number()
      .min(30, t('minNumberValidation') + '30')
      .required(t('requiredField')),
    maxCouponDiscount: yup
      .number()
      .min(1, t('minNumberValidation') + '1')
      .max(10000, t('maxNumberValidation') + '10000'),
    minOrderAmount: yup
      .number()
      .min(1, t('minNumberValidation') + '1')
      .max(10000, t('maxNumberValidation') + '10000'),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        discountAmount: isCreate ? '' : reward?.discountAmount,
        pointAmount: isCreate ? '' : parseInt(reward?.pointAmount),
        isExcludeDiscountedProducts: isCreate
          ? false
          : reward?.isExcludeDiscountedProducts,
        isExcludedCategories: isCreate ? false : reward?.isExcludedCategories,
        isExcludedProducts: isCreate ? false : reward?.isExcludedProducts,
        expiryPeriod: isCreate ? '' : reward?.expiryPeriod,
        minOrderAmount: isCreate ? '' : reward?.minOrderAmount,
        excludedCategories: isCreate ? [] : reward?.excludedCategories,
        excludedProducts: isCreate ? [] : reward?.excludedProducts,
        maxCouponDiscount: isCreate ? '' : reward?.maxCouponDiscount,
      }}
      onSubmit={async (values) => {
        await submitForm({
          body: {
            merchantId: user?.idMerchant,
            isActive: isCreate ? true : reward?.isActive,
            idRewardTypes: 1,
            ...(!isCreate && { idCampaign: reward?.idCampaign }),
            ...values,
          },
        });
        mutate(`/reward/${user?.idMerchant}`);
        onClose();
      }}
    >
      {({ values, setFieldValue }) => (
        <Form
          style={{
            marginTop: '26px',
            padding: '0px 25px 25px 25px',
            overflow: 'auto',
            maxHeight: '82vh',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 500,
              color: '#292222',
            }}
          >
            {t(
              `reward:${
                isCreate ? 'fixed-form-title-add' : 'fixed-form-title-update'
              }`
            )}
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 400,
              color: '#8181A5',
              marginTop: '16px',
              marginBottom: '56px',
            }}
          >
            {t('reward:fixed-form-subtitle')}
          </p>

          <p
            style={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#292222',
              marginBottom: '16px',
            }}
          >
            {t('reward:reward-value')}
          </p>

          <div style={{ display: 'flex', gap: '52px' }}>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#292222',
                  marginBottom: '8px',
                }}
              >
                {t('reward:customer-redeem')}
              </p>
              <TextInput
                name="pointAmount"
                variant="filled"
                type="number"
                fullWidth
                alignCenter
                disabled={!isCreate}
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={t('points')}
                adornmentPosition="end"
              />
            </div>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#292222',
                  marginBottom: '8px',
                }}
              >
                {t('reward:to-get')}
              </p>
              <TextInput
                name="discountAmount"
                variant="filled"
                type="number"
                disabled={!isCreate}
                fullWidth
                alignCenter
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={user?.currency ? user?.currency : t('sar')}
                adornmentPosition="end"
              />
            </div>
          </div>

          {isZid && (
            <div
              style={{
                marginTop: '32px',
                display: 'flex',
                alignItems: 'center',
                gap: '52px',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                  minWidth: '245px',
                }}
              >
                <ActivationToggle
                  value={minOrder}
                  disabled={!isCreate}
                  onChange={(checked) => setMinOrder(checked)}
                />
                <p
                  style={{
                    fontSize: '18px',
                    fontWeight: 500,
                    color: '#292222',
                  }}
                >
                  {t('reward:minimum-order-value')}
                </p>
              </div>

              {minOrder && (
                <div style={{ width: '100%' }}>
                  <TextInput
                    name="minOrderAmount"
                    variant="filled"
                    type="number"
                    fullWidth
                    disabled={!isCreate}
                    alignCenter
                    style={{
                      borderRadius: '4px',
                    }}
                    inputPropsStyle={{
                      backgroundColor: '#F5F5FA',
                      height: '60px',
                    }}
                    disableUnderline
                    withAdornment={user?.currency ? user?.currency : t('sar')}
                    adornmentPosition="end"
                  />
                </div>
              )}
            </div>
          )}

          <p
            style={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#292222',
              marginBottom: '20px',
              marginTop: '32px',
            }}
          >
            {t('reward:exclude')}
          </p>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Checkbox
                checked={!!values.isExcludeDiscountedProducts}
                onChange={(_, checked) => {
                  setFieldValue('isExcludeDiscountedProducts', checked);
                }}
                disabled={!isCreate}
                color="primary"
                size="small"
                style={{
                  padding: '0px',
                }}
              />
              <p style={{ fontSize: '16px', fontWeight: 500 }}>
                {t('reward:discounted-products')}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Checkbox
                checked={!!values.isExcludedCategories}
                onChange={(_, checked) => {
                  setFieldValue('isExcludedCategories', checked);
                }}
                disabled={
                  !canExcludeCategories ||
                  !isCreate ||
                  (isZid && values.isExcludeDiscountedProducts)
                }
                color="primary"
                size="small"
                style={{
                  padding: '0px',
                }}
              />
              <p style={{ fontSize: '16px', fontWeight: 500 }}>
                {t('reward:specific-categories')}
              </p>
              <GrowthPlan style={{ padding: '3px 4px' }} />
            </div>
            {canExcludeCategories && (
              <ExcludeSpecificCategories
                categoriesValue={values.excludedCategories}
                isExcludedCategories={values.isExcludedCategories}
                setFieldValue={setFieldValue}
                loading={loading}
                disabled={
                  !isCreate || (isZid && values.isExcludeDiscountedProducts)
                }
              />
            )}

            {!isZid && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                  }}
                >
                  <Checkbox
                    checked={!!values.isExcludedProducts}
                    onChange={(_, checked) => {
                      setFieldValue('isExcludedProducts', checked);
                    }}
                    color="primary"
                    size="small"
                    disabled={!isCreate || !canExcludeProducts}
                    style={{
                      padding: '0px',
                    }}
                  />
                  <p style={{ fontSize: '16px', fontWeight: 500 }}>
                    {t('reward:specific-products')}
                  </p>
                  <GrowthPlan style={{ padding: '3px 4px' }} />
                </div>

                {canExcludeProducts && (
                  <ExcludeSpecificProducs
                    productsValues={values.excludedProducts}
                    isExcludedProducts={values.isExcludedProducts}
                    setFieldValue={setFieldValue}
                    loading={loading}
                    disabled={!isCreate}
                  />
                )}
              </>
            )}
          </div>

          <div
            style={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              gap: '52px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                marginTop: '30px',
                minWidth: '245px',
              }}
            >
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#292222',
                }}
              >
                {t('reward:reward-expiry')}
              </p>
            </div>

            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#8181A5',
                  marginBottom: '8px',
                }}
              >
                {t('reward:reward-expiry-title')}
              </p>
              <TextInput
                name="expiryPeriod"
                variant="filled"
                type="number"
                fullWidth
                alignCenter
                disabled={!isCreate}
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={t('days')}
                adornmentPosition="end"
              />
            </div>
          </div>
          {isCreate && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
              }}
            >
              <Button
                type="submit"
                disabled={loading || !isCreate}
                loading={loading}
                bColor="primary"
                color="white"
                fullwidth={0}
                padding="8px 80px"
              >
                {loading ? t('submitting') : t('save')}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

const PercentageForm = ({
  reward,
  onClose,
}: {
  reward: any;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { user } = useGlobalState();
  const [discountRestrictionEnabled, setDiscountRestrictionEnabled] = useState(
    !!reward?.maxCouponDiscount || !!reward?.minOrderAmount
  );
  const { mutate } = useSWRConfig();

  const isCreate = !!!reward;
  const isZid = isZidFunc(user?.EcommerceType?.id);

  const canExcludeCategories =
    user?.permissions['RewardsModule']['Exclude Specific Categories'];
  const canExcludeProducts =
    user?.permissions['RewardsModule']['Exclude Specific Products'];

  const [{ loading, error }, submitForm] = usePost({
    apiFunction: postRewardTypeAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const validationSchema = yup.object({
    pointAmount: intergerNumberValidationWithMaxAndMin(
      t('Points'),
      1,
      10000,
      false,
      t
    ),
    discountAmount: numberValidationWithMaxAndMin(
      t('Discount'),
      1,
      100,
      false,
      t
    ),
    expiryPeriod: yup
      .number()
      .min(30, t('minNumberValidation') + '30')
      .required(t('requiredField')),
    maxCouponDiscount: yup
      .number()
      .min(1, t('minNumberValidation') + '1')
      .max(10000, t('maxNumberValidation') + '10000'),
    minOrderAmount: yup
      .number()
      .min(1, t('minNumberValidation') + '1')
      .max(10000, t('maxNumberValidation') + '10000'),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        discountAmount: isCreate ? '' : reward?.discountAmount,
        pointAmount: isCreate ? '' : parseInt(reward?.pointAmount),
        isExcludeDiscountedProducts: isCreate
          ? false
          : reward?.isExcludeDiscountedProducts,
        isExcludedCategories: isCreate ? false : reward?.isExcludedCategories,
        isExcludedProducts: isCreate ? false : reward?.isExcludedProducts,
        expiryPeriod: isCreate ? '' : reward?.expiryPeriod,
        excludedCategories: isCreate ? [] : reward?.excludedCategories,
        excludedProducts: isCreate ? [] : reward?.excludedProducts,
        minOrderAmount: isCreate ? '' : reward?.minOrderAmount,
        maxCouponDiscount: isCreate ? '' : reward?.maxCouponDiscount,
      }}
      onSubmit={async (values) => {
        await submitForm({
          body: {
            merchantId: user?.idMerchant,
            ...(!isCreate && { idCampaign: reward?.idCampaign }),
            isActive: isCreate ? true : reward?.isActive,
            idRewardTypes: 2,
            ...values,
          },
        });
        mutate(`/reward/${user?.idMerchant}`);
        onClose();
      }}
    >
      {({ values, setFieldValue }) => (
        <Form
          style={{
            marginTop: '26px',
            padding: '0px 25px 25px 25px',
            overflow: 'auto',
            maxHeight: '82vh',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 500,
              color: '#292222',
            }}
          >
            {t(
              `reward:${
                isCreate
                  ? 'percentage-form-title-add'
                  : 'percentage-form-title-update'
              }`
            )}
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 400,
              color: '#8181A5',
              marginTop: '16px',
              marginBottom: '56px',
            }}
          >
            {t('reward:percentage-form-subtitle')}
          </p>

          <p
            style={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#292222',
              marginBottom: '16px',
            }}
          >
            {t('reward:reward-value')}
          </p>

          <div style={{ display: 'flex', gap: '52px' }}>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#292222',
                  marginBottom: '8px',
                }}
              >
                {t('reward:customer-redeem')}
              </p>
              <TextInput
                name="pointAmount"
                disabled={!isCreate}
                variant="filled"
                type="number"
                fullWidth
                alignCenter
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={t('points')}
                adornmentPosition="end"
              />
            </div>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#292222',
                  marginBottom: '8px',
                }}
              >
                {t('reward:to-get')}
              </p>
              <TextInput
                disabled={!isCreate}
                name="discountAmount"
                variant="filled"
                type="number"
                fullWidth
                alignCenter
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={'%'}
                adornmentPosition="end"
              />
            </div>
          </div>

          <div
            style={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              gap: '52px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                minWidth: '245px',
              }}
            >
              <ActivationToggle
                disabled={!isCreate}
                value={discountRestrictionEnabled}
                onChange={(checked) => setDiscountRestrictionEnabled(checked)}
              />
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#292222',
                }}
              >
                {isZid
                  ? t('reward:minimum-order-value')
                  : t('reward:max-discount-coupon')}
              </p>
            </div>

            {discountRestrictionEnabled && (
              <div style={{ width: '100%' }}>
                <TextInput
                  name={isZid ? 'minOrderAmount' : 'maxCouponDiscount'}
                  variant="filled"
                  type="number"
                  fullWidth
                  disabled={!isCreate}
                  alignCenter
                  style={{
                    borderRadius: '4px',
                  }}
                  inputPropsStyle={{
                    backgroundColor: '#F5F5FA',
                    height: '60px',
                  }}
                  disableUnderline
                  withAdornment={user?.currency ? user?.currency : t('sar')}
                  adornmentPosition="end"
                />
              </div>
            )}
          </div>

          <p
            style={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#292222',
              marginBottom: '20px',
              marginTop: '32px',
            }}
          >
            {t('reward:exclude')}
          </p>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Checkbox
                disabled={!isCreate}
                checked={!!values.isExcludeDiscountedProducts}
                onChange={(_, checked) => {
                  setFieldValue('isExcludeDiscountedProducts', checked);
                }}
                color="primary"
                size="small"
                style={{
                  padding: '0px',
                }}
              />
              <p style={{ fontSize: '16px', fontWeight: 500 }}>
                {t('reward:discounted-products')}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Checkbox
                checked={!!values.isExcludedCategories}
                onChange={(_, checked) => {
                  setFieldValue('isExcludedCategories', checked);
                }}
                disabled={
                  !canExcludeCategories ||
                  !isCreate ||
                  (isZid && values.isExcludeDiscountedProducts)
                }
                color="primary"
                size="small"
                style={{
                  padding: '0px',
                }}
              />
              <p style={{ fontSize: '16px', fontWeight: 500 }}>
                {t('reward:specific-categories')}
              </p>
              <GrowthPlan style={{ padding: '3px 4px' }} />
            </div>
            {canExcludeCategories && (
              <ExcludeSpecificCategories
                categoriesValue={values.excludedCategories}
                isExcludedCategories={values.isExcludedCategories}
                setFieldValue={setFieldValue}
                loading={false}
                disabled={
                  !isCreate || (isZid && values.isExcludeDiscountedProducts)
                }
              />
            )}

            {!isZid && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                  }}
                >
                  <Checkbox
                    checked={!!values.isExcludedProducts}
                    disabled={!isCreate || !canExcludeProducts}
                    onChange={(_, checked) => {
                      setFieldValue('isExcludedProducts', checked);
                    }}
                    color="primary"
                    size="small"
                    style={{
                      padding: '0px',
                    }}
                  />
                  <p style={{ fontSize: '16px', fontWeight: 500 }}>
                    {t('reward:specific-products')}
                  </p>
                  <GrowthPlan style={{ padding: '3px 4px' }} />
                </div>

                {canExcludeProducts && (
                  <ExcludeSpecificProducs
                    productsValues={values.excludedProducts}
                    isExcludedProducts={values.isExcludedProducts}
                    setFieldValue={setFieldValue}
                    disabled={!isCreate}
                    loading={false}
                  />
                )}
              </>
            )}
          </div>

          <div
            style={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              gap: '52px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                marginTop: '30px',
                minWidth: '245px',
              }}
            >
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#292222',
                }}
              >
                {t('reward:reward-expiry')}
              </p>
            </div>

            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#8181A5',
                  marginBottom: '8px',
                }}
              >
                {t('reward:reward-expiry-title')}
              </p>
              <TextInput
                name="expiryPeriod"
                variant="filled"
                type="number"
                fullWidth
                alignCenter
                disabled={!isCreate}
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={t('days')}
                adornmentPosition="end"
              />
            </div>
          </div>
          {isCreate && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
              }}
            >
              <Button
                type="submit"
                disabled={!isCreate || loading}
                loading={loading}
                bColor="primary"
                color="white"
                fullwidth={0}
                padding="8px 80px"
              >
                {loading ? t('submitting') : t('save')}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

const ShippingForm = ({
  reward,
  onClose,
}: {
  reward: any;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { user } = useGlobalState();
  const { mutate } = useSWRConfig();

  const [{ loading, error }, submitForm] = usePost({
    apiFunction: postRewardTypeAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const validationSchema = yup.object({
    pointAmount: intergerNumberValidationWithMaxAndMin(
      t('Points'),
      1,
      10000,
      false,
      t
    ),
    expiryPeriod: yup
      .number()
      .min(30, t('minNumberValidation') + '30')
      .required(t('requiredField')),
  });

  const isCreate = !!!reward;

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        pointAmount: isCreate ? '' : parseInt(reward?.pointAmount),
        expiryPeriod: isCreate ? '' : reward?.expiryPeriod,
      }}
      onSubmit={async (values) => {
        await submitForm({
          body: {
            merchantId: user?.idMerchant,
            isActive: isCreate ? true : reward?.isActive,
            ...(!isCreate && { idCampaign: reward?.idCampaign }),
            idRewardTypes: 3,
            ...values,
          },
        });
        mutate(`/reward/${user?.idMerchant}`);
        onClose();
      }}
    >
      {() => (
        <Form
          style={{
            marginTop: '26px',
            padding: '0px 25px 25px 25px',
            overflow: 'auto',
            maxHeight: '82vh',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 500,
              color: '#292222',
            }}
          >
            {t(
              `reward:${
                isCreate
                  ? 'shipping-form-title-add'
                  : 'shipping-form-title-update'
              }`
            )}
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 400,
              color: '#8181A5',
              marginTop: '16px',
              marginBottom: '56px',
            }}
          >
            {t('reward:shipping-form-subtitle')}
          </p>

          <p
            style={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#292222',
              marginBottom: '16px',
            }}
          >
            {t('reward:reward-value')}
          </p>

          <div style={{ display: 'flex', gap: '52px' }}>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#292222',
                  marginBottom: '8px',
                }}
              >
                {t('reward:customer-redeem')}
              </p>
              <TextInput
                name="pointAmount"
                variant="filled"
                type="number"
                fullWidth
                disabled={!isCreate}
                alignCenter
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={t('points')}
                adornmentPosition="end"
              />
            </div>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#292222',
                  marginBottom: '8px',
                }}
              >
                {t('reward:to-get')}
              </p>
              <TextInput
                name="none"
                variant="filled"
                readOnly
                fullWidth
                placeholder={t('reward:freeShipping')}
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
              />
            </div>
          </div>

          <div
            style={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              gap: '52px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                marginTop: '30px',
                minWidth: '245px',
              }}
            >
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#292222',
                }}
              >
                {t('reward:reward-expiry')}
              </p>
            </div>

            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#8181A5',
                  marginBottom: '8px',
                }}
              >
                {t('reward:reward-expiry-title')}
              </p>
              <TextInput
                name="expiryPeriod"
                variant="filled"
                type="number"
                disabled={!isCreate}
                fullWidth
                alignCenter
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={t('days')}
                adornmentPosition="end"
              />
            </div>
          </div>
          {isCreate && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
              }}
            >
              <Button
                type="submit"
                disabled={loading || !isCreate}
                loading={loading}
                bColor="primary"
                color="white"
                fullwidth={0}
                padding="8px 80px"
              >
                {loading ? t('submitting') : t('save')}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

const ProductForm = ({
  reward,
  onClose,
}: {
  reward: any;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { user } = useGlobalState();
  const { mutate } = useSWRConfig();

  const [{ loading, error }, submitForm] = usePost({
    apiFunction: postRewardTypeAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const isZid = isZidFunc(user?.EcommerceType?.id);

  const validationSchema = yup.object({
    pointAmount: intergerNumberValidationWithMaxAndMin(
      t('Points'),
      1,
      10000,
      false,
      t
    ),
    free_product: yup.object({
      name: yup.string(),
      id: yup.string().required(t('requiredField')),
    }),
    expiryPeriod: yup
      .number()
      .min(30, t('minNumberValidation') + '30')
      .required(t('requiredField')),
  });

  const isCreate = !!!reward;

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        pointAmount: isCreate ? '' : parseInt(reward?.pointAmount),
        expiryPeriod: isCreate ? '' : reward?.expiryPeriod,
        free_product: isCreate
          ? { name: '', id: '' }
          : (reward?.free_product as { name: string; id: string }),
      }}
      onSubmit={async (values) => {
        await submitForm({
          body: {
            merchantId: user?.idMerchant,
            isActive: isCreate ? true : reward?.isActive,
            ...(!isCreate && { idCampaign: reward?.idCampaign }),
            idRewardTypes: 4,
            ...values,
          },
        });
        mutate(`/reward/${user?.idMerchant}`);
        onClose();
      }}
    >
      {({ setFieldValue, values, errors }) => (
        <Form
          style={{
            marginTop: '26px',
            padding: '0px 25px 25px 25px',
            overflow: 'auto',
            maxHeight: '82vh',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 500,
              color: '#292222',
            }}
          >
            {t(
              `reward:${
                isCreate
                  ? 'product-form-title-add'
                  : 'product-form-title-update'
              }`
            )}
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 400,
              color: '#8181A5',
              marginTop: '16px',
              marginBottom: '56px',
            }}
          >
            {t('reward:product-form-subtitle')}
          </p>

          <p
            style={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#292222',
              marginBottom: '16px',
            }}
          >
            {t('reward:reward-value')}
          </p>

          <div style={{ display: 'flex', gap: '52px' }}>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#292222',
                  marginBottom: '8px',
                }}
              >
                {t('reward:customer-redeem')}
              </p>
              <TextInput
                name="pointAmount"
                variant="filled"
                type="number"
                fullWidth
                disabled={!isCreate}
                alignCenter
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={t('points')}
                adornmentPosition="end"
              />
            </div>
            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#292222',
                  marginBottom: '8px',
                }}
              >
                {t('reward:to-get')}
              </p>
              <TextInput
                name="none"
                variant="filled"
                readOnly
                fullWidth
                placeholder={t('reward:freeProduct')}
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
              />
            </div>
          </div>

          <p
            style={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#292222',
              marginBottom: '16px',
              marginTop: '32px',
            }}
          >
            {t('reward:product')}
          </p>
          <p
            style={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#8181A5',
              marginBottom: '8px',
            }}
          >
            {t(`reward:${isZid ? 'product-desc-zid' : 'product-desc-salla'}`)}
          </p>

          {isZid ? (
            <TextInput
              name="free_product.id"
              variant="filled"
              fullWidth
              placeholder={t('reward:sku-placeholder')}
              disabled={!isCreate}
              style={{
                borderRadius: '4px',
              }}
              inputPropsStyle={{
                backgroundColor: '#F5F5FA',
                height: '60px',
              }}
              disableUnderline
            />
          ) : (
            <>
              <SelectSpecificProducs
                productValue={values.free_product}
                setFieldValue={setFieldValue}
                disabled={!isCreate}
                loading={false}
              />
              {errors?.['free_product'] && (
                <p style={{ color: '#f44336', fontSize: '14px' }}>
                  {errors?.['free_product'] && errors?.free_product.id}
                </p>
              )}
            </>
          )}

          <div
            style={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              gap: '52px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                marginTop: '30px',
                minWidth: '245px',
              }}
            >
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#292222',
                }}
              >
                {t('reward:reward-expiry')}
              </p>
            </div>

            <div style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#8181A5',
                  marginBottom: '8px',
                }}
              >
                {t('reward:reward-expiry-title')}
              </p>
              <TextInput
                name="expiryPeriod"
                variant="filled"
                type="number"
                disabled={!isCreate}
                fullWidth
                alignCenter
                style={{
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
                withAdornment={t('days')}
                adornmentPosition="end"
              />
            </div>
          </div>
          {isCreate && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
              }}
            >
              <Button
                type="submit"
                disabled={loading || !isCreate}
                loading={loading}
                bColor="primary"
                color="white"
                fullwidth={0}
                padding="8px 80px"
              >
                {loading ? t('submitting') : t('save')}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

const ActivationToggle = ({
  value,
  onChange,
  disabled,
  style = {},
  size = 'medium',
}: {
  value: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small' | undefined;
  onChange?: (isActive: boolean) => void;
  style?: React.CSSProperties | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <IOSSwitch
      disabled={disabled}
      checked={value}
      onChange={(e, checked) => onChange?.(checked)}
      name="checked"
      size={size}
    />
  );
};
