import styled from '@emotion/styled';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import HeaderBG from '../../assets/svg/mobile-header-bg.svg';
import { mq } from '../../utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '32px',
      fontWeight: 700,
      color: '#292222',
      fontFamily: theme.direction === 'rtl' ? 'Cairo' : 'Poppins !important',
    },

    secondTitle: {
      fontSize: '25px',
      fontWeight: 600,
      color: '#292222',
      fontFamily: theme.direction === 'rtl' ? 'Cairo' : 'Poppins !important',
    },

    subTitle: {
      fontSize: '14x',
      fontWeight: 400,
      fontFamily: theme.direction === 'rtl' ? 'Cairo' : 'Poppins !important',
      color: '#292222',
    },
  })
);

export const Layout = styled.section`
  display: flex;
  background-color: #ececf2;
  height: 100%;
  min-height: 100vh;
`;

type LeftProps = {
  direction: boolean;
};

export const Left = styled.div<LeftProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: scroll;

  border-radius: ${(props) =>
    props.direction ? '0 20px 20px 0' : '20px 0 0 20px'};
  background-color: white;
  position: relative;

  img {
    width: 150px;
  }

  div.sign_left {
    padding-top: 10%;
    height: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .f-title {
    width: 100%;
    font-weight: 600;
    font-size: 1.8rem;
  }

  form {
    background-color: white;
    width: 80%;
    height: 100%;
    max-width: 420px;
    > * {
      margin-bottom: 1.5em;
    }

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      > button {
        flex-basis: 100%;
      }
    }
    input {
      width: 100%;
    }
  }

  ${mq('screen')} {
    div.sign_left {
      justify-content: flex-start;
    }
  }

  ${mq('tablet')} {
    background-color: #ececf2;
    text-align: center;
    border-radius: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 100%;

    div.sign_left {
      width: 100%;
      max-width: unset;
      margin: auto;
    }
    form {
      max-width: unset;
      border-radius: 20px 20px 0 0;
      display: block;
      width: 100%;
      padding: 2em;
    }
  }
`;

export const Right = styled.div`
  flex-basis: 40%;
  max-height: 100vh;
  overflow: hidden;

  img {
    margin-left: 10%;
    width: 90%;
    height: 100%;
  }

  ${mq('tablet')} {
    display: none;
  }
`;

export const Content = styled.div`
  .title {
    margin-bottom: 5px !important;
  }
  > * {
    margin-bottom: 40px;
  }

  section {
    > {
      margin-bottom: 0;
    }
  }

  ${mq('tablet')} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 5em;

    background-image: url(${HeaderBG});
    background-repeat: no-repeat;
    background-size: 100%;

    .title {
      margin-bottom: 0 !important;
      font-weight: 700;
    }
    .second-title {
      font-size: 1.35rem;
      font-weight: 400;
    }
  }
`;
