import ar from 'date-fns/locale/ar';
import en from 'date-fns/locale/en-US';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ar', ar);
registerLocale('en', en);

export const ReactDatePicker = (props: any) => <DatePicker {...props} />;
