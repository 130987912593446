import React, { FC, memo, ReactElement, ReactNode } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';

interface Props {
  data: object[];
  xAxis: string;
  yAxis: { stroke: string; fill: string; key: string }[];
  toolTipContent?: ReactElement | ((props: any) => ReactNode);
  strokeWidth?: number;
}

export const AreaChartV2: FC<Props> = memo(
  ({ data, xAxis, yAxis, toolTipContent, strokeWidth = 2 }) => {
    return (
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{ top: 0, right: 30, left: 40, bottom: 0 }}
        >
          <XAxis dataKey={xAxis} tick={{ fontSize: 12 }} tickLine={false} />
          <CartesianGrid
            strokeDasharray="3 2"
            strokeWidth={1}
            stroke="rgba(204, 204, 204, 0.7)"
            horizontal={false}
          />
          <Tooltip content={toolTipContent} />
          {yAxis.map((area) => (
            <Area
              key={area.key}
              type="monotone"
              strokeWidth={strokeWidth}
              dataKey={area.key}
              stroke={area.stroke}
              fill={area.fill}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    );
  }
);
