import { Grid } from '@material-ui/core';
import equal from 'deep-equal';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import { putNotificationSettingsAPI } from '../../api';
import { BoxesLoader, Button, Popup } from '../../components/Common';
import { useGlobalState } from '../../components/GlobalState';
import { NotificationItems } from '../../components/Settings';
import { paths, usePost } from '../../utils';
import { NotificationsLayout } from './styled';

const Notifications = () => {
  const { user } = useGlobalState();
  const { t } = useTranslation();

  const { data: { data } = { data: { notifictionSetting: [] } } }: any = useSWR(
    `/settings/notifications/${user?.idMerchant}`
  );

  const { mutate } = useSWRConfig();

  const [{ error, loading }, submitForm] = usePost({
    apiFunction: putNotificationSettingsAPI,
    updateUser: false,
    to: paths.settings + '/' + paths.notifications,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const [openConfirmation, setConfiramtion] = React.useState(false);

  const onConfirm = async (body: any, resetForm: any) => {
    await submitForm({
      body: {
        merchantId: user?.idMerchant,

        ...body,
      },
    });

    mutate(`/settings/notifications/${user?.idMerchant}`);
  };

  if (error)
    return <h3 className="title">Opps, {error} please try again later</h3>;

  if (!!data?.notifictionSetting?.length)
    return (
      <NotificationsLayout className="mr-bottom card">
        <div className="main">
          <Formik
            initialValues={data}
            onSubmit={() => {
              setConfiramtion(true);
            }}
          >
            {({ values, initialValues, resetForm, setFieldValue }) => (
              <>
                <Popup
                  title="Alert"
                  fullWidth={true}
                  open={openConfirmation}
                  maxWidth="sm"
                  handleClose={() => setConfiramtion(false)}
                  onConfirm={onConfirm}
                  data={values}
                  resetForm={resetForm}
                >
                  <div style={{ padding: '2em 0' }}>
                    <p>{t('changesConfirmation')}</p>
                  </div>
                </Popup>
                <Form>
                  <div className="mr-bottom-1">
                    {values?.notifictionSetting?.map(
                      (item: any, idx: number) => (
                        <NotificationItems
                          name={`notifictionSetting.[${idx}]`}
                          label={t(`settings:${item.label}`)}
                          setFieldValue={setFieldValue}
                          active={item?.isActive}
                          subTitle={t(`settings:${item.subTitle}`)}
                        />
                      )
                    )}
                  </div>
                  <Grid container>
                    <Grid item xs={12} md={6} container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Button
                          type="submit"
                          disabled={equal(initialValues, values)}
                          loading={loading}
                          bColor="primary"
                          color="white"
                          fullwidth={1}
                        >
                          {loading ? t('submitting') : t('save')}
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button
                          backcolor="#8181A5"
                          type="button"
                          loading={loading}
                          bColor="primary"
                          color="white"
                          fullwidth={1}
                          handleClick={resetForm}
                        >
                          {t('cancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </NotificationsLayout>
    );
  return (
    <BoxesLoader
      items={[
        { width: 600, height: 70 },
        { width: 600, height: 70 },
        { width: 600, height: 70 },
        { width: 600, height: 70 },
      ]}
    />
  );
};

export default Notifications;
