import React from 'react';
import { useTranslation } from 'react-i18next';

export const EarnDescription = ({ points, action }: any) => {
  const { t } = useTranslation();

  return (
    <p className="secondary">
      {t('earningMethods:customersWillEarn')}{' '}
      <strong style={{ fontWeight: 700 }}>
        ({points ? points : '_'}) {t('points')}{' '}
      </strong>{' '}
      {t('when')} {t(action)}
    </p>
  );
};
