import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { useNavigate } from '@reach/router';
import { Formik } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import * as yup from 'yup';
import { postReferralCreateAPI, postReferralUpdateAPI } from '../../../api';
import { Button, TextInput } from '../../../components/Common';
import { IOSSwitch } from '../../../components/Common/Toggle/styled';
import { useGlobalState } from '../../../components/GlobalState';
import { usePost } from '../../../utils';
import { Header } from './components/header';

export const Setup = () => {
  const { isEnglish, user } = useGlobalState();
  const { data } = useSWR('/referral');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [{ loading: loadingCreate }, submitCreate] = usePost({
    apiFunction: postReferralCreateAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const [{ loading: loadingUpdate }, submitUpdate] = usePost({
    apiFunction: postReferralUpdateAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });

  const accessDenied = !user?.permissions?.['ReferralModule']?.['Edit'];

  const referral = data?.data;

  const isUpdateReferral =
    referral &&
    (referral.isActive ||
      referral.referredDiscount ||
      referral.referrerDiscount ||
      referral.idRewardTypes == '3' ||
      referral.idCampaign);

  const validationSchema = yup.object({
    expiryPeriod: yup
      .number()
      .min(30, t('minNumberValidation') + '30')
      .required(t('requiredField')),
    referrerDiscount: yup
      .number()
      .nullable()
      .notRequired()
      .when('idRewardTypes', {
        is: (idRewardTypes) => idRewardTypes == '1' || idRewardTypes == '2',
        then: yup
          .number()
          .min(1, t('minNumberValidation') + '1')
          .required(t('requiredField')),
      })
      .when('idRewardTypes', {
        is: (idRewardTypes) => idRewardTypes == '2',
        then: yup
          .number()
          .max(100, t('maxNumberValidation') + '100')
          .min(1, t('minNumberValidation') + '1')
          .required(t('requiredField')),
      }),
    referredDiscount: yup
      .number()
      .nullable()
      .notRequired()
      .when('idRewardTypes', {
        is: (idRewardTypes) => idRewardTypes == '1' || idRewardTypes == '2',
        then: yup
          .number()
          .min(1, t('minNumberValidation') + '1')
          .required(t('requiredField')),
      })
      .when('idRewardTypes', {
        is: (idRewardTypes) => idRewardTypes == '2',
        then: yup
          .number()
          .max(100, t('maxNumberValidation') + '100')
          .min(1, t('minNumberValidation') + '1')
          .required(t('requiredField')),
      }),
  });

  return (
    <div>
      <Header title={t('referral:referral-setup')} />
      <Formik
        enableReinitialize
        initialValues={{
          referrerDiscount: referral?.referrerDiscount ?? '',
          referredDiscount: referral?.referredDiscount ?? '',
          isExcludeDiscountedProducts:
            referral?.isExcludeDiscountedProducts ?? false,
          expiryPeriod: referral?.expiryPeriod ?? '',
          isActive: true,
          idRewardTypes: referral?.idRewardTypes ?? '1',
        }}
        onSubmit={async (body: any) => {
          if (isUpdateReferral) {
            await submitUpdate({
              body: {
                ...body,
                idCampaign: referral?.idCampaign,
              },
            });
          } else {
            await submitCreate({
              body,
            });
          }

          navigate('/programs/referral/');
        }}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, submitForm }) => (
          <div style={{ maxWidth: '755px', margin: 'auto' }}>
            <Paper
              elevation={0}
              style={{
                marginTop: 25,
                padding: '19px 26px',
                borderRadius: '4px',
                border: '1px solid #E8E8E8',
              }}
            >
              <p
                style={{
                  fontSize: '18px',
                  color: '#13131E',
                  fontWeight: '500',
                  marginBottom: '18px',
                }}
              >
                {t('referral:reward-type')}
              </p>
              <div>
                <p
                  style={{
                    fontSize: 15,
                    color: '#1A2D4C',
                    fontWeight: '500',
                    marginBottom: 10,
                  }}
                >
                  {t('campaign:select-coupon-type')}
                </p>
                <FormControl style={{ width: '70%' }}>
                  <Select
                    disableUnderline
                    value={values.idRewardTypes}
                    className=""
                    variant="filled"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    style={{
                      backgroundColor: '#F5F5FA',
                      fontSize: 16,
                      color: '#8181A5',
                      height: 59,
                      borderRadius: '4px',
                      border: '1px solid #E8E8E8',
                    }}
                    onChange={(event) => {
                      setFieldValue('idRewardTypes', event.target.value);
                    }}
                  >
                    {rewardTypes.map((type) => (
                      <MenuItem value={type.value}>
                        {isEnglish ? type.enName : type.arName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Paper>
            {values.idRewardTypes != '3' && (
              <Paper
                elevation={0}
                style={{
                  marginTop: 46,
                  padding: '19px 26px',
                  borderRadius: '4px',
                  border: '1px solid #E8E8E8',
                }}
              >
                <p
                  style={{
                    fontSize: '18px',
                    color: '#13131E',
                    fontWeight: '500',
                    marginBottom: '22px',
                  }}
                >
                  {t('referral:reward-value')}
                </p>
                <div
                  style={{
                    marginBottom: '22px',
                    display: 'flex',
                    alignItems: 'start',
                    flexWrap: 'wrap',
                    gap: '50px',
                  }}
                >
                  <div style={{ minWidth: '45%' }}>
                    <p
                      style={{
                        fontSize: '14px',
                        color: '#8181A5',
                        marginBottom: '16px',
                        display: 'flex',
                        gap: '5px',
                      }}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {t('referral:discount-label-1')}
                      </span>
                      <span
                        style={{
                          fontWeight: '500',
                          color: '#13131E',
                          whiteSpace: 'nowrap',
                        }}
                      >{`${values.referrerDiscount || '_'}${
                        values.idRewardTypes == '2'
                          ? '%'
                          : user?.currency
                          ? t(user.currency)
                          : ' ' + t('sar')
                      }`}</span>
                      <span>{t('referral:discount-label-2')}</span>
                    </p>
                    <TextInput
                      name="referrerDiscount"
                      variant="filled"
                      type="number"
                      alignCenter
                      fullWidth
                      style={{
                        borderRadius: '4px',
                      }}
                      inputPropsStyle={{
                        backgroundColor: '#F5F5FA',
                        height: '60px',
                      }}
                      disableUnderline
                      withAdornment={
                        values.idRewardTypes == 2
                          ? '%'
                          : user?.currency
                          ? user?.currency
                          : t('sar')
                      }
                      adornmentPosition="end"
                    />
                  </div>
                  <div style={{ minWidth: '45%' }}>
                    <p
                      style={{
                        fontSize: '14px',
                        color: '#8181A5',
                        marginBottom: '16px',
                        display: 'flex',
                        gap: '5px',
                      }}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {t('referral:discount-label-1-2')}
                      </span>
                      <span
                        style={{
                          fontWeight: '500',
                          color: '#13131E',
                          whiteSpace: 'nowrap',
                        }}
                      >{`${values.referredDiscount || '_'}${
                        values.idRewardTypes == '2'
                          ? '%'
                          : user?.currency
                          ? t(user.currency)
                          : ' ' + t('sar')
                      }`}</span>
                      <span>{t('referral:discount-label-2')}</span>
                    </p>
                    <TextInput
                      name="referredDiscount"
                      variant="filled"
                      type="number"
                      fullWidth
                      alignCenter
                      style={{
                        borderRadius: '4px',
                      }}
                      inputPropsStyle={{
                        backgroundColor: '#F5F5FA',
                        height: '60px',
                      }}
                      disableUnderline
                      withAdornment={
                        values.idRewardTypes == 2
                          ? '%'
                          : user?.currency
                          ? user?.currency
                          : t('sar')
                      }
                      adornmentPosition="end"
                    />
                  </div>
                </div>
                <ExcludeDiscountedProducts
                  setFieldValue={setFieldValue}
                  active={values.isExcludeDiscountedProducts}
                  loading={false}
                />
              </Paper>
            )}

            <Paper
              elevation={0}
              style={{
                marginTop: 46,
                padding: '19px 26px',
                borderRadius: '4px',
                border: '1px solid #E8E8E8',
              }}
            >
              <p
                style={{
                  fontSize: '18px',
                  color: '#13131E',
                  fontWeight: '500',
                  marginBottom: '4px',
                }}
              >
                {t('referral:reward-expiry')}
              </p>
              <p
                style={{
                  fontSize: '14px',
                  color: '#292222',
                  marginBottom: '12px',
                }}
              >
                {t('referral:reward-expiry-detail')}
              </p>
              <TextInput
                id="expiryPeriod"
                name="expiryPeriod"
                adornmentPosition="end"
                withAdornment={t('days')}
                type="number"
                variant="filled"
                alignCenter={true}
                style={{
                  width: '316px',
                  borderRadius: '4px',
                }}
                inputPropsStyle={{
                  backgroundColor: '#F5F5FA',
                  height: '60px',
                }}
                disableUnderline
              />
            </Paper>
            <div
              style={{
                display: 'flex',
                marginTop: '36px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                style={{
                  ...((loadingCreate || loadingUpdate || accessDenied) && {
                    backgroundColor: '#e1d5d3',
                    color: 'white',
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                  }),
                }}
                type="submit"
                disabledLabel={t('growth-plan')}
                loading={loadingCreate || loadingUpdate}
                handleClick={submitForm}
                bColor="primary"
                color="white"
                fullwidth={0}
                padding="8px 80px"
              >
                {accessDenied && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      ...(isEnglish ? { right: 0 } : { left: 0 }),
                      color: 'white',
                      backgroundColor: '#ff6645',
                      borderRadius: '3px',
                      padding: '1px 3px',
                      fontSize: '8px',
                      margin: '3px 2px',
                    }}
                  >
                    {t('growth-plan')}
                  </div>
                )}
                {loadingCreate || loadingUpdate ? t('submitting') : t('save')}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

const rewardTypes = [
  {
    arName: 'خصم مبلغ ثابت',
    enName: 'Fixed Amount Discount',
    value: 1,
  },
  { arName: 'خصم نسبة مئوية', enName: 'Percentage Discount', value: 2 },
  { arName: 'شحن مجاني', enName: 'Free Shipping', value: 3 },
];

interface IExcludeDiscountedProducts {
  active: boolean;
  loading: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ExcludeDiscountedProducts: FC<IExcludeDiscountedProducts> = ({
  active,
  loading,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <p
        style={{
          color: '#13131E',
          fontSize: '18px',
          fontWeight: '500',
          marginBottom: '18px',
        }}
      >
        {t('reward:exclude-discounted-products')}
      </p>

      <Toggle
        label={t('reward:exclude-discounted-products-label')}
        checked={active}
        disabled={loading}
        handleChange={(event) =>
          setFieldValue('isExcludeDiscountedProducts', event.target.checked)
        }
      />
    </Box>
  );
};

interface ToggleProps {
  checked?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: any;
  disabled?: boolean;
}

function Toggle({
  checked,
  handleChange,
  label,
  disabled,
  ...rest
}: ToggleProps) {
  return (
    <FormGroup>
      <FormControlLabel
        style={{ fontSize: 11 }}
        disabled={disabled}
        control={
          <IOSSwitch
            checked={checked}
            onChange={handleChange}
            name="checked"
            {...rest}
          />
        }
        label={
          <span
            style={{
              fontSize: 15,
              color: '#414717',
              marginBottom: 10,
              display: 'inline-block',
            }}
          >
            {label}
          </span>
        }
      />
    </FormGroup>
  );
}
