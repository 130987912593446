import { Divider } from '@material-ui/core';
import React from 'react';

import { Toggle } from '../../Common';
import { Layout } from './styled';

interface Props {
  label: string;
  subTitle: string;
  name: string;
  active: boolean;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const NotificationItems = ({
  label,
  name,
  setFieldValue,
  active,
  subTitle,
}: Props) => {
  const [isActive, setActive] = React.useState(active);
  return (
    <>
      <Layout>
        <div className="flex space-between" style={{ marginBottom: '0' }}>
          <div className="column">
            <h3 className="title">{label}</h3>
            <p className="secondary">{subTitle}</p>
          </div>
          <Toggle
            checked={isActive}
            handleChange={() => {
              setActive(!isActive);
              // @ts-expect-error
              setFieldValue(`${name}.isActive`, !isActive);
            }}
          />
        </div>
      </Layout>
      <Divider light />
    </>
  );
};
