import { TableBody } from '@material-ui/core';
import { Link } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, paths, useMemorization } from '../../../utils';
import { PageError, Table } from '../../Common';
import { useGlobalState } from '../../GlobalState';
import { StatusBox, StyledTableCell, StyledTableRow } from './styled';

type TableData = {
  couponAmount: number;
  idCoupon: string;
  orderId: string;
  referralIncome: string;
  referredCustomerName: string;
  referredIdCustomer: number;
  referrerCustomerName: string;
  referrerIdCustomer: number;
  rewardType: number;
};

export const ReferralsTable = ({ data, error, isValidating }: any) => {
  const { isEnglish, user } = useGlobalState();
  const { t } = useTranslation();

  const headerData = useMemorization(
    [
      { label: t('referral:referrer'), val: 'referral:referrer' },
      {
        label: t('referral:referred-customer'),
        val: 'referral:referred-customer',
      },
      { label: t('referral:reward'), val: 'referral:reward' },
      { label: t('referral:order-id'), val: 'referral:order-id' },
      { label: t('referral:referral-income'), val: 'referral:referral-income' },
    ],
    [t]
  );

  if (error)
    return (
      <PageError
        buttonText={'home'}
        text={`Opps, ${JSON.stringify(error)} please try again later`}
      />
    );

  return (
    <Table
      loading={isValidating}
      withoutAction={true}
      requestSort={() => {}}
      sortConfig={() => {}}
      headerData={headerData}
      noData={!data}
      noDataMessasge={t('home:noActionHasBeenMade')}
    >
      <TableBody>
        {data?.map((row: TableData, idx: number) => {
          const toReferrer = `${paths.segmentation}${paths.customer}?id=${row.referrerIdCustomer}&&name=${row.referrerCustomerName}`;
          const toReferred = `${paths.segmentation}${paths.customer}?id=${row.referredIdCustomer}&&name=${row.referredCustomerName}`;

          const getReward = () => {
            switch (row.rewardType) {
              case 1:
                return `${t('referral:used')} - ${row.couponAmount} ${
                  user?.currency ? t(user.currency) : t('sar')
                } ${t('referral:coupon')}`;
              case 2:
                return `${t('referral:used')} - ${row.couponAmount}% ${t(
                  'referral:coupon'
                )}`;
              case 3:
                return t('reward:freeShipping');
              default:
                return `${t('referral:used')} - ${row.couponAmount} ${
                  user?.currency ? t(user.currency) : t('sar')
                } ${t('referral:coupon')}`;
            }
          };

          return (
            <StyledTableRow key={idx}>
              <StyledTableCell
                to={toReferrer}
                //@ts-ignore
                component={Link}
                state={row}
                align="center"
              >
                <span style={{ color: '#5E81F4' }}>
                  {row.referrerCustomerName ?? 'Customer'}
                </span>
                <p>
                  {t('ID')} {row.referrerIdCustomer}
                </p>
              </StyledTableCell>
              <StyledTableCell
                to={toReferred}
                //@ts-ignore
                component={Link}
                align="center"
                state={row}
              >
                <span style={{ color: '#5E81F4' }}>
                  {row.referredCustomerName ?? 'Customer'}
                </span>
                <p>
                  {t('ID')} {row.referredIdCustomer}
                </p>
              </StyledTableCell>
              <StyledTableCell>
                <span>{getReward()}</span>
                <p>{row.idCoupon}</p>
              </StyledTableCell>
              <StyledTableCell>{`${t('referral:number-id')}: ${
                row.orderId
              }`}</StyledTableCell>
              <StyledTableCell>{`${t(row.referralIncome)} ${
                user?.currency ? t(user.currency) : t('sar')
              }`}</StyledTableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
