import { Box, useTheme } from '@material-ui/core';
import React from 'react';

export const RadioSelectionGroup = ({
  options,
  selected,
  setSelected,
}: {
  options: Array<any>;
  selected: any;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      borderColor="#8181A5"
      bgcolor="white"
      borderRadius={24}
    >
      {options.map((option, idx) => {
        const isSelected = selected.id === option.id;
        return (
          <Box
            onClick={() => setSelected(option)}
            borderRadius={24}
            key={idx}
            px={3}
            py={1}
            style={{
              backgroundColor: isSelected ? theme.palette.primary.main : '',
              color: isSelected ? 'white' : '',
              cursor: 'pointer',
            }}
          >
            <p style={{ color: 'inherit' }}>{option.title}</p>
          </Box>
        );
      })}
    </Box>
  );
};
