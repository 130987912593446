import React from 'react';
import ContentLoader from 'react-content-loader';

interface ItemsProps {
  items: Array<{ width: number; height: number }>;
}
export const BoxesLoader = ({ items }: ItemsProps) => (
  <div style={{ flex: 1 }}>
    {items.map((item, idx) => (
      <ContentLoader
        viewBox={`0 0 ${item.width} ${item.height}`}
        // backgroundColor="rgba(250,250,250)"
        backgroundColor="rgba(0, 0, 0, 0.06)"
        foregroundColor="rgba(0, 0, 0, 0.2)"
      >
        <rect
          x="0"
          y="0"
          rx="5"
          ry="5"
          width={item.width}
          height={item.height}
        />
      </ContentLoader>
    ))}
  </div>
);
