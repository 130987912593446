import styled from '@emotion/styled';
import { Link } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SetupLayout } from '.';
import { getProgressValue } from '../../utils';
import { Button } from '../Common';
import { useGlobalState } from '../GlobalState';
import { StepProps } from './index';

const Layout = styled.div`
  button {
    padding-right: 4em;
    padding-left: 4em;
  }
`;
export const StepTwo = ({ step }: StepProps) => {
  const { user, setUser } = useGlobalState();
  const { t } = useTranslation();
  const isIntegrated = user?.isIntegrated;

  React.useEffect(() => {
    if (user) {
      setUser({ ...user, completed: true });
    }
  }, [setUser, user]);

  return (
    <SetupLayout
      firstTitle={isIntegrated ? t('done') : t('onBording:underDevelopment')}
      subTitle={isIntegrated ? t('welcomeToBonatFamily') : ''}
      hint={
        isIntegrated
          ? t('youAreReadyToStartEngagingYourCustomer')
          : t('onBording:ourTeamWillContactYouSoon')
      }
      progress={getProgressValue(step, 4)}
      isDone={!!isIntegrated}
    >
      <Layout>
        <Link
          to="/"
          style={{ width: '100%' }}
          onClick={() =>
            setUser({
              ...user!,
              completed: true,
              onlineConfiguration: {
                ...user?.onlineConfiguration!,
                nextStep: 2,
              },
            })
          }
        >
          <Button
            type="button"
            fullwidth={isIntegrated ? 1 : 0}
            bColor="primary"
            color="white"
          >
            {isIntegrated ? t('getStarted') : t('done')}
          </Button>
        </Link>
      </Layout>
    </SetupLayout>
  );
};
