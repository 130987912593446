import styled from '@emotion/styled';
import {
  createStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { mq } from '../../../utils';

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 'none',
      fontSize: '13.5px !important',
    },
    head: {
      backgroundColor: 'rgba(246,247,248,1)',
      color: '#8181A5',
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export const Layout = styled.section`
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  ${mq('tablet')} {
    max-width: 1000px;
  }
`;

export const TableHeader = styled.nav`
  padding: 20px 25px;

  h1 {
    font-size: 1rem;
    font-weight: 500;
  }

  ${mq('tablet')} {
    flex-direction: column;
  }
`;

export const StatusBox = styled.div<{ status: string }>`
  text-align: center;

  background: ${({ status }) =>
    status === 'collected' ? 'rgba(129,129,165,0.2)' : 'rgba(255,102,69,0.2)'};

  color: ${({ status }) =>
    status === 'collected' ? 'rgba(129,129,165,1)' : 'rgba(255,102,69,1)'};

  font-size: ${({ status }) => (status === 'collected' ? '14px' : '13px')};

  width: 80%;
  padding: 0.8em;
  border-radius: 8px;

  margin-left: -1vw;

  ${mq('labtop')} {
    margin-left: 0;
    width: 90%;
  }
`;
