import styled from '@emotion/styled';
import { mq } from '../../../../utils';

export const Layout = styled.section`
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 0 4em;

    .left {
      grid-column: 1/2;
    }
    .right {
      grid-column: 2/4;
    }

    .title {
      font-weight: 500;
    }

    button {
      width: auto;
      margin-top: 10px;
      background-color: white !important;
      padding: 0.6em 1em;
      font-size: 0.9rem;
      text-transform: capitalize;
      font-weight: 400;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    .paragraph {
      color: #13131e;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }

  .border-bottom {
    border-bottom: 1px dashed #d0d0d4;
  }

  .actionable {
    .icon {
      margin-right: 1em;
      margin-left: 1em;
    }
  }

  ${mq('tablet')} {
    .grid {
      .left {
        margin-bottom: 2em;
      }
      .left,
      .right {
        grid-column: -1/1;
      }
    }
  }
`;
