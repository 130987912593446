import styled from '@emotion/styled';
import { useTheme } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { formatDate, mainColor } from '../../../../utils';
import { BoxLoader, Tabs } from '../../../Common';
import { AreaChartV2 } from '../../../Common/AreaChartV2';
import { Tooltip } from '../../../Common/ChartsTooltip';
import { useGlobalState } from '../../../GlobalState';

const RedeemedVsCollected = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isEnglish } = useGlobalState();
  const [filterBy, setFilterBy] = React.useState('month');
  const { data, error }: any = useSWR(
    `/merchant/pointsChart?filter=${filterBy}`
  );

  const fetchedData = data?.data ?? [];

  const yAxis = useMemo(
    () => [
      {
        fill: theme.colors.brand100,
        stroke: theme.colors.brand,
        key: 'collectedPoints',
      },
      {
        fill: theme.colors.brandAccent300,
        stroke: theme.colors.brandAccent,
        key: 'redeemedPoints',
      },
    ],
    [theme]
  );

  const formatedData = useMemo(
    () =>
      fetchedData?.map?.((record: any) => ({
        ...record,
        collectedPoints: +record.collectedPoints,
        redeemedPoints: +record.redeemedPoints,
        date: formatDate(new Date(record.date), 'dd MMMM', isEnglish),
      })) ?? [],
    [fetchedData, isEnglish]
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setFilterBy(newValue);
  };

  const customTooltip = useCallback(
    ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Tooltip>
            <p className="gray">{label}</p>
            <p style={{ color: payload[0].color }}>
              {t(`home:collectedPoints`)} <strong>{payload[0].value}</strong>
            </p>
            <p style={{ color: payload[1].color }}>
              {t(`home:redeemedPoint`)} <strong>{payload[1].value}</strong>
            </p>
          </Tooltip>
        );
      }

      return null;
    },
    [t]
  );

  if (error || !Array.isArray(fetchedData))
    return (
      <h3 className="title">
        Opps, {JSON.stringify(error) || fetchedData} please try again later
      </h3>
    );

  return (
    <Layout className="chart r-v-c-chart card">
      <div className="chart-header">
        <h3>
          {t('points')} | <span className="secondary">{t('redeemed')}</span>{' '}
          {t('vs')} <span className="primary">{t('collected')}</span>
        </h3>
        <Tabs
          values={[
            { val: 'month', label: t('month') },
            { val: 'week', label: t('week') },
            { val: 'day', label: t('day') },
          ]}
          handleChange={handleChange}
          value={filterBy}
          bColor="#FFF2F3"
          borderColor={mainColor}
        />
      </div>

      {Array.isArray(fetchedData) && fetchedData?.length > 0 ? (
        <AreaChartV2
          xAxis="date"
          yAxis={yAxis}
          data={formatedData}
          toolTipContent={customTooltip}
        />
      ) : (
        <BoxLoader width={500} height={130} />
      )}
    </Layout>
  );
};

export default React.memo(RedeemedVsCollected);

const Layout = styled.div`
  grid-column: 1/3;

  h3 {
    font-weight: 500;
    font-size: 0.9rem;
  }
`;
