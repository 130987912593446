import {
  Box,
  FormControl,
  Hidden,
  ListItem,
  ListItemIcon,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DedcutionIcon } from '../../assets/svg/dedcution-history.svg';
import { ReactComponent as RedemptionIcon } from '../../assets/svg/redemption-history.svg';
import { ReactComponent as EarningIcon } from '../../assets/svg/earning-history.svg';
import { MuiSelect } from './index';

export const useVerticalTabStyles = makeStyles(() => ({
  root: {
    position: 'sticky',
    top: 92,
    '& .activeCampign': {
      backgroundColor: '#F6F7F8',
      '& path': {
        fill: '#1C1D21',
      },
    },
  },
  item: {
    maxHeight: 40,
    marginBottom: 8,
  },

  hidden: {
    display: 'none !important',
  },
  heading: {
    fontSize: '13px',
    fontWeight: '400 !important',
  },
  formControl: {
    width: '100%',
  },
}));

export const VerticalTabs = ({ activeTab, handleChange, tabs, title }) => {
  const classes = useVerticalTabStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <Box pt={4} pb={2} px={3}>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <Hidden smDown>
          <MenuList>
            {tabs.map((tab, idx) => {
              tab.label = t(tab.id);
              const isActiveTab = activeTab.id === tab.id;

              return (
                <MenuItem
                  key={tab.label}
                  onClick={() => handleChange(tab)}
                  className={
                    isActiveTab ? `${classes.item} activeCampign` : classes.item
                  }
                >
                  <ListItemIcon style={{ minWidth: 'fit-content' }}>
                    {idx === 0 && <EarningIcon />}
                    {idx === 1 && <DedcutionIcon />}
                    {idx === 2 && <RedemptionIcon />}
                  </ListItemIcon>
                  <ListItem spacing={0}>
                    <p style={{ fontSize: 14 }}>
                      {t(tab.id)} {tab?.value}
                    </p>
                  </ListItem>
                </MenuItem>
              );
            })}
          </MenuList>
        </Hidden>
        <Hidden mdUp>
          <FormControl
            variant="filled"
            className={classes.formControl}
            fullWidth
          >
            <MuiSelect
              fullWidth
              color="#8181A5"
              fontSize={14}
              disableUnderline
              optionLabel="label"
              optionValue="id"
              options={tabs}
              value={activeTab.id}
              defaultValue={tabs.id}
              onChange={(e) => {
                const newValue = tabs.filter((d) => d.id === e.target.value)[0];
                handleChange(newValue);
              }}
            />
          </FormControl>
        </Hidden>
      </Box>
    </Paper>
  );
};
