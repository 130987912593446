import styled from '@emotion/styled';
import { Box, Paper } from '@material-ui/core';
import { useNavigate } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import { putEarningAPI } from '../../api';
import { mq, usePost } from '../../utils';
import GrowthPlan from '../Common/GrowthPlan';
import { IOSSwitch } from '../Common/Toggle/styled';
import { useGlobalState } from '../GlobalState';
import { renderIcon } from '../SVG';

export const ActiveEarningItems = () => {
  const { user, isEnglish } = useGlobalState();
  const { data: { data } = { data: [] }, error }: any = useSWR(
    `/points-program/${user?.idMerchant}`
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, submitForm] = usePost({
    apiFunction: putEarningAPI,
    updateUser: false,
    alertOnSuccess: t('DataChangedSuccessfully'),
  });
  const { mutate } = useSWRConfig();

  const canFollowTiktok =
    user?.permissions?.['EarningPointsModule']?.['Tiktok Follow'];

  const canFollowSnapchat =
    user?.permissions?.['EarningPointsModule']?.['Snapchat Follow'];

  const canFollowTwitter =
    user?.permissions?.['EarningPointsModule']?.['Twitter Follow'];

  const canFollowInstagram =
    user?.permissions?.['EarningPointsModule']?.['Instagram Follow'];

  const canPurchase =
    user?.permissions?.['EarningPointsModule']?.['Points per Purchase'];

  const canCelebrateBirthDay =
    user?.permissions?.['EarningPointsModule']?.['Celebrate a Birthday'];

  const accessDenied = (idReward: number) => {
    switch (idReward) {
      case 29:
        return !canPurchase;
      case 21:
        return !canCelebrateBirthDay;
      case 24:
        return !canFollowTwitter;
      case 27:
        return !canFollowInstagram;
      case 32:
        return !canFollowTiktok;

      case 33:
        return !canFollowSnapchat;

      default:
        return false;
    }
  };

  const isSocialMediaMethod = (id: any) =>
    id == 24 || id == 27 || id == 32 || id == 33;

  return (
    <div className="right mr-bottom">
      <h3 className="title">{t('earningMethods:waysToEarn')}</h3>
      {error && <h3 className="title">Opps, {error} please try again later</h3>}
      <WaysGrid>
        {earningWays.map((item) => {
          const isPlaceNewOrder = item.idEarnWay === 29;

          return (
            <div key={item.idEarnWay} style={{ position: 'relative' }}>
              {!(item.idEarnWay === 20 || item.idEarnWay === 29) && (
                <GrowthPlan
                  style={{
                    position: 'absolute',
                    top: 5,
                    ...(isEnglish ? { left: 5 } : { right: 5 }),
                  }}
                />
              )}
              <ActivationToggle
                style={{
                  position: 'absolute',
                  top: 5,
                  ...(isEnglish ? { right: 5 } : { left: 5 }),
                }}
                disabled={accessDenied(item.idEarnWay)}
                onChange={async (_, checked) => {
                  const earningWay: any = data.find(
                    (way: any) => way.idEarnWay == item.idEarnWay
                  );

                  if (
                    isSocialMediaMethod(item.idEarnWay) &&
                    !earningWay?.wayUserName
                  ) {
                    return navigate(
                      `/programs/earning-ways/edit/${item.idEarnWay}`
                    );
                  }

                  await submitForm({
                    body: {
                      status: checked ? 1 : 0,
                      idEarnWay: item.idEarnWay,
                      ...((item.idEarnWay == 20 || item.idEarnWay == 21) && {
                        points: earningWay?.points ?? 1,
                      }),
                      ...((item.idEarnWay == 24 ||
                        item.idEarnWay == 27 ||
                        item.idEarnWay == 32 ||
                        item.idEarnWay == 33) && {
                        points: earningWay?.points ?? 1,
                        wayUserName: earningWay?.wayUserName ?? '',
                      }),
                      ...(isPlaceNewOrder && {
                        points: earningWay?.points ?? 1,
                        price: earningWay?.price ?? 1,
                        countOrderWhenCompleted:
                          earningWay?.countOrderWhenCompleted,
                      }),
                    },
                  });
                  mutate(`/points-program/${user?.idMerchant}`);
                }}
                value={
                  data?.find(
                    (methode: any) => methode.idEarnWay === item.idEarnWay
                  )?.status
                }
              />
              <Paper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: 0,
                  gap: '0.7rem',
                  padding: '26px',
                  cursor: accessDenied(item.idEarnWay)
                    ? 'not-allowed'
                    : 'pointer',
                  justifyContent: 'center',
                  height: 198,
                }}
                onClick={() => {
                  if (accessDenied(item.idEarnWay)) return;
                  navigate(`/programs/earning-ways/edit/${item.idEarnWay}`);
                }}
              >
                <div className="icon"> {renderIcon(item.name)}</div>
                <h3 className="title">
                  {isEnglish ? item.title_en : item.title_ar}
                </h3>
                <p className="secondary">
                  <strong style={{ color: '#111' }}>
                    {isPlaceNewOrder &&
                      `${
                        data?.find(
                          (methode: any) => methode.idEarnWay === item.idEarnWay
                        )?.price ?? 1
                      } ${user?.currency ? t(user.currency) : t('sar')} = `}
                    {`${
                      data?.find(
                        (methode: any) => methode.idEarnWay === item.idEarnWay
                      )?.points ?? 1
                    } ${t('points')}`}
                  </strong>
                </p>
              </Paper>
            </div>
          );
        })}
      </WaysGrid>
    </div>
  );
};

const WaysGrid = styled.div`
  width: 100%;
  display: grid;

  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(220px, 290px));
  grid-gap: 1em;
  white-space: nowrap;

  h3.title {
    font-size: 0.85rem;
  }

  > div,
  a {
    border: 1px solid #f0f0f3;
    > * {
      margin-bottom: 1em;
    }

    .icon {
      padding: 1em;
      min-width: 60px;
    }
  }

  ${mq('labtop')} {
    grid-template-columns: repeat(auto-fit, minmax(180px, 250px));
  }

  ${mq('phone')} {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
`;

export const earningWays = [
  {
    idEarnWay: 29,
    title_ar: 'إتمام طلب',
    title_en: 'Complete an order',
    name: 'placeOrder',
  },
  {
    idEarnWay: 20,
    title_ar: 'تسجيل في المتجر',
    title_en: 'Sign in store',
    name: 'signup',
  },
  {
    idEarnWay: 21,
    title_ar: 'احتفل بيوم ميلادك',
    title_en: 'Celebrate a Birthday',
    name: 'birthday',
  },
  {
    idEarnWay: 24,
    title_ar: 'تابعنا في تويتر',
    title_en: 'Twitter Follow',
    name: 'twitterFollow',
  },
  {
    idEarnWay: 27,
    title_ar: 'تابعنا في انستاغرام',
    title_en: 'Instagram Follow',
    name: 'instagramFollow',
  },
  {
    idEarnWay: 32,
    title_ar: 'تابعنا في التيك توك',
    title_en: 'Tiktok Follow',
    name: 'tiktokFollow',
  },
  {
    idEarnWay: 33,
    title_ar: 'تابعنا في السناب شات',
    title_en: 'Snapchat Follow',
    name: 'SnapchatFollow',
  },
];

const ActivationToggle = ({
  value,
  onChange,
  style,
  disabled,
}: {
  value: boolean;
  disabled: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  style?: React.CSSProperties | undefined;
}) => {
  return (
    <Box
      style={{ borderRadius: 4, ...style }}
      bgcolor={!!value && !disabled ? 'rgba(109, 210,48, 0.2)' : '#F2F2F6'}
      px={0.4}
    >
      <IOSSwitch
        disabled={disabled}
        checked={!!value}
        onChange={onChange}
        name="checked"
      />
    </Box>
  );
};
