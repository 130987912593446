import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import 'react-phone-input-2/lib/material.css';
import { useGlobalState } from '../../GlobalState';
import { RenderIcon } from '../../SVG';
import { Layout } from './styled';

export const PhoneNumber = ({ logo: Logo, setValue, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const { t } = useTranslation();
  const { isRtl } = useGlobalState();

  return (
    <Layout
      className="column"
      style={{ textAlign: 'start' }}
      error={!!errorText}
      isRtl={isRtl}
    >
      <label className="icons flex space-between">
        <RenderIcon logo={Logo} />
        <p style={{ margin: '0 8px' }}>{t('mobileNumber')}</p>
      </label>
      <ReactPhoneInput
        {...(isRtl && { localization: ar })}
        country="SA"
        jumpCursorToEnd={true}
        countryCodeEditable={false}
        onlyCountries={['sa']}
        inputProps={{
          maxLength: field?.value?.replace?.('966', '')?.startsWith('0')
            ? 17
            : 16,
        }}
        buttonStyle={{
          width: 40,
        }}
        inputStyle={{
          width: '100%',
          ...(isRtl && { direction: 'ltr', textAlign: 'end' }),
        }}
        helperText={errorText}
        error={!!errorText}
        value={field.value}
        onChange={(val, { dialCode, countryCode, name }) => {
          setValue({ val, dialCode, countryCode, country: name });
        }}
      />
      {errorText && <p className="error">{errorText}</p>}
    </Layout>
  );
};
