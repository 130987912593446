//@ts-nocheck
import styled from '@emotion/styled';
import {
  createStyles,
  makeStyles,
  TableCell,
  withStyles,
} from '@material-ui/core';

export const StyledTableCell = withStyles((theme) =>
  createStyles({
    root: {
      borderBottom: 'none',
      fontSize: '14px !important',
      cursor: ({ cursor }) => (cursor ? cursor : 'pointer'),
      textAlign: ({ isLeft }) => {
        return isLeft
          ? theme.direction === 'rtl'
            ? 'right !important'
            : 'left !important'
          : 'center';
      },
      whiteSpace: ({ noWrap }) => (noWrap ? 'nowrap' : 'normal'),
      padding: ({ padding }) => (padding ? padding : '16px 24px'),
      color: ({ color }) => (color ? color : ''),
      lineHeight: 2,

      '& a.ellipsis': {
        display: 'block',
        width: 115,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  })
)(TableCell);

export const useTableStyles = makeStyles((theme) => ({
  table: {
    border: ({ withBorder }) => (withBorder ? '1px solid #F6F7F8' : ''),
    minWidth: 650,
    borderRadius: 8,
    '& td': {
      padding: '16px 24px',
    },

    '& thead': {
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor ? backgroundColor : '#8181A5',
    },

    '& thead th': {
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor ? backgroundColor : '#8181A5',
      fontWeight: 300,
      color: ({ color }) => (color ? color : '#8181A5'),
      padding: '16px 24px',
      marginBottom: '0.5rem',
      whiteSpace: 'nowrap',
    },

    '& tbody': {
      '& th': {
        padding: '10px 25px',
      },
      '& td': {
        fontWeight: '400 !important',
      },
      '& p': {
        color: '#999',
        fontWeight: 400,
      },
    },
  },
}));

export const TableLayout = styled.div`
  display: grid;
  max-width: 100%;
  table th,
  td {
    text-align: center;
  }
  thead .sortable {
    &:after {
      content: '↑';
      display: inline-block;
      margin: 0 4px;
      opacity: 0;
    }
  }

  table .asc {
    &:after {
      content: '↑';
      opacity: 1;
    }
  }

  table .desc {
    &:after {
      content: '↓';
      opacity: 1;
    }
  }

  table tr a {
    color: #1c1d21;
  }
`;
