import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const Layout = styled.div`
  position: relative;

  label.icons {
    width: fit-content;
    z-index: 111;
    position: absolute;
    left: ${({ isRtl }) => (!isRtl ? '0%' : 'unset')};
    right: ${({ isRtl }) => (!isRtl ? 'unset' : '0%')};
    top: 0%;
    transform: translateY(-50%);
    cursor: pointer;

    p {
      white-space: nowrap;
    }
    .logo {
      min-width: unset !important;
      margin-right: 10px;
    }
  }

  .react-tel-input .form-control + div:before {
    left: 0;
  }

  .react-tel-input .form-control {
    border: 0;

    &:focus {
      border: 0;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .react-tel-input {
    position: relative;

    &:before {
      background-color: #eee !important;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      height: 0.5px;
      position: absolute;

      ${({ error }) =>
        error &&
        css`
          background-color: red !important;
          height: 2px;
        `};
    }

    .flag-dropdown {
      margin-right: ${({ isRtl }) => (isRtl ? '24px' : 'unset')};
    }
    .form-control {
      padding: 18.5px 58px !important;
      & + div:before {
        content: '';
        color: rgb(41, 34, 34);
        font-size: 13.5px;
        font-weight: 400;
        padding: 0;
      }
    }
  }
  p {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.66;
  }
`;
