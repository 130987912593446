export function setGetParam(key: any, value: any) {
  if (window.history.pushState) {
    var params = new URLSearchParams(window.location.search);
    params.set(key, value);
    var newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      params.toString();
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
}

export function getParam(key: any) {
  var params = new URLSearchParams(window.location.search);
  return params.get(key);
}
