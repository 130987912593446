import { Grid } from '@material-ui/core';
import React from 'react';
import useSWR from 'swr';
import { BoxLoader } from '../../components/Common';
import {
  CustomerActivity,
  CustomerStatistics,
  PersonalInfo,
} from '../../components/Segmentation';
import ProfileHeader from '../../components/Segmentation/profile/header';

const Index = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: any = Object.fromEntries(urlSearchParams.entries());
  const { data: { data, isSuccess } = { data: [], isSuccess: false } }: any =
    useSWR(`/customers/${params?.id}`);

  if (!data || (!data.length && !isSuccess))
    return <BoxLoader width={200} height={400} />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ProfileHeader
          idCustomer={params.id}
          blocked={data[0]?.personalInfo?.blocked}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={4} lg={3}>
            <PersonalInfo personalInfo={data[0]?.personalInfo} />
          </Grid>
          <Grid item sm={12} md={8} lg={9}>
            <CustomerStatistics statsDetails={data[0]?.statsDetails} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomerActivity customerId={params.id} />
      </Grid>
    </Grid>
  );
};

export default Index;
